import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';
import HeroSection from './HeroSection';
import FeatureCard from './FeatureCard';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const EatingHouseLicense = ({ height }) => {

  // Eating House License grid items
  const items = [
    {imagePath: "Grp.webp", title: 'Legal Operation', description: 'Ensures that your eating house operates legally and complies with local regulations.' },
    {imagePath: "Costeff.webp", title: 'Health Compliance', description: 'Helps maintain health and safety standards in your establishment.' },
    { imagePath: "flexi.webp", title: 'Customer Assurance', description: 'Builds trust with customers by adhering to legal and safety standards.' },
    {imagePath: "limltd.webp", title: 'Avoid Penalties', description: 'Prevents legal issues and penalties by ensuring proper licensing.' },
    { imagePath: "lessreg.webp",title: 'Streamlined Operations', description: 'Facilitates smooth operation without legal hindrances.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Operation -",
      description:"Guarantees that your eating house is operating within the law and meets all local regulations."
    },
    {
      imageright: "right.png",
      title:"Health Compliance -",
      description:"Ensures adherence to health and safety standards, contributing to a safer dining environment."
    },
    {
      imageright: "right.png",
      title:"Customer Assurance -",
      description:"Builds trust with customers by showcasing compliance with legal and safety standards."
    },
    {
      imageright: "right.png",
      title:"Avoid Penalties -",
      description:"Prevents potential legal issues and fines by maintaining proper licensing."
    },
    {
      imageright: "right.png",
      title:"Streamlined Operations -",
      description:"Facilitates smooth business operations without facing legal or regulatory barriers."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Regulatory Compliance -",
      description:"Requires adherence to strict health and safety regulations, which can be challenging to maintain."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost of Licensing -",
      description:"Involves costs related to obtaining and renewing the Eating House License."
    },
    {
      imageright: "dangerous.webp",
      title:"Inspection Requirements -",
      description:"Subject to periodic inspections by health authorities to ensure ongoing compliance."
    },
    {
      imageright: "dangerous.webp",
      title:"Operational Restrictions -",
      description:"Licensing may impose specific operational restrictions and standards."
    },
    {
      imageright: "dangerous.webp",
      title:"Penalties for Non-Compliance -",
      description:"Failure to comply with licensing requirements can result in penalties or closure."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description: "Valid identity proof of the owner, such as Aadhar card, PAN card, or passport."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Premises Proof",
      description: "Proof of ownership or lease agreement for the premises where the eating house operates."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Fire Safety Certificate",
      description: "Certificate confirming compliance with fire safety regulations for the premises."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "No Objection Certificate (NOC)",
      description: "NOC from the local police authority to operate an eating house in the specified location."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Health Trade License",
      description: "License from the local municipal corporation confirming adherence to health and safety regulations."
    }
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents including identity proof, premises proof, and fire safety certificate."
    },
    {
      title: "Complete Application",
      description: "Fill out the application form with accurate details about your eating house and its operations."
    },
    {
      title: "Submit Application",
      description: "Submit the completed application along with required documents to the local authority."
    },
    {
      title: "Inspection",
      description: "Undergo an inspection by health and safety authorities to verify compliance with regulations."
    },
    {
      title: "Receive License",
      description: "Upon successful inspection and verification, receive the Eating House License to operate legally."
    }
  ];
  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const elpoints = [
    {
      title:"Provide Identity Proof -",
      description:"Submit valid identity proof of the owner or business entity."
    },
    {
      title:"Submit Premises Proof -",
      description:" Provide proof of ownership or lease agreement for the premises."
    },
    {
      title:"Obtain Fire Safety Certificate -",
      description:" Ensure compliance with fire safety regulations and obtain the necessary certificate."
    },
    {
      title:"Acquire NOC from Police -",
      description:" Obtain a No Objection Certificate from the local police authority."
    },
    {
      title:"Health Trade License -",
      description:" Secure a health trade license from the municipal corporation for health and safety compliance."
    },
  ]

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Eating House License"
        ctaText="Get in touch"
        imagePath="./MSME.webp"
        children={<EatingTo />}
      />

      <div className='w-full flex flex-col px-3 md:px-8 '>
        <div id="overview" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>Eating House License Overview</h6>
          <p className='max-w-[1086px] text-center mt-4'>An Eating House License ensures that your food establishment meets all legal and safety requirements, allowing you to operate your eating house smoothly and without legal interruptions.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16' >

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is an Eating House License?</h6>

            <p className='mb-4'>An Eating House License is required to legally operate a food establishment such as a restaurant, café, or food truck. It ensures that the premises comply with health, safety, and fire regulations, providing a safe environment for customers.</p>
          </div>

          <div id="eligibility" className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Eating House License</h6>
            <p className='mb-4'>To be eligible for an Eating House License, you must:
            <ul className="list-disc">
              {elpoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
            </p>
          </div>

          <div className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Eating House License</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>


          <div id="documents" className='docs-req mt-16 '>
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Eating House License</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="steps" className='how-to-apply mt-16 '>
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Apply for Eating House License</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
          </div>

          <div id="why" className='why-choose my-5'>
            <h6 className='text-blue-500 text-lg font-semibold '>Why Choose Us for Eating House License?</h6>
            <p>We offer expert assistance in obtaining your Eating House License, ensuring compliance with all local regulations and facilitating a smooth application process.</p>
          </div>
        </div>
      </div>
      <ScrollToTopButton/>
    </>
  );
};

function EatingTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Obtaining an Eating House License is crucial for running a food business legally. Our streamlined process helps you secure the necessary license efficiently and ensures compliance with all regulations.
      </p>
      <p>Ensure smooth operations and legal compliance with our expert assistance in obtaining your Eating House License.</p>
    </>
  );
};

export default EatingHouseLicense;