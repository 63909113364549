import React from "react";

const Documentsrr = ({ imageDoc, imageDoc2,imageLine, title, para }) => {
  return (
    <div className=" relative  w-full md:max-w-[268px] md:max-h-[318px] text-center border-1  rounded-2xl overflow-visible">
      <img
        className="z-20 absolute top-[-30px] left-1/2 transform -translate-x-1/2 w-[119.24px] h-[103px]"
        src={imageDoc}
        alt=""
      />
      <div className="relative top-0 w-full h-[75px] overflow-clip rounded-b-full">
        <img className="w-full" src="/pankh.svg" alt="pankh" />
        <img
          className="absolute top-[-1px] left-1/2 transform -translate-x-1/2 w-[119px] h-[103p]"
          src={imageDoc2} 
          alt=""
        />
      </div>
      <div className="flex flex-col h-[150px] justify-around ">
      <h6 className="font-semibold text-sm text-[#125B9A] px-3 h-[20%] ">{title} </h6>
      <img className="place-content-center " src="/Line.png" alt="line" />
      <p className="font-normal text-sm h-[50%]">{para}</p>
      </div>
    </div>
  );
};

export default Documentsrr;
