import React from 'react'

const Page2 = () => {
  return (
    <div className='div3 w-[80%] flex flex-col '>

      <div className='pp'>
    <span className='text-[26px] font-semibold'>REFUND POLICY</span>
    <p>Last updated: 17-09-2024</p>


    
    </div>

    <div className='pp-c'>
      <h6>1.REFUND ELIGIBILITY</h6>

<p>1.1 Service Eligibility: Refunds may be issued solely at the discretion of DialT2F where it is determined that the service purchased from us has not been provided as described or is otherwise unsatisfactory due to a fault on our part.</p>
      
<p>1.2 Timeframe: Requests for refunds must be made within 30 days of the date of purchase or service delivery, whichever is applicable.</p>




      <h6 className='mt-2'>2. HOW TO REQUEST A REFUND</h6>
      <p>2.1 Contact: To request a refund, please contact DialT2F's customer service by 9910960606 at [customer service contact details]. You must provide detailed information regarding the reason for your refund request.</p>


      <p>2.2 Documentation: Depending on the nature of the refund request, you may be required to provide supporting documentation or evidence to support your claim.</p>

    
      <h6 className='mt-2'>3. REFUND PROCESSING</h6>
      <p>3.1 Evaluation: Once a refund request is received, it will be evaluated based on the circumstances provided and compliance with our refund policy.</p>

      <p>3.2 Approval or Rejection: We will notify you of the approval or rejection of your refund request. If approved, the refund will be processed within 30 days and will be issued using the original payment method, unless otherwise agreed.</p>

      <p>3.3 Non-Refundable Services: Certain services may be designated as non-refundable, and refunds will not be provided for such services unless otherwise specified.</p>

      <p>3.4 Government charges are not refundable.</p>

      <h6 className='mt-2'>4. CONTACT US</h6>

      <p>If you have any questions about our refund policy, please contact us at:</p>

      <p>IIBS SECURITIES PRIVATE LIMITED collaborated with RYCOON Financial Services Limited </p>

      <p>Address: D-24, Kalkaji, New Delhi, 110019</p>

      <p>Email: info@dialt2f.com</p>


  

    </div>
    </div>
  )
}

export default Page2

