import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import FeatureCard from './FeatureCard';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const SocietyRegistration = ({ height }) => {

  // Society Registration grid items
  const items = [
    { 
      imagePath: "Grp.webp",
      title: 'Legal Framework', 
      description: 'Understand the legal framework for establishing a society.' 
    },
    { 
      imagePath: "Costeff.webp",
      title: 'Draft Memorandum', 
      description: 'Prepare a memorandum of association outlining the society’s objectives and governance.' 
    },
    { 
      imagePath: "flexi.webp",
      title: 'Member Selection', 
      description: 'Select and recruit members to form the society.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'Registration Process',
      description: 'Follow the process for registering the society with relevant authorities.' },
    { 
      imagePath: "lessreg.webp",
      title: 'Compliance Requirements', 
      description: 'Ensure compliance with ongoing regulatory and reporting obligations.'
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Non-Profit Purpose - ",
      description:"Ideal for organizations focused on social, educational, or charitable purposes."
    },
    {
      imageright: "right.png",
      title:"Tax Exemptions - ",
      description:"Societies often qualify for tax exemptions on donations and income."
    },
    {
      imageright: "right.png",
      title:"Legal Entity -",
      description:"Provides a formal legal structure for managing activities and assets."
    },
    {
      imageright: "right.png",
      title:"Credibility -",
      description:"Enhances the credibility and legitimacy of the organization."
    },
    {
      imageright: "right.png",
      title:"Funding Opportunities -",
      description:"Access to various funding and grant opportunities available for societies."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complexity -",
      description:"Setting up and managing a society can involve complex legal requirements."
    },
    {
      imageright: "dangerous.webp",
      title:"Governance -",
      description:"Requires adherence to strict governance and operational rules."
    },
    {
      imageright: "dangerous.webp",
      title:"Regulatory Compliance -",
      description:"Must comply with specific regulations and reporting requirements."
    },
    {
      imageright: "dangerous.webp",
      title:"Limited Flexibility -",
      description:"Changes in objectives or structure can be difficult to implement."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Memorandum of Association",
      description: "A document outlining the society's objectives, rules, and governance."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "List of Members",
      description: "Details of the members, including their names and addresses."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Address",
      description: "Proof of address for the society's registered office and members."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description: "Identity proof of the founding members and office bearers."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "KYC Documents",
      description: "Know Your Customer (KYC) documents for verification purposes."
    }
  ];

  const steps = [
    {
      title: "Draft Memorandum",
      description: "Prepare a detailed memorandum outlining the society’s objectives and rules."
    },
    {
      title: "Select Members",
      description: "Recruit and finalize the list of founding members for the society."
    },
    {
      title: "File Registration Documents",
      description: "Submit the memorandum and other required documents with the relevant authorities."
    },
    {
      title: "Obtain Registration Certificate",
      description: "Receive the official certificate of registration from the authorities."
    },
    {
      title: "Compliance and Reporting",
      description: "Ensure compliance with legal requirements and submit periodic reports as required."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const spoints =[
    {
      title:"Draft Memorandum -",
      description:"Prepare a detailed memorandum outlining the society's objectives and governance structure."
    },
    {
      title:"Select Members -",
      description:"Recruit and finalize the list of founding members for the society."
    },
    {
      title:"Submit Registration Documents -",
      description:"File the memorandum and other required documents with the relevant authorities."
    },
    {
      title:"Obtain Registration Certificate -",
      description:"Receive the official certificate of registration from the authorities."
    },
    {
      title:"Compliance and Reporting -",
      description:"Ensure compliance with legal requirements and submit periodic reports."
    },
  ]

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Society Registration"
        ctaText="Get in touch"
        imagePath="./LLP_Icon.webp"
        children={<Societyto />}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8'>
        <div id="overview" className="LLPRegis flex flex-col w-full mt-16 items-center">
          <h6 className='text-2xl text-center font-semibold'>Society Registration</h6>
          <p className='max-w-[1086px] text-center mt-4'>Society Registration involves the formation of a legal entity for non-profit activities. It is ideal for organizations with a social, educational, or charitable purpose, providing a formal structure for managing and conducting activities.</p>
        </div>

        <div className=' mt-16 max-w-[972px] md:px-16' >

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Society Registration?</h6>

            <p className='mb-4'>Society Registration is the process of establishing a formal non-profit organization that operates for social, educational, or charitable purposes. This involves drafting a memorandum of association, selecting members, and complying with legal requirements.</p>
          </div>

          <div id="application" className='pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Application Process for Society Registration</h6>
            <p className='mb-4'>To register a society, follow these steps:
              <ul>
              {spoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
              </ul>
            </p>
          </div>

          <div className="mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features
            </h6>
            <div id='features' className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="Documents mt-16 ">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for LLP Registration
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>
            
            <div id="how" className="Howto mt-16">
              <div className="registration-steps">
                <h2 className="text-blue-500 text-lg font-semibold">How to Register</h2>
                <p >Follow these steps for a smooth registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
            </div>
        </div>
      </div>

    </>
  );
};

function Societyto() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
      Society Registration involves creating a formal non-profit entity dedicated to social, educational, or charitable purposes. Our service assists in drafting the necessary documents, recruiting members, and ensuring compliance with legal requirements.
      </p>

      <p>Get professional assistance in setting up a society that aligns with your goals.</p>
    </>
  );
}
export default SocietyRegistration;