import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "./LLPcard";
import "./LLPcardgrid.css";
import "./LLP.css";
import Section8 from "./servicesIMG/Section8.webp";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const Section8Navbar = ({ height }) => {

  const items = [
    {
      imagePath: "Grp.webp",
      title: "Non-Profit Organization",
      description:
        "Section 8 companies are formed with a charitable or social objective.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Tax Benefits",
      description:
        "Offers various tax exemptions for donations and income under specific conditions.",
    },
    {
      imagePath: "flexi.webp",
      title: "Limited Liability",
      description:
        "Provides limited liability to its members, protecting personal assets.",
    },
    {
      imagePath: "limltd.webp",
      title: "Government Regulation",
      description:
        "Subject to strict regulations by the Ministry of Corporate Affairs (MCA).",
    },
    {
      imagePath: "lessreg.webp",
      title: "Enhanced Credibility",
      description:
        "More credible than other non-profit organizations like trusts or societies.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [

    {
      imageright: "right.png",
      title:"Tax Benefits -",
      description:"Eligible for tax exemptions on donations and income under section 12A and 80G."
    },
    {
      imageright: "right.png",
      title:"Limited Liability -",
      description:"Protects the personal assets of members"
    },
    {
      imageright: "right.png",
      title:"Enhanced Credibility -",
      description:"Recognized as a legitimate and structured non-profit by the government."
    },
    {
      imageright: "right.png",
      title:"No Minimum Capital Requirement -",
      description:"Section 8 companies are not required to have a minimum capital."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Stringent Regulations -",
      description:"Must comply with various MCA regulations and guidelines."
    },
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming Process -",
      description:"Registration process is more complex and takes longer than other non-profits."
    },
    {
      imageright: "dangerous.webp",
      title:"Limited Profit Distribution -",
      description:"Profits cannot be distributed among members, only used for the charitable purpose."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "Aadhar card, Voter ID, Passport, or Driving License for all directors and members.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Residential Proof",
      description: "Recent documents like bank statements or utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport-size Photos",
      description: "With a white background.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Memorandum of Association (MOA)",
      description:
        "Clearly stating the charitable or social objective of the company.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Articles of Association (AOA)",
      description: "Defining the rules and regulations of the company.",
    },
  ];

  const steps = [
    {
      title: "Obtain Digital Signature Certificate (DSC)",
      description:
        "Mandatory for all directors to sign the incorporation forms.",
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      description: "All directors must have a valid DIN.",
    },
    {
      title: "Reserve a Name for Your Section 8 Company",
      description:
        "The name must clearly reflect the charitable or social objective.",
    },
    {
      title: "Draft MOA and AOA",
      description: "Clearly outlining the company’s objectives and rules.",
    },
    {
      title: "Submit Form INC-12",
      description: "Apply to the MCA for the Section 8 company license.",
    },
    {
      title: "Incorporation Filing",
      description:
        "Once the license is obtained, submit incorporation forms to the Registrar of Companies.",
    },
    {
      title: "Obtain Certificate of Incorporation",
      description:
        "Once approved, the RoC issues the certificate of incorporation.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];


  const points=[
    {
      title:"Non-Profit Organization:",
      description:"Established with charitable or social objectives."
    },
    {
      title:"Tax Benefits:",
      description:"Enjoy various tax exemptions under Section 12A and 80G."
    },
    {
      title:"Government Regulated:",
      description:"Must adhere to Ministry of Corporate Affairs regulations."
    },
  ];

  const elpoints= [
    {
      title:"Charitable Objective -",
      description:"Must have a clear objective related to charitable activities.",
    },
    {
      title:"Minimum 2 Members -",
      description:"A minimum of two members are required at the time of registration.",
    },
    {
      title:"Directors -",
      description:"At least two directors are mandatory, and they can also be members.",
    },

  ]
  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Sections 8 Company"
        ctaText="Get in touch"
        imagePath="./LLP_Icon.webp"
        children={<Sectionto />}
      />

      <div className="mainLLP w-full flex flex-col px-3 md:px-8 ">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Section 8 Registration with Ease
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Starting a non-profit organization? Section 8 companies provide a
            formal structure with credibility and tax benefits. Our experts at
            T2F simplify the registration process, ensuring compliance with all
            legalities and timely filings. Contact us today to embark on your
            social impact journey.
          </p>
        </div>

        <div id="secondLP" className="mt-16 max-w-[972px] md:px-16 ">
        <div className=" pr-2 pt-5 ">
          <h6 className="text-blue-500 text-lg font-semibold">
            What is a Section 8 Company?
          </h6>

          <p className="mb-4">
            Section 8 Companies are entities established with the objective of
            promoting charity, education, religion, or other social causes. They
            are regulated under the Companies Act, 2013 and offer a formal,
            structured way to operate as a non-profit organization.
          </p>
          <div>
            <ul>
            {points.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>

          <p>
            Section 8 companies operate on the principle of using profits to
            achieve charitable goals.
          </p>
        </div>

        <div id="el" className=" pr-2 mt-16 ">
          <h6 className="text-blue-500 text-lg font-semibold">
            Eligibility and Requirements for Registration
          </h6>
          <p className="mb-4">
            To register a Section 8 Company, the following criteria must be met:
            <ul>
            {elpoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </p>
        </div>

        <div className="mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>


          <div className="Documents mt-5">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed For Section 8 Company
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="how" className="Howto mt-16">
              <div className="registration-steps">
                <h2>How to Register</h2>
                <p >Follow these steps for a smooth registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
            </div>

        <div id="choose" className=" mt-5 w-full">
          <h6 className="text-blue-500 text-lg font-semibold">
            Why Choose Us?
          </h6>
          <p>
            With extensive experience in registering Section 8 Companies, our
            team ensures that your non-profit complies with all legalities and
            helps you secure necessary certifications like 80G and 12A. From
            drafting MOA/AOA to obtaining your certificate of incorporation, we
            handle the complexities so you can focus on making a social impact.
          </p>
        </div>
        </div>
      </div>
    </>
  );
};

function Sectionto() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Sections 8 Company A Section 8 Company is a non-profit organization
        dedicated to charitable or social purposes, recognized by the
        government.
      </p>

      <p>Join 5K+ Non-Profits Trusted with Section 8 Registration</p>
    </>
  );
}

export default Section8Navbar;
