import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const TradeLicenseNavbar = ({ height }) => {
  // Trade License grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Regulated by Authorities",
      description:
        "Trade licenses are issued and regulated by local municipal authorities.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Business Legitimacy",
      description:
        "Provides legal authorization to conduct business activities.",
    },
    {
      imagePath: "flexi.webp",
      title: "Renewable License",
      description:
        "Requires periodic renewal to ensure compliance with regulations.",
    },
    {
      imagePath: "limltd.webp",
      title: "Wide Scope",
      description:
        "Applicable to a variety of business activities and sectors.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Compliance Requirement",
      description: "Ensures compliance with local laws and regulations.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Authorization -",
      description:"Provides formal approval to operate a business legally."
    },
    {
      imageright: "right.png",
      title:"Enhanced Credibility -",
      description:"Boosts business reputation and trustworthiness."
    },
    {
      imageright: "right.png",
      title:"Compliance -",
      description:"Helps ensure that your business meets local regulations."
    },
    {
      imageright: "right.png",
      title:"Access to Public Contracts -",
      description:"Required for bidding on public contracts or tenders."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Renewal Process -",
      description:"Requires periodic renewal and may involve additional fees."
    },
    {
      imageright: "dangerous.webp",
      title:"Regulatory Compliance -",
      description:"Must adhere to local regulations and guidelines."
    },
    {
      imageright: "dangerous.webp",
      title:"Documentation -",
      description:"Involves submission of various documents which can be time-consuming."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Business Address",
      description: "Includes lease agreement or ownership documents.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description: "Recent government-issued ID of the applicant.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Document",
      description:
        "Proof of business registration or incorporation certificate.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport-size Photos",
      description: "Recent photographs of the applicant.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "For tax purposes.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "No Objection Certificate (NOC)",
      description:
        "From the local municipal authority or landlord if applicable.",
    },
  ];

  const steps = [
    {
      title: "Gather Required Documents",
      description: "Collect all necessary documents as per the guidelines.",
    },
    {
      title: "Fill Out Application Form",
      description:
        "Complete the trade license application form with accurate details.",
    },
    {
      title: "Submit Application",
      description:
        "Submit the completed application form and documents to the local authority.",
    },
    {
      title: "Pay Fees",
      description: "Pay the applicable processing fees for the trade license.",
    },
    {
      title: "Inspection",
      description:
        "An inspection may be conducted by the local authority if required.",
    },
    {
      title: "Receive Trade License",
      description:
        "Once approved, receive the trade license from the authority.",
    },
    {
      title: "Renewal",
      description:
        "Ensure to renew the trade license before its expiration date.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Trade License"
        ctaText="Get in touch"
        imagePath="./MSME.webp"
        children={<TradeTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8 ">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Trade License Made Simple with T2F
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Ready to establish a legitimate business with a trade license? Look
            no further than T2F. Our expertise ensures a smooth application
            process for your trade license, guiding you every step of the way.
            Contact us today to get started.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is a Trade License?
            </h6>

            <p className="mb-4">
              A Trade License is a formal document that authorizes businesses to
              operate legally within a specific area. Here’s why trade licenses
              are essential:
            </p>
            <div>
              <ul>
                <strong>Regulated by Authorities:</strong> Issued and regulated
                by local municipal authorities.
              </ul>

              <ul>
                <strong>Business Legitimacy:</strong> Provides legal
                authorization to conduct business activities.
              </ul>

              <ul>
                <strong>Renewable License:</strong> Requires periodic renewal to
                stay compliant.
              </ul>
            </div>

            <p>
              Trade licenses are crucial for ensuring that your business
              operations are recognized and compliant with local regulations.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Registration
            </h6>
            <p className="mb-4">
              To obtain a trade license, you need to fulfill the following
              criteria:
              <ul>
                <li>
                  <strong>Business Address -</strong> Proof of a valid business
                  address within the jurisdiction.
                </li>

                <li>
                  <strong>Business Registration -</strong> Valid registration or
                  incorporation certificate of the business.
                </li>

                <li>
                  <strong>Applicant’s ID -</strong> Government-issued identity
                  proof of the applicant.
                </li>
                <li>
                  <strong>Application Form -</strong> Complete the trade license
                  application form accurately.
                </li>
              </ul>
            </p>
          </div>

          <div className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of a Trade License
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>


            <div className="Documents my-12">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for Trade License Registration
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="how" className="Howto my-12">
              <div className="registration-steps">
                <h2 className="text-blue-500 text-lg font-semibold">How to Register</h2>
                <p>Follow these steps for a smooth registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}/>
                  ))}
                </div>
              </div>
            </div>

            <div id="choose" className="Why my-12">
              <h6 className="text-blue-500 text-lg font-semibold">
                Why Choose T2F for Your Trade License Registration
              </h6>
              <p>T2F is your trusted partner for obtaining a trade license:</p>
              <ul>
                <li>
                  <strong>Expert Guidance - </strong> Professional help with the
                  entire trade license application process.
                </li>

                <li>
                  <strong>Document Assistance - </strong> Assistance with
                  gathering and preparing required documents.
                </li>

                <li>
                  <strong>Regulatory Compliance -</strong> Ensure adherence to
                  local regulations and requirements.
                </li>

                <li>
                  <strong>Efficient Processing -</strong> Fast and efficient
                  processing of your trade license application.
                </li>

                <li>
                  <strong>Ongoing Support -</strong> Continuous support
                  throughout the registration and renewal process.
                </li>

                <li>
                  <strong>Fee Management -</strong> Assistance with fee payment
                  and submission procedures.
                </li>
              </ul>
            </div>
          </div>
        </div>
    </>
  );
};

function TradeTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        A Trade License is a legal document required to operate a business
        legally within a specific jurisdiction.
      </p>

      <p>10K+ Businesses Successfully Licensed</p>
    </>
  );
}
export default TradeLicenseNavbar;
