import React from 'react'

const Page4 = () => {
  return (
    <div className='div3 w-[80%] flex flex-col '>

      <div className='pp'>
    <span className='text-[26px] font-semibold'>DISCLAIMER</span>
    
    </div>

    <div className='pp-c'>
      <h6>1.INTRODUCTION</h6>

      <p>1.1 DialT2F we provides business advisory, taxation advisory and other services advisory through its platform and services. </p>
      <p>1.2 By using our platform and services, you agree to the terms of this disclaimer. If you do not agree with any part of this disclaimer, please do not use our platform or services. </p>

      
      <h6>2. NO PROFESSIONAL ADVICE</h6>

      <p>2.1 The information provided on our platform and through our services is for general informational purposes only. It is not intended to constitute professional advice of any kind.</p>
      
      <p>2.2 We do not provide legal, financial, tax, or any other professional advice. Any information provided is not a substitute for professional advice and should not be relied upon for making decisions. </p>

      
      <h6>LIMITATION OF LIABILITY</h6>

      <p>3.1 We strive to ensure the accuracy and reliability of the information provided on our platform. However, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability with respect to the platform or the information, products, services, or related graphics contained on the platform for any purpose.</p>
      
      <p>3.2 To the fullest extent permitted by law, we disclaim any and all liability for any direct, indirect, consequential, incidental, special, or punitive damages, losses, or expenses arising out of or in connection with the use of our platform or services, or reliance on any information provided on the platform. </p>

      <h6>4. THIRD-PARTY LINKS</h6>

      <p>4.1 Our platform may contain links to third-party websites or services that are not owned or controlled by us. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services.</p>
      
      <p>4.2 You acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites or services. </p>

      



      <h6 className='mt-2'>5. MODIFICATIONS</h6>
      <p>5.1 We reserve the right to modify, amend, or update this disclaimer at any time without prior notice. Any changes will be effective immediately upon posting on our platform. Your continued use of our platform and services following the posting of changes constitutes your acceptance of such changes.</p>


     
    
      <h6 className='mt-2'>6. CONTACT US</h6>
      <p>If you have any questions about this disclaimer, please contact us at: info@dialt2f.com</p>

     
        
    </div>
    </div>
  )
}

export default Page4

