import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import FeatureCard from './FeatureCard';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const InsuranceCompany = ({ height }) => {


  const items = [
    { 
      imagePath: "Grp.webp",
      title: 'What is an Insurance Company?', 
      description: 'An Insurance Company provides coverage and financial protection to individuals and businesses against various risks in exchange for premium payments.' 
    },
    { 
      imagePath: "Costeff.webp",
      title: 'Types of Insurance Companies', 
      description: 'Insurance companies offer various types of policies, including life, health, property, and casualty insurance, each catering to different needs.'
      },
    { 
      imagePath: "flexi.webp",
      title: 'Features of Insurance Companies', 
      description: 'Insurance companies provide risk management, financial security, and peace of mind through a range of insurance products and services.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'Eligibility for Insurance Companies', 
      description: 'Insurance companies must comply with regulatory requirements, including obtaining licenses and meeting capital requirements as per the insurance regulatory authority.' 
    },
    { 
      imagePath: "lessreg.webp",
      title: 'Regulatory Framework', 
      description: 'Insurance companies are regulated by specific laws and guidelines to ensure they operate fairly, maintain solvency, and protect policyholders.' 
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Financial Protection -",
      description:"Provides coverage against various risks and uncertainties."
    },
    {
      imageright: "right.png",
      title:"Risk Management -",
      description:"Helps individuals and businesses manage and mitigate financial risks."
    },

    {
      imageright: "right.png",
      title:"Peace of Mind -",
      description:"Offers assurance and security to policyholders in times of need."
    },
    {
      imageright: "right.png",
      title:"Diverse Products -",
      description:"Offers a wide range of insurance products to meet different needs."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Premium Costs -",
      description:"Insurance premiums can be expensive depending on the coverage and risk factors."
    },
    {
      imageright: "dangerous.webp",
      title:"Complex Policies -",
      description:"Insurance policies can be complex and difficult to understand."
    },
    {
      imageright: "dangerous.webp",
      title:"Regulatory Compliance -",
      description:"Insurance companies face stringent regulations and compliance requirements."
    },
    {
      imageright: "dangerous.webp",
      title:"Claim Disputes -",
      description:"There may be disputes or delays in claim processing and approval."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Incorporation Certificate",
      description: "Certificate of incorporation issued by the relevant regulatory authority."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "License to Operate",
      description: "Valid license from the insurance regulatory authority to operate as an insurance company."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Statements",
      description: "Detailed financial statements demonstrating the company's financial health and solvency."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Plan",
      description: "A comprehensive business plan outlining the insurance company's products, market strategy, and financial projections."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Risk Assessment Reports",
      description: "Reports assessing the risks covered by the insurance policies and the company's risk management strategies."
    }
  ];

  const steps = [
    {
      title: "Obtain License",
      description: "Apply for and obtain a license from the insurance regulatory authority to operate legally."
    },
    {
      title: "Draft Policies",
      description: "Develop and draft insurance policies that meet regulatory requirements and customer needs."
    },
    {
      title: "Set Up Operations",
      description: "Establish operational processes, including underwriting, claims processing, and customer service."
    },
    {
      title: "Launch Products",
      description: "Introduce and market insurance products to potential customers."
    },
    {
      title: "Maintain Compliance",
      description: "Ensure ongoing compliance with regulatory requirements and maintain financial solvency."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const points = [
    {
    
      title: 'Stable and Profitable Business Model',
      description: 'Insurance companies have a stable and predictable income stream through premiums, which offers significant profit potential and business growth opportunities.',
    },
    {
    
      title: 'Diverse Product Offerings',
      description: 'Insurance companies can provide a variety of products like life, health, vehicle, property, and business insurance, catering to different customer needs and diversifying revenue streams.',
    },
    {
    
      title: 'Regulatory Support and Oversight',
      description: 'Insurance companies operate under a regulated environment, ensuring credibility and gaining customer trust through compliance with industry standards and practices.',
    },
    {
    
      title: 'Access to Investment Opportunities',
      description: 'Insurance companies can invest premiums collected in various financial assets, including stocks and bonds, generating additional income and enhancing financial stability.',
    },
    {
      title: 'Long-Term Customer Relationships',
      description: 'Insurance companies benefit from long-term relationships with customers through policy renewals and extended services, ensuring customer retention and loyalty.',
    },
  ];
  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Insurance Company"
        ctaText="Get in touch"
        imagePath="./InsuranceCOmp.webp"
        children={<InsuranceTo />}
      />

      <div className='w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Insurance Company</h6>
          <p className='max-w-[1086px] text-center mt-4'>
            Offer financial protection and security through a range of well-regulated insurance products.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16 '>

          <div className=' pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is an Insurance Company?</h6>

            <p className='mb-4'>An Insurance Company provides coverage to mitigate financial risks in exchange for premium payments from policyholders.</p>
          </div>

          <div id="el" className='pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Insurance Companies</h6>
            <p className='mb-4'>Insurance companies must meet regulatory requirements, including obtaining necessary licenses and maintaining financial stability.</p>
          </div>

          <div id="features" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Insurance Companies</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className='text-blue-500 text-lg font-semibold'>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className='text-blue-500 text-lg font-semibold'>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Insurance Companies</h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Insurance Companies</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
          </div>

        <div id='choose' className=' mt-10'>
          <h6 className='text-lg font-semibold text-blue-500'>Why Choose</h6>
        <ul className="list-disc">
              {points.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
        </div>
              </div>
      </div>
    </>
  );
};
function InsuranceTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Provide comprehensive financial protection with a well-regulated insurance company.
      </p>

      <p>Protect individuals and businesses from financial risks through a diverse range of insurance products and services.</p>
    </>
  );
};
export default InsuranceCompany;