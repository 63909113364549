import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import HeroSection from "./HeroSection";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const PrepInMinutes = ({ height }) => {
  // Preparation in Minutes grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Quick Setup",
      description:
        "Get your documents and preparations done swiftly without delays.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Easy Instructions",
      description:
        "Follow straightforward steps for efficient preparation and submission.",
    },
    {
      imagePath: "flexi.webp",
      title: "Instant Access",
      description:
        "Access all required resources and templates instantly for quick preparation.",
    },
    {
      imagePath: "limltd.webp",
      title: "Expert Assistance",
      description:
        "Receive expert guidance to ensure everything is in order and ready for submission.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Efficient Process",
      description:
        "Streamline the preparation process to save time and avoid unnecessary complications.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Speed -",
      description:
        "Complete your preparation tasks in a fraction of the time with our efficient process.",
    },
    {
      imageright: "right.png",
      title: "Clarity -",
      description:
        "Clear instructions and expert guidance make the preparation process simple and straightforward.",
    },
    {
      imageright: "right.png",
      title: "Convenience -",
      description:
        "Access all necessary resources and templates quickly and easily.",
    },
    {
      imageright: "right.png",
      title: "Expert Help -",
      description:
        "Professional assistance ensures that your preparation is thorough and accurate.",
    },
    {
      imageright: "right.png",
      title: "Time-Saving -",
      description:
        "Streamlined processes minimize the time required for document preparation and submission.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Dependence on Tools -",
      description:
        " Requires access to specific tools or templates which might not be available to everyone.",
    },
    {
      imageright: "dangerous.webp",
      title: "Learning Curve -",
      description:
        "There might be a learning curve for those unfamiliar with the preparation process.",
    },
    {
      imageright: "dangerous.webp",
      title: "Potential Errors -",
      description:
        " Quick processes may sometimes lead to overlooked details or mistakes.",
    },
    {
      imageright: "dangerous.webp",
      title: "Limited Customization -",
      description:
        " Predefined templates might limit customization options for specific needs.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Preparation Checklist",
      description:
        "A comprehensive checklist to ensure you have all necessary documents and information ready.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Templates",
      description:
        "Access to various templates that can be used to streamline your preparation process.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Instruction Manual",
      description:
        "Detailed instructions on how to complete the preparation efficiently and correctly.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Expert Contact",
      description:
        "Contact details for professional assistance if needed during the preparation phase.",
    },
  ];

  const steps = [
    {
      title: "Review Requirements",
      description:
        "Understand the requirements and gather all necessary documents for preparation.",
    },
    {
      title: "Follow Instructions",
      description:
        "Use the provided instructions and templates to complete your preparation tasks.",
    },
    {
      title: "Verify Information",
      description:
        "Double-check all information and documents to ensure accuracy and completeness.",
    },
    {
      title: "Submit Documents",
      description:
        "Submit your prepared documents through the appropriate channels or platforms.",
    },
    {
      title: "Seek Assistance",
      description:
        "Contact experts if you encounter any issues or need further guidance during the process.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Preparation in Minutes"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<PrepTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Streamlined Preparation in Minutes
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our preparation process is designed to be quick and efficient,
            ensuring you can complete your tasks in minutes with minimal effort.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Preparation in Minutes?
            </h6>

            <p className="mb-4">
              Preparation in Minutes refers to an efficient and rapid approach
              to completing necessary documentation and tasks. Our process
              enables you to prepare everything swiftly while ensuring accuracy
              and completeness.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Quick Preparation
            </h6>
            <p className="mb-4">
              To benefit from our quick preparation services, ensure you have:
              <ul>
                <li>
                  <strong>Access to Required Documents -</strong> All necessary
                  documents and information ready for submission.
                </li>

                <li>
                  <strong>Follow Guidelines -</strong> Adhere to provided
                  instructions and utilize available templates effectively.
                </li>

                <li>
                  <strong>Timely Submission -</strong> Ensure that documents are
                  submitted within the specified time frame.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Our Preparation Services
            </h6>

            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-4">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req my-10 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Quick Preparation</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply my-10 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps for Quick Preparation</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-10">
            <h6 className="text-blue-500 text-lg font-semibold">
              Why Choose Us for Quick Preparation?
            </h6>
            <p>
              We offer a streamlined process to help you complete your
              preparations in minutes. Our expert team ensures that all steps
              are handled efficiently, allowing you to save time and avoid
              unnecessary complications.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function PrepTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Experience a streamlined preparation process that saves you time and
        effort. Our approach ensures that you complete your preparations quickly
        and accurately.
      </p>
      <p>
      Utilize our tools and expert assistance to get everything done in no time and avoid unnecessary delays.
      </p>
    </>
  );
}
export default PrepInMinutes;
