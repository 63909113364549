import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const MOAAmendment = ({ height }) => {
  // MOA Amendment grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Name Change",
      description:
        "Update your company’s name in the MOA to reflect new branding or legal requirements.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Address Update",
      description:
        "Amend your registered office address in the MOA for accurate correspondence.",
    },
    {
      imagePath: "flexi.webp",
      title: "Change in Objectives",
      description:
        "Modify the objectives of your company as per new business activities or strategic goals.",
    },
    {
      imagePath: "limltd.webp",
      title: "Share Capital Adjustment",
      description:
        "Revise the share capital details in the MOA to align with current financial structure.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Directors Update",
      description:
        "Update the details of directors in the MOA as per the latest appointments or resignations.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Compliance -",
      description:"Ensures that your company's MOA is in line with legal requirements and current business operations."
    },
    {
      imageright: "right.png",
      title:"Accurate Records -",
      description:"Maintains accurate and updated records for official and regulatory purposes."
    },
    {
      imageright: "right.png",
      title:"Improved Branding -",
      description:"Allows for changes in company name or objectives to align with new branding strategies."
    },
    {
      imageright: "right.png",
      title:"Financial Alignment -",
      description:"Updates share capital details to reflect the current financial status of the company."
    },
    {
      imageright: "right.png",
      title:"Enhanced Transparency -",
      description:"Keeps stakeholders informed with accurate information about the company’s directors and business activities."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Cost Implications -",
      description:"Amendments may incur costs related to legal and administrative fees."
    },
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming -",
      description:" The process of amending the MOA can be time-consuming and may affect business operations temporarily."
    },
    {
      imageright: "dangerous.webp",
      title:"Regulatory Procedures -",
      description:" Requires adherence to regulatory procedures which can be complex and require professional assistance."
    },
    {
      imageright: "dangerous.webp",
      title:"Possible Delays -",
      description:" Processing time for MOA amendments can vary, leading to potential delays in reflecting changes."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Resolution Copy",
      description:
        "A copy of the board resolution or shareholder resolution approving the MOA amendment.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Amended MOA",
      description:
        "The draft of the amended Memorandum of Association reflecting the proposed changes.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Company Registration Certificate",
      description:
        "The existing certificate of incorporation or registration of the company.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "Identity proof of the company’s directors or authorized signatories for verification purposes.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Address",
      description:
        "Proof of the new address if it involves a change in the registered office.",
    },
  ];

  const steps = [
    {
      title: "Draft Amendments",
      description:
        "Prepare the draft of the amended MOA including all proposed changes.",
    },
    {
      title: "Board Resolution",
      description:
        "Obtain approval for the amendments through a board or shareholders’ resolution.",
    },
    {
      title: "File with Authorities",
      description:
        "Submit the amended MOA and required documents to the relevant regulatory authorities.",
    },
    {
      title: "Update Records",
      description:
        "Update all company records and registers to reflect the changes made in the MOA.",
    },
    {
      title: "Communicate Changes",
      description:
        "Inform stakeholders and relevant parties about the amendments to ensure transparency.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title=" Streamline Your MOA Amendments with Us"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<MOATo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Efficient MOA Amendments for Your Company
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our MOA amendment services ensure that your company’s Memorandum of
            Association is updated accurately to reflect necessary changes. From
            name changes to address updates, we handle it all.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is MOA Amendment?
            </h6>

            <p className="mb-4">
              MOA Amendment involves making changes to the Memorandum of
              Association (MOA) of a company. This could include updates to the
              company’s name, address, objectives, share capital, or director
              details, in compliance with legal and business requirements.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for MOA Amendment
            </h6>
            <p className="mb-4">
              To be eligible for MOA amendment, your company must meet the
              following criteria:
              <ul>
                <li>
                  <strong>Board Approval -</strong> The amendments must be
                  approved by the company’s board of directors or shareholders.
                </li>

                <li>
                  <strong>Valid Reasons -</strong> There must be valid reasons
                  for the amendments, such as changes in business operations or
                  legal requirements.
                </li>

                <li>
                  <strong>Documentary Evidence -</strong> Relevant documents
                  supporting the need for amendments must be provided.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of MOA Amendment Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for MOA Amendment</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps for MOA Amendment</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for MOA Amendments?
            </h6>
            <p>
              Our team of experts ensures that your MOA amendments are handled
              with precision and efficiency. We assist you through every step of
              the process, making sure your company’s records are up-to-date and
              compliant with regulations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function MOATo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Need to amend your Memorandum of Association (MOA)? We offer
        comprehensive services to help you update your company's MOA
        efficiently and in compliance with legal requirements.
      </p>
      <p>
      Our expert team ensures that your MOA amendments are processed
      smoothly, minimizing disruptions to your business.
      </p>
    </>
  );
}

export default MOAAmendment;
