import React, { useState } from 'react';
import PropTypes from 'prop-types';
import 'tailwindcss/tailwind.css';
import { FaCheckCircle } from 'react-icons/fa';
import axios from 'axios';

const OtpModal = ({ showModal, handleClose }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [otpVerified, setOtpVerified] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    console.log("Modal status : ",showModal);
    

    const handleSendOtp = async () => {
        setLoading(true);
        setError('');

        // Generate a random OTP
        const otpCode = Math.floor(100000 + Math.random() * 900000);
        const message = `Your OTP is: ${otpCode}`;

        const payload = {
            customerId: 'IIBS_SECUR_ZQWmAj3hFdPicxLZXbW3', // Your customer ID
            destinationAddress: phoneNumber,
            message: message,
            sourceAddress: 'IIBS', // Your SMS header
            messageType: 'SERVICE_IMPLICIT', // Specify as SERVICE_IMPLICIT for OTP
            dltTemplateId: 'TEMPLATE_ID_HERE', // Replace with your actual DLT template ID
            entityId: '1001805185066474386', // Your unique entity ID
            otp: true, // Indicate this is an OTP
        };

        try {
            const response = await axios.post('/api/v1/send-sms', payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic ' + btoa('IIBS_SECUR_ZQWmAj3hFdPicxLZXbW3:NKzGaPgikQUQ5vIQ'), // Basic Auth
                },
            });

            console.log('OTP sent successfully:', response.data);
            setOtpSent(true);
            // Storing the OTP for verification
            localStorage.setItem('otp', otpCode.toString());
        } catch (error) {
            console.error('Error sending OTP:', error);
            setError('Failed to send OTP. Please try again.');
            setOtpSent(false); // Ensure OTP sent state is reset
        } finally {
            setLoading(false);
        }
    };

    const handleVerifyOtp = async () => {
        setLoading(true);
        setError('');

        // Retrieve the OTP from local storage for verification
        const storedOtp = localStorage.getItem('otp');

        if (otp === storedOtp) {
            setOtpVerified(true);
        } else {
            setError('Failed to verify OTP. Please try again.');
        }

        setLoading(false);
    };

    return (
        <>
            {showModal && (
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-96 relative">
                        <button 
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                            onClick={handleClose}
                        >
                            <span className="text-2xl">&times;</span>
                        </button>
                        {!otpVerified ? (
                            <>
                                <h2 className="text-xl font-bold mb-4">OTP Verification</h2>
                                <div className="mb-4">
                                    <label htmlFor="phoneNumber" className="block text-gray-700">Phone Number</label>
                                    <input
                                        type="text"
                                        id="phoneNumber"
                                        value={phoneNumber}
                                        onChange={(e) => setPhoneNumber(e.target.value)}
                                        className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                    />
                                    {phoneNumber && !/^\d{10}$/.test(phoneNumber) && (
                                        <p className="text-red-500 text-xs mt-1">Invalid phone number. Must be 10 digits.</p>
                                    )}
                                </div>
                                {!otpSent ? (
                                    <button
                                        onClick={handleSendOtp}
                                        disabled={!/^\d{10}$/.test(phoneNumber) || loading}
                                        className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 focus:outline-none"
                                    >
                                        {loading ? 'Sending...' : 'Send OTP'}
                                    </button>
                                ) : (
                                    <>
                                        <div className="mb-4">
                                            <label htmlFor="otp" className="block text-gray-700">Enter OTP</label>
                                            <input
                                                type="text"
                                                id="otp"
                                                value={otp}
                                                onChange={(e) => setOtp(e.target.value)}
                                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                                            />
                                        </div>
                                        <button
                                            onClick={handleVerifyOtp}
                                            disabled={!otp || loading}
                                            className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 focus:outline-none"
                                        >
                                            {loading ? 'Verifying...' : 'Verify OTP'}
                                        </button>
                                        {error && <p className="text-red-500 mt-2">{error}</p>}
                                    </>
                                )}
                                {error && !otpSent && (
                                    <p className="text-red-500 mt-2">{error}</p>
                                )}
                            </>
                        ) : (
                            <div className="flex flex-col items-center justify-center text-center">
                                <div className="bg-green-100 p-4 rounded-full mb-4">
                                    <FaCheckCircle className="text-green-500 text-4xl" />
                                </div>
                                <h3 className="text-lg font-semibold text-green-700 mb-2">OTP Verified Successfully!</h3>
                                <p className="text-gray-700">Thanks for reaching out!</p>
                                <p className="text-gray-700">We'll get back to you faster than you can say Support Superstar</p>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </>
    );
};

OtpModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default OtpModal;