import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const LUTNavbar = ({ height }) => {
  // LUT Filing service grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Tax Exemption",
      description:
        "Allows exporters to export goods and services without paying GST, ensuring a tax-free transaction.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Ease of Compliance",
      description:
        "Simplifies compliance with GST regulations for exporters by filing a Letter of Undertaking (LUT).",
    },
    {
      imagePath: "flexi.webp",
      title: "Quick Processing",
      description:
        "Expedited processing and approval of LUT applications, facilitating smooth export operations.",
    },
    {
      imagePath: "limltd.webp",
      title: "Cash Flow Management",
      description:
        "Helps in better cash flow management by avoiding the upfront payment of GST on exports.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Government Support",
      description:
        "Provides access to various government schemes and benefits available for exporters.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Tax Exemption -",
      description:"Exporters can export goods and services without the burden of paying GST, making transactions tax-free."
    },
    {
      imageright: "right.png",
      title:"Ease of Compliance -",
      description:"Filing LUT simplifies compliance with GST regulations for exporters, reducing administrative overhead."
    },
    {
      imageright: "right.png",
      title:"Quick Processing -",
      description:" Faster processing and approval of LUT applications help exporters manage their operations smoothly."
    },
    {
      imageright: "right.png",
      title:"Cash Flow Management -",
      description:"Avoids upfront GST payments, improving cash flow management for exporters."
    },
    {
      imageright: "right.png",
      title:"Government Support -",
      description:"Access to various government schemes and incentives available for exporters under the LUT framework."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Documentation -",
      description:"Requires meticulous documentation and adherence to GST regulations, which can be complex for some exporters."
    },
    {
      imageright: "dangerous.webp",
      title:"Compliance Risk -",
      description:"Incorrect filing or non-compliance can lead to penalties or rejection of LUT applications."
    },
    {
      imageright: "dangerous.webp",
      title:"Initial Processing Time -",
      description:"The initial approval process for LUT might take some time, which could impact the export schedule."
    },
    {
      imageright: "dangerous.webp",
      title:"Professional Fees -",
      description:"Costs may be incurred if professional assistance is needed for LUT filing and compliance."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "GST Registration Certificate",
      description:
        "A valid GST registration certificate of the business is required for LUT filing.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Export Invoices",
      description:
        "Copies of export invoices for the goods or services to be exported under the LUT.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Details",
      description:
        "Bank account details of the business for receiving payments and handling export transactions.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Export",
      description:
        "Documents proving the actual export of goods or services, such as shipping bills or export receipts.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Company PAN Card",
      description:
        "A valid PAN card of the company or business entity for identification and verification purposes.",
    },
  ];

  const steps = [
    {
      title: "Gather Documents",
      description:
        "Collect all necessary documents including GST registration, export invoices, and proof of export.",
    },
    {
      title: "Fill LUT Application",
      description:
        "Complete the LUT application form with accurate details about your export transactions and business.",
    },
    {
      title: "Submit Application",
      description:
        "Submit the LUT application online through the GST portal or to the relevant tax authorities.",
    },
    {
      title: "Receive Approval",
      description:
        "Await approval of the LUT from the tax authorities, which allows for tax-free export transactions.",
    },
    {
      title: "Maintain Records",
      description:
        "Maintain proper records of LUT filings and export transactions for future reference and compliance checks.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseLUTFiling = [
    {
      title: "Hassle-Free Filing",
      description: "Our streamlined process ensures a smooth and hassle-free LUT filing experience for exporters."
    },
    {
      title: "Compliance Expertise",
      description: "We stay up-to-date with compliance requirements, ensuring your LUT filing is accurate and fully compliant."
    },
    {
      title: "Time-Saving",
      description: "Our efficient process minimizes time and paperwork, so you can focus on growing your export business."
    },
    {
      title: "Ongoing Support",
      description: "We offer ongoing support for any issues related to LUT, including renewals and compliance guidance."
    }
  ];
  

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="LUT Filing for Exporters"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<LutTo/>}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            LUT Filing Made Easy
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Filing a Letter of Undertaking (LUT) ensures GST exemption on your
            export transactions. Our process simplifies the filing and
            compliance, allowing you to focus on your export business.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is LUT Filing?
            </h6>

            <p className="mb-4">
              LUT Filing refers to submitting a Letter of Undertaking to the tax
              authorities to claim GST exemption on exports. It allows exporters
              to avoid paying GST upfront on their export transactions, thereby
              facilitating smoother export operations.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for LUT Filing
            </h6>
            <p className="mb-4">
              To file an LUT, you need to:
              <ul>
                <li>
                  <strong>GST Registration Certificate -</strong> A valid GST
                  registration certificate for the business.
                </li>

                <li>
                  <strong>Export Invoices -</strong> Copies of export invoices
                  for the goods or services to be exported under the LUT.
                </li>

                <li>
                  <strong>Bank Account Details -</strong> Bank account details
                  of the business for handling export transactions.
                </li>

                <li>
                  <strong>Proof of Export -</strong> Documentation proving the
                  actual export of goods or services.
                </li>

                <li>
                  <strong>Company PAN Card -</strong> A valid PAN card of the
                  company or business entity.
                </li>
              </ul>
            </p>
          </div>

          <div className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of LUT Filing
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for LUT Filing</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Apply for LUT Filing</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseLUTFiling.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function LutTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Filing a Letter of Undertaking (LUT) is essential for exporters to ensure GST exemption on exports. Our streamlined process helps exporters navigate through LUT filing efficiently and ensures compliance with GST regulations.
      </p>
      <p>
      Thousands of exporters have streamlined their GST compliance with our expert assistance.
      </p>
    </>
  );
}

export default LUTNavbar;
