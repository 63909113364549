import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
import HeroSection from "./HeroSection";

const ITReturn7Navbar = ({ height }) => {
  // IT-Return 7 grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Income from Salary",
      description:
        "Detailed assistance with reporting income from salary, including allowable deductions and exemptions.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Income from Property",
      description:
        "Guidance on reporting income from property rentals, including deductions for maintenance and repairs.",
    },
    {
      imagePath: "flexi.webp",
      title: "Income from Investments",
      description:
        "Advice on reporting income from investments such as fixed deposits, bonds, and mutual funds.",
    },
    {
      imagePath: "limltd.webp",
      title: "Income from Capital Gains",
      description:
        "Instructions on reporting capital gains from the sale of assets, including the calculation of long-term and short-term gains.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Tax Credits and Deductions",
      description:
        "Overview of available tax credits and deductions to optimize your tax liability and ensure compliance.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Comprehensive Income Reporting -",
      description:"Covers various types of income including salary, property, investments, and capital gains."
    },
    {
      imageright: "right.png",
      title:"Detailed Guidance -",
      description:"Provides detailed instructions for reporting and optimizing different income sources."
    },
    {
      imageright: "right.png",
      title:"Maximized Tax Benefits -",
      description:"Helps in identifying and claiming all eligible tax credits and deductions."
    },
    {
      imageright: "right.png",
      title:"Clear Reporting Process -",
      description:"Simplifies the reporting process with clear instructions and expert advice."
    },
    {
      imageright: "right.png",
      title:"Regulatory Compliance -",
      description:"Ensures compliance with tax regulations and timely filing of returns."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complex Documentation -",
      description:"Requires detailed documentation for different types of income and deductions."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Errors -",
      description:"Risk of errors if not all income sources and deductions are accurately reported."
    },
    {
      imageright: "dangerous.webp",
      title:"Additional Costs -",
      description:" May incur additional costs for specialized tax advice and filing services."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Salary Slips",
      description:
        "Monthly salary slips or income statements from your employer for reporting salary income.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Property Rental Agreements",
      description:
        "Rental agreements and receipts for reporting income from property rentals.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Investment Statements",
      description:
        "Statements showing income from investments, including interest, dividends, and bonds.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Capital Gains Reports",
      description:
        "Reports or statements showing capital gains from the sale of assets such as property or shares.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Credit Certificates",
      description:
        "Certificates for tax credits and deductions claimed, such as TDS certificates and investment proofs.",
    },
  ];

  const steps = [
    {
      title: "Collect Income Documents",
      description:
        "Gather all necessary documents including salary slips, rental agreements, and investment statements.",
    },
    {
      title: "Assess Income Sources",
      description:
        "Review all sources of income and determine applicable deductions and exemptions.",
    },
    {
      title: "Complete IT-Return 7 Form",
      description:
        "Fill out the IT-Return 7 form with accurate information on income, deductions, and credits.",
    },
    {
      title: "File the Return",
      description:
        "Submit the completed IT-Return 7 form electronically or manually as per tax regulations.",
    },
    {
      title: "Verify Compliance",
      description:
        "Ensure compliance with all tax regulations and retain records of submission for future reference.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseITReturn7 = [
    {
      title: "Designed for Non-Profit Entities",
      description: "ITR-7 is specifically crafted for charitable trusts, religious organizations, and non-profits, ensuring their unique financial structures are accurately represented."
    },
    {
      title: "Compliance with Exemptions",
      description: "The form allows eligible entities to report income under exempt categories, helping non-profits claim exemptions and reduce tax liability."
    },
    {
      title: "Transparent Financial Reporting",
      description: "ITR-7 enables detailed financial disclosures, promoting transparency and trust with regulatory bodies and stakeholders."
    },
    {
      title: "Support for Government Grants and Donations",
      description: "This form allows non-profits to properly account for government grants, donations, and other funding sources, ensuring compliance."
    },
    {
      title: "Structured Record Keeping",
      description: "ITR-7 encourages organized financial reporting, aiding non-profits in maintaining systematic records for audit and compliance purposes."
    },
    {
      title: "Simplifies Complex Filing Requirements",
      description: "ITR-7 streamlines the filing process for entities with specialized income sources, such as religious or charitable institutions, making tax compliance easier."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="IT-Return 7: Expert Filing Services"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ItrSeven />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            IT-Return 7 Filing Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our IT-Return 7 services provide expert guidance on reporting income
            from salary, property, investments, and capital gains. Benefit from
            detailed assistance to ensure accurate reporting and optimize your
            tax benefits.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is IT-Return 7?
            </h6>

            <p className="mb-4">
              IT-Return 7 is designed for individuals with diverse income
              sources, including salary, property, investments, and capital
              gains. It provides a comprehensive approach to tax filing,
              ensuring all income sources are reported and deductions are
              claimed.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for IT-Return 7
            </h6>
            <p className="mb-4">
              To file IT-Return 7, you should meet the following criteria:
              <ul>
                <li>
                  <strong>Diverse Income Sources -</strong> Applicable if you
                  have income from salary, property, investments, and capital
                  gains.
                </li>

                <li>
                  <strong>Complete Documentation -</strong> Ensure you have all
                  required documentation for accurate reporting and deduction
                  claims.
                </li>

                <li>
                  <strong>Filing Requirements -</strong> Be aware of tax filing
                  requirements and deadlines for IT-Return 7.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of IT-Return 7
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for IT-Return 7
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              IT-Return 7 Filing Process
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold my-3">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseITReturn7.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function ItrSeven() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
    IT-Return 7 offers comprehensive filing solutions for reporting income from salary, property, investments, and capital gains. Our services help you navigate complex tax regulations and maximize your tax benefits.
      </p>
      <p>
      Efficiently handle your tax filing with IT-Return 7, ensuring all income sources are accurately reported and deductions are maximized.
      </p>
    </>
  );
}

export default ITReturn7Navbar;
