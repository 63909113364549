import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const OnePersonCompanyNavbar = ({ height }) => {
  // OPC service grid items
  const featuresCard = [
    {
      imagePath: "./Grp.webp",
      title: "Single Shareholder",
      description:
        "Only one person is required to form an OPC, making it ideal for sole entrepreneurs.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Limited Liability",
      description:
        "Shareholder has limited liability, protecting personal assets from business debts.",
    },
    {
      imagePath: "flexi.webp",
      title: "Separate Legal Entity",
      description:
        "The company is a separate legal entity from its owner, with its own legal rights and obligations.",
    },
    {
      imagePath: "limltd.webp",
      title: "Perpetual Succession",
      description:
        "The company continues to exist regardless of changes in ownership or management.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Simple Compliance",
      description:
        "Less compliance burden compared to a Private Limited Company.",
    },
  ];

  // Advantages and Disadvantages
  const advantagesList = [
    {
      imageright: "right.png",
      title: "Single Shareholder ",
      parag: "Protects personal assets from business liabilities.",
    },
    {
      imageright: "right.png",
      title: "Limited Liability",
      parag: "Protects personal assets from business liabilities.",
    },
    {
      imageright: "right.png",
      title: "Separate Legal Entity",
      parag: "The company is distinct from its owner.",
    },
    {
      imageright: "right.png",
      title: "Perpetual Succession",
      parag: "Continues to exist despite changes in ownership or management.",
    },
    {
      imageright: "right.png",
      title: "Simplified Compliance",
      parag:
        "Fewer compliance requirements compared to other company structures..",
    },
  ];

  const disadvantagesList = [
    {
      imageright: "dangerous.webp",
      title: "Limited Scope",
      parag:"Cannot be converted into a Public Company or carry out non-compliant business activities.",
    },
    {
      imageright: "dangerous.webp",
      title: "Single Ownership",
      parag: "Can be risky if the sole owner is unavailable or incapacitated.",
    },
    {
      imageright: "dangerous.webp",
      title: "Restricted Share Transfer",
      parag:
        "Shares cannot be freely transferred, which can limit flexibility.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof of Director",
      description: "Recent documents like bank statements or utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof of Director",
      description: "Passport, Aadhar card, or driving license.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport-size Photos",
      description: "With a white background.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "For the sole director.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Digital Signature Certificate (DSC)",
      description: "Required for the director.",
    },
  ];

  const steps = [
    {
      title: "Obtain a Digital Signature Certificate (DSC)",
      description: "Required for the sole director.",
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      description: "For the sole director.",
    },
    {
      title: "Choose a Company Name",
      description:
        "Ensure it meets legal requirements and is not already taken.",
    },
    {
      title: "Prepare and File Incorporation Documents",
      description:
        "Submit necessary documents and forms to the Registrar of Companies (RoC).",
    },
    {
      title: "Draft the Memorandum and Articles of Association",
      description: "Outline the company's objectives and internal rules.",
    },
    {
      title: "Receive Certificate of Incorporation",
      description: "Issued by the Registrar of Companies (RoC).",
    },
    {
      title: "Apply for PAN and TAN",
      description: "Essential for tax purposes.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const opcPoints = [
    {
      title:"Single Shareholder",
      description:"An OPC requires only one shareholder, making it ideal for sole proprietors.",
    },
    {
      title:"Limited Liability",
      description:"The shareholder’s liability is limited to their investment in the company.",
    },
    {
      title:"Separate Legal Entity",
      description:"The company is a separate legal entity from its owner, with its own legal rights and obligations.",
    },
    {
      title:"Perpetual Succession",
      description:"The company continues to exist independently of changes in ownership or management.",
    },
    {
      title:"Simplified Compliance",
      description:"OPCs face fewer compliance requirements compared to other company structures.",
    },
  ]

  const elPoints = [
    {
      title: "Indian Citizen ",
      description:"The sole shareholder and director must be an Indian citizen and resident in India.",
    },
    {
      title: "Single Shareholder",
      description:"Only one shareholder is required to form an OPC.",
    },
    {
      title: "Minimum Capital Requirement",
      description:"There is no minimum capital requirement for OPCs, but authorized capital must be mentioned.",
    },
    {
      title: "Director",
      description:"The sole director can also be the sole shareholder.",
    },
    {
      title: "Unique Company Name",
      description:"The name should be unique and include “One Person Company” or “OPC” at the end.",
    },
  ];

  const chooseOpc =[
    {
      title :"Expert Consultation",
      description:"Personalized guidance to help you set up your OPC.",
    },
    {
      title :"Name Reservation",
      description:"We’ll assist in reserving your desired company name.",
    },
    {
      title :"Comprehensive Documentation",
      description:"Help with preparing and filing all necessary documents.",
    },
    {
      title :"DSC and DIN Assistance",
      description:"Facilitation of obtaining digital signatures and identification numbers.",
    },
    {
      title :"PAN and TAN Application",
      description:"Assistance with applying for tax IDs.",
    },
    {
      title :"Ongoing Support",
      description:" Continued support throughout the registration process and beyond.",
    },
  ]

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="One Person Company (OPC)"
        ctaText="Get in touch"
        imagePath="./OPC.webp"
        children={<OnePC />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="overview" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            One Person Company Registration Made Easy with T2F
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Looking to start a business with complete control? A One Person
            Company (OPC) allows you to be the sole owner while enjoying the
            benefits of a separate legal entity and limited liability. T2F
            simplifies the registration process, ensuring a smooth experience
            from documentation to compliance. Get in touch with us to get
            started.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="w-auto h-[15%] pr-2 pt-5 ">
            <h6 className="text-blue-500 text-2xl font-semibold">
              What is a One Person Company (OPC)?
            </h6>

            <p className="mb-4">
              A One Person Company (OPC) is a business structure that allows a
              single individual to operate a company with limited liability. Key
              characteristics include:
            </p>
            <div>
            <ul className="list-disc">
              {opcPoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
            </div>
            <p>
              OPCs are governed by the Companies Act and are suitable for
              individuals who want to enjoy the benefits of a company structure
              while maintaining full control.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Registration
            </h6>
            <p className="mb-4">
              To register a One Person Company (OPC) in India, you need to
              fulfill the following criteria:
            </p>
            <ul className="list-disc">
              {elPoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of a One Person Company
            </h6>

            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {featuresCard.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantagesList.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.parag}
                />
              ))}
            </div>
            <div className="">
              <h2>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantagesList.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.parag}
                  />
                ))}
              </div>
            </div>
          </div>
          </div>
        

        <div id="why" className="secondLLP mt-16 max-w-[972px] md:px-16 ">
          <div className="Documents">
            <h6 className="text-blue-500 text-lg font-semibold">
              Documents Needed for One Person Company Registration
            </h6>
            <p>Here’s what you’ll need:</p>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className="Howto mt-16">
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth OPC registration process:</p>
              <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
            </div>
          </div>

          <div id="choose" className="Why mt-12">
            <h6 className="text-blue-500 text-lg font-semibold">
              Why Choose T2F for your One Person Company Registration
            </h6>
            <p>
              T2F is your trusted partner for One Person Company registration:
            </p>
            <ul className="list-disc">
              {chooseOpc.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
function OnePC() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        A One Person Company (OPC) is a business structure that allows a single
        individual to establish a company, providing limited liability and
        separate legal entity status.
      </p>

      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
}

export default OnePersonCompanyNavbar;
