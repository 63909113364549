import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
import HeroSection from "./HeroSection";

const GenerateForm16 = ({ height }) => {
  // Form 16 grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Understand Form 16",
      description:
        "Form 16 is a certificate issued by an employer, summarizing the tax deducted and paid to the government on behalf of an employee. It is essential for filing income tax returns.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Gather Required Details",
      description:
        "Collect all necessary documents and details, including salary slips, tax deductions, and employer information to generate Form 16 accurately.",
    },
    {
      imagePath: "flexi.webp",
      title: "Generate Form 16",
      description:
        "Use tax software or consult with your payroll department to generate Form 16 based on the gathered details and ensure all fields are correctly filled.",
    },
    {
      imagePath: "limltd.webp",
      title: "Verify Information",
      description:
        "Check the generated Form 16 for accuracy, ensuring that all the information, including tax deductions and personal details, is correct.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Distribute Form 16",
      description:
        "Provide the completed Form 16 to employees for their records and to facilitate the filing of their income tax returns.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Accurate Documentation -",
      description:"Provides a precise record of tax deductions and payments made, essential for accurate tax filing."
    },
    {
      imageright: "right.png",
      title:"Employer Compliance -",
      description:"Ensures that employers meet their tax obligations and provide necessary documentation to employees."
    },
    {
      imageright: "right.png",
      title:"Ease of Tax Filing -",
      description:"Simplifies the process of filing income tax returns for employees by providing a consolidated tax statement."
    },
    {
      imageright: "right.png",
      title:"Financial Transparency -",
      description:"Promotes transparency in salary and tax deductions, helping employees understand their earnings and tax liabilities."
    },
    {
      imageright: "right.png",
      title:"Legal Requirement -",
      description:" Meets legal requirements for tax documentation and helps avoid potential penalties for non-compliance."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complexity - ",
      description:"Generating Form 16 can be complex, requiring accurate input of various tax-related details."
    },
    {
      imageright: "dangerous.webp",
      title:"Errors -",
      description:"Mistakes in Form 16 can lead to discrepancies and issues during tax filing."
    },
    {
      imageright: "dangerous.webp",
      title:"Dependence on Employer -",
      description:"Employees depend on their employers to generate and provide Form 16, which may cause delays if not done promptly."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost -",
      description:"There may be additional costs associated with tax software or professional services for generating Form 16."
    },
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming -",
      description:"The process of collecting details and generating Form 16 can be time-consuming, especially for large organizations."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Salary Slips",
      description:
        "Monthly salary slips that provide details of salary payments, deductions, and other relevant information for generating Form 16.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Deduction Records",
      description:
        "Records of tax deductions made by the employer during the financial year, including any additional deductions or exemptions.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Employer Details",
      description:
        "Information about the employer, including name, address, and PAN, required for accurate completion of Form 16.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Details",
      description:
        "Personal Identification Number (PAN) details of the employee, necessary for generating and verifying Form 16.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Income Details",
      description:
        "Complete details of the employee's income, including salary, bonuses, and any other taxable income for accurate Form 16 preparation.",
    },
  ];

  const steps = [
    {
      title: "Collect Documentation",
      description:
        "Gather all necessary documents and details required for generating Form 16, including salary slips and tax deduction records.",
    },
    {
      title: "Prepare Details",
      description:
        "Compile the collected information and prepare it for entry into the tax software or documentation system.",
    },
    {
      title: "Generate Form 16",
      description:
        "Use the prepared details to generate Form 16 using tax software or with the help of your payroll department.",
    },
    {
      title: "Verify Accuracy",
      description:
        "Review the generated Form 16 for any errors or discrepancies and ensure all details are correct before distribution.",
    },
    {
      title: "Distribute Form 16",
      description:
        "Provide the completed Form 16 to employees for their records and use in income tax return filing.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseForm16 = [
    {
      title: "Proof of Income and Tax Paid",
      description: "Form 16 serves as an official document for salaried individuals, providing proof of income earned and taxes deducted by the employer."
    },
    {
      title: "Simplifies Income Tax Filing",
      description: "Form 16 offers detailed income and tax information, making it easier to file accurate income tax returns and claim deductions."
    },
    {
      title: "Essential for Loan Applications",
      description: "Lenders often require Form 16 as proof of income for salaried individuals, facilitating loan approvals and enhancing credibility."
    },
    {
      title: "Clarifies Tax Deductions",
      description: "It provides a breakdown of tax deductions under various sections, helping employees understand how their tax was computed."
    },
    {
      title: "Useful for Financial Planning",
      description: "Form 16’s detailed salary and tax information assists in financial planning by offering insight into earnings, deductions, and tax liabilities."
    },
    {
      title: "Assists in Claiming Tax Refunds",
      description: "If excess tax was deducted, Form 16 helps in accurately claiming refunds by providing clear records of taxes already paid."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Generate Form 16"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<Form16 />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Generate Form 16
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our Form 16 generation services ensure accurate and timely
            preparation of this crucial document for employees. We assist in
            streamlining the process and ensuring compliance with tax
            regulations.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Form 16?
            </h6>

            <p className="mb-4">
              Form 16 is a certificate issued by an employer to an employee,
              summarizing the total salary paid and the tax deducted during the
              financial year. It serves as proof of tax payments and is
              necessary for filing income tax returns.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Form 16
            </h6>
            <p className="mb-4">
              Employees who have received salary from an employer and had tax
              deducted at source are eligible for Form 16. It is issued annually
              and should be provided to all employees whose tax has been
              deducted by the employer.
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Features of Form 16
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Form 16 Generation
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Process for Generating Form 16
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold my-3">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseForm16.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function Form16() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
    Form 16 is an essential document for employees, summarizing the tax deducted and paid on their behalf by the employer. It plays a crucial role in filing income tax returns and ensures accurate tax documentation.
      </p>
      <p>
      Efficiently generate Form 16 with our services to streamline tax documentation and compliance.
      </p>
    </>
  );
}

export default GenerateForm16;
