import React from 'react';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';
const ProprietorshipNavbar = ({ height }) => {

  // Proprietorship service grid items
  const featuresCard = [

    { 
      imagePath: "./Grp.webp",
      title: 'Single Ownership',
      para: 'A proprietorship firm is owned and managed by one person.' 
    },
    {
      imagePath: "Costeff.webp",
      title: 'Easy Setup', 
      para: 'Setting up a proprietorship firm is quick and hassle-free.' 
    },
    { 
      imagePath: "flexi.webp",
      title: 'Minimal Compliance', 
      para: 'Fewer regulatory compliances compared to other business structures.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'Complete Control', 
      para: 'The owner has full control over the business operations and profits.' 
    },
    {
      imagePath: "lessreg.webp",
      title: 'Tax Benefits', 
      para:'Profits are taxed as personal income, potentially reducing tax liabilities.' 
    },
  ];

  // Advantages and Disadvantages
  const advantageList = [

    {
      imageright: "right.png",
      title: "Full Control",
      parag: "The proprietor has complete control over decision-making and business operations.",
    },
    {
      imageright: "right.png",
      title: "Easy Setup",
      parag:"Minimal legal formalities and registration requirements make it easy to start.",
    },
    {
      imageright: "right.png",
      title: "Low Operational Costs",
      parag: "Fewer regulatory compliances help reduce operational costs.",
    },
    {
      imageright: "right.png",
      title: "Tax Benefits",
      parag: "Proprietors can take advantage of personal tax benefits.",
    },

  ];
  const disadvantagesList = [
    {
    imageright: "dangerous.webp",
    title: "Unlimited Liability",
    parag: "The proprietor is personally liable for all business debts.",
  },
  {
    imageright: "dangerous.webp",
    title: "Limited Growth Potential",
    parag:"Raising capital or expanding the business can be challenging.",
  },
  {
    imageright: "dangerous.webp",
    title: "No Legal Distinction ",
    parag:"The proprietor and the business are legally considered the same entity.",
  },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      para: "A copy of the PAN card of the proprietor is required."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      para: "Includes passport, voter ID, or Aadhaar card."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof",
      para: "Recent documents like utility bills, rental agreements, or bank statements."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Address Proof",
      para: "Proof of the business’s operational address, such as utility bills."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Details",
      para: "A copy of the bank account statement for the business account."
    }
  ];

  const steps = [
    {
      title: "Choose a Business Name",
      para: "Ensure the chosen business name is unique and represents the services offered."
    },
    {
      title: "Obtain PAN for the Business",
      para: "Use the PAN of the proprietor for tax registration."
    },
    {
      title: "Open a Current Bank Account",
      para: "Open a bank account in the business name for transactions."
    },
    {
      title: "Register for GST",
      para: "If applicable, register for Goods and Services Tax (GST)."
    },
    {
      title: "Obtain Business Licenses",
      para: "Depending on the nature of your business, you may need specific licenses."
    },
    {
      title: "Commence Business Operations",
      para: "Once all necessary documents and licenses are in place, begin your operations."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className='sticky z-20' style={{"top":`${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
        <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Proprietorship Firm"
        ctaText="Get in touch"
        imagePath="./Propfirm.webp"
        children={<Proprietorship />}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>Proprietorship Registration Made Easy</h6>
          <p className='max-w-[1086px] text-center mt-4'>Setting up a proprietorship firm with us is a straightforward process. From selecting a business name to starting operations, we provide comprehensive support to get your firm up and running quickly.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16' >

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Proprietorship Firm?</h6>

            <p className='mb-4'>A proprietorship firm is a simple business structure where a single individual owns and controls the business. It is ideal for small and medium enterprises due to its ease of setup and minimal regulatory requirements.</p>
          </div>

          <div id="el" className='pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className=''>To register a proprietorship firm in India, you need to meet the following criteria:

              <ul className='list-disc'>
                <li>
                  <p><strong>Single Owner -</strong> The business is solely owned and managed by one person.</p></li>

                <li>
                  <p><strong>Business Name -</strong> The name should not infringe on any trademarks and should reflect your business.</p></li>

                <li>
                  <p><strong>PAN Card -</strong> You must have a valid PAN card for registration.</p></li>

                <li>
                  <p><strong>Address Proof -</strong> Provide proof of your business and personal address.</p></li>
              </ul>
            </p>
          </div>

          <div className='pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Proprietorship Firm
            </h6>

            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {featuresCard.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.para}
                />
              ))}
            </div>
          
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantageList.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.parag}
                />
              ))}
            </div>
            <div className="">
              <h2>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantagesList.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.parag}
                  />
                ))}
              </div>
            </div>
          </div>
          <div id="why" className='why-choose mt-16'>
            <h2>Documents Required for Proprietorship Firm Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.para}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='how-to-apply mt-16 mb-6 '>
            <h2>Steps to Register a Proprietorship Firm</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 ">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.para}
                    />
                  ))}
                </div>
          </div>
        </div>
      </div>
    </>
  );
};

function Proprietorship (){
  return(
    <>
    <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              A proprietorship firm is one of the simplest forms of business structure owned and operated by an individual.
            </p>

            <p>Thousands of proprietorship firms have successfully registered with us.</p>
    </>
  )
}
export default ProprietorshipNavbar;