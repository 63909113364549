import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import FeatureCard from './FeatureCard';
import Advantages from './llp/Advantages';
import Disadvantages from './llp/Disadvantages';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const PTRNavbar = ({ height }) => {

  // Professional Tax Registration service grid items
  const items = [
    { imagePath: "Grp.webp",title: 'Tax Compliance', description: 'Ensures compliance with local tax regulations for businesses and individuals.' },
    {imagePath: "Costeff.webp", title: 'Legal Requirements', description: 'Meets the legal requirements for professional tax registration in your state.' },
    { imagePath: "flexi.webp", title: 'Simplified Process', description: 'Streamlines the process of registering for professional tax with minimal hassle.' },
    {imagePath: "limltd.webp", title: 'Expert Assistance', description: 'Provides expert guidance to navigate the registration and filing processes.' },
    {imagePath: "lessreg.webp", title: 'Timely Updates', description: 'Keeps you updated with any changes in tax laws and regulations.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Tax Compliance -",
      description:"Ensures that your business or profession is compliant with local tax regulations."
    },
    {
      imageright: "right.png",
      title:"Legal Requirements -",
      description:"Fulfills legal requirements for professional tax registration, avoiding potential penalties."
    },
    {
      imageright: "right.png",
      title:"Simplified Process -",
      description:"Makes the registration process straightforward and manageable."
    },
    {
      imageright: "right.png",
      title:"Expert Assistance -",
      description:"Access to professional guidance to handle tax-related queries and issues."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Costs -",
      description:"There may be costs associated with registration and ongoing compliance."
    },
    {
      imageright: "dangerous.webp",
      title:"Administrative Work -",
      description:"Requires ongoing administrative work to stay compliant with tax regulations."
    },
    {
      imageright: "dangerous.webp",
      title:"Complexity -",
      description:" Can be complex depending on the state regulations and specific business needs."
    }
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Identity",
      description: "A valid government-issued identity proof such as Aadhar card or passport is required."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Address",
      description: "Proof of residence, such as a utility bill or bank statement, needs to be provided."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Documents",
      description: "Documents related to the business registration, such as the Certificate of Incorporation."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "A valid PAN card of the business or individual is needed for verification."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Professional Details",
      description: "Details related to the nature of business or profession must be provided."
    }
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents required for professional tax registration."
    },
    {
      title: "Check Eligibility",
      description: "Ensure that your business or profession meets the eligibility criteria for professional tax registration."
    },
    {
      title: "Apply Online",
      description: "Submit the professional tax registration application online through the relevant state portal."
    },
    {
      title: "Verification",
      description: "Complete any verification processes required by the state authorities."
    },
    {
      title: "Receive Registration",
      description: "After successful verification, receive your professional tax registration certificate."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyTaxData = [
    {
      title: "Legal Compliance",
      description: "Ensure your business complies with state regulations by registering for professional tax.",
    },
    {
      title: "Easy Application",
      description: "Simple and user-friendly process for quick registration.",
    },
    {
      title: "Secure Processing",
      description: "Confidential handling of all your business information during the registration process.",
    },
    {

      title: "Expert Guidance",
      description: "Professional assistance to navigate through the registration process smoothly.",
    },
    {
      title: "Affordable Services",
      description: "Cost-effective services without compromising on quality.",
    },
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": '${height}px' }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Professional Tax Registration"
        ctaText="Get in touch"
        imagePath="./ESI.webp"
        children={<PTRTo />}
      />

      <div className='w-full flex flex-col px-3 md:px-8'>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>Professional Tax Registration Made Easy</h6>
          <p className='max-w-[1086px] text-center mt-4'>Professional Tax Registration is crucial for compliance with local tax regulations. We make the registration process simple and efficient, ensuring you meet all necessary requirements.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16 ' >

          <div className='pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Professional Tax?</h6>

            <p className='mb-4'>Professional Tax is a tax imposed by the state governments on professionals, trades, and employees. It ensures compliance with local regulations and is required for businesses and individuals who earn income through professional activities.</p>
          </div>

          <div id="el" className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Professional Tax Registration</h6>
            <p className='mb-4'>To register for Professional Tax, you need to meet the following requirements:
              <ul>
                <li><strong>Proof of Identity -</strong> Provide a valid government-issued identity proof such as an Aadhar card or passport.</li>

                <li><strong>Proof of Address -</strong> Submit proof of residence, such as a utility bill or bank statement.</li>

                <li><strong>Business Registration Documents -</strong> Provide documents related to business registration, such as the Certificate of Incorporation.</li>

                <li><strong>PAN Card -</strong> A valid PAN card of the business or individual is needed for verification.</li>

                <li><strong>Professional Details -</strong> Details related to the nature of business or profession must be provided.</li>
              </ul>
            </p>
          </div>

          <div className='pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Professional Tax Registration</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-5">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 '>
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Professional Tax Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className='how-to-apply my-12 '>
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Apply for Professional Tax Registration</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-5">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyTaxData.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function PTRTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Professional Tax Registration is essential for businesses and individuals to comply with local tax regulations, ensuring that you meet all legal requirements for operating in your state.
      </p>
      <p>
      Thousands of businesses and professionals trust us for their tax registration needs.
      </p>
    </>
  );
}

export default PTRNavbar;