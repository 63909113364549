import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";

const CloudAccounting = ({ height }) => {
  // Cloud Accounting grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Real-Time Access",
      description:
        "Access your financial data anytime, anywhere with cloud-based solutions.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Automated Updates",
      description:
        "Get automatic updates and upgrades to the latest accounting features and security.",
    },
    {
      imagePath: "flexi.webp",
      title: "Cost Efficiency",
      description:
        "Reduce costs associated with hardware and IT maintenance by using cloud-based services.",
    },
    {
      imagePath: "limltd.webp",
      title: "Scalability",
      description:
        "Easily scale your accounting solution to match your business growth and needs.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Enhanced Security",
      description:
        "Benefit from advanced security features and backups provided by cloud accounting providers.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Real-Time Access -",
      description:"Provides instant access to your financial data from any location, improving accessibility and flexibility."
    },
    {
      imageright: "right.png",
      title:"Automated Updates -",
      description:"Ensures you always have the latest features and security updates without manual intervention."
    },
    {
      imageright: "right.png",
      title:"Cost Efficiency -",
      description:"Reduces the need for expensive hardware and IT infrastructure, lowering overall costs."
    },
    {
      imageright: "right.png",
      title:"Scalability -",
      description:"Easily adapt the solution to accommodate business growth and changing requirements."
    },
    {
      imageright: "right.png",
      title:"Enhanced Security -",
      description:"Offers robust security features and regular backups to protect your financial information."
    }
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Internet Dependency -",
      description:"Requires a stable internet connection to access and use cloud accounting services."
    },
    {
      imageright: "dangerous.webp",
      title:"Data Security Concerns -",
      description:"Potential risks related to data breaches and unauthorized access if security measures are not adequate."
    },
    {
      imageright: "dangerous.webp",
      title:"Subscription Costs -",
      description:" Ongoing subscription fees can add up over time compared to traditional software purchases."
    },
    {
      imageright: "dangerous.webp",
      title:"Integration Challenges -",
      description:" May face difficulties integrating with existing systems or software used by the business."
    },
    {
      imageright: "dangerous.webp",
      title:"Compliance Issues -",
      description:"Ensuring compliance with local regulations and standards can be complex when using cloud-based solutions."
    }
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Details",
      description:
        "Valid business registration documents to verify the legitimacy of your organization.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Statements",
      description:
        "Recent financial statements and records to integrate into the cloud accounting system.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Identification Number",
      description:
        "Your business's tax identification number for accurate financial reporting and compliance.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Information",
      description:
        "Details of your business bank accounts for reconciliation and transaction tracking.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "User Identification",
      description:
        "Identification details of users who will access the cloud accounting system for user management and security.",
    },
  ];

  const steps = [
    {
      title: "Choose a Cloud Accounting Provider",
      description:
        "Select a cloud accounting service provider that meets your business needs and budget.",
    },
    {
      title: "Set Up Your Account",
      description:
        "Create and configure your cloud accounting account, including user access and initial settings.",
    },
    {
      title: "Import Financial Data",
      description:
        "Upload and integrate your existing financial data into the cloud accounting system.",
    },
    {
      title: "Configure Features",
      description:
        "Customize the cloud accounting system with features and settings that align with your business processes.",
    },
    {
      title: "Train Users",
      description:
        "Provide training to your team on how to use the cloud accounting system effectively.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Cloud Accounting"
        ctaText="Get in touch"
        imagePath="./Cloudacc.webp"
        children={<CloudAccount />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Cloud Accounting Overview
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Cloud accounting offers a modern approach to managing your business
            finances, providing real-time access, automated updates, and cost
            efficiency. Embrace the future of accounting with our cloud-based
            solutions designed to enhance your financial management.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Cloud Accounting?
            </h6>

            <p className="mb-4">
              Cloud accounting refers to the use of cloud-based software to
              manage your financial data and accounting processes. It offers
              advantages such as real-time data access, automated updates, and
              scalability, making it a popular choice for modern businesses.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Cloud Accounting Solutions
            </h6>
            <p className="mb-4">
              To benefit from cloud accounting, you need:
              <ul>
                <li>
                  <strong>Business Registration Details -</strong> Proof of
                  business registration to validate your organization.
                </li>

                <li>
                  <strong>Financial Statements -</strong> Recent financial
                  records to integrate into the cloud accounting system.
                </li>

                <li>
                  <strong>Tax Identification Number -</strong> Your business tax
                  ID for accurate financial reporting and compliance.
                </li>

                <li>
                  <strong>Bank Account Information -</strong> Details of your
                  business bank accounts for reconciliation and transaction
                  tracking.
                </li>

                <li>
                  <strong>User Identification -</strong> Identification details
                  of users accessing the cloud accounting system.
                </li>
              </ul>
            </p>
          </div>

          <div className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of Cloud Accounting
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-4">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="documents" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Cloud Accounting</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="steps" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Implement Cloud Accounting</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className="why-choose my-4">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for Cloud Accounting?
            </h6>
            <p>
              Our cloud accounting solutions offer unparalleled flexibility,
              cost savings, and enhanced security. Let us help you transition to
              cloud-based accounting with ease and efficiency.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function CloudAccount() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Cloud accounting offers modern businesses the ability to manage their
        finances efficiently and securely from anywhere. Discover how our cloud
        accounting solutions can transform your financial management with
        real-time access and advanced features.
      </p>
      <p>
      Empower your business with our cutting-edge cloud accounting
      services designed to streamline your financial operations.
      </p>
    </>
  );
}

export default CloudAccounting;
