import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";

const EInvoicing = ({ height }) => {
  // E-Invoicing grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Streamlined Process",
      description:
        "Automate and streamline invoicing processes to enhance efficiency and accuracy.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Compliance Ready",
      description:
        "Ensure compliance with all regulatory requirements through automated systems.",
    },
    {
      imagePath: "flexi.webp",
      title: "Real-Time Tracking",
      description:
        "Track and manage invoices in real-time for better financial control.",
    },
    {
      imagePath: "limltd.webp",
      title: "Integration Capabilities",
      description:
        "Seamlessly integrate with existing accounting and ERP systems.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Enhanced Security",
      description:
        "Ensure secure and encrypted handling of all invoicing data.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title :"Streamlined Process -",
      description:"Automate invoicing to reduce manual work and errors, improving overall efficiency."
    },
    {
      imageright: "right.png",
      title :"Compliance Ready -",
      description:"Built to meet all regulatory requirements, reducing the risk of compliance issues."
    },
    {
      imageright: "right.png",
      title :"Real-Time Tracking -",
      description:"Monitor invoices and financial transactions in real-time for better control and oversight."
    },
    {
      imageright: "right.png",
      title :"Integration Capabilities -",
      description:"Easily integrates with other accounting and ERP systems for seamless operations."
    },
    {
      imageright: "right.png",
      title :"Enhanced Security -",
      description:" Provides robust security features to protect sensitive invoicing data."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Initial Setup Cost -",
      description:"There may be a significant initial investment for software and integration."
    },
    {
      imageright: "dangerous.webp",
      title:"Training Required -",
      description:"Staff may need training to effectively use the new invoicing system."
    },
    {
      imageright: "dangerous.webp",
      title:"Technical Issues -",
      description:" Potential for technical issues or glitches during implementation and use."
    },
    {
      imageright: "dangerous.webp",
      title:"Dependence on Technology -",
      description:"Reliance on technology could lead to disruptions in case of system failures."
    },
    {
      imageright: "dangerous.webp",
      title:"Ongoing Maintenance -",
      description:"Regular updates and maintenance may be required to ensure smooth operation."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Certificate",
      description:
        "Proof of business registration to set up invoicing processes.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Identification Number",
      description: "Required for compliance and accurate invoicing.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Details",
      description: "Necessary for managing payments and transactions.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Invoice Templates",
      description: "Templates for creating standardized invoices.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Compliance Documentation",
      description:
        "Documents showing compliance with local invoicing regulations.",
    },
  ];

  const steps = [
    {
      title: "Assess Needs",
      description:
        "Evaluate your invoicing needs and choose the right e-invoicing solution.",
    },
    {
      title: "Set Up System",
      description:
        "Implement the e-invoicing system and configure settings according to your business requirements.",
    },
    {
      title: "Integrate with ERP",
      description:
        "Integrate the e-invoicing system with your existing ERP or accounting software.",
    },
    {
      title: "Train Staff",
      description:
        "Provide training for staff to effectively use the new system.",
    },
    {
      title: "Monitor and Optimize",
      description:
        "Continuously monitor the system and make necessary adjustments to improve efficiency.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="E-Invoicing Solutions"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<TradeTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            E-Invoicing Made Efficient
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our e-invoicing solutions automate and streamline your invoicing
            processes, ensuring compliance with regulatory requirements and
            offering enhanced tracking and security features.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is E-Invoicing?
            </h6>

            <p className="mb-4">
              E-Invoicing refers to the use of electronic systems to create,
              send, and manage invoices. Our solution helps businesses automate
              invoicing processes, ensure regulatory compliance, and improve
              financial tracking.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements
            </h6>
            <p className="mb-4">
              To implement e-invoicing, your business needs:
              <ul>
                <li>
                  <strong>Business Registration -</strong> A registered business
                  entity to set up the invoicing system.
                </li>

                <li>
                  <strong>Tax Identification Number -</strong> Required for
                  compliance and accurate invoicing.
                </li>

                <li>
                  <strong>Bank Account Details -</strong> For managing payments
                  and transactions related to invoicing.
                </li>

                <li>
                  <strong>Invoice Templates -</strong> Standardized templates to
                  ensure consistency in invoices.
                </li>

                <li>
                  <strong>Compliance Documentation -</strong> Documentation to
                  demonstrate adherence to local invoicing regulations.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of E-Invoicing
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for E-Invoicing</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Implement E-Invoicing</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="choose" className="why-choose my-10">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for E-Invoicing?
            </h6>
            <p>
              Our team offers comprehensive support in implementing e-invoicing
              solutions tailored to your business needs. We ensure a seamless
              integration with existing systems, provide training, and offer
              ongoing support to optimize your invoicing processes.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function TradeTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Transform your invoicing process with our advanced e-invoicing solutions. Enhance efficiency, ensure compliance, and enjoy real-time tracking of all invoices.
      </p>
      <p>
      Our e-invoicing system is designed to automate and streamline your invoicing processes, integrating seamlessly with your existing systems and offering robust security features.
      </p>
    </>
  );
}

export default EInvoicing;
