import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';

const TaxNoticeAppeals = ({ height }) => {

  // Tax Notice and Appeals grid items
  const items = [
    { title: 'Expert Representation', description: 'Receive professional assistance to handle tax notices and appeals effectively.' },
    { title: 'Timely Response', description: 'Ensure prompt and accurate responses to avoid further complications.' },
    { title: 'Documentation Assistance', description: 'Help with gathering and organizing necessary documentation for your case.' },
    { title: 'Legal Compliance', description: 'Ensure that all responses and appeals are in compliance with current tax laws and regulations.' },
    { title: 'Appeal Preparation', description: 'Professional support in preparing and filing appeals to address any tax disputes.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    "Expert Representation - Professional assistance to navigate through tax notices and appeals efficiently.",
    "Timely Response - Ensures prompt and accurate responses to avoid escalating the issue.",
    "Documentation Assistance - Help with organizing and preparing necessary documents for your case.",
    "Legal Compliance - Ensures all responses and appeals adhere to tax laws and regulations.",
    "Appeal Preparation - Expert help in preparing and filing appeals to address any tax disputes effectively."
  ];

  const disadvantages = [
    "Complexity - Handling tax notices and appeals can be complex and require detailed knowledge.",
    "Costs - Professional services may involve costs that need to be considered.",
    "Time-Consuming - The process may be time-consuming and require thorough attention.",
    "Documentation Requirements - Gathering and preparing extensive documentation can be burdensome.",
    "Potential Delays - There may be delays in resolving the issues, impacting business operations."
  ];

  const documents = [
    {
      title: "Tax Notice Copy",
      description: "A copy of the received tax notice that needs to be addressed."
    },
    {
      title: "Supporting Documents",
      description: "Documents that support your position or claim in response to the notice."
    },
    {
      title: "Correspondence Records",
      description: "Records of all correspondence related to the tax notice or appeal."
    },
    {
      title: "Previous Returns",
      description: "Copies of tax returns and other relevant documents for the assessment period."
    },
    {
      title: "Legal Opinions",
      description: "Any legal opinions or advice obtained related to the tax notice or appeal."
    }
  ];

  const steps = [
    {
      title: "Review Notice",
      description: "Thoroughly review the tax notice to understand the issues raised and the required response."
    },
    {
      title: "Gather Documents",
      description: "Collect all necessary documents and supporting evidence to address the notice or appeal."
    },
    {
      title: "Prepare Response",
      description: "Draft a detailed and accurate response to the tax notice, addressing all points raised."
    },
    {
      title: "File Response",
      description: "Submit the prepared response to the relevant tax authority within the specified deadline."
    },
    {
      title: "Follow Up",
      description: "Monitor the status of your response or appeal and take further actions as needed based on feedback or decisions."
    }
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-screen">
          <div className="flex flex-row flex-wrap justify-around items-center">
            <a href="#overview" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Overview</a>
            <a href='#eligibility' className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Eligibility</a>
            <a href="#features" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Features</a>
            <a href="#pros-cons" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Pros/Cons</a>
            <a href="#documents" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Documents</a>
            <a href="#steps" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Steps</a>
            <a href="#why" className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">Why Choose Us?</a>
          </div>
        </nav>
      </div>

      <Container fluid className="main-content-container"
        style={{
          background: '#E3EEFB',
          minHeight: '470px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '90px',
        }}
      >
        <Row className="w-100 h-100">
          <Col md={6} xs={12} className="textcontent"
            style={{
              padding: '20px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              textAlign: 'left'
            }}
          >
            <h1 style={{ fontWeight: '700', fontSize: '36px', lineHeight: '52px' }}>
              Respond to Tax Notices & Appeals
            </h1>
            <p style={{ marginTop: '10px', fontWeight: '500', fontSize: '18px', lineHeight: '30px' }}>
              Navigate tax notices and appeals with our expert guidance. We ensure timely, accurate responses and help you prepare for any tax disputes effectively.
            </p>

            <p>Handling tax notices and appeals can be complex and challenging. Our services provide the expertise needed to respond effectively, ensuring compliance and addressing any issues promptly.</p>

            <Button className='btncon' href="get-free-assistance"
              style={{
                width: 'fit-content',
                backgroundColor: '#125B9A',
                marginTop: '10px'
              }}>
              Get in touch&gt;
            </Button>
          </Col>

          <Col md={6} xs={12} className="image-space"
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginTop: '20px',
            }}>
            <div style={{
              width: '632px',
              height: '420px',
              marginBottom: '35px',
              backgroundColor: '#C6D9F1',
              borderRadius: '8px',
            }}>
              {/* Empty image box */}
            </div>
          </Col>
        </Row>
      </Container>

      <div className='mainLLP w-full flex flex-wrap'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Responding to Tax Notices & Appeals Made Simple</h6>
          <p className='text-center mt-4'>Our services help you effectively respond to tax notices and appeals, ensuring timely and accurate submissions. We assist you in handling tax disputes and navigating complex tax regulations.</p>
        </div>

        <div className='LLP-container w-auto mt-4 flex flex-wrap' >

          <div className='pl-16 pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Responding to Tax Notices & Appeals?</h6>

            <p className='mb-4'>Responding to tax notices and appeals involves addressing issues raised by tax authorities, preparing necessary documentation, and filing responses or appeals. It ensures compliance and helps resolve disputes related to tax matters.</p>
          </div>

          <div id="eligibility" className='pl-16 pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements</h6>
            <p className='mb-4'>To respond to tax notices and appeals, you need:

              <ul>
                <li><strong>Received Tax Notice -</strong> A notice from the tax authority that requires a response.</li>

                <li><strong>Supporting Evidence -</strong> Relevant documents and evidence to address the notice or appeal.</li>

                <li><strong>Correspondence Records -</strong> Records of all related communications.</li>

                <li><strong>Previous Returns -</strong> Copies of past tax returns and assessments.</li>

                <li><strong>Legal Advice -</strong> Professional advice to ensure compliance and accurate responses.</li>
              </ul>
            </p>
          </div>

          <div id="features" className='pl-16 pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Key Features of Tax Notice/Appeals Responses</h6>
            <ul>
              {items.map((item, index) => (
                <li key={index}>
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="pros-cons" className='pl-16 pr-2 mt-16'>
            <div className='AdvantagesLLP'>
              <h2>Advantages of Responding to Tax Notices & Appeals</h2>
              <ul>
                {advantages.map((advantage, index) => (
                  <li key={index}>
                    {advantage}
                  </li>
                ))}
              </ul>
            </div>

            <div className='DisadvantagesLLP pl-16 mt-16'>
              <h2>Disadvantages of Responding to Tax Notices & Appeals</h2>
              <ul>
                {disadvantages.map((disadvantage, index) => (
                  <li key={index}>
                    {disadvantage}
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div id="documents" className='docs-req mt-16 px-16'>
            <h2>Documents Required for Responding to Tax Notices & Appeals</h2>
            <ul>
              {documents.map((doc, index) => (
                <li key={index}>
                  <strong>{doc.title}:</strong> {doc.description}
                </li>
              ))}
            </ul>
          </div>

          <div id="steps" className='how-to-apply mt-16 px-16'>
            <h2>Steps to Respond to Tax Notices & Appeals</h2>
            <ol>
              {steps.map((step, index) => (
                <li key={index}>
                  <strong>{step.title}:</strong> {step.description}
                </li>
              ))}
            </ol>
          </div>

          <div id="why" className='why-choose'>
            <h6 className='text-blue-500 text-lg font-semibold text-center'>Why Choose Us for Responding to Tax Notices & Appeals?</h6>
            <p>We provide expert guidance to ensure accurate and timely responses to tax notices and appeals. Our team helps you gather the necessary documentation, prepares detailed responses, and manages the process efficiently to resolve tax disputes and ensure compliance.</p>
          </div>
        </div>
      </div>

    </>
  );
};

export default TaxNoticeAppeals;