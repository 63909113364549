import React from 'react'

const Newsroom = () => {
  return (
    <div>
      Newsroom
    </div>
  )
}

export default Newsroom
