import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
import HeroSection from "./HeroSection";

const ITReturn6Navbar = ({ height }) => {
  // IT-Return 6 grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Income from Business or Profession",
      description:
        "Guidance on reporting income from business or professional activities, including deductions for business expenses.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Income from Capital Gains",
      description:
        "Detailed information on taxation of capital gains from the sale of assets such as property, shares, and mutual funds.",
    },
    {
      imagePath: "flexi.webp",
      title: "Income from Other Sources",
      description:
        "How to handle and report income from sources other than business or capital gains, such as interest, dividends, and royalties.",
    },
    {
      imagePath: "limltd.webp",
      title: "Deductions and Exemptions",
      description:
        "Overview of available deductions and exemptions to reduce taxable income and optimize tax liabilities.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Tax Filing Requirements",
      description:
        "Information on the tax filing requirements and deadlines for IT-Return 6, ensuring compliance with tax regulations.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Comprehensive Reporting -",
      description:"Covers a wide range of income sources including business, capital gains, and other incomes."
    },
    {
      imageright: "right.png",
      title:"Detailed Deductions -",
      description:"Provides guidance on various deductions and exemptions to reduce taxable income."
    },
    {
      imageright: "right.png",
      title:"Clear Filing Requirements -",
      description:"Outlines tax filing requirements and deadlines to ensure timely compliance."
    },
    {
      imageright: "right.png",
      title:"Optimized Tax Planning -",
      description:"Helps in strategic tax planning to minimize liabilities."
    },
    {
      imageright: "right.png",
      title:"Efficient Documentation -",
      description:"Streamlines the documentation process for different income sources."
    }
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complexity in Reporting -",
      description:"Handling multiple income sources and deductions can be complex."
    },
    {
      imageright: "dangerous.webp",
      title:"Detailed Documentation Needed -",
      description:"Requires comprehensive documentation to support various claims."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential for Errors -",
      description:"Increased risk of errors if information is not accurately reported."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Income Records",
      description:
        "Detailed records of business income and expenses, including profit and loss statements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Capital Gains Statements",
      description:
        "Statements showing capital gains from the sale of assets like property and investments.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Income from Other Sources",
      description:
        "Proof of income from other sources, such as interest, dividends, and royalties.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Deduction Documents",
      description:
        "Documents supporting claims for deductions and exemptions, including receipts and certificates.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Filing Compliance Records",
      description:
        "Records related to tax filing requirements and deadlines, including acknowledgments of return submission.",
    },
  ];

  const steps = [
    {
      title: "Gather All Income Documents",
      description:
        "Collect records for business income, capital gains, and other sources of income.",
    },
    {
      title: "Review Deductions and Exemptions",
      description:
        "Identify and gather documents for all eligible deductions and exemptions.",
    },
    {
      title: "Prepare IT-Return 6",
      description:
        "Fill out the IT-Return 6 form with accurate information on income, deductions, and exemptions.",
    },
    {
      title: "File the Return",
      description:
        "Submit the completed IT-Return 6 form electronically or manually according to tax regulations.",
    },
    {
      title: "Confirm Compliance",
      description:
        "Ensure compliance with tax regulations and deadlines, and retain records of submission.",
    },
  ];

  const whyChooseITReturn6 = [
    {
      title: "Tailored for Corporate Tax Filers",
      description: "ITR-6 is specifically designed for companies, covering all corporate income tax needs and ensuring complete compliance with tax regulations."
    },
    {
      title: "Detailed Financial Disclosure",
      description: "This form provides an in-depth view of a company's financial health, covering income, deductions, and investments comprehensively."
    },
    {
      title: "Maximizes Tax Efficiency",
      description: "ITR-6 helps corporations claim deductions, exemptions, and carry forward losses, enabling effective tax planning and reduction."
    },
    {
      title: "Enhanced Compliance and Transparency",
      description: "The form's structured format ensures that all income sources and expenses are transparently reported, fostering regulatory confidence."
    },
    {
      title: "Supports Digital Filing",
      description: "With mandatory e-filing, ITR-6 streamlines the submission process, making it quicker and more efficient for corporate filers."
    },
    {
      title: "Ensures Accurate Tax Assessment",
      description: "ITR-6 minimizes errors by prompting for detailed entries, allowing companies to avoid penalties and ensure accurate tax assessment."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="IT-Return 6: Comprehensive  Tax Filing Solutions"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ItrSix />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            IT-Return 6 Filing Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our IT-Return 6 services offer a complete solution for reporting
            income from business, capital gains, and other sources. Benefit from
            expert assistance in optimizing your tax filings and ensuring
            compliance with tax regulations.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is IT-Return 6?
            </h6>

            <p className="mb-4">
              IT-Return 6 is designed for individuals with multiple income
              sources, including business income, capital gains, and other
              earnings. It simplifies the tax filing process and provides
              detailed guidance on reporting and deductions.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for IT-Return 6
            </h6>
            <p className="mb-4">
              To file IT-Return 6, you should meet the following criteria:
              <ul>
                <li>
                  <strong>Multiple Income Sources -</strong> Applicable if you
                  have income from business, capital gains, and other sources.
                </li>

                <li>
                  <strong>Documentation -</strong> Ensure you have all required
                  documentation for income reporting and deduction claims.
                </li>

                <li>
                  <strong>Filing Requirements -</strong> Be aware of tax filing
                  requirements and deadlines to ensure compliance.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of IT-Return 6
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for IT-Return 6
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              IT-Return 6 Filing Process
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold my-3">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseITReturn6.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function ItrSix() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
      IT-Return 6 provides an all-encompassing solution for reporting various income sources, including business, capital gains, and other income types. Utilize our services for optimized tax planning and accurate filing.
      </p>
      <p>
      Efficiently manage your tax filing with IT-Return 6, covering all necessary income sources and maximizing available deductions and exemptions.
      </p>
    </>
  );
}

export default ITReturn6Navbar;
