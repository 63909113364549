import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
import FeatureCard from "./FeatureCard";

const AnnualReturns = ({ height }) => {
  // Annual Returns grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Regulatory Compliance",
      description:
        "Ensure compliance with annual filing requirements to avoid penalties.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Timely Submissions",
      description: "Manage and track the submission deadlines efficiently.",
    },
    {
      imagePath: "flexi.webp",
      title: "Financial Reporting",
      description:
        "Accurate reporting of financial performance and business activities.",
    },
    {
      imagePath: "limltd.webp",
      title: "Documentation Management",
      description:
        "Organize and maintain all required documentation for filings.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Expert Assistance",
      description:
        "Get professional help to prepare and submit annual returns accurately.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Regulatory Compliance -",
      description:"Ensures that your business meets all legal filing requirements and avoids fines."
    },
    {
      imageright: "right.png",
      title:"Timely Submissions -",
      description:"Helps manage and track submission deadlines to ensure timely filings."
    },
    {
      imageright: "right.png",
      title:"Financial Reporting -",
      description:"Provides accurate reporting of your business’s financial status and activities."
    },
    {
      imageright: "right.png",
      title:"Documentation Management -",
      description:"Facilitates organization and maintenance of all necessary documents for filings."
    },
    {
      imageright: "right.png",
      title:"Expert Assistance -",
      description:"Professional help available to ensure accuracy and compliance in preparing annual returns."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complexity -",
      description:"The process can be complex and time-consuming, requiring careful attention to detail."
    }, 
    {
      imageright: "dangerous.webp",
      title:"Costs -",
      description:"There may be costs associated with preparing and filing annual returns, including professional fees."
    }, 
    {
      imageright: "dangerous.webp",
      title:"Risk of Penalties -",
      description:"Failure to file on time or inaccuracies can lead to legal and financial penalties."
    }, 
    {
      imageright: "dangerous.webp",
      title:"Documentation Requirements -",
      description:"Maintaining extensive documentation can be burdensome."
    }, 
    {
      imageright: "dangerous.webp",
      title:"Ongoing Obligations -",
      description:"Regular updates and compliance measures are necessary throughout the year."
    }, 
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Statements",
      description:
        "Detailed financial statements to be included in the annual returns.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Board Resolutions",
      description:
        "Resolutions passed by the board of directors for annual filings.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Audit Reports",
      description:
        "Reports from auditors confirming the accuracy of financial statements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Returns",
      description:
        "Completed tax returns that need to be submitted along with the annual returns.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Company Records",
      description: "Up-to-date records of company activities and resolutions.",
    },
  ];

  const steps = [
    {
      title: "Review Requirements",
      description:
        "Understand the annual return requirements specific to your business type and jurisdiction.",
    },
    {
      title: "Gather Documents",
      description:
        "Collect all necessary documents and financial statements required for submission.",
    },
    {
      title: "Prepare Returns",
      description:
        "Prepare the annual returns based on the gathered information and ensure accuracy.",
    },
    {
      title: "File Returns",
      description:
        "Submit the prepared annual returns to the relevant authorities before the deadline.",
    },
    {
      title: "Confirm Submission",
      description:
        "Verify the submission and retain proof of filing for future reference.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Annual Returns Filing"
        ctaText="Get in touch"
        imagePath="./AnnualReturn.webp"
        children={<AnnualTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="overview" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Annual Returns Made Easy
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our annual returns services simplify the process, ensuring
            compliance with regulations and accurate financial reporting. Let us
            handle the complexities while you focus on your business.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">

        <div className="pr-2 pt-5 ">
          <h6 className="text-blue-500 text-lg font-semibold">
            What are Annual Returns?
          </h6>

          <p className="mb-4">
            Annual Returns are documents that companies are required to file
            yearly to report their financial status and other relevant
            information to regulatory authorities. They ensure compliance with
            legal requirements and provide transparency.
          </p>
        </div>

        <div id="el">
          <h6 className="text-blue-500 text-lg font-semibold my-2">
            Eligibility and Requirements
          </h6>
          <p className="mb-4">
            To file annual returns, your business needs:
            <ul>
              <li>
                <strong>Company Registration -</strong> A registered company
                entity is required for filing annual returns.
              </li>

              <li>
                <strong>Financial Statements -</strong> Detailed financial
                statements for the reporting period.
              </li>

              <li>
                <strong>Board Resolutions -</strong> Resolutions passed by the
                board related to annual filings.
              </li>

              <li>
                <strong>Audit Reports -</strong> Reports from auditors
                confirming the accuracy of financial statements.
              </li>

              <li>
                <strong>Tax Returns -</strong> Completed tax returns for the
                fiscal year.
              </li>
            </ul>
          </p>
        </div>

        <div id="features " className="my-8">
          <h6 className="text-blue-500 text-lg font-semibold">
            Key Features of Annual Returns
          </h6>
          <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard 
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
        </div>

        <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

        <div id="why" className="docs-req mt-16 ">
          <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Annual Returns</h2>
          <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
        </div>

        <div id="how" className="how-to-apply mt-16 ">
          <h2 className="text-blue-500 text-lg font-semibold">Steps to File Annual Returns</h2>
          <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
        </div>

        <div id="choose" className="why-choose flex-row flex-wrap my-10">
          <h6 className="text-blue-500 text-lg font-semibold ">
            Why Choose Us for Annual Returns?
          </h6>
          <p>
            We offer comprehensive support for filing annual returns, ensuring
            compliance with regulatory requirements. Our team helps you gather
            the necessary documents, prepares accurate returns, and handles the
            submission process, saving you time and avoiding potential
            penalties.
          </p>
        </div>
        </div>
      </div>
    </>
  );
};

function AnnualTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Simplify your annual returns filing with our expert services. Ensure compliance, manage deadlines, and get professional assistance for accurate submissions.
      </p>
      <p>
      Our annual returns filing services help you meet regulatory requirements, maintain proper documentation, and avoid penalties. We ensure your filings are completed accurately and on time.
      </p>
    </>
  );
}

export default AnnualReturns;
