import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const DSCNavbar = ({ height }) => {
  // DSC service grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Enhanced Security",
      description:
        "Provides a secure way to sign and authenticate documents electronically.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Legal Validity",
      description:
        "Legally recognized for electronic transactions and document signing.",
    },
    {
      imagePath: "flexi.webp",
      title: "Ease of Use",
      description:
        "Simple to use for signing documents and ensuring authenticity.",
    },
    {
      imagePath: "limltd.webp",
      title: "Government Compliance",
      description:
        "Compliant with regulations for digital signatures in various sectors.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Quick Issuance",
      description:
        "Fast processing and issuance of the Digital Signature Certificate.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Enhanced Security -",
      description:
        "Digital signatures provide a high level of security and authenticity for electronic documents.",
    },
    {
      imageright: "right.png",
      title: "Legal Validity -",
      description:
        "Digital signatures are legally recognized and accepted for various types of documents.",
    },
    {
      imageright: "right.png",
      title: "Efficient Processing -",
      description:
        "Streamlines the process of document signing and verification.",
    },
    {
      imageright: "right.png",
      title: "Compliance -",
      description:
        "Meets regulatory requirements for secure electronic transactions.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Technical Issues -",
      description:
        "Potential technical issues or compatibility problems with certain software.",
    },
    {
      imageright: "dangerous.webp",
      title: "Cost -",
      description:
        "PThere may be costs associated with obtaining and renewing the DSC.",
    },
    {
      imageright: "dangerous.webp",
      title: "Complexity for Some -",
      description:
        "Might be complex for users unfamiliar with digital signatures.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "A valid government-issued identity proof such as Aadhar card or passport is required.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof",
      description:
        "Proof of residence, such as a utility bill or bank statement, needs to be provided.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Photographs",
      description:
        "Recent passport-sized photographs may be required for the application process.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Documents",
      description:
        "For business DSC, provide documents related to the business registration and PAN.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description:
        "A valid PAN card of the individual or business is needed for verification.",
    },
  ];

  const steps = [
    {
      title: "Collect Documents",
      description:
        "Gather all necessary documents required for DSC application.",
    },
    {
      title: "Choose DSC Type",
      description:
        "Select the type of DSC (Class 2, Class 3) based on your needs.",
    },
    {
      title: "Apply Online",
      description:
        "Submit the DSC application online through a registered DSC provider.",
    },
    {
      title: "Verification",
      description: "Complete the verification process with the DSC provider.",
    },
    {
      title: "Receive DSC",
      description:
        "After successful verification, receive your Digital Signature Certificate.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChoose = [
    {
      id: 1,
      title: "Secure Transactions",
      description:
        "Our DSC ensures secure digital transactions with encryption.",
    },
    {
      id: 2,
      title: "Trusted Certification",
      description:
        "Recognized by major authorities, providing trusted authentication.",
    },
    {
      id: 3,
      title: "Easy Integration",
      description:
        "Seamlessly integrates with various platforms and applications.",
    },
    {
      id: 4,
      title: "Cost Effective",
      description: "Affordable pricing with multiple plans to suit your needs.",
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="DSC Signature"
        ctaText="Get in touch"
        imagePath="./DSC.webp"
        children={<DSCTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            DSC Application Made Easy
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Obtaining a Digital Signature Certificate (DSC) is essential for
            secure online transactions and document signing. We guide you
            through a seamless application process for your DSC.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16 ">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is DSC?
            </h6>

            <p className="mb-4">
              A Digital Signature Certificate (DSC) is an electronic form of
              signature used to authenticate documents and transactions online.
              It ensures the security and integrity of digital communications
              and is legally accepted for various purposes.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for DSC Application
            </h6>
            <p className="mb-4">
              To apply for a DSC, you must meet the following requirements:
              <ul>
                <li>
                  <strong>Identity Proof -</strong> Provide a valid
                  government-issued identity proof such as an Aadhar card or
                  passport.
                </li>

                <li>
                  <strong>Address Proof -</strong> Submit proof of residence,
                  such as a utility bill or bank statement.
                </li>

                <li>
                  <strong>Photographs -</strong> Recent passport-sized
                  photographs may be required.
                </li>

                <li>
                  <strong>Business Registration Documents -</strong> For
                  business DSC, include documents related to business
                  registration and PAN.
                </li>
              </ul>
            </p>

            <div className=" pr-2 mt-16 ">
              <h6 className="text-blue-500 text-lg font-semibold" id="features">
                Key Features of DSC
              </h6>
              <div className=" w-full grid  md:grid-cols-2 gap-6">
                {items.map((item) => (
                  <FeatureCard
                    imageUrl={item.imagePath}
                    title={item.title}
                    para={item.description}
                  />
                ))}
              </div>
            </div>

            <div id="pro/con" className="Advan  mt-16">
              <h2m className="text-blue-500 text-lg font-semibold">
                Advantages
              </h2m>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {advantages.map((ticpoint, index) => (
                  <Advantages
                    key={index}
                    imagePrl={ticpoint.imageright}
                    title={ticpoint.title}
                    para={ticpoint.description}
                  />
                ))}
              </div>
              <div className="">
                <h2 className="text-blue-500 text-lg font-semibold">
                  Disadvantages
                </h2>
                <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                  {disadvantages.map((dicpoint, index) => (
                    <Disadvantages
                      key={index}
                      imageSrl={dicpoint.imageright}
                      title={dicpoint.title}
                      para={dicpoint.description}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div id="why" className="why-choose mt-16 ">
              <h2 className="text-blue-500 text-lg font-semibold">
                Documents Required for DSC Application
              </h2>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="how" className="how-to-apply mt-16 ">
              <h2 className="text-blue-500 text-lg font-semibold">
                Steps to Apply for DSC
              </h2>
              <div className=" w-full grid  md:grid-cols-2 gap-6">
                {steps.map((items, index) => (
                  <HowToReg
                    key={index}
                    index={index + 1}
                    title={items.title}
                    para={items.description}
                  />
                ))}
              </div>
            </div>

            <div className='my-5'>
          <h6 className="text-blue-500 text-lg font-semibold"> Why Choose </h6>
          <ul className="list-disc">
              {whyChoose.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          </div>
        </div>
      </div>
    </>
  );
};

function DSCTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        A Digital Signature Certificate (DSC) is crucial for secure online
        transactions and document signing, ensuring the authenticity and
        integrity of electronic communications.
      </p>

      <p>
        Thousands of businesses and individuals have secured their DSCs with us.
      </p>
    </>
  );
}

export default DSCNavbar;
