import HeroSection from "./HeroSection";
import { Link } from "react-router-dom";
import TickText from "./TickText";

function Maincontent() {
  return (
    <HeroSection
      title="Your trusted partner for business success, at no cost"
      ctaText="Get free Assistance"
      videoSrc={"/DialT2F Form a Business.mp4"}
      children={<HomeDescription />}
    />
  );
}

function HomeDescription() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        <Link to="dialT2F" style={{ textDecoration: "none", color: "#125B9A" }}>
          dialT2F
        </Link>
        offers a streamlined platform where users can complete every step to
        build their business,
        <Link to="pricing" style={{ textDecoration: "none", color: "#125B9A" }}>
          simple and affordably
        </Link>
      </p>
      <div className="smallhead flex flex-row flex-wrap ">
        <TickText image='./right.png' text={"Timely reporting"} />
        <TickText image='./right.png' text={"Advanced Tech & Experts"} />
        <TickText image='./right.png' text={"Innovative Solutionns"} />
      </div>
    </>
  );
}

export default Maincontent;
