import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const AppealAgainstIncomeTaxOrdersNavbar = ({ height }) => {
  // Appeal against Income Tax Orders grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Understanding Income Tax Appeals",
      description:
        "Learn about the process of filing an appeal against income tax orders, including reasons for appeal and the procedure involved.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Preparing Your Appeal",
      description:
        "Guidance on gathering necessary documentation and preparing a strong appeal case against income tax orders.",
    },
    {
      imagePath: "flexi.webp",
      title: "Filing the Appeal",
      description:
        "Steps involved in filing the appeal with the appropriate authorities and ensuring all requirements are met.",
    },
    {
      imagePath: "limltd.webp",
      title: "Handling Correspondence",
      description:
        "Support in managing communication with tax authorities during the appeal process.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Final Resolution",
      description:
        "Assistance in receiving and understanding the final decision on your appeal and taking further actions if needed.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Expert Guidance -",
      description:"Professional help to navigate the complexities of tax appeals."
    },
    {
      imageright: "right.png",
      title:"Comprehensive Support -",
      description:"Assistance with documentation, filing, and communication with authorities."
    },
    {
      imageright: "right.png",
      title:"Increased Chances of Success -",
      description:"Enhanced likelihood of a favorable outcome with a well-prepared appeal."
    },
    {
      imageright: "right.png",
      title:"Legal Compliance -",
      description:"Ensuring all procedural and legal requirements are met during the appeal process."
    },
    {
      imageright: "right.png",
      title:"Peace of Mind -",
      description:"Reducing stress and uncertainty associated with handling tax disputes."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming -",
      description:"The appeal process can be lengthy and require significant time investment."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Costs -",
      description:"Professional fees and other costs associated with filing an appeal."
    },
    {
      imageright: "dangerous.webp",
      title:"Complexity -",
      description:"Handling appeals can be complex, requiring thorough knowledge of tax laws and procedures."
    }
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Income Tax Order Copy",
      description:
        "Copy of the income tax order that is being appealed against.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Appeal Form",
      description:
        "The prescribed form for filing an appeal with the tax authorities.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Supporting Documentation",
      description:
        "Additional documents and evidence supporting the appeal, such as financial statements, correspondence, etc.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Payment",
      description:
        "Proof of any tax payments or adjustments related to the appeal.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Written Submission",
      description:
        "Detailed written submission outlining the grounds for appeal and arguments.",
    },
  ];

  const steps = [
    {
      title: "Review Income Tax Order",
      description:
        "Thoroughly review the income tax order to understand the issues and grounds for appeal.",
    },
    {
      title: "Prepare Appeal",
      description:
        "Gather necessary documentation and prepare a detailed appeal, including legal arguments and supporting evidence.",
    },
    {
      title: "File Appeal",
      description:
        "Submit the appeal along with all required documents to the relevant tax authority.",
    },
    {
      title: "Respond to Queries",
      description:
        "Handle any correspondence or queries from tax authorities related to the appeal.",
    },
    {
      title: "Receive Decision",
      description:
        "Review the final decision on the appeal and take necessary actions based on the outcome.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseTaxAppeal = [
    {
      title: "Expert Legal Support",
      description: "Our team provides specialized legal support to guide you through the appeal process and strengthen your case."
    },
    {
      title: "Timely Filing",
      description: "We ensure your appeal is filed within the required deadlines, helping you avoid delays or penalties."
    },
    {
      title: "Detailed Documentation",
      description: "We assist with comprehensive documentation to support your appeal and increase the likelihood of a favorable outcome."
    },
    {
      title: "Transparent Process",
      description: "We keep you informed at each step, ensuring transparency and clarity throughout the appeal process."
    }
  ];
  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Filing Appeal Against Income Tax"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ResTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Filing Appeal Against Income Tax Orders
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our services for filing appeals against income tax orders ensure
            that you receive expert support throughout the process, from
            understanding the order to preparing and filing the appeal.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Filing an Appeal Against Income Tax Orders?
            </h6>

            <p className="mb-4">
              Filing an appeal against an income tax order involves challenging
              the decision made by tax authorities regarding your tax
              assessment. This process is essential when you believe there has
              been an error or injustice in the tax order. The appeal allows you
              to present your case and seek a reconsideration of the decision.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Filing an Appeal
            </h6>
            <p className="mb-4">
              To file an appeal against an income tax order, the following
              requirements and documents are necessary:
              <ul>
                <li>
                  <strong>Income Tax Order Copy -</strong> Copy of the income
                  tax order you are challenging.
                </li>

                <li>
                  <strong>Appeal Form -</strong> The prescribed form for filing
                  an appeal with the tax authorities.
                </li>

                <li>
                  <strong>Supporting Documentation -</strong> Additional
                  documents and evidence supporting your appeal, such as
                  financial statements, correspondence, etc.
                </li>

                <li>
                  <strong>Proof of Payment -</strong> Proof of any tax payments
                  or adjustments related to the appeal.
                </li>

                <li>
                  <strong>Written Submission -</strong> Detailed written
                  submission outlining the grounds for appeal and arguments.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Our Filing Appeal Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">
              Documents Required for Filing Appeal Against Income Tax Orders
            </h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to File an Appeal Against Income Tax Orders</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>
          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseTaxAppeal.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function ResTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Filing an appeal against an income tax order is a formal process to challenge decisions made by tax authorities. Our services offer comprehensive support to ensure your appeal is well-prepared, filed correctly, and managed efficiently throughout the process.
      </p>
      <p>
      Get expert assistance in filing your appeal and navigating the complexities of income tax disputes.
      </p>
    </>
  );
}

export default AppealAgainstIncomeTaxOrdersNavbar;
