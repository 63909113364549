import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';
const PublicLtdCompanyNavbar = ({ height }) => {

  // Public Limited Company grid items
  const featuresCard = [
    { 
      imagePath: "./Grp.webp",
      title: 'Raising Capital', 
      para: 'Ability to raise capital by issuing shares to the public through stock exchanges'
    },
    { 
      imagePath: "Costeff.webp",
      title: 'Limited Liability', 
      para: 'Provides limited liability protection to shareholders, safeguarding personal assets.' 
    },
    { 
      imagePath: "flexi.webp",
      title: 'Market Presence', 
      para: 'Enhanced market presence and credibility due to public listing and regulatory compliance.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'Share Liquidity',
      para: 'Shares are more liquid and can be easily traded on stock exchanges.' },
    { 
      imagePath: "lessreg.webp",
      title: 'Regulatory Oversight', 
      para: 'Subject to rigorous regulatory oversight, ensuring transparency and accountability.' },
  ];

  // Advantages and Disadvantages
  const advantagesList = [

    {
      imageright: "right.png",
      title: "Raising Capital ",
      parag: "A Public Limited Company can raise significant capital by offering shares to the public through stock exchanges.",
    },
    {
      imageright: "right.png",
      title: "Limited Liability",
      parag:"Shareholders enjoy limited liability, protecting their personal assets from company debts and liabilities",
    },
    {
      imageright: "right.png",
      title: "Market Presence",
      parag: "Enhanced visibility and credibility due to public listing and adherence to strict regulatory requirements.",
    },
    {
      imageright: "right.png",
      title: "Share Liquidity ",
      parag: "Shares of a public company are more liquid and can be traded easily on stock exchanges.",
    },
    {
      imageright: "right.png",
      title: "Regulatory Oversight",
      parag: "Subject to rigorous regulatory oversight, promoting transparency and good corporate governance.",
    },
  ];

  const disadvantagesList = [
    {
      imageright: "dangerous.webp",
      title: "Regulatory Compliance - ",
      parag: "Requires adherence to extensive regulatory and reporting requirements, which can be costly and time-consuming.",
    },
    {
      imageright: "dangerous.webp",
      title: "Public Scrutiny",
      parag:
        "Subject to increased public and media scrutiny, which can impact company reputation and operations.",
    },
    {
      imageright: "dangerous.webp",
      title: " Cost",
      parag:
        "Higher costs associated with compliance, reporting, and maintaining public company status.",
    },
    {
      imageright: "dangerous.webp",
      title: "Management Complexity",
      parag:
        "Greater complexity in management due to larger scale and regulatory demands.",
    },
  ];
  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      para: "Valid government-issued identity proofs of all directors and shareholders, such as Aadhar card or passport."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof",
      para: "Proof of the registered office address of the company, such as a utility bill or lease agreement."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Incorporation Documents",
      para: "Documents required for incorporation, including the Memorandum of Association (MOA) and Articles of Association (AOA)."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN and TAN",
      para: "Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the company."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Director Identification Number (DIN)",
      para: "DIN for all proposed directors of the company."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Share Capital Details",
      para: "Details regarding the share capital structure and number of shares to be issued."
    }
  ];

  const steps = [
    {
      title: "Choose a Company Name",
      para: "Select a unique name for your company and ensure it complies with naming regulations."
    },
    {
      title: "Prepare Documents",
      para: "Gather all required documents including identity proofs, address proofs, and incorporation documents."
    },
    {
      title: "Register with ROC",
      para: "Submit the registration application along with required documents to the Registrar of Companies (ROC)."
    },
    {
      title: "Obtain PAN and TAN",
      para: "Apply for the Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the company."
    },
    {
      title: "Share Capital and Listing",
      para: "Finalize share capital details and apply for listing on a stock exchange if required."
    },
    {
      title: "Receive Certificate of Incorporation",
      para: "Upon successful verification, receive the Certificate of Incorporation from the ROC."
    }
  ];

  const elPoints = [
    {
      title: "Identity Proof",
      description:"Provide valid government-issued identity proofs of all directors and shareholders, such as Aadhar card or passport.",
    },
    {
      title: "Address Proof",
      description:"Submit proof of the registered office address, such as a utility bill or lease agreement.",
    },
    {
      title: "Incorporation Documents",
      description:"Prepare necessary documents including the Memorandum of Association (MOA) and Articles of Association (AOA).",
    },
    {
      title: "PAN and TAN",
      description:"Obtain the Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the company.",
    },
    {
      title: "Director Identification Number (DIN) -",
      description:"Ensure all proposed directors have a valid DIN.",
    },
    {
      title: "Share Capital -",
      description:"Provide details regarding the share capital structure and the number of shares to be issued.",
    },
  ];


  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
        <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Public Limited Company Registration"
        ctaText="Get in touch"
        imagePath="./Publicltd.webp"
        children={<PublicltdCompany/>}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>Establish Your Public Limited Company with Confidence</h6>
          <p className='max-w-[1086px] text-center mt-4'>Setting up a Public Limited Company offers numerous benefits including access to public capital markets and enhanced business credibility. We ensure a smooth and compliant registration process for your public company.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16' >

          <div className='pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Public Limited Company?</h6>

            <p className='mb-4'>A Public Limited Company is a type of business entity that offers its shares to the public through stock exchanges. It provides the benefit of raising substantial capital while limiting shareholder liability. This structure is ideal for larger businesses seeking to expand and attract public investment.</p>
          </div>

          <div id="el" className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Public Limited Company Registration</h6>
            <p className='mb-4'>To register a Public Limited Company, you must meet the following requirements:
            </p>
            <ul className="list-disc">
              {elPoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>

          <div className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Public Limited Company</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {featuresCard.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.para}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantagesList.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.parag}
                />
              ))}
            </div>
            <div className="mt-4">
              <h2>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantagesList.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.parag}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 '>
            <h2>Documents Required for Public Limited Company Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.para}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='how-to-apply mt-16 py-4'>
            <h2>Steps to Register a Public Limited Company</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.para}
                    />
                  ))}
                </div>
          </div>
        </div>
      </div>

    </>
  );
};

function PublicltdCompany(){
  return(
    <>
    <p>
              Registering a Public Limited Company provides significant advantages such as the ability to raise capital through public offerings and limited liability protection. This structure is suitable for businesses seeking substantial growth and market presence.
            </p>

            <p>We guide you through the registration process to ensure your company complies with all regulatory requirements.</p>
            </>
  )
}

export default PublicLtdCompanyNavbar;