import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "./LLPcard";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const RBIRegistration = ({ height }) => {
  // RBI Registration grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Regulatory Oversight",
      description:
        "Ensure compliance with RBI regulations and guidelines for financial institutions.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Financial Stability",
      description:
        "Contribute to the stability and integrity of the financial system.",
    },
    {
      imagePath: "flexi.webp",
      title: "Investor Trust",
      description:
        "Build trust among investors and stakeholders through RBI compliance.",
    },
    {
      imagePath: "limltd.webp",
      title: "Operational Guidelines",
      description:
        "Adhere to RBI’s operational guidelines and reporting requirements.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Enhanced Credibility",
      description:
        "Improve your company’s credibility in the financial sector with RBI registration.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Regulatory Oversight -",
      description:"Adherence to RBI regulations ensures compliance and avoids penalties."
    },
    {
      imageright: "right.png",
      title:"Financial Stability -",
      description:"Contributes to maintaining financial stability and integrity in the market."
    },
    {
      imageright: "right.png",
      title:"Investor Trust -",
      description:"Enhances trust among investors and stakeholders by meeting RBI standards."
    },
    {
      imageright: "right.png",
      title:"Operational Guidelines -",
      description:"Ensures that your operations align with RBI’s operational guidelines."
    },
    {
      imageright: "right.png",
      title:"Enhanced Credibility -",
      description:"Boosts your company’s reputation and credibility in the financial industry."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Regulatory Complexity -",
      description:"Understanding and adhering to RBI regulations can be complex and time-consuming."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost of Compliance -",
      description:"May involve additional costs for compliance and reporting requirements."
    },
    {
      imageright: "dangerous.webp",
      title:"Frequent Updates -",
      description:"RBI regulations may change, requiring ongoing adjustments and updates."
    },
    {
      imageright: "dangerous.webp",
      title:"Documentation Requirements -",
      description:"Requires extensive documentation for registration and compliance."
    },
    {
      imageright: "dangerous.webp",
      title:"Monitoring and Reporting -",
      description:"Ongoing monitoring and reporting obligations can be demanding."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Application Form",
      description: "Complete application form for RBI registration.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Company Documents",
      description:
        "Incorporation certificate, MOA, AOA, and other relevant company documents.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Compliance Certificate",
      description: "Certificate of compliance with RBI regulations.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Statements",
      description: "Audited financial statements of the company.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Plan",
      description:
        "Detailed business plan outlining your company’s financial operations.",
    },
  ];

  const steps = [
    {
      title: "Prepare Documents",
      description:
        "Collect and prepare all necessary documents for RBI registration.",
    },
    {
      title: "Complete Application",
      description:
        "Fill out the RBI registration application form with accurate details.",
    },
    {
      title: "Submit to RBI",
      description:
        "Submit the completed application form and documents to the Reserve Bank of India.",
    },
    {
      title: "Review and Approval",
      description:
        "RBI reviews the application and documents before granting registration.",
    },
    {
      title: "Compliance Adherence",
      description:
        "Ensure ongoing adherence to RBI regulations and reporting requirements post-registration.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="RBI Registration"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<TradeTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            RBI Registration Made Easy
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Ensure your company meets RBI regulatory requirements with our
            streamlined registration process. Enhance your credibility and
            compliance with expert support every step of the way.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is RBI Registration?
            </h6>

            <p className="mb-4">
              RBI registration is a mandatory process for companies operating in
              the financial sector in India. It ensures compliance with
              regulations set by the Reserve Bank of India, promoting financial
              stability and market integrity.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements
            </h6>
            <p className="mb-4">
              To obtain RBI registration, your company must:
              <ul>
                <li>
                  <strong>Complete Application Form -</strong> Accurately fill
                  out the RBI registration application form.
                </li>

                <li>
                  <strong>Company Documents -</strong> Provide incorporation
                  certificate, MOA, AOA, and other relevant documents.
                </li>

                <li>
                  <strong>Compliance Certificate -</strong> Submit a certificate
                  confirming compliance with RBI regulations.
                </li>

                <li>
                  <strong>Financial Statements -</strong> Include audited
                  financial statements for your company.
                </li>

                <li>
                  <strong>Business Plan -</strong> Provide a detailed business
                  plan outlining your company’s financial operations.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of RBI Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for RBI Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply my-10">
            <h2 className="text-blue-500 text-lg font-semibold">Steps for RBI Registration</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose my-10">
            <h6 className="text-blue-500 text-lg font-semibold">
              Why Choose Us for RBI Registration?
            </h6>
            <p>
              Our expert team offers comprehensive assistance with RBI
              registration, ensuring that your company meets all regulatory
              requirements efficiently. We provide personalized support to
              streamline the process and enhance your credibility in the
              financial sector.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function TradeTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Obtaining RBI registration is essential for ensuring compliance with the Reserve Bank of India's regulations and standards. Our expert team provides comprehensive support throughout the registration process, ensuring a smooth and efficient experience.
      </p>
      <p>
      Strengthen your company’s credibility and maintain regulatory compliance with our RBI registration services.
      </p>
    </>
  );
}
export default RBIRegistration;
