import React, { useEffect, useRef, useState } from "react";
import SendEmail from "../Api/SendEmail";

const BlogsNArticles = ({height}) => {
  const [emailInput, setEmailInput] = useState(null);
  const [stage, setStage] = useState("");
  const inptHt=useRef(0);

  

  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };
    scrollToTop();
  }, []);

  const checkValidations = (emailAddress) => {
    if (emailAddress === "") return false;
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (regex.test(emailAddress)) return true;
    return false;
  };

  const HandleFormSubmit = async (event) => {
    event.preventDefault();
    if (checkValidations(emailInput)) {
      setStage("Subscribing Please Wait....");
      SendEmail(emailInput)
        .then((response) => {
          setStage("Subscribed Successfully");
        })
        .catch((error) => {
          setStage("Failed to Subscribe , Try Again!!");
        });
    } else setStage("Please Enter valid Email Address");
  };

  return (
    <div style={{marginTop:height}} className="bg-gradient-to-r from-blog-light to-blog-dark h-[60vh] flex flex-col justify-evenly px-[1vw]">
      <div className="flex flex-col flex-wrap justify-evenly">
        <h3>Blogs</h3>
        <p>Subscribe to get latest Finance related blogs instantly!</p>
      </div>
      <form className="flex flex-col w-[90%] sm:w-[80%] md:w-[40%] justify-center h-[10vh]">
  <div className="flex flex-col md:flex-row h-full">
    <div className="w-8/12 h-full">
      <input
        className="outline-none rounded-md px-2 py-4 w-full border h-full"
        placeholder="Enter your Email"
        ref={inptHt}
        type="email"
        name="email"
        id="email"
        value={emailInput}
        onChange={(event) => {
          setEmailInput(event.target.value);
        }}
      />
    </div>

    <div className="w-3/12 h-full ml-2 ">
      <button
        className="text-white rounded-md bg-blue-800 m-0 p-0 px-4 h-[20vh] w-full text-lg flex items-center justify-center"
        onClick={HandleFormSubmit}
        type="submit"
        style={{height:inptHt?.current?.offsetHeight || "50px"}}
      >
        Subscribe
      </button>
    </div>
  </div>
  <div className="text-center mt-4">
    {stage.includes("valid") || stage.includes("Failed") ? (
      <span className="text-red-500">{stage}</span>
    ) : stage.includes("Subscribing") ? (
      <span className="text-yellow-400">{stage}</span>
    ) : (
      <span className="text-green-500">{stage}</span>
    )}
  </div>
</form>

      </div>
  );
};

export default BlogsNArticles;
