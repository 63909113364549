import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "./LLPcard";
import "./LLPcardgrid.css";
import "./LLP.css";
import tradeopp from "./servicesIMG/tradeop.webp";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import HeroSection from "./HeroSection";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const TrademarkOpposition = ({ height }) => {
  // Trademark Opposition grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Understand Opposition Grounds",
      description:
        "Review the reasons provided for the opposition, including any legal or procedural grounds cited against your trademark application.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Prepare Your Defense",
      description:
        "Formulate a robust defense against the opposition by gathering evidence and building arguments to support your trademark application.",
    },
    {
      imagePath: "flexi.webp",
      title: "File Defense Documents",
      description:
        "Submit your defense to the relevant trademark office, ensuring all necessary documents and arguments are included in your response.",
    },
    {
      imagePath: "limltd.webp",
      title: "Attend Hearings",
      description:
        "If required, participate in hearings to present your case in person and address any additional concerns raised by the opposing party.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Follow Up and Monitor",
      description:
        "Keep track of the opposition proceedings and any subsequent actions or requirements, ensuring timely responses to any further developments.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Defense Opportunity -",
      description:
        " Provides a chance to defend your trademark application against opposition, safeguarding your brand rights.",
    },
    {
      imageright: "right.png",
      title: "Professional Guidance -",
      description:
        " Offers access to legal expertise to navigate complex opposition proceedings effectively.",
    },
    {
      imageright: "right.png",
      title: "Preserve Application -",
      description:
        " Helps maintain your trademark application despite challenges, ensuring a clearer path to registration.",
    },
    {
      imageright: "right.png",
      title: "Resolve Disputes -",
      description:
        "Addresses and resolves conflicts that may otherwise hinder the registration process.",
    },
    {
      imageright: "right.png",
      title: "Strengthen Application -",
      description:
        " Allows for refinement of your application in response to valid concerns, potentially enhancing its chances of success.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Complex Process -",
      description:
        "Opposition proceedings can be intricate, requiring detailed legal knowledge and documentation.",
    },
    {
      imageright: "dangerous.webp",
      title: "Cost -",
      description:
        "Legal representation and preparation of defense can incur additional expenses.",
    },
    {
      imageright: "dangerous.webp",
      title: "Time-Consuming - ",
      description:
        "The process can be lengthy and require significant time and effort to address opposition effectively.",
    },
    {
      imageright: "dangerous.webp",
      title: "Risk of Rejection -",
      description:
        "There is a possibility that the opposition may lead to rejection of your trademark application.",
    },
    {
      imageright: "dangerous.webp",
      title: "Additional Requirements -",
      description:
        " May involve further amendments or additional information, complicating the process.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Opposition Notice",
      description:
        "The official notice from the trademark office detailing the grounds for opposition against your trademark application.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Defense Statement",
      description:
        "Your formal response to the opposition, including arguments, evidence, and supporting documents to counter the claims.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Supporting Evidence",
      description:
        "Documentation and evidence supporting your defense, such as proof of use, distinctiveness, or other relevant materials.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Legal Correspondence",
      description:
        "Any communication with legal representatives or the trademark office regarding the opposition and defense.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Hearing Documents",
      description:
        "Documents required for hearings if scheduled, including any additional evidence or statements to be presented.",
    },
  ];

  const steps = [
    {
      title: "Review Opposition Notice",
      description:
        "Examine the notice of opposition to understand the specific grounds and reasons for the opposition.",
    },
    {
      title: "Prepare Defense Materials",
      description:
        "Gather evidence and prepare a detailed defense against the opposition, addressing each point raised.",
    },
    {
      title: "File Defense Documents",
      description:
        "Submit your defense to the trademark office, including all necessary documents and arguments.",
    },
    {
      title: "Participate in Hearings",
      description:
        "Attend any scheduled hearings to present your case and address additional concerns from the opposing party.",
    },
    {
      title: "Monitor Proceedings",
      description:
        "Track the status of the opposition proceedings and respond to any further requirements or developments.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const reasons = [
    {
      
      title: 'Professional Assistance',
      description: 'Expert legal support to help you file or defend a trademark opposition with the right strategies.'
    },
    {
      
      title: 'Protect Your Brand',
      description: 'Ensures that your brand identity remains safeguarded by challenging infringing trademarks effectively.'
    },
    {
      
      title: 'Detailed Analysis',
      description: 'In-depth research and analysis of the trademark opposition to build a strong case.'
    },
    {
      title: 'Timely Filing',
      description: 'Assistance in filing the opposition within the stipulated timeframe to avoid any procedural delays.'
    },
    {
      title: 'Experienced Attorneys',
      description: 'Legal experts with extensive experience in trademark opposition cases to represent your interests.'
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Trademark Opposition"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<TradeOpp/>}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Trademark Opposition Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our Trademark Opposition services assist you in addressing
            challenges raised against your trademark application. We provide
            expert support to navigate the opposition process and secure your
            trademark rights.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Trademark Opposition?
            </h6>

            <p className="mb-4">
              Trademark Opposition is a legal challenge filed by a third party
              against your trademark application. This opposition can be based
              on various factors such as similarity to existing trademarks or
              claims of prior rights. Successfully addressing the opposition is
              crucial to securing your trademark registration.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Trademark Opposition
            </h6>
            <p className="mb-4">
              To oppose a trademark application, you must have a legitimate
              legal interest, such as owning an existing trademark that could be
              confused with the new application. Ensure that you file your
              opposition within the specified period after the publication of
              the trademark application.
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of Trademark Opposition Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-3">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Trademark Opposition
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Process for Trademark Opposition
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {reasons.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function TradeOpp() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Trademark Opposition is a formal procedure where a third party contests
        your trademark application. This opposition could be based on various
        grounds, and handling it effectively is essential to securing your
        trademark rights and advancing your application.
      </p>
      <p>
      Address trademark oppositions with professional support to
      navigate the complexities and protect your brand.
      </p>
    </>
  );
}

export default TrademarkOpposition;
