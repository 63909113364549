import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const WindingUpOfCompanyNavbar = ({ height }) => {
  // Winding Up of a Company grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Understanding Winding Up",
      description:
        "Learn about the process and legal requirements involved in winding up a company, including voluntary and compulsory winding up.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Preparation and Documentation",
      description:
        "Guidance on preparing the necessary documentation and complying with regulatory requirements for winding up a company.",
    },
    {
      imagePath: "flexi.webp",
      title: "Settlement of Liabilities",
      description:
        "Assistance with settling outstanding liabilities and debts before the company can be formally dissolved.",
    },
    {
      imagePath: "limltd.webp",
      title: "Filing Final Returns",
      description:
        "Support in filing final returns and completing all statutory obligations required for the winding up process.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Completion and Closure",
      description:
        "Ensure the company’s closure is legally compliant and all administrative procedures are completed.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Comprehensive Process Management -",
      description:"Expert assistance throughout the entire winding up process."
    },
    {
      imageright: "right.png",
      title:"Regulatory Compliance -",
      description:"Ensures all legal and regulatory requirements are met."
    },
    {
      imageright: "right.png",
      title:"Efficient Documentation -",
      description:"Streamlined handling of all necessary paperwork and filings."
    },
    {
      imageright: "right.png",
      title:"Debt Settlement Support -",
      description:"Guidance on settling outstanding liabilities and obligations."
    },
    {
      imageright: "right.png",
      title:"Smooth Closure -",
      description:"Facilitates a smooth and compliant closure of the company."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complex Procedures -",
      description:"The winding up process can be complex and time-consuming."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Costs -",
      description:"Professional services may involve additional costs."
    },
    {
      imageright: "dangerous.webp",
      title:"Legal Challenges -",
      description:"Navigating legal requirements and potential disputes can be challenging."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Board Resolution",
      description:
        "Resolution passed by the board of directors authorizing the winding up process.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Creditors’ List",
      description:
        "List of all creditors and details of outstanding liabilities to be settled.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Final Accounts",
      description:
        "Final financial statements including balance sheet and profit & loss account.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Returns",
      description: "Final tax returns and documents proving tax compliance.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Dissolution Form",
      description:
        "Form required for applying for the dissolution of the company.",
    },
  ];

  const steps = [
    {
      title: "Board Resolution",
      description:
        "Pass a board resolution to initiate the winding up process and inform all stakeholders.",
    },
    {
      title: "Prepare Financial Statements",
      description:
        "Prepare final accounts and financial statements for the company.",
    },
    {
      title: "Settle Liabilities",
      description:
        "Clear all outstanding debts and liabilities before proceeding with dissolution.",
    },
    {
      title: "File Final Returns",
      description:
        "Submit final returns and other required documents to the relevant authorities.",
    },
    {
      title: "Apply for Dissolution",
      description:
        "Apply for the company’s dissolution with the appropriate regulatory body.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const windingUpBenefits = [
    {
      title: "Expert Legal Guidance",
      description: "Our experienced legal team ensures that your company's winding up process is handled according to all regulatory requirements."
    },
    {
      title: "Complete Documentation Support",
      description: "We assist with all necessary documentation, ensuring that everything is filed correctly to avoid delays and legal issues."
    },
    {
      title: "Efficient Process",
      description: "We streamline the winding up process to ensure it is completed efficiently, allowing you to focus on future ventures."
    },
    {
      title: "Asset Distribution",
      description: "Our experts provide advice on fair and legal distribution of assets during the winding up process, minimizing conflicts."
    },
    {
      title: "Confidentiality Guaranteed",
      description: "We maintain strict confidentiality to protect sensitive business information during the winding up process."
    }
  ];


  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Winding Up of a Company"
        ctaText="Get in touch"
        imagePath="./Wind.webp"
        children={<CompanyTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Winding Up of a Company
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our services for winding up a company provide comprehensive support
            to ensure all legal and regulatory requirements are met. From
            preparing documentation to filing final returns, we assist in every
            step of the winding up process.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Winding Up of a Company?
            </h6>

            <p className="mb-4">
              Winding up of a company refers to the process of dissolving a
              company’s operations, settling its liabilities, and fulfilling all
              legal requirements to close the company. This can be initiated
              voluntarily by the company’s shareholders or by order of a court.
              Our services guide you through this complex process to ensure
              compliance and proper closure.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Winding Up of a Company
            </h6>
            <p className="mb-4">
              To wind up a company, the following requirements and documents are
              necessary:
              <ul>
                <li>
                  <strong>Board Resolution -</strong> Resolution passed by the
                  board of directors authorizing the winding up process.
                </li>

                <li>
                  <strong>Creditors’ List -</strong> List of all creditors and
                  details of outstanding liabilities to be settled.
                </li>

                <li>
                  <strong>Final Accounts -</strong> Final financial statements
                  including balance sheet and profit & loss account.
                </li>

                <li>
                  <strong>Tax Returns -</strong> Final tax returns and documents
                  proving tax compliance.
                </li>

                <li>
                  <strong>Dissolution Form -</strong> Form required for applying
                  for the dissolution of the company.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="flex flex-wrap my-6">
            <h6 className="text-blue-500 text-lg font-semibold text-center">
              Key Features of Our Winding Up Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className=" my-8">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold ">Documents Required for Winding Up of a Company</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold ">Steps to Handle Winding Up of a Company</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {windingUpBenefits.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function CompanyTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        The winding up of a company is a critical process that involves closing down the company's operations, settling its liabilities, and fulfilling all legal requirements. Our services provide comprehensive support throughout this process to ensure a smooth and compliant closure.
      </p>
      <p>
      Facilitate the effective winding up of your company with our expert assistance and comprehensive services.
      </p>
    </>
  );
}

export default WindingUpOfCompanyNavbar;
