import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const TrustRegistration = ({ height }) => {

  // Trust Registration grid items
  const items = [
    { 
      imagePath: "Grp.webp",
      title: 'What is Trust Registration?',
       description: 'Trust registration in India allows individuals to establish a legal entity for charitable, educational, or religious purposes.' 
      },
    { 
      imagePath: "Costeff.webp",
      title: 'Who Can Form a Trust?', 
      description: 'Any group of individuals or a person can form a trust to carry out non-commercial objectives like helping society.'
     },
    { 
      imagePath: "flexi.webp",
      title: 'Key Features of a Trust', 
      description: 'A registered trust gains legal status, enabling it to manage assets and conduct lawful activities.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'Types of Trusts', 
      description: 'Trusts can be public or private, with public trusts aimed at benefiting the general public, while private trusts are for specific individuals.' 
    },
    { 
      imagePath: "lessreg.webp",
      title: 'Legal Governance', 
      description: 'Trusts are governed by state-specific laws like the Indian Trusts Act, 1882, or the state’s Public Trusts Act.' 
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Recognition -",
      description:"Registered trusts have legal standing and can engage in various charitable activities with full legal backing."
    },
    {
      imageright: "right.png",
      title:"Tax Benefits -",
      description:"Trusts can apply for exemptions under Sections 11 and 12 of the Income Tax Act for tax benefits."
    },
    {
      imageright: "right.png",
      title:"Asset Management -",
      description:"Trusts can hold and manage property for the benefit of the public or specific beneficiaries."
    },
    {
      imageright: "right.png",
      title:"Public Confidence -",
      description:"Trusts provide transparency and accountability, increasing trust from donors and authorities."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complex Compliance -",
      description:"Registered trusts must adhere to various legal and financial compliances, including filing annual returns."
    },
    {
      imageright: "dangerous.webp",
      title:"Limited Control -",
      description:"Trustees are legally bound to the objectives of the trust and cannot diverge from its intended purpose."
    },
    {
      imageright: "dangerous.webp",
      title:"Inflexibility -",
      description:"Trusts have rigid structures and are difficult to amend once registered."
    },
    {
      imageright: "dangerous.webp",
      title:"Dependency on Donations -",
      description:"Trusts often rely heavily on donations or grants, limiting operational flexibility."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Trust Deed",
      description: "A legal document that defines the objectives, beneficiaries, and trustees of the trust."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "ID and Address Proof of Settlor",
      description: "The settlor is the person who creates the trust. Their ID and address proof must be submitted."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Trustee Details",
      description: "The names, addresses, and identification documents of all trustees must be provided."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Registered Office Address",
      description: "Proof of the registered office address where the trust will be headquartered is required."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Trust Registration Fee",
      description: "The registration fee needs to be paid as per state regulations."
    }
  ];

  const steps = [
    {
      title: "Prepare Trust Deed",
      description: "Draft the trust deed, clearly outlining the objectives, trustees, and management of the trust."
    },
    {
      title: "Submit Documents",
      description: "Submit the trust deed along with the required documents to the Registrar of Trusts in your state."
    },
    {
      title: "Verification by Registrar",
      description: "The Registrar reviews the documents and may conduct interviews with trustees to ensure compliance."
    },
    {
      title: "Issue Certificate of Registration",
      description: "Once the verification is complete, the trust is registered and the registration certificate is issued."
    },
    {
      title: "Compliance and Maintenance",
      description: "After registration, the trust must comply with annual filings and legal obligations."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];
  return (
    <>
      <div className='sticky z-20' style={{ 'top': '${height}px '}}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Trust Registration"
        ctaText="Get in touch"
        imagePath="./LLP_Icon.webp"
        children={<TrustTo />}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="LLPRegis flex flex-col w-full mt-16 items-center">
          <h6 className='text-2xl text-center font-semibold'>Trust Registration</h6>
          <p className='text-center mt-4'>A legal process to register a trust for charitable or religious purposes under the Indian Trusts Act, 1882.</p>
        </div>

        <div id="secondLP" className="mt-16 max-w-[972px] md:px-16 ">
          <div className=' pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Trust Registration?</h6>

            <p className='mb-4'>Trust registration allows individuals or groups to form a non-profit entity to serve charitable or religious objectives.</p>
          </div>

          <div id="el" className=' mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Trust Registration</h6>
            <p className='mb-4'>To register a trust, a settlor must appoint trustees and clearly define the trust’s objectives and beneficiaries.</p>
          </div>

          <div className="mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="Documents mt-16 ">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for Trust
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="how" className="Howto mt-16 mb-7">
              <div className="registration-steps">
                <h2 className=' text-blue-500 text-lg font-semibold'>How to Register</h2>
                <p >Follow these steps for a smooth registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
            </div>
            </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

function TrustTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Register a trust to operate for charitable, educational, or religious purposes and gain legal standing.
      </p>
      
      <p>Ensure your trust complies with legal norms and can serve its beneficiaries effectively.</p>

      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
}

export default TrustRegistration;