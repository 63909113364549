import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
import ScrollToTopButton from "./ScrollTop";

const TrademarkAssignment = ({ height }) => {
  // Trademark Assignment grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Legal Framework",
      description:
        "Understand the legal framework governing trademark assignment and the requirements for a valid transfer.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Assignment Agreement",
      description:
        "Draft and execute a trademark assignment agreement detailing the terms of the transfer.",
    },
    {
      imagePath: "flexi.webp",
      title: "Recordation Process",
      description:
        "Follow the necessary steps to record the trademark assignment with the relevant authorities.",
    },
    {
      imagePath: "limltd.webp",
      title: "Due Diligence",
      description:
        "Conduct due diligence to ensure that all rights and obligations are properly transferred and documented.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Post-Assignment Compliance",
      description:
        "Ensure compliance with any post-assignment requirements to maintain trademark protection.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Clarity of Ownership -",
      description:"Provides clear documentation of ownership transfer for legal and business purposes."
    },
    {
      imageright: "right.png",
      title:"Market Flexibility -",
      description:"Allows for the transfer of trademarks as part of business sales, mergers, or restructuring."
    },
    {
      imageright: "right.png",
      title:"Legal Protection -",
      description:" Ensures that the new owner receives all legal rights and protections associated with the trademark."
    },
    {
      imageright: "right.png",
      title:"Value Addition -",
      description:" Can increase the value of a business by effectively managing and reallocating trademark assets."
    },
    {
      imageright: "right.png",
      title:"Strategic Growth - ",
      description:" Facilitates strategic business decisions by allowing the reassignment of trademarks to align with new market strategies."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complexity - ",
      description:"Involves complex legal procedures and documentation to ensure a valid and enforceable assignment."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Disputes - ",
      description:"Risk of disputes or challenges if the assignment is not properly documented or if rights are contested."
    },
    {
      imageright: "dangerous.webp",
      title:"Administrative Burden - ",
      description:"Requires careful handling of administrative tasks, including recordation and compliance with legal requirements."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost -",
      description:"May involve legal fees and other costs associated with drafting agreements and filing necessary documentation."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Trademark Assignment Agreement",
      description:
        "A formal document outlining the terms and conditions of the trademark transfer between parties.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Ownership",
      description:
        "Documentation proving the current owner's rights to the trademark being assigned.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Assignment Form",
      description:
        "Completed forms required for recording the assignment with trademark authorities.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identification Documents",
      description:
        "Identification and verification documents for both the assignor and assignee.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Payment Receipts",
      description:
        "Receipts or proof of payment for any fees associated with the trademark assignment process.",
    },
  ];

  const steps = [
    {
      title: "Prepare the Assignment Agreement",
      description:
        "Draft a comprehensive trademark assignment agreement detailing the terms, conditions, and parties involved in the transfer.",
    },
    {
      title: "Gather Required Documents",
      description:
        "Collect all necessary documents, including proof of ownership, identification, and payment receipts.",
    },
    {
      title: "Execute the Agreement",
      description:
        "Have both parties sign the trademark assignment agreement and ensure that all legal requirements are met.",
    },
    {
      title: "Record the Assignment",
      description:
        "File the assignment with the relevant trademark authorities to update the official records and reflect the new ownership.",
    },
    {
      title: "Ensure Compliance",
      description:
        "Monitor and comply with any post-assignment requirements to maintain the validity and protection of the trademark.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseData = [
    {
      title: "Legal Ownership",
      description: "Trademark Assignment ensures the transfer of legal ownership rights to the assignee, providing them with exclusive rights to use the trademark."
    },
    {
      title: "Monetary Benefits",
      description: "The assignor can receive monetary compensation for the transfer of trademark ownership, making it a beneficial transaction for both parties."
    },
    {
      title: "Expands Business Opportunities",
      description: "Assignees can use the acquired trademark to expand their brand's reach, leverage its reputation, and grow their business efficiently."
    },
    {
      title: "Enhances Brand Value",
      description: "Assignment allows businesses to enhance their brand value by gaining ownership of established trademarks, boosting market presence and credibility."
    },
    {
      title: "Ease of Transfer",
      description: "Trademark Assignment is a straightforward process that legally transfers the rights of a trademark without any complications, making it convenient for businesses."
    }
  ];
  

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Trademark Assignment"
        ctaText="Get in touch"
        imagePath="./TradeRigs.webp"
        children={<TradeAssign />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Trademark Assignment Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our Trademark Assignment services ensure a smooth and legally
            compliant transfer of trademark ownership. Whether you're selling
            your business or transferring trademark rights, we provide expert
            support to handle all aspects of the assignment process.
          </p>
        </div>
        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Trademark Assignment?
            </h6>

            <p className="mb-4">
              Trademark Assignment is the legal process of transferring
              ownership rights of a trademark from one party to another. This is
              often necessary in business transactions such as sales, mergers,
              or restructuring, ensuring that the new owner obtains all legal
              rights associated with the trademark.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Trademark Assignment
            </h6>
            <p className="mb-4">
              To be eligible for trademark assignment, ensure the following:
              <ul>
                <li>
                  <strong>Valid Ownership -</strong> The current owner must hold
                  valid ownership rights to the trademark being transferred.
                </li>

                <li>
                  <strong>Completed Documentation -</strong> All required
                  documentation and agreements must be prepared and executed.
                </li>

                <li>
                  <strong>Legal Compliance -</strong> The assignment must comply
                  with all legal requirements and regulations governing
                  trademark transfers.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of Trademark Assignment
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Trademark Assignment
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Trademark Assignment Filing Process
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseData.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ScrollToTopButton/>
    </>
  );
};

function TradeAssign() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
      Trademark Assignment involves the transfer of ownership of a trademark from one party to another. This process is crucial for businesses involved in mergers, acquisitions, or sales of intellectual property assets.
      </p>
      {""}
      <p>
      Ensure a smooth and legally compliant trademark assignment with our expert guidance and support.
      </p>
    </>
  );
}

export default TrademarkAssignment;
