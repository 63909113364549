import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const CompanyAddressChange = ({ height }) => {
  // Company Address Change grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Address Change Notification",
      description:
        "Notify relevant authorities and stakeholders about the change of address.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Document Preparation",
      description:
        "Prepare and update necessary documents reflecting the new address.",
    },
    {
      imagePath: "flexi.webp",
      title: "Legal Compliance",
      description:
        "Ensure compliance with legal requirements and update records with regulatory bodies.",
    },
    {
      imagePath: "limltd.webp",
      title: "Update Business Registrations",
      description:
        "Update address details in business registrations and licenses.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Communicate with Clients",
      description:
        "Inform clients and partners about the address change to avoid disruptions.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Compliance -",
      description:"Ensure all legal and regulatory requirements are met."
    },
    {
      imageright: "right.png",
      title:"Efficiency -",
      description:"Streamline the process of updating your address across various platforms."
    },
    {
      imageright: "right.png",
      title:"Professionalism -",
      description:"Maintain a professional image by promptly updating your address."
    },
    {
      imageright: "right.png",
      title:"Minimize Disruptions -",
      description:"Avoid potential disruptions by informing stakeholders in advance."
    },
    {
      imageright: "right.png",
      title:"Centralized Process -",
      description:"Manage all aspects of the address change in a single process."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Administrative Burden - ",
      description:"The process can be administratively intensive and time-consuming."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Costs -",
      description:"There may be associated costs for updating documents and registrations."
    },
    {
      imageright: "dangerous.webp",
      title:"Risk of Oversight -",
      description:"There's a risk of missing some updates if not managed carefully."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Change Form",
      description:
        "Official form required to notify authorities of the change of address.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Updated Business Registration Certificate",
      description: "Updated certificate reflecting the new address.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of New Address",
      description:
        "Documents verifying the new address, such as utility bills or lease agreements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Board Resolution",
      description:
        "Resolution from the board of directors approving the address change.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Updated Licenses and Permits",
      description: "Updated licenses and permits reflecting the new address.",
    },
  ];

  const steps = [
    {
      title: "Prepare Address Change Form",
      description:
        "Fill out the official form required to notify authorities about the address change.",
    },
    {
      title: "Gather Required Documents",
      description:
        "Collect all necessary documents, such as proof of the new address and board resolutions.",
    },
    {
      title: "Submit Address Change Notification",
      description:
        "Submit the address change form and documents to relevant authorities and regulatory bodies.",
    },
    {
      title: "Update Business Registrations",
      description:
        "Update your business registration certificates and other licenses to reflect the new address.",
    },
    {
      title: "Notify Clients and Stakeholders",
      description:
        "Inform clients, partners, and stakeholders about the address change to ensure smooth communication.",
    },
  ];

  const addressChangeBenefits = [
    {
      title: "Compliance with Regulations",
      description: "We ensure that your company address change is in full compliance with government regulations, avoiding legal issues."
    },
    {
      title: "Seamless Documentation",
      description: "Our team handles all the necessary documentation and filings, making the process smooth and hassle-free for you."
    },
    {
      title: "Expert Guidance",
      description: "Receive expert advice on how to update your address across various departments, ensuring all stakeholders are informed."
    },
    {
      title: "Time-Efficient",
      description: "Our services are designed to expedite the address change process, allowing you to focus on running your business."
    },
    {
      title: "Minimal Disruption",
      description: "We help you manage the address change without causing disruptions to your business operations or communications."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Company Address Change"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ChangeTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="overview" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Company Address Change
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Changing your company’s address requires careful management to
            ensure that all records are updated appropriately. Our service helps
            you navigate this process efficiently and ensures compliance with
            all legal and regulatory requirements.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Company Address Change?
            </h6>

            <p className="mb-4">
              Company Address Change involves updating your business’s
              registered address with regulatory authorities, updating records
              with clients and partners, and ensuring all necessary
              documentation reflects the new address.
            </p>
          </div>

          <div id="application" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Application Process for Address Change
            </h6>
            <p className="mb-4">
              To change your company’s address, follow these steps:
              <ul>
                <li>
                  <strong>Prepare Address Change Form -</strong> Complete the
                  official form required for notifying authorities of the
                  address change.
                </li>

                <li>
                  <strong>Gather Required Documents -</strong> Collect necessary
                  documents such as proof of new address and board resolutions.
                </li>

                <li>
                  <strong>Submit Address Change Notification -</strong> Submit
                  the form and documents to relevant authorities.
                </li>

                <li>
                  <strong>Update Business Registrations -</strong> Update your
                  business registration certificates and licenses with the new
                  address.
                </li>

                <li>
                  <strong>Notify Clients and Stakeholders -</strong> Inform
                  clients, partners, and stakeholders of the address change to
                  avoid disruptions.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="llp-card-grid flex flex-wrap">
            <h6 className="text-blue-500 text-lg font-semibold text-center">
              Key Features of Our Company Address Change Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="documents" className="why-choose mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Documents Required for Company Address Change
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="process" className="how-to-apply mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Steps to Complete Company Address Change
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {addressChangeBenefits.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function ChangeTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Changing your company’s address is an essential administrative task that involves updating records with various authorities and stakeholders. Our service ensures that your address change is managed efficiently and in compliance with all legal requirements.
      </p>
      <p>
      Ensure a smooth transition with our expert assistance in updating your company's address.
      </p>
    </>
  );
}

export default CompanyAddressChange;
