import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import ScrollToTopButton from "./ScrollTop";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const EPFRegistrationNavbar = ({ height }) => {
  // EPF grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Mandatory for Employers",
      description:
        "EPF registration is mandatory for employers with 20 or more employees.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Employee Benefits",
      description:
        "Provides financial security and benefits for employees post-retirement.",
    },
    {
      imagePath: "flexi.webp",
      title: "Tax Benefits",
      description:
        "Contributions to EPF are eligible for tax benefits under Indian tax laws.",
    },
    {
      imagePath: "limltd.webp",
      title: "Interest Accumulation",
      description:
        "EPF contributions earn interest, which is compounded annually.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Employee Coverage",
      description: "Covers employees across various sectors and industries.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Financial Security -",
      description:
        "Ensures a secure financial future for employees after retirement.",
    },
    {
      imageright: "right.png",
      title: "Tax Benefits -",
      description:
        "Contributions and interest earned are tax-exempt under certain conditions.",
    },
    {
      imageright: "right.png",
      title: "Employee Welfare -",
      description:
        "Contributes to the overall welfare and financial health of employees.",
    },
    {
      imageright: "right.png",
      title: "Easy Compliance -",
      description:
        "Simple procedures for both registration and ongoing compliance..",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Mandatory Requirement -",
      description:
        "Employers with fewer than 20 employees are not required to register.",
    },
    {
      imageright: "dangerous.webp",
      title: "Contribution Costs -",
      description:
        "Employers must contribute a percentage of salary towards EPF.",
    },
    {
      imageright: "dangerous.webp",
      title: "Administrative Burden -",
      description:
        "Requires regular maintenance of records and compliance with regulations.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Establishment",
      description:
        "Includes registration documents or proof of business operations.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "For the business and for the authorized signatory.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof",
      description: "Recent utility bills or lease agreements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Employee Details",
      description: "List of employees along with their KYC details.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Details",
      description: "Business bank account details for EPF transactions.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Digital Signature",
      description: "For the authorized person handling the EPF registration.",
    },
  ];

  const steps = [
    {
      title: "Collect Required Documents",
      description: "Gather all necessary documents for EPF registration.",
    },
    {
      title: "Register Online",
      description: "Complete the EPF registration form on the EPFO website.",
    },
    {
      title: "Submit Documents",
      description: "Upload the required documents and submit them online.",
    },
    {
      title: "Verification Process",
      description:
        "The EPFO will verify the submitted information and documents.",
    },
    {
      title: "Receive EPF Registration Number",
      description:
        "Once approved, you will receive a unique EPF registration number.",
    },
    {
      title: "Start EPF Contributions",
      description: "Begin making EPF contributions as per the regulations.",
    },
    {
      title: "Maintain Records",
      description:
        "Keep accurate records of contributions and employee details.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Employee Provident Fund"
        ctaText="Get in touch"
        imagePath="./EPF.webp"
        children={<EPFTO />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            EPF Registration Made Simple with T2F
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Looking to register for Employee Provident Fund (EPF)? T2F
            simplifies the EPF registration process, ensuring compliance with
            all regulations and smooth handling of all formalities. Contact us
            today to get started on securing your employees' future.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16 ">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is EPF?
            </h6>

            <p className="mb-4">
              The Employee Provident Fund (EPF) is a savings scheme managed by
              the EPFO, where both employers and employees contribute towards a
              fund that provides retirement benefits. Here’s why EPF is
              essential:
            </p>
            <div>
              <ul>
                <strong>Mandatory for Employers:</strong> Required for employers
                with 20 or more employees.
              </ul>

              <ul>
                <strong>Employee Benefits:</strong> Provides a financial cushion
                for employees post-retirement.
              </ul>
              <ul>
                <strong>Tax Benefits:</strong> Contributions and interest earned
                are tax-exempt under specific conditions.
              </ul>
            </div>

            <p>
              EPF helps in ensuring financial security and stability for
              employees once they retire from active service.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Registration
            </h6>
            <p className="mb-4">
              To register for EPF, you need to meet the following criteria:
              <ul>
                <li>
                  <strong>Employee Count -</strong> Must have 20 or more
                  employees to be eligible for EPF registration.
                </li>

                <li>
                  <strong>Proof of Establishment -</strong> Valid business
                  registration or establishment proof.
                </li>

                <li>
                  <strong>Bank Account -</strong> Business bank account for EPF
                  transactions.
                </li>
                <li>
                  <strong>Employee Details -</strong> Details of all employees
                  for EPF account creation.
                </li>
              </ul>
            </p>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of EPF
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

            <div className="Documents my-12">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for EPF Registration
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="how" className="Howto mt-16">
              <div className="registration-steps">
                <h2 className="text-blue-500 text-lg font-semibold">How to Register</h2>
                <p>Follow these steps for a smooth EPF registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div id="choose" className="Why my-12">
              <h6 className="text-blue-500 text-lg font-semibold">
                Why Choose T2F for Your EPF Registration
              </h6>
              <p>T2F is your reliable partner for EPF registration:</p>
              <ul >
                <li>
                  <strong>Expert Guidance - </strong> Professional support with
                  the EPF registration process.
                </li>

                <li>
                  <strong>Document Assistance - </strong> Help with gathering
                  and preparing the necessary documents.
                </li>

                <li>
                  <strong>Compliance Assurance -</strong> Ensure adherence to
                  EPF regulations and guidelines.
                </li>

                <li>
                  <strong>Efficient Processing -</strong> Fast and smooth
                  handling of your EPF application.
                </li>

                <li>
                  <strong>Ongoing Support -</strong> Continuous assistance
                  throughout the registration and compliance process.
                </li>

                <li>
                  <strong>Contribution Management -</strong> Support with
                  managing EPF contributions and records.
                </li>
              </ul>
            </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

function EPFTO() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        The Employee Provident Fund (EPF) is a retirement benefits scheme for
        employees, managed by the Employees' Provident Fund Organisation (EPFO).
      </p>
      <p>10K+ Companies Successfully Registered for EPF</p>
    </>
  );
}

export default EPFRegistrationNavbar;
