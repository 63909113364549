import React from "react";
import Card from "./LLPcard";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const Invoicing = ({ height }) => {
  // Invoicing grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Automated Generation",
      description:
        "Generate invoices automatically from your sales data, reducing manual effort and errors.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Customizable Templates",
      description:
        "Use customizable templates to ensure invoices match your branding and business needs.",
    },
    {
      imagePath: "flexi.webp",
      title: "Compliance Features",
      description:
        "Ensure your invoices comply with tax regulations and standards, avoiding legal issues.",
    },
    {
      imagePath: "limltd.webp",
      title: "Integrated Payment Options",
      description:
        "Integrate with payment gateways for seamless invoice payments and tracking.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Real-Time Tracking",
      description:
        "Track invoice statuses in real-time, from creation to payment, for better financial management.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Automated Generation -",
      description:"Automatically generate invoices from your sales records, saving time and reducing errors."
    },
    {
      imageright: "right.png",
      title:"Customizable Templates -",
      description:"Personalize invoice templates to reflect your brand and meet specific business requirements."
    },
    {
      imageright: "right.png",
      title:"Compliance Features -",
      description:"Includes features to ensure that invoices comply with tax laws and regulatory standards."
    },
    {
      imageright: "right.png",
      title:"Integrated Payment Options - ",
      description:"Seamlessly integrate with payment gateways to facilitate easy invoice payments and tracking."
    },
    {
      imageright: "right.png",
      title:"Real-Time Tracking - ",
      description:"Monitor the status of invoices in real-time, helping you manage finances more effectively."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"System Dependency -",
      description:"Requires reliable software and internet access to manage and generate invoices effectively."
    },
    {
      imageright: "dangerous.webp",
      title:"Learning Curve -",
      description:" May involve a learning curve for users unfamiliar with invoicing software or features."
    },
    {
      imageright: "dangerous.webp",
      title:"Integration Challenges - ",
      description:" Integrating with existing systems or payment gateways can sometimes be complex."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost of Software - ",
      description:" There may be costs associated with invoicing software or services, depending on the features you choose."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Company Details",
      description:
        "Include your company's legal name, address, and contact information on the invoice.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Client Information",
      description:
        "Add the client's name, address, and contact details to ensure proper invoicing.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Invoice Details",
      description:
        "Specify invoice number, date, and payment terms for clarity and record-keeping.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Product/Service Details",
      description:
        "Provide a detailed description of the products or services provided, including quantities and pricing.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Information",
      description:
        "Include applicable taxes and tax rates, if any, to ensure compliance with tax regulations.",
    },
  ];

  const steps = [
    {
      title: "Set Up Invoice Templates",
      description:
        "Customize your invoice templates to include all necessary business and client details.",
    },
    {
      title: "Input Sales Data",
      description:
        "Enter the details of the sales transactions that need to be invoiced, including products or services provided.",
    },
    {
      title: "Generate Invoice",
      description:
        "Create the invoice using your chosen template and sales data. Review for accuracy.",
    },
    {
      title: "Send Invoice",
      description:
        "Send the invoice to the client via email or any other preferred method.",
    },
    {
      title: "Track Payment",
      description:
        "Monitor the payment status of the invoice and follow up if necessary.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Invoicing"
        ctaText="Get in touch"
        imagePath="./Invoicing.webp"
        children={<StremIn />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Invoicing Made Easy
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our invoicing solutions are designed to simplify the billing
            process, ensuring accurate and efficient management of your
            financial transactions. From automated generation to real-time
            tracking, we've got you covered.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Invoicing?
            </h6>

            <p className="mb-4">
              Invoicing is the process of generating and issuing bills for goods
              or services provided to clients. It is a critical aspect of
              financial management that ensures you receive payment for your
              business transactions in a timely manner.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Invoicing
            </h6>
            <p className="mb-4">
              To create and manage invoices, you need to:
              <ul>
                <li>
                  <strong>Company Details -</strong> Your business's legal name,
                  address, and contact information.
                </li>

                <li>
                  <strong>Client Information -</strong> The client's name,
                  address, and contact details for accurate billing.
                </li>

                <li>
                  <strong>Invoice Details -</strong> Invoice number, date, and
                  payment terms for proper record-keeping.
                </li>

                <li>
                  <strong>Product/Service Details -</strong> Detailed
                  descriptions of the goods or services provided.
                </li>

                <li>
                  <strong>Tax Information -</strong> Applicable taxes and tax
                  rates, if any, for compliance with tax regulations.
                </li>
              </ul>
            </p>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of Our Invoicing Solutions
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>


          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Invoicing</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Generate Invoices</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Our Invoicing Solutions?
            </h6>
            <p>
              Our invoicing solutions are designed to make billing easier, more
              accurate, and compliant with all necessary regulations. Partner
              with us to streamline your invoicing processes and enhance
              financial management.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function StremIn() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
       Efficient invoicing is crucial for managing business transactions. Our solutions offer automated, customizable invoicing to simplify your financial processes and ensure accuracy.
      </p>
      <p>
      Enhance your business operations with our advanced invoicing system designed to streamline and manage your billing efficiently.
      </p>
    </>
  );
}

export default Invoicing;
