import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import HeroSection from './HeroSection';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const BrokerLicense = ({ height }) => {

  // Broker License grid items
  const items = [
    {imagePath: "Grp.webp", title: 'What is a Broker License?', description: 'A broker license grants legal authority to individuals or firms to engage in buying, selling, or renting real estate or financial assets on behalf of clients.' },
    {imagePath: "Costeff.webp", title: 'Who Can Apply for a Broker License?', description: 'Individuals or firms intending to act as intermediaries in real estate transactions or financial dealings can apply for a broker license.' },
    { imagePath: "flexi.webp",title: 'Types of Broker Licenses', description: 'Broker licenses can be for real estate, insurance, or financial securities, depending on the area of specialization.' },
    { imagePath: "limltd.webp",title: 'Validity and Renewal', description: 'Broker licenses are valid for a specific period and require periodic renewal to maintain legal status.' },
    { imagePath: "lessreg.webp",title: 'Regulatory Compliance', description: 'Licensed brokers must adhere to industry regulations and ethical standards to ensure fair dealing and consumer protection.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Standing -",
      description:"A broker license provides legal status to engage in intermediary transactions."
    },
    {
      imageright: "right.png",
      title:"Industry Credibility -",
      description:"Enhances trust and reputation in the market among clients and peers."
    },
    {
      imageright: "right.png",
      title:"Business Opportunities -",
      description:"Broadens the scope for business expansion in real estate or financial markets."
    },
    {
      imageright: "right.png",
      title:"Consumer Trust -",
      description:"A licensed broker builds confidence among clients for secure and reliable transactions."
    },

  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Strict Regulations -",
      description:"The license process includes compliance with rigorous laws and industry standards."
    },
    {
      imageright: "dangerous.webp",
      title:"Ongoing Maintenance -",
      description:"Renewal and continued compliance require consistent effort and cost."
    },
    {
      imageright: "dangerous.webp",
      title:"High Costs -",
      description:"Fees for obtaining and renewing licenses can be high, especially for larger firms."
    },
    {
      imageright: "dangerous.webp",
      title:"Market Competition -",
      description:"Intense competition in the brokerage market can make sustaining the business challenging."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Application Form",
      description: "A completed and signed application form is necessary for starting the broker license process."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Business Address",
      description: "Evidence of the business’s registered address is required for verification."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Qualification Certificates",
      description: "Certificates showing the applicant's qualifications in brokerage, finance, or real estate."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Background Check",
      description: "Applicants must undergo a criminal background check to ensure they meet legal requirements."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "License Fees",
      description: "Proof of payment for the associated broker license fees must be submitted."
    }
  ];

  const steps = [
    {
      title: "Meet Eligibility Criteria",
      description: "Ensure you meet the minimum requirements for education, experience, and background checks."
    },
    {
      title: "Submit Application",
      description: "Complete the application form and submit it along with the necessary documents."
    },
    {
      title: "Pass Licensing Exam",
      description: "For certain broker licenses, you must pass a government-mandated exam to qualify."
    },
    {
      title: "Receive Approval",
      description: "After verification and approval from the regulatory body, you will receive your license."
    },
    {
      title: "Maintain Compliance",
      description: "Stay compliant with all regulatory and renewal requirements to keep your license active."
    }
  ];
  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const brokerBenefits = [
    {
      title: "Legal Authorization",
      description: "Obtaining a broker license grants legal permission to act as a broker, ensuring compliance with regulations and avoiding legal issues.",
    },
    {
      title: "Access to Multiple Trading Platforms",
      description: "Licensed brokers can access a variety of trading platforms, providing more opportunities for business and client transactions.",
    },
    {
      title: "Increased Credibility",
      description: "A broker license enhances credibility and trust among clients, as it signifies adherence to industry standards and regulations.",
    },
    {
      title: "Better Business Opportunities",
      description: "Licensed brokers can explore partnerships and collaborations with other financial institutions, leading to better business opportunities.",
    },
    {
      title: "Protection Against Fraud",
      description: "A broker license ensures proper vetting and monitoring by regulatory authorities, reducing the risk of fraudulent activities.",
    },
  ];

  return (
    <>
      <div className='sticky z-20' style={{ 'top': '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
           {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Broker License"
        ctaText="Get in touch"
        imagePath="./MSME.webp"
        children={<MSMETo />}
      />

      <div className='w-full flex flex-col px-3 md:px-8'>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>Broker License</h6>
          <p className='max-w-[1086px] text-center mt-4'>A license required to legally act as a broker in real estate, financial, or insurance transactions.</p>
        </div>

            <div className='mt-16 max-w-[972px] md:px-16'>

          <div className=' pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Broker License?</h6>

            <p className='mb-4'>A broker license allows individuals or firms to legally operate as intermediaries in real estate, insurance, or financial transactions.</p>
          </div>

          <div id="el" className=' mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for a Broker License</h6>
            <p className='mb-4'>Applicants must meet educational, experience, and ethical requirements to qualify for a broker license.</p>
          </div>

          <div id="features" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of a Broker License</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Broker License</h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Obtain a Broker License</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
          </div>

          <div className='my-5'>
          <h6 className="text-blue-500 text-lg font-semibold"> Why Choose </h6>
          <ul className="list-disc">
              {brokerBenefits.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
              </div>
      </div>

      <ScrollToTopButton />
    </>
  );
};

function MSMETo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
      Obtain a broker license to legally operate in real estate or financial markets as an intermediary.
      </p>

      <p>Ensure compliance with regulatory standards while expanding your business opportunities in brokerage services.</p>
    </>
  );
};

export default BrokerLicense;