import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import ScrollToTopButton from "./ScrollTop";
import { useNavigate } from "react-router-dom";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Advantages from "./llp/Advantages";
import Disadvantages from "./llp/Disadvantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const SecondaryNavbar = ({ height }) => {
  const navigate = useNavigate();

  const advantageList = [
    {
      imageright: "right.png",
      title: "Independent Legal Status - ",
      parag: "Can operate independently and undertake legal actions.",
    },
    {
      imageright: "right.png",
      title: "Protected Liability",
      parag:
        "Partner's personal assets are safeguarded against business liabilities.",
    },
    {
      imageright: "right.png",
      title: "Affordable Setup",
      parag: "Can operate independently and undertake legal actions.",
    },
    {
      imageright: "right.png",
      title: "Flexible Capital Contribution -",
      parag: "No fixed minimum capital requirement.",
    },
  ];
  const disadvantagesList = [
    {
      imageright: "dangerous.webp",
      title: "Regulatory Compliance - ",
      parag: "Penalties for failing to meet regulatory requirements.",
    },
    {
      imageright: "dangerous.webp",
      title: "Operational Risks - ",
      parag:
        "Must cease operations if fewer than two partners remain or if it cannot settle its debts.",
    },
    {
      imageright: "dangerous.webp",
      title: " Investment Limitations -",
      parag:
        "Challenging to attract large-scale investments compared to public companies.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Registered Office \n Address",
      para: "Includes rent agreement and utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof",
      para: "Recent documents like bank statements or utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Residential Proof",
      para: "Recent documents like bank statements or utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport-size Photos",
      para: "With a white background.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card/ID Proof",
      para: "For all partners.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Digital Signature Certificate (DSC)",
      para: "Required for at least one designated partner.",
    },
  ];

  const steps = [
    {
      title: "Get a Digital Signature Certificate (DSC)",
      para: "Required for all partners.",
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      para: "For all designated partners.",
    },
    {
      title: "Select and Reserve Your LLP Name",
      para: "Ensure it meets MCA guidelines.",
    },
    {
      title: "Complete Form FiLLiP",
      para: "Submit information about the LLP, partners, and registered office.",
    },
    {
      title: "Draft and File LLP Agreement",
      para: "Must be notarized and filed within 30 days of incorporation.",
    },
    {

      title: "Receive Certificate of Incorporation",
      para: "Issued by the Registrar of Companies (RoC).",
    },
    {
      
      title: "Apply for PAN and TAN",
      para: "Essential for tax purposes.",
    },
  ];

  const llpPoints = [
    {
      title: "Legal Entity",
      description:
        "Like a company, an LLP is a separate legal entity. This means it can enter into contracts, own property, and take legal actions independently.",
    },
    {
      title: "Limited Liability",
      description:
        "Partners are only liable for their own actions and contributions, keeping their personal assets safe.",
    },
    {
      title: "Operational Flexibility",
      description:
        " LLPs offer the ease of partnership operations with the added advantages of a corporate structure.",
    },
  ];

  const elPoints = [
    {
      title: "Minimum 2 Partners ",
      description:"An LLP must have at least two partners, with no maximum limit.",
    },
    {
      title: "Designated Partners  ",
      description:"At least two designated partners must be individuals, and one must be an Indian resident.",
    },
    {
      title: "Authorized Capital ",
      description:"An LLP should have a minimum authorized capital of Rs. 1 lakh",
    },
    {
      title: "Corporate Partners",
      description:"If a corporate body is involved, a natural person must represent it.",
    },
  ];

  const featuresCard = [
    {
      imagePath: "Grp.webp",
      title: "Distinct Legal Identity",
      para: "Operates as a separate entity from its owners.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Cost Efficient",
      para: "Lower setup and maintenance costs compared to companies.",
    },
    {
      imagePath: "flexi.webp",
      title: "Flexible Partner Structure",
      para: "Requires at least one designated partner with no cap on the total number of partners.",
    },
    {
      imagePath: "limltd.webp",
      title: "Limited Liability",
      para: "Partners are only liable for their contributions.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Less Regulation",
      para: "Fewer compliance requirements than larger entities.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];
  return (
    <>
      <div className="sticky z-50" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Limited Liability Partnership(LLP)"
        ctaText="Get in touch"
        imagePath="./LLP_Icon.webp"
        children={<LLpDescription />}
      />

      <div id="mainLLP" className="w-full flex flex-col px-3 md:px-8 ">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            LLP Registration Made Simple with dialT2F
          </h6>
          <p className=" max-w-[1086px] text-center mt-4">
            Are you ready to set up a business that offers both flexibility and
            legal protection? Look no further than a Limited Liability
            Partnership (LLP). With LLPs, you get the best of both worlds— the
            collaborative spirit of partnerships combined with the security of
            limited liability. At dialT2F, we make LLP registration in India
            straightforward and efficient. Our experts will guide you through
            every step, ensuring a smooth process from start to finish. Contact
            us today to begin your journey toward a successful LLP.
          </p>
        </div>

        <div id="secondLP" className="mt-16 max-w-[972px] md:px-16 ">
          <div className=" w-auto h-[15%] pr-2 pt-5 ">
            <h6 className="text-blue-500 text-2xl  font-semibold">
              What is LLP?
            </h6>
            <p className="mb-4">
              A Limited Liability Partnership (LLP) is a modern business
              structure that merges the benefits of a partnership with the
              security of a company. Here’s what sets LLPs apart:
            </p>
            <ul className="list-disc">
              {llpPoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
            <p>
              Introduced in India in 2008 under the Limited Liability
              Partnership Act, LLPs are a versatile option for businesses of all
              sizes.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Registration
            </h6>
            <p className="mb-4">
              To register an LLP in India, you need to meet the following
              criteria:
            </p>
            <ul className="list-disc">
              {elPoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>


          <div className="mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {featuresCard.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.para}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantageList.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.parag}
                />
              ))}
            </div>
            <div className="">
              <h2>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantagesList.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.parag}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="secondLLP pr-2  mt-16">
            <div className="Choosing">
              <h6 className="text-blue-500 text-lg font-semibold">
                Choosing your LLP Name
              </h6>
              <ul>
                <li>
                  <p>
                    <strong>Unique and Available - </strong> Ensure your LLP
                    name is unique and not already in use.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Descriptive - </strong> Reflects your business
                    activities clearly.
                  </p>
                </li>
                <li>
                  <p>
                    <strong>Suffix Requirement -</strong>Must end with “LLP” or
                    “Limited Liability Partnership".
                  </p>
                </li>
              </ul>
            </div>

            <div className="Documents ">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for LLP Registration
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.para}
                  />
                ))}
              </div>
            </div>
            <div id="how" className="Howto mt-16">
              <div className="registration-steps">
                <h2>How to Register</h2>
                <p >Follow these steps for a smooth registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.para}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div id="choose" className="Why mt-12">
              <h6 className="text-blue-500 text-lg font-semibold">
                Why Choose dialT2F for your LLP Registration
              </h6>
              <p>dialT2F is your trusted partner for LLP registration:</p>
              <ul>
                <li>
                  <p>
                  <strong>LLP Agreement Drafting - </strong> Expert help with
                  drafting and filing your LLP agreement.</p>
                </li>
                <li>
                  <p>
                  <strong>Name Reservation - </strong> We’ll help you secure
                  your desired LLP name.</p>
                </li>
                <li>
                  <p>
                  <strong>Expert Support -</strong>Our experienced team ensures
                  a smooth registration process.</p>
                </li>
                <li>
                  <p>
                  <strong>Ongoing Assistance -</strong>We provide updates and
                  support throughout your registration journey.</p>
                </li>
                <li>
                  <p>
                  <strong>DSC and DIN Assistance -</strong>We facilitate
                  obtaining necessary digital signatures and identification
                  numbers.
                  </p>
                </li>
                <li>
                  <p>
                  <strong>PAN and TAN Application -</strong>Simplified
                  application process for tax IDs.</p>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
        <ScrollToTopButton />
        </>
  );
};

function LLpDescription() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        A Limited Liability Partnership (LLP) is a hybrid business structure
        that combines elements of both partnerships and corporations.
      </p>

      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
};
export default SecondaryNavbar;
