import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import FeatureCard from './FeatureCard';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const NBFNavbar = ({ height }) => {

  // NBFC service grid items
  const items = [
    { 
      imagePath: "Grp.webp",
      title: 'Regulated by RBI', 
      description: 'NBFCs are strictly regulated by the Reserve Bank of India.' 
    },
    { 
      imagePath: "Costeff.webp",
      title: 'Wide Financial Services', 
      description: 'Offers a variety of financial services including loans, investments, and asset financing.' 
    },
    { 
      imagePath: "flexi.webp",
      title: 'Flexibility in Operations', 
      description: 'NBFCs have more operational flexibility compared to traditional banks.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'No Requirement for Full Banking License', 
      description: 'NBFCs don’t need a full banking license, reducing regulatory burden.' 
    },
    { 
      imagePath: "lessreg.webp",
      title: 'Investment Opportunities', 
      description: 'Provides opportunities for financial growth through investment services.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Diverse Financial Services -",
      description:"NBFCs offer a range of services including loans, asset financing, and investments."
    },
    {
      imageright: "right.png",
      title:"Less Stringent Regulations -",
      description:"Compared to banks, NBFCs face fewer restrictions in operations."
    },
    {
      imageright: "right.png",
      title:"Growth Opportunities -",
      description:"NBFCs cater to sectors and demographics underserved by traditional banks."
    },
    {
      imageright: "right.png",
      title:"Flexible Credit Policies -",
      description:"Easier credit policies make it accessible for various customer segments."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"No Demand Deposit -",
      description:"NBFCs are not allowed to accept demand deposits, limiting liquidity options."
    },
    {
      imageright: "dangerous.webp",
      title:"Dependence on Borrowing -",
      description:"NBFCs rely heavily on external borrowing for funds."
    },
    {
      imageright: "dangerous.webp",
      title:"Regulatory Challenges -",
      description:"Despite flexibility, NBFCs must still comply with several RBI guidelines."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Certificate of Incorporation",
      description: "Issued by the Registrar of Companies (RoC)."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Detailed Business Plan",
      description: "A comprehensive business plan outlining financial services, target market, and growth projections."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Director and Shareholder Details",
      description: "Includes PAN, Aadhaar, and other identification proofs."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Audited Financial Statements",
      description: "For at least three years if applicable, along with the balance sheet."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Net Owned Funds (NOF) Proof",
      description: "A minimum of Rs. 2 crores of net owned funds is required."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Registered Office Proof",
      description: "Address proof of the registered office of the NBFC."
    }
  ];

  const steps = [
    {
      title: "Company Incorporation",
      description: "Ensure your NBFC is registered as a company under the Companies Act."
    },
    {
      title: "Secure Net Owned Funds",
      description: "Ensure a minimum of Rs. 2 crore of Net Owned Funds (NOF)."
    },
    {
      title: "Submit Online Application",
      description: "Apply through the RBI’s official portal and attach all required documents."
    },
    {
      title: "Conduct Due Diligence",
      description: "Ensure all directors and promoters meet the 'fit and proper' criteria set by the RBI."
    },
    {
      title: "Compliance with Regulatory Framework",
      description: "Ensure adherence to RBI guidelines on capital adequacy and financial reporting."
    },
    {
      title: "Receive NBFC License",
      description: "Upon verification, the RBI issues the NBFC registration certificate."
    }
  ];

  const whychoose = [ 
    {
      title: 'Flexible Financial Operations',
      description: 'NBFCs offer a wide range of financial services like loans, credit facilities, and investment products without being as heavily regulated as banks, allowing more operational flexibility.',
    },
    {
      title: 'Diverse Business Opportunities',
      description: 'NBFCs can engage in various financial activities such as asset financing, hire purchase, leasing, and micro-financing, enabling diverse revenue streams.',
    },
    {
      title: 'Access to Financial Markets',
      description: 'With NBFC registration, companies can raise capital through debt instruments, debentures, and other means, providing access to essential funds for business growth.',
    },
    {
      title: 'No Requirement for CRR and SLR',
      description: 'Unlike banks, NBFCs are not required to maintain Cash Reserve Ratio (CRR) or Statutory Liquidity Ratio (SLR), reducing the burden of compliance and improving liquidity management.',
    },
    {
      title: 'Government Support and Schemes',
      description: 'The government and RBI offer various schemes and incentives for NBFCs, including priority sector lending benefits, making it easier for NBFCs to grow and sustain operations.',
    },
  ]

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="NBFC Registration"
        ctaText="Get in touch"
        imagePath="./LLP_Icon.webp"
        children={<NBFCregis />}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8'>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>NBFC Registration Simplified with T2F</h6>
          <p className=' max-w-[1086px] text-center mt-4'>At T2F, we help you navigate the NBFC registration process, ensuring compliance with RBI regulations and a smooth setup. Contact us today to begin your NBFC journey and start providing financial services in India.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16 ' >

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is an NBFC?</h6>

            <p className='mb-4'>A Non-Banking Financial Company (NBFC) is a financial institution that offers services similar to banks, including loans and asset financing, but doesn’t have a full banking license. NBFCs are regulated by the RBI to ensure compliance and financial health.</p>

            <p>With flexible financial services and regulatory advantages, NBFCs serve as a critical part of India’s financial landscape.</p>
          </div>

          <div id="el" className='pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register as an NBFC in India, the following criteria must be met:

              <ul>
                <li><strong>Minimum Net Owned Funds -</strong> At least Rs. 2 crore of NOF is mandatory.</li>
                <li><strong>Minimum Directors -</strong> A minimum of two directors, with at least one being a resident of India.</li>
                <li ><strong>Company Incorporation -</strong> The company must be registered under the Companies Act.</li>
                <li ><strong>Business Plan -</strong> A detailed plan outlining the NBFC’s services and financial operations is required.</li>
              </ul>
            </p>
          </div>

          <div className='LLP-content  ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Features of NBFC Registration</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className="text-blue-500 text-lg font-semibold" >Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="Documents ">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for LLP Registration
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="how" className="Howto mt-16">
              <div className="registration-steps">
                <h2 className="text-blue-500 text-lg font-semibold">How to Register</h2>
                <p >Follow these steps for a smooth registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div id="choose" className="Why mt-12">
              <h6 className="text-blue-500 text-lg font-semibold">
                Why Choose dialT2F for your LLP Registration
              </h6>
              <p>dialT2F is your trusted partner for LLP registration:</p>
              <ul>
              {whychoose.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
              </ul>
            </div>

        </div>
      </div>
    </>
  );
};

function NBFCregis() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
      A Non-Banking Financial Company (NBFC) offers financial services such as loans, investments, and asset financing without holding a full banking license.
      </p>
      <p>Join 10K+ Registered NBFCs with Our Assistance</p>
    </>
  );
}

export default NBFNavbar;