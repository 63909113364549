import React from "react";
import Card from "./LLPcard";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import FeatureCard from "./FeatureCard";
import HowToReg from "./llp/HowToReg";

const RegistrationCancellation = ({ height }) => {
  // Registration Cancellation / Revocation grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "What is Registration Cancellation?",
      description:
        "Registration cancellation is the process of formally terminating a registered entity’s status to cease its legal existence.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Who Can Apply for Revocation?",
      description:
        "Typically, the registered entity, its representatives, or regulatory authorities can apply for revocation.",
    },
    {
      imagePath: "flexi.webp",
      title: "Reasons for Cancellation",
      description:
        "Common reasons include non-compliance, prolonged inactivity, or failure to meet legal obligations.",
    },
    {
      imagePath: "limltd.webp",
      title: "Consequences of Cancellation",
      description:
        "Cancellation leads to the removal of the entity’s legal status and the cessation of all associated rights and obligations.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Reinstatement Possibilities",
      description:
        "Depending on the jurisdiction, there might be options to reinstate a canceled registration under certain conditions.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Preventing Legal Issues -",
      description:" Reduces risks associated with inactive or non-compliant registrations."
    },
    {
      imageright: "right.png",
      title:"Cost Efficiency -",
      description:" Eliminates ongoing costs related to maintaining unnecessary registrations."
    },
    {
      imageright: "right.png",
      title:"Operational Focus -",
      description:" Allows the entity to concentrate resources on active and compliant operations."
    },
    {
      imageright: "right.png",
      title:"Clarity in Legal Standing -",
      description:" Ensures legal records reflect only active and compliant entities."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Loss of Rights - ",
      description:"Revocation results in the loss of legal rights and protections associated with the registration."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Financial Loss -",
      description:"May lead to financial repercussions or loss of business opportunities."
    },
    {
      imageright: "dangerous.webp",
      title:"Complex Process -",
      description:"The cancellation process can be complex and require extensive documentation."
    },
    {
      imageright: "dangerous.webp",
      title:"Difficulty in Reinstatement -",
      description:"Reinstating a revoked registration may be challenging and not always possible."
    }
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Non-Compliance",
      description:
        "Documentation showing the registered entity’s failure to comply with legal requirements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Inactivity",
      description:
        "Evidence demonstrating that the entity or trademark has been inactive for a specified period.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Cancellation Request Form",
      description:
        "A formal request form for the cancellation or revocation of the registration.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Affidavits and Declarations",
      description:
        "Legal affidavits or declarations supporting the cancellation request.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Payment Evidence",
      description:
        "Receipts or proof of payment for any fees associated with the cancellation process.",
    },
  ];

  const steps = [
    {
      title: "Assess Grounds for Cancellation",
      description:
        "Determine if there are valid reasons for canceling the registration based on inactivity or non-compliance.",
    },
    {
      title: "Gather Required Documentation",
      description:
        "Collect all necessary documents, including proof of non-compliance and application forms.",
    },
    {
      title: "Submit Cancellation Application",
      description:
        "File the cancellation application with the appropriate authority along with the required documentation.",
    },
    {
      title: "Review and Decision",
      description:
        "The authority will review the application and make a decision regarding the cancellation.",
    },
    {
      title: "Post-Cancellation Actions",
      description:
        "Complete any required follow-up actions to ensure the registration is properly removed from records.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseCancellation = [
    {
      title: "Expert Guidance",
      description: "Our team provides expert guidance throughout the registration cancellation process, ensuring all steps are followed accurately."
    },
    {
      title: "Time-Efficient",
      description: "We handle the process swiftly, minimizing delays and ensuring your cancellation or revocation is completed efficiently."
    },
    {
      title: "Comprehensive Support",
      description: "From documentation to legal formalities, we offer comprehensive support to streamline the entire process."
    },
    {
      title: "Transparent Process",
      description: "Our transparent approach keeps you informed at every step, with clear communication and updates on your request status."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Registration Cancellation / Revocation"
        ctaText="Get in touch"
        imagePath="./GSTcancel.webp"
        children={<RegCanTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Registration Cancellation / Revocation
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            The formal process of terminating the registration status of
            entities or trademarks, often due to inactivity or non-compliance.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Registration Cancellation?
            </h6>

            <p className="mb-4">
              Registration cancellation involves formally terminating the legal
              status of a registered entity or trademark, leading to the
              cessation of its existence and associated rights.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Revocation
            </h6>
            <p className="mb-4">
              Entities or trademarks that have failed to meet legal requirements
              or have been inactive for an extended period may be eligible for
              revocation.
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Features of Registration Cancellation
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Cancellation
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Process for Cancellation
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseCancellation.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function RegCanTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Cancel or revoke registrations when entities or trademarks fail to comply with legal requirements or become inactive.
      </p>
      <p>
      Ensure accurate legal records and avoid unnecessary costs by canceling registrations that are no longer needed.
      </p>
    </>
  );
}
export default RegistrationCancellation;
