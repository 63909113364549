import React, { useState } from "react";
import ArrowDownIcon from "./icons/ArrowDown";
// import ArrowDownIcon from "./icons/ArrowDownIcon";

function FAQ() {
  const [activeKey, setActiveKey] = useState("0"); // Set initial active key to "0"

  const faqs = [
    {
      id: "0",
      question: "Are there any hidden fees?",
      answer:
        "ABC company has no hidden things; their services are very transparent.",
    },
    {
      id: "1",
      question: "What services does T2f provide?",
      answer:
        "ABC company has no hidden things; their services are very transparent.",
    },
    {
      id: "2",
      question: "How long does it take to complete the company registration?",
      answer:
        "ABC company has no hidden things; their services are very transparent.",
    },
    {
      id: "3",
      question: "Is the consultation really free?",
      answer:
        "ABC company has no hidden things; their services are very transparent.",
    },
  ];

  const handleToggle = (key) => {
    setActiveKey(activeKey === key ? null : key);
  };

  return (
    <div className="Faq-container w-full md:px-16" id="faqq">
      <h4 className="Faq-heading">Frequently Asked Questions</h4>

      <div className="flex flex-col space-y-5 py-12">
        {faqs.map((faq) => (
          <div className="flex flex-col px-7 bg-[#F1F8FE]" key={faq.id}>
            <div
              className="flex justify-between items-center py-4 cursor-pointer"
              onClick={() => setActiveKey(activeKey === faq.id ? null : faq.id)}
            >
              <span className="font-bold">{faq.question}</span>
              <span className={`${activeKey === faq.id ? "rotate-180" : ""}`}>
                <ArrowDownIcon />
              </span>
            </div>
            <div className={`${activeKey === faq.id ? "block" : "hidden"}`}>
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
