import React, { useEffect, useState } from 'react'
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import ScrollToTopButton from './ScrollTop';
import { useParams } from 'react-router-dom';

const Sample2 = () => {

  const {pageNum}=useParams();
    const [page,setPage]=useState(0);


    useEffect(()=>{
      const scrollToTop = () => {
          window.scrollTo({
              top: 0,
              behavior: "smooth",
          });
      };
      scrollToTop();
      setPage(pageNum);
  },[pageNum]);

  const pages=[
    {id:0,pageName:"Privacy Policy"},
    {id:1,pageName:"Refund Policy"},
    {id:2,pageName:"Terms of Service"},
    {id:3,pageName:"Disclaimer"},
  ];

  const comps=
  [Page1,Page2,Page3,Page4];

  


  return (
    <div className="div1 flex flex-row flex-wrap mt-[5%] mx-[5%] h-[auto] ">
      <div className='div2 w-[20%]  flex flex-col'>
       <div><h3>Legal Pages:</h3></div>
       <div className='div4 flex flex-col mt-[10%] pr-6'>
       {
        pages.map((page,index)=>(
           <>
           <div key={index} className='flex w-[80%] flex-row justify-evenly items-center ml-[1%] cursor-pointer hover:text-blue-500' onClick={()=>setPage(index)}>
            <span className='mr-1'>{page?.pageName}</span> 
            &gt;
            </div>
            <hr />
            </>
        ))
       }
       </div>
      </div>
        {
          comps[page]()
        }
        <ScrollToTopButton/>
      </div>
  );


}

export default Sample2
