import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const TaxSavingServices = ({ height }) => {
  // Tax Saving Services grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "High Returns",
      description:
        "Fixed Deposits and Bonds offer competitive interest rates to maximize your returns.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Tax Benefits",
      description:
        "Enjoy tax deductions under Section 80C of the Income Tax Act on investments in specified bonds.",
    },
    {
      imagePath: "flexi.webp",
      title: "Security",
      description:
        "Investments in FDs and Bonds are typically low-risk and secure.",
    },
    {
      imagePath: "limltd.webp",
      title: "Flexible Tenure",
      description:
        "Choose from a variety of tenures that suit your financial goals.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Interest Options",
      description:
        "Select from monthly, quarterly, or annual interest payment options.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"High Returns -",
      description:"Fixed Deposits and Bonds offer attractive interest rates for better returns on your investment."
    },
    {
      imageright: "right.png",
      title:"Tax Benefits -",
      description:"Avail tax deductions under Section 80C of the Income Tax Act for investments in certain bonds."
    },
    {
      imageright: "right.png",
      title:"Security -",
      description:"FDs and Bonds are generally considered low-risk investments, providing a safe option for your savings."
    },
    {
      imageright: "right.png",
      title:"Flexible Tenure -",
      description:"Choose from a range of tenures to align with your investment horizon and financial goals."
    },
    {
      imageright: "right.png",
      title:"Interest Options -",
      description:"Options to receive interest payouts monthly, quarterly, or annually as per your preference."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Liquidity -",
      description:"Fixed Deposits have a lock-in period, and early withdrawals might incur penalties."
    },
    {
      imageright: "dangerous.webp",
      title:"Interest Rate Risk -",
      description:"Interest rates on Bonds can be influenced by market conditions, potentially affecting returns."
    },
    {
      imageright: "dangerous.webp",
      title:"Inflation Impact -",
      description:"Fixed interest rates may be affected by inflation, impacting the real returns on investment."
    },
    {
      imageright: "dangerous.webp",
      title:"Taxability -",
      description:"Interest income from FDs and Bonds is taxable under Income Tax Act provisions."
    },
    {
      imageright: "dangerous.webp",
      title:"Limited Growth -",
      description:"Fixed Deposits and Bonds might not provide the same growth potential as equities or mutual funds."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "KYC Documents",
      description: "Identity and address proof documents for KYC compliance.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "Permanent Account Number (PAN) card for tax purposes.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Investment Details",
      description: "Details of the investment amount and tenure.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Information",
      description:
        "Bank account details for interest payouts and transactions.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Form 15G/15H",
      description: "Forms to ensure no tax deduction at source if eligible.",
    },
  ];

  const steps = [
    {
      title: "Choose Investment Type",
      description:
        "Select between Fixed Deposits or Bonds based on your financial goals.",
    },
    {
      title: "Complete KYC",
      description:
        "Submit necessary KYC documents to comply with regulatory requirements.",
    },
    {
      title: "Make Investment",
      description: "Invest the chosen amount for the selected tenure.",
    },
    {
      title: "Receive Confirmation",
      description:
        "Obtain confirmation of your investment and interest rate details.",
    },
    {
      title: "Track Returns",
      description:
        "Monitor your investment and receive periodic interest payouts.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];


  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Tax Saving with FDs/Bonds"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<FdbTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Tax Saving with FDs/Bonds
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Maximize your tax savings and secure high returns with Fixed
            Deposits and Bonds. Our streamlined investment process helps you
            leverage tax benefits effectively.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What are Tax Saving Services through FDs/Bonds?
            </h6>

            <p className="mb-4">
              Tax Saving Services through Fixed Deposits (FDs) and Bonds offer a
              way to save on taxes while earning attractive returns. These
              investments are a popular choice for individuals looking for safe
              and effective ways to reduce their taxable income while ensuring
              capital security.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements
            </h6>
            <p className="mb-4">
              To invest in FDs and Bonds for tax saving, you need to:
              <ul>
                <li>
                  <strong>Complete KYC -</strong> Provide KYC documents to
                  comply with regulatory requirements.
                </li>

                <li>
                  <strong>PAN Card -</strong> Ensure you have a PAN card for tax
                  purposes.
                </li>

                <li>
                  <strong>Investment Details -</strong> Decide on the investment
                  amount and tenure.
                </li>

                <li>
                  <strong>Bank Account -</strong> Provide bank account details
                  for transactions and interest payouts.
                </li>

                <li>
                  <strong>Form 15G/15H -</strong> Submit forms to avoid TDS
                  deductions if eligible.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Tax Saving FDs/Bonds
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Tax Saving Services</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Invest in Tax Saving FDs/Bonds</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose my-10">
            <h6 className="text-blue-500 text-lg font-semibold">
              Why Choose Us for Tax Saving Investments?
            </h6>
            <p>
              Our dedicated team offers expert assistance with investing in
              Fixed Deposits and Bonds for tax saving. We provide personalized
              guidance to help you make informed investment decisions and
              maximize your tax benefits.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function FdbTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Invest in Fixed Deposits and Bonds to enjoy significant tax benefits and
        secure high returns. Our services ensure a smooth investment process
        with maximum benefits.
      </p>
      <p>
        Optimize your tax savings and investment returns with our expert
        guidance on FDs and Bonds.
      </p>
    </>
  );
}

export default TaxSavingServices;
