import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const ITReturn4Navbar = ({ height }) => {
  // IT-Return 4 grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Presumptive Income Tax Scheme",
      description:
        "Eligibility and benefits of filing under the Presumptive Income Tax Scheme for small businesses and professionals.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Income from Business or Profession",
      description:
        "Taxation on income earned from business or professional services, including self-employment.",
    },
    {
      imagePath: "flexi.webp",
      title: "Tax Deductions and Exemptions",
      description:
        "Available deductions and exemptions under IT-Return 4 for businesses, including Section 44AD, 44ADA, and 44AE.",
    },
    {
      imagePath: "limltd.webp",
      title: "Simplified Return Filing Process",
      description:
        "Streamlined process for eligible taxpayers to file their return under the presumptive taxation scheme.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Compliance and Reporting",
      description:
        "Ensure compliance with Indian tax laws and accurate reporting of income from businesses and professions.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Easy Filing Process -",
      description:"Simplified return filing under the presumptive income scheme, reducing the paperwork and complexity."
    },
    {
      imageright: "right.png",
      title:"Low Taxable Income -",
      description:"Applicable to small businesses and professionals, leading to reduced taxable income based on turnover."
    },
    {
      imageright: "right.png",
      title:"Tax Deductions -",
      description:"Ability to claim deductions under relevant sections such as 44AD, 44ADA, and 44AE."
    },
    {
      imageright: "right.png",
      title:"Less Audits -",
      description:"Reduced likelihood of scrutiny or audits under presumptive taxation schemes."
    },
    {
      imageright: "right.png",
      title:"No Detailed Books Required -",
      description:" Small businesses and professionals are exempt from maintaining detailed books of accounts."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Limited to Small Businesses - ",
      description:"Only applicable for small businesses or professionals with a specified turnover limit."
    },
    {
      imageright: "dangerous.webp",
      title:"No Additional Deductions - ",
      description:"Additional deductions like home loans or savings investments may not be claimed under presumptive income schemes."
    },
    {
      imageright: "dangerous.webp",
      title:"Turnover Restrictions -",
      description:"Turnover must fall within prescribed limits to qualify for presumptive taxation."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Income Proof",
      description:
        "Documents such as invoices, bank statements, and payment receipts showing income earned from business or professional services.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Previous Tax Returns",
      description:
        "Copies of previous years' tax returns for continuity and reference during filing.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Expenses",
      description:
        "Proof of expenses related to business or professional services, including rent, salaries, utilities, etc.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "GST Returns (if applicable)",
      description:
        "GST return filings for businesses registered under the Goods and Services Tax regime.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Turnover Records",
      description:
        "Details of turnover, sales, or revenue generated from business or profession.",
    },
  ];

  const steps = [
    {
      title: "Check Eligibility",
      description:
        "Ensure your business or profession meets the eligibility criteria for filing under IT-Return 4.",
    },
    {
      title: "Gather Required Documents",
      description:
        "Collect all necessary documents such as income proof, turnover details, and previous tax returns.",
    },
    {
      title: "Calculate Presumptive Income",
      description:
        "Determine your presumptive income based on turnover under Section 44AD, 44ADA, or 44AE.",
    },
    {
      title: "File IT-Return 4",
      description:
        "Prepare and submit IT-Return 4, ensuring all income, deductions, and exemptions are correctly reported.",
    },
    {
      title: "Comply with Tax Regulations",
      description:
        "Ensure full compliance with tax regulations, including GST filings (if applicable) and maintaining basic financial records.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseITReturn4 = [
    {
      title: "Simple Filing Process",
      description: "The ITR-4 form is designed to simplify the tax filing process for small and medium businesses, making it easier to meet compliance requirements."
    },
    {
      title: "Presumptive Taxation Benefits",
      description: "Individuals and businesses eligible for presumptive taxation can enjoy reduced paperwork and simpler tax calculations under Section 44AD, 44ADA, and 44AE."
    },
    {
      title: "Time-Saving",
      description: "The ITR-4 form requires minimal data entry, saving time for professionals and business owners who can avoid the hassle of complex tax computations."
    },
    {
      title: "Low Cost of Compliance",
      description: "By using the ITR-4 form, eligible taxpayers can reduce compliance costs, as they are not required to maintain detailed books of accounts."
    },
    {
      title: "Less Documentation",
      description: "Unlike other forms, ITR-4 requires fewer supporting documents, reducing the need for extensive record-keeping."
    },
    {
      title: "Quick Processing",
      description: "ITR-4 returns are processed faster by tax authorities, ensuring timely receipt of refunds and updates on tax status."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="IT-Return 4: Simplified Tax Filing for Small Businesses"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ItrFour />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            IT-Return 4 Filing Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our IT-Return 4 services simplify tax filing for small businesses
            and professionals under the presumptive income scheme, ensuring
            compliance with Indian tax laws.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is IT-Return 4?
            </h6>

            <p className="mb-4">
              IT-Return 4 is a simplified tax return form designed for small
              businesses, professionals, and self-employed individuals who opt
              for the presumptive taxation scheme. This scheme allows for easier
              tax filing and reduced compliance requirements.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for IT-Return 4
            </h6>
            <p className="mb-4">
              To file IT-Return 4, the following eligibility criteria must be
              met:
              <ul>
                <li>
                  <strong>Turnover Limit -</strong> Your business or profession
                  must have a turnover not exceeding the prescribed limit (e.g.,
                  ₹2 crore for businesses under Section 44AD).
                </li>

                <li>
                  <strong>Presumptive Income Scheme -</strong> The presumptive
                  income scheme is applicable to eligible businesses and
                  professions under Sections 44AD, 44ADA, and 44AE.
                </li>

                <li>
                  <strong>No Detailed Bookkeeping -</strong> Taxpayers filing
                  IT-Return 4 are not required to maintain detailed books of
                  accounts.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of IT-Return 4
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for IT-Return 4
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  my-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500 my-6">
              IT-Return 4 Filing Process
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold my-3">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseITReturn4.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function ItrFour() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        IT-Return 4 is designed for small businesses and professionals, offering a simplified process for filing income tax returns under presumptive income schemes.
      </p>
      <p>
      Ensure compliance with Indian tax regulations while benefiting from reduced tax liabilities through our IT-Return 4 services.
      </p>
    </>
  );
}

export default ITReturn4Navbar;
