import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const TrademarkSearch = ({ height }) => {

  const items = [
    {
      imagePath: "Grp.webp",
      title: "Search Databases",
      description:
        "Utilize online databases to search for existing trademarks and assess their availability.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Conduct a Comprehensive Search",
      description:
        "Perform a detailed search to identify any potential conflicts with existing trademarks.",
    },
    {
      imagePath: "flexi.webp",
      title: "Analyze Search Results",
      description:
        "Evaluate the search results to determine the likelihood of registration and potential conflicts.",
    },
    {
      imagePath: "limltd.webp",
      title: "Consult with an Expert",
      description:
        "Seek advice from a trademark attorney to interpret the search results and guide you through the next steps.",
    },
    {
      imagePath: "lessreg.webp",
      title: "File a Trademark Application",
      description:
        "Once the search is clear, proceed to file a trademark application with the relevant authorities.",
    },
  ];

  const advantages = [
    {
      imageright: "right.png",
      title: "Avoid Conflicts -",
      description:
        "Helps identify potential conflicts with existing trademarks before applying for registration",
    },
    {
      imageright: "right.png",
      title: "Save Time and Money -",
      description:
        "Avoids spending resources on a trademark that may already be in use or too similar to existing marks.",
    },
    {
      imageright: "right.png",
      title: "Informed Decisions -",
      description:
        "Provides valuable information to make informed decisions about the likelihood of successful registration.",
    },
    {
      imageright: "right.png",
      title: "Peace of Mind -",
      description:
        "Ensures that your trademark is unique and reduces the risk of legal disputes or rejections.",
    },
    {
      imageright: "right.png",
      title: "Professional Guidance -",
      description:
        "Offers expert advice and insights to navigate the trademark search process effectively.",
    },
  ];

  const disadvantages = [
    {
      
      imageright: "dangerous.webp",
      title: "Cost - ",
      description:
        "Conducting a comprehensive search may involve fees, especially if using professional services.",
    },
    {
      imageright: "dangerous.webp",
      title: "Complexity -",
      description:
        "The search process can be complex and may require expertise to interpret results accurately.",
    },
    {
      imageright: "dangerous.webp",
      title: "No Guarantees -",
      description:
        "A search does not guarantee that a trademark will be registered or that no conflicts will arise.",
    },
    {
      imageright: "dangerous.webp",
      title: "Time-Consuming -",
      description:
        "The search process can take time, which may delay the overall trademark registration process.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Trademark Search Report",
      description:
        "A detailed report from a trademark search, outlining the findings and potential conflicts.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Trademark Application Form",
      description:
        "The form needed for filing a trademark application, which can be prepared once the search is complete.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identification Documents",
      description:
        "Proof of identity for the applicant or business entity, as required for trademark registration.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Use",
      description:
        "Evidence of the trademark's use in commerce, if applicable.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Payment Receipts",
      description:
        "Receipts or proof of payment for search and application fees.",
    },
  ];

  const steps = [
    {
      title: "Identify Trademark Scope",
      description:
        "Define the scope and nature of your trademark, including its design and the goods or services it will cover.",
    },
    {
      title: "Perform Preliminary Search",
      description:
        "Conduct a preliminary search using online databases to identify potential conflicts or similar trademarks.",
    },
    {
      title: "Engage Professional Services",
      description:
        "Consider hiring a trademark attorney or specialist to conduct a comprehensive search and provide expert advice.",
    },
    {
      title: "Review Search Results",
      description:
        "Analyze the search results to determine the potential for conflicts and assess the likelihood of successful registration.",
    },
    {
      title: "Prepare and File Application",
      description:
        "If the search results are favorable, prepare and file your trademark application with the relevant trademark office.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whySearchData = [
    {
      title: "Comprehensive Search",
      description: "Thorough and detailed search to ensure your trademark is unique.",
    },
    {
      title: "Expert Analysis",
      description: "Get insights from experienced professionals to guide your decisions.",
    },
    {
      title: "Avoid Legal Issues",
      description: "Prevent legal disputes by identifying similar trademarks early.",
    },
    {
      title: "Quick Results",
      description: "Efficient search process to provide you with timely results.",
    },
    {
      title: "Affordable Pricing",
      description: "Cost-effective services to fit your budget.",
    },
  ];
  

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Trademark Search"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<TradeTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Trademark Search Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our Trademark Search services are designed to help you identify
            potential conflicts and assess the availability of your trademark.
            We provide comprehensive search solutions and expert guidance to
            ensure that your trademark is unique and protectable.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Trademark Search?
            </h6>

            <p className="mb-4">
              Trademark Search is the process of searching existing trademarks
              to determine if your desired trademark is already in use or too
              similar to an existing mark. This step is crucial in the trademark
              registration process as it helps avoid potential conflicts and
              ensures the uniqueness of your trademark.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Trademark Search
            </h6>
            <p className="mb-4">
              There are no specific eligibility requirements for conducting a
              trademark search. However, it is important to conduct a search
              before filing a trademark application to ensure that your mark is
              unique and does not conflict with existing trademarks.
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of Trademark Search
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Trademark Search
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full my-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Trademark Search Process
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whySearchData.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function TradeTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Trademark Search is a critical step in the trademark registration process. It involves searching existing trademarks to ensure that your desired trademark is unique and does not conflict with existing marks. A comprehensive search helps in making informed decisions and avoiding potential legal issues.
      </p>
      <p>
      Get started with our expert services to conduct a thorough trademark search and ensure the uniqueness of your trademark.
      </p>
    </>
  );
}

export default TrademarkSearch;
