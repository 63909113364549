import React from "react";
import Card from "./LLPcard"; // Assuming Card component is used for feature display
import "./LLPcardgrid.css"; // Custom styling for card grid
import "./LLP.css"; // Custom styling for the page
import ScrollToTopButton from "./ScrollTop"; // Custom component for scroll to top button
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const FSSAILicenseNavbar = ({ height }) => {
  // Features, advantages, and process items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Legal Requirement",
      description: "FSSAI license is mandatory for food businesses in India.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Consumer Trust",
      description: "Enhances trust and credibility with consumers.",
    },
    {
      imagePath: "flexi.webp",
      title: "Compliance",
      description:
        "Ensures compliance with food safety and standards regulations.",
    },
    {
      imagePath: "limltd.webp",
      title: "Market Expansion",
      description: "Enables expansion into new markets and regions.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Health & Safety",
      description: "Ensures adherence to health and safety regulations.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Legal Requirement -",
      description: "FSSAI license is mandatory for food businesses in India.",
    },
    {
      imageright: "right.png",
      title: "Consumer Trust -",
      description: "Enhances trust and credibility with consumers.",
    },
    {
      imageright: "right.png",
      title: "Compliance -",
      description:
        "Ensures compliance with food safety and standards regulations.",
    },
    {
      imageright: "right.png",
      title: "Market Expansion -",
      description: "Enables expansion into new markets and regions.",
    },
    {
      imageright: "right.png",
      title: "Health & Safety -",
      description: "Ensures adherence to health and safety regulations.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Regulatory Complexity -",
      description: "The application process can be complex and time-consuming.",
    },
    {
      imageright: "dangerous.webp",
      title: "Ongoing Compliance -",
      description: "Requires continuous compliance with food safety standards.",
    },
    {
      imageright: "dangerous.webp",
      title: "Cost -",
      description:
        "There are costs associated with obtaining and maintaining the license.",
    },
    {
      imageright: "dangerous.webp",
      title: "Documentation -",
      description: "Requires thorough documentation and record-keeping.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Identity",
      description:
        "Identity proof of the applicant (e.g., Aadhaar card, passport).",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Address",
      description:
        "Proof of address of the business premises (e.g., utility bill, lease agreement).",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Certificate",
      description:
        "Certificate of registration of the business (e.g., Company registration, Partnership deed).",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Food Safety Management System Plan",
      description: "Plan detailing the food safety management system in place.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "List of Food Products",
      description: "Detailed list of food products to be handled or sold.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Health Certificate",
      description:
        "Health certificate of the food business operator and key staff.",
    },
  ];

  const steps = [
    {
      title: "Determine the Type of License",
      description:
        "Identify the type of FSSAI license required based on the size and nature of your business.",
    },
    {
      title: "Prepare Documents",
      description:
        "Gather all necessary documents required for the application.",
    },
    {
      title: "Fill the Application Form",
      description:
        "Complete the FSSAI license application form available on the FSSAI website.",
    },
    {
      title: "Submit the Application",
      description:
        "Submit the completed application form along with the required documents to the FSSAI.",
    },
    {
      title: "Inspection",
      description: "An FSSAI official may visit your premises for inspection.",
    },
    {
      title: "Receive License",
      description: "Upon successful verification, receive the FSSAI license.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Broker License"
        ctaText="Get in touch"
        imagePath="./FSSAI.webp"
        children={<FSSAITo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="overview" className="flex flex-col w-full mt-16 items-center  ">
          <h6 className="text-2xl text-center font-semibold">
            FSSAI License (Food License)
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            An FSSAI license is a mandatory requirement for any food business
            operating in India. It ensures that your business adheres to food
            safety standards and regulations, thereby enhancing consumer trust
            and market credibility.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is FSSAI License?
            </h6>

            <p className="mb-4">
              FSSAI (Food Safety and Standards Authority of India) license is a
              legal certification that is required for all food businesses in
              India. It ensures that food products meet safety standards and are
              safe for consumption.
            </p>
            <div>
              <ul>
                <strong>Legal Requirement:</strong> FSSAI license is mandatory
                for food businesses in India.
              </ul>

              <ul>
                <strong>Consumer Trust:</strong> Enhances trust and credibility
                with consumers.
              </ul>

              <ul>
                <strong>Compliance:</strong> Ensures compliance with food safety
                and standards regulations.
              </ul>

              <ul>
                <strong>Market Expansion:</strong> Enables expansion into new
                markets and regions.
              </ul>

              <ul>
                <strong>Health & Safety:</strong> Ensures adherence to health
                and safety regulations.
              </ul>
            </div>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of FSSAI License
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="documents" className="secondLLP  mt-16">
            <div className="Documents">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for FSSAI License
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="process" className="Howto mt-16">
              <div className="registration-steps">
                <h2>How to Obtain FSSAI License</h2>
                <p>Follow these steps to get your FSSAI license:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div id="why-choose" className="Why my-12">
              <h6 className="text-blue-500 text-lg font-semibold">
                Why Choose T2F for Your FSSAI License?
              </h6>
              <p>
                T2F is your trusted partner for obtaining your FSSAI license:
              </p>
              <ul>
                <li>
                  <strong>Expert Consultation - </strong> Personalized guidance
                  to help you through the licensing process.
                </li>

                <li>
                  <strong>Documentation Assistance - </strong> Help with
                  preparing and filing all necessary documents.
                </li>

                <li>
                  <strong>Application Submission -</strong> Assistance with
                  submitting your application to the FSSAI.
                </li>

                <li>
                  <strong>Compliance Support -</strong> Ensure adherence to food
                  safety regulations.
                </li>

                <li>
                  <strong>Ongoing Support -</strong> Continued support
                  throughout the licensing process and beyond.
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

function FSSAITo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Obtaining an FSSAI license is crucial for any food business in India. It
        ensures compliance with food safety regulations and enhances consumer
        trust. T2F offers expert guidance to help you navigate the process
        efficiently.
      </p>

      <p>10K+ Successful Licenses</p>
    </>
  );
}

export default FSSAILicenseNavbar;
