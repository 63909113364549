import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
const BusinessPlanPreparation = ({ height }) => {
  // Business Plan Preparation grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Market Research",
      description:
        "Conduct comprehensive research to understand market trends and opportunities.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Financial Projections",
      description:
        "Develop detailed financial forecasts, including revenue, expenses, and profit margins.",
    },
    {
      imagePath: "flexi.webp",
      title: "Business Model",
      description:
        "Define your business model and strategy for achieving your goals.",
    },
    {
      imagePath: "limltd.webp",
      title: "Executive Summary",
      description:
        "Create a compelling executive summary that highlights the key aspects of your business plan.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Implementation Plan",
      description:
        "Outline the steps and timeline for executing your business plan successfully.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Strategic Planning -",
      description:
        "Helps in setting clear goals and strategies for business growth.",
    },
    {
      imageright: "right.png",
      title: "Investor Attraction -",
      description:
        "A well-prepared business plan can attract investors and secure funding.",
    },
    {
      imageright: "right.png",
      title: "Risk Management -",
      description:
        "Identifies potential risks and outlines strategies to mitigate them.",
    },
    {
      imageright: "right.png",
      title: "Organized Structure -",
      description:
        "Provides a structured approach to managing and operating the business.",
    },
    {
      imageright: "right.png",
      title: "Goal Setting -",
      description:
        "Establishes clear objectives and benchmarks for measuring success.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Time-Consuming -",
      description:
        "Preparing a comprehensive business plan can be time-consuming.",
    },
    {
      imageright: "dangerous.webp",
      title: "Complexity -",
      description: "May require significant effort to gather and analyze data.",
    },
    {
      imageright: "dangerous.webp",
      title: "Possibility of Over-Optimism -",
      description: "Projections and assumptions may be overly optimistic.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Market Research Report",
      description:
        "Detailed report on market trends, customer needs, and competitive analysis.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Projections",
      description:
        "Forecasts including profit and loss, cash flow, and balance sheet.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Model Canvas",
      description:
        "Outline of your business model, including value proposition, key activities, and revenue streams.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Executive Summary",
      description: "Summary of the key elements of your business plan.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Implementation Plan",
      description:
        "Detailed plan for executing the business strategy and achieving goals.",
    },
  ];

  const steps = [
    {
      title: "Conduct Market Research",
      description:
        "Gather and analyze data on the market, competitors, and target audience.",
    },
    {
      title: "Develop Financial Projections",
      description:
        "Create detailed financial forecasts, including revenue, expenses, and profitability.",
    },
    {
      title: "Define Business Model",
      description:
        "Outline your business model and strategy for achieving success.",
    },
    {
      title: "Prepare Executive Summary",
      description:
        "Draft a concise summary that highlights the key points of your business plan.",
    },
    {
      title: "Create Implementation Plan",
      description:
        "Develop a step-by-step plan for executing your business strategy.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseBusinessPlan = [
    {
      title: "Experienced Professionals",
      description: "Our team of experts has extensive experience in creating successful business plans across various industries."
    },
    {
      title: "Tailored Approach",
      description: "We develop business plans that are customized to meet the specific needs and goals of your business."
    },
    {
      title: "Comprehensive Analysis",
      description: "Our plans include in-depth market research and financial projections, providing a strong foundation for your business."
    },
    {
      title: "Investor-Ready Documents",
      description: "We prepare professional and persuasive business plans that make a strong impression on potential investors."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Business Plan Preparation"
        ctaText="Get in touch"
        imagePath="./Taxplan.webp"
        children={<TaxTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Business Plan Preparation
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            A business plan outlines your business goals, strategies, and
            financial projections. It serves as a roadmap for achieving success
            and is crucial for attracting investors and managing business
            operations effectively.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Business Plan Preparation?
            </h6>

            <p className="mb-4">
              Business Plan Preparation involves creating a detailed document
              that outlines your business goals, strategies, and financial
              projections. It provides a roadmap for running your business and
              helps in attracting investors and managing operations effectively.
            </p>
          </div>

          <div id="application" className="pr-2 my-10 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Application Process for Business Plan Preparation
            </h6>
            <p className="mb-4">
              To prepare a business plan, follow these steps:
              <ul>
                <li>
                  <strong>Conduct Market Research -</strong> Gather data on the
                  market, competitors, and target audience.
                </li>

                <li>
                  <strong>Develop Financial Projections -</strong> Create
                  forecasts for revenue, expenses, and profitability.
                </li>

                <li>
                  <strong>Define Business Model -</strong> Outline your business
                  model and strategy.
                </li>

                <li>
                  <strong>Prepare Executive Summary -</strong> Draft a summary
                  highlighting key elements of the business plan.
                </li>

                <li>
                  <strong>Create Implementation Plan -</strong> Develop a plan
                  for executing your business strategy.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="llp-card-grid flex flex-wrap">
            <h6 className="text-blue-500 text-lg font-semibold text-center">
              Key Features of Our Business Plan Preparation Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="how" className="why-choose mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Documents Required for Business Plan Preparation
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="process" className="how-to-apply mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Steps to Complete Business Plan Preparation
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseBusinessPlan.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function TaxTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        A comprehensive business plan is essential for guiding your business
        towards success. Our service assists in preparing a detailed business
        plan that covers all critical aspects, including market research,
        financial projections, and implementation strategies.
      </p>
      <p>
        Get expert help in creating a business plan that sets you up for
        success.
      </p>
    </>
  );
}

export default BusinessPlanPreparation;
