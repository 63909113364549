import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const GSTNavbar = ({ height }) => {
  // GST Registration service grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Legal Compliance",
      description:
        "Ensures compliance with the Goods and Services Tax Act and avoids legal penalties.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Input Tax Credit",
      description:
        "Allows businesses to claim input tax credit on purchases, reducing overall tax liability.",
    },
    {
      imagePath: "flexi.webp",
      title: "Increased Credibility",
      description:
        "Enhances the credibility of your business by being a registered entity under GST.",
    },
    {
      imagePath: "limltd.webp",
      title: "Ease of Trade",
      description:
        "Facilitates easier inter-state trade with proper documentation and compliance.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Simplified Tax System",
      description:
        "Streamlines the tax system with a single tax rate for goods and services.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Compliance -",
      description:" GST registration ensures your business complies with legal requirements and avoids penalties for non-compliance."
    },
    {
      imageright: "right.png",
      title:"Input Tax Credit -",
      description:" Enables businesses to claim input tax credit on purchases, reducing the overall tax burden and improving cash flow."
    },
    {
      imageright: "right.png",
      title:"Increased Credibility -",
      description:" A GST registration adds credibility to your business, making it more trustworthy in the eyes of clients and partners."
    },
    {
      imageright: "right.png",
      title:"Ease of Trade -",
      description:" Simplifies inter-state trade with a unified tax system and proper documentation, enhancing business operations."
    },
    {
      imageright: "right.png",
      title:"Simplified Tax System -",
      description:" Provides a single tax rate for goods and services, reducing complexity and administrative burden."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Compliance Costs -",
      description:" May incur costs related to compliance, including accounting software, professional fees, and regular tax filings."
    },
    {
      imageright: "dangerous.webp",
      title:"Administrative Burden -",
      description:"Requires regular maintenance of records, preparation of tax returns, and adherence to GST regulations."
    },
    {
      imageright: "dangerous.webp",
      title:"Complexity for Small Businesses -",
      description:"The process and requirements of GST registration may be complex for small businesses or startups."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Penalties -",
      description:"Non-compliance with GST rules can result in penalties and legal consequences, requiring diligent adherence."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description:
        "A valid Permanent Account Number (PAN) card of the business or the proprietor.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Proof",
      description:
        "Proof of business address, such as utility bills or lease agreements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "Identity proof of the business owner or authorized signatory, such as Aadhar card or passport.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Statement",
      description: "A recent bank statement of the business entity.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Certificate",
      description:
        "Certificate of business registration or incorporation, if applicable.",
    },
  ];

  const steps = [
    {
      title: "Gather Required Documents",
      description:
        "Collect all necessary documents for GST registration, including identity proof, business proof, and PAN card.",
    },
    {
      title: "Complete GST Application",
      description:
        "Fill out the GST registration application form available on the GST portal or with the help of a professional.",
    },
    {
      title: "Submit Application",
      description:
        "Submit the completed application along with the required documents to the GST authorities.",
    },
    {
      title: "Verification Process",
      description:
        "The GST authorities will verify the submitted documents and application details.",
    },
    {
      title: "Receive GSTIN",
      description:
        "Upon successful verification, receive your Goods and Services Tax Identification Number (GSTIN).",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const gstBenefits = [
  {
    title: "Legal Recognition",
    description: "GST registration gives your business legal recognition and a unique identification number, enhancing its credibility."
  },
  {
    title: "Input Tax Credit",
    description: "GST allows businesses to claim input tax credit on purchases, reducing the overall tax liability."
  },
  {
    title: "Simplified Taxation",
    description: "GST replaces multiple indirect taxes with a single tax, making tax compliance easier for businesses."
  },
  {
    title: "Easier Compliance",
    description: "The GST system provides a centralized platform for tax filing and payments, ensuring easier compliance."
  },
  {
    title: "Nationwide Validity",
    description: "GST is valid across India, allowing businesses to operate seamlessly without state-specific taxes."
  }
];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="GST Registration"
        ctaText="Get in touch"
        imagePath="./GSTregis.webp"
        children={<GstTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            GST Registration Simplified
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            GST Registration is essential for businesses to meet regulatory
            requirements and enjoy benefits like input tax credits. Our
            streamlined process makes it easy for you to get registered and stay
            compliant.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is GST Registration?
            </h6>

            <p className="mb-4">
              GST Registration is the process of registering your business under
              the Goods and Services Tax (GST) regime. It allows businesses to
              collect GST on sales, claim input tax credits, and comply with the
              tax regulations. It is a legal requirement for businesses with a
              turnover above the prescribed threshold.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for GST Registration
            </h6>
            <p className="mb-4">
              To apply for GST registration, you must meet the following
              requirements:
              <ul>
                <li>
                  <strong>PAN Card -</strong> A valid Permanent Account Number
                  (PAN) card of the business or the proprietor.
                </li>

                <li>
                  <strong>Business Proof -</strong> Proof of business address,
                  such as utility bills or lease agreements.
                </li>

                <li>
                  <strong>Identity Proof -</strong> Identity proof of the
                  business owner or authorized signatory, such as Aadhar card or
                  passport.
                </li>

                <li>
                  <strong>Bank Statement -</strong> A recent bank statement of
                  the business entity.
                </li>

                <li>
                  <strong>Business Registration Certificate -</strong>{" "}
                  Certificate of business registration or incorporation, if
                  applicable.
                </li>
              </ul>
            </p>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of GST Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for GST Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Apply for GST Registration</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>
          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {gstBenefits.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function GstTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        GST Registration is crucial for businesses to comply with the Goods and
        Services Tax laws. It allows you to claim input tax credits, enhances
        your business’s credibility, and simplifies the tax system for smoother
        operations.
      </p>
      <p>
        Thousands of businesses have streamlined their tax processes with our
        assistance.
      </p>
    </>
  );
}

export default GSTNavbar;
