import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';
const NidhiNavbar = ({ height }) => {

  // Nidhi service grid items
  const items = [
    { 
      imagePath: "Grp.webp",
      title: 'Regulated by RBI', 
      description: 'Nidhi companies are regulated by the Reserve Bank of India (RBI).' 
    },
    {
      imagePath: "Costeff.webp",
      title: 'Promotes Savings', 
      description: 'Encourages people to save money and participate in community financial activities.'
     },
    { 
      imagePath: "flexi.webp",
      title: 'Loan Facilities', 
      description: 'Provides various types of loans to its members.'
     },
    { 
      imagePath: "limltd.webp",
      title: 'Low Capital Requirement', 
      description: 'Requires lower capital compared to other financial institutions.' 
    },
    { 
      imagePath: "lessreg.webp",
      title: 'Member-Focused', 
      description: 'Only offers services to its members, creating a community-centric approach.' 
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Regulated Operations -",
      description:"Adheres to RBI guidelines ensuring regulated and safe operations."
    },
    {
      imageright: "right.png",
      title:"Encourages Savings -",
      description:"Promotes savings among members through attractive schemes."
    },
    {
      imageright: "right.png",
      title:"Community Focused -",
      description:"Operates on a mutual benefit principle among members."
    },
    {
      imageright: "right.png",
      title:"Affordable Setup -",
      description:"Lower initial investment compared to banks and other financial institutions."
    }
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Limited Scope -",
      description:"Services are restricted to members only, limiting outreach."
    },
    {
      imageright: "dangerous.webp",
      title:"Regulatory Constraints -",
      description:"Must adhere to stringent RBI regulations which can be complex."
    },
    {
      imageright: "dangerous.webp",
      title:"Operational Risks -",
      description:"Dependency on member participation and financial health of the community."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Residential Proof",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport-size Photos",
      description: "With a white background."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card/ID Proof",
      description: "For all directors and members."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Digital Signature Certificate (DSC)",
      description: "Required for at least one director."
    }
  ];

  const steps = [
    {
      title: "Obtain a Digital Signature Certificate (DSC)",
      description: "Required for all directors."
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      description: "For all directors."
    },
    {
      title: "Choose and Reserve Your Nidhi Name",
      description: "Ensure it meets the RBI guidelines."
    },
    {
      title: "Complete Form Nidhi",
      description: "Submit information about the Nidhi, directors, and registered office."
    },
    {
      title: "Draft and File Nidhi Agreement",
      description: "Must be notarized and filed within 30 days of incorporation."
    },
    {
      title: "Receive Certificate of Incorporation",
      description: "Issued by the Registrar of Companies (RoC)."
    },
    {
      title: "Apply for PAN and TAN",
      description: "Essential for tax purposes."
    }
  ];
  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];
  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Nidhi Company"
        ctaText="Get in touch"
        imagePath="/NidhiCompany.webp"
        children={<NidhiTO/>}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>Nidhi Registration Made Simple with T2F</h6>
          <p className='max-w-[1086px] text-center mt-4'>Ready to establish a Nidhi Company that fosters savings and financial activities? Look no further than T2F. With Nidhi Companies, you get a unique model focused on mutual benefit and regulated by the RBI. At T2F, we simplify the Nidhi registration process, guiding you through every step to ensure a smooth setup. Contact us today to start your Nidhi journey.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16 ' >

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Nidhi?</h6>

            <p className='mb-4'>A Nidhi Company is a type of non-banking financial company that promotes the habit of saving among its members and provides loan facilities. Here’s what makes Nidhi Companies unique:</p>
            <div>
              <ul><strong>Regulated by RBI:</strong> Nidhi Companies are governed by the Reserve Bank of India, ensuring regulated operations.</ul>

              <ul><strong>Promotes Savings:</strong> Focuses on encouraging savings among its members.</ul>

              <ul><strong>Loan Facilities:</strong> Provides loans to its members with favorable terms.</ul>
            </div>

            <p>Introduced under the Nidhi Companies Rules, Nidhi Companies offer a community-based approach to financial services.</p>
          </div>

          <div id="el" className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for Registration</h6>
            <p className='mb-4'>To register a Nidhi Company in India, you need to meet the following criteria:

              <ul>
                <li><strong>Minimum 7 Members -</strong> A Nidhi Company must have at least seven members at the time of registration.</li>

                <li><strong>Minimum 3 Directors -</strong> At least three directors are required, with a majority being Indian residents.</li>

                <li ><strong>Paid-Up Capital -</strong>A minimum paid-up capital of Rs. 5 lakh is required.</li>
                <li ><strong>Company Name -</strong> The name must include “Nidhi Limited” at the end.</li>
              </ul>
            </p>
          </div>

          <div className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Nidhi Company</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className='text-blue-500 text-lg font-semibold'>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className='text-blue-500 text-lg font-semibold'>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>


        <div id='why' className='secondLLP pr-2  mt-16'>
          <div className='Choosing'>
            <h6 className='text-blue-500 text-lg font-semibold'>Choosing your Nidhi Name</h6>
            <ul>
              <li><strong>Unique and Available - </strong> Ensure your Nidhi name is unique and not already in use.</li>

              <li><strong>Descriptive - </strong> Reflects your financial activities clearly.</li>

              <li ><strong>Suffix Requirement -</strong>Must end with “Nidhi Limited”</li>
            </ul>
          </div>

          <div className='Documents mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Documents Needed for Nidhi Registration</h6>
            <p>Here’s what you’ll need:</p>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>

          <div id='how' className='Howto mt-16'>
            <div className="registration-steps">
              <h2 className='text-blue-500 text-lg font-semibold'>How to Register</h2>
              <p>Follow these steps for a smooth registration process:</p>
              <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
            </div>
          </div>

          <div id='choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for your Nidhi Registration</h6>
            <p>T2F is your trusted partner for Nidhi registration:</p>
            <ul>
              <li><strong>Nidhi Agreement Drafting - </strong> Expert help with drafting and filing your Nidhi agreement.</li>

              <li><strong>Name Reservation - </strong> We’ll help you secure your desired Nidhi name.</li>

              <li ><strong>Expert Support -</strong> Our experienced team ensures a smooth registration process.</li>

              <li ><strong>Ongoing Assistance -</strong> We provide updates and support throughout your registration journey.</li>

              <li ><strong>DSC and DIN Assistance -</strong> We facilitate obtaining necessary digital signatures and identification numbers.</li>

              <li ><strong>PAN and TAN Application -</strong> Simplified application process for tax IDs.</li>
            </ul>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

function NidhiTO() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
      A Nidhi Company is a type of non-banking financial company that focuses on savings and loans among its members.
      </p>

      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
}

export default NidhiNavbar;
