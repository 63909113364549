import React from 'react';
import Card from './LLPcard'; // Assuming Card component is used for feature display
import './LLPcardgrid.css'; // Custom styling for card grid
import './LLP.css'; // Custom styling for the page
import ScrollToTopButton from './ScrollTop'; // Custom component for scroll to top button
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const IndianSubsidiaryNavbar = ({ height }) => {

  // Indian Subsidiary service grid items
  const items = [

    { 
      imagePath: "./Grp.webp",
      title: 'Foreign Ownership',
       description: 'Allows foreign companies to establish a presence in India while retaining majority ownership.' 
      },
    { 
      imagePath: "Costeff.webp",
      title: 'Limited Liability', 
      description: 'Shareholders’ liability is limited to their shareholding in the subsidiary.' 
    },
    { 
      imagePath: "flexi.webp",
      title: 'Separate Legal Entity',
       description: 'The subsidiary is a separate legal entity from the parent company.'
       },
    { 
      imagePath: "limltd.webp",
      title: 'Operational Flexibility', 
      description: 'Ability to operate independently in India while benefiting from the parent company’s resources.' 
    },
    { 
      imagePath: "lessreg.webp",
      title: 'Market Access',
       description: 'Provides access to the Indian market and its economic opportunities.'
       },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Foreign Ownership",
      description:"Allows foreign companies to establish a presence in India while retaining majority ownership."
    },
    {
      imageright: "right.png",
      title:"Limited Liability ",
      description:"Shareholders’ liability is limited to their shareholding in the subsidiary."
    },
    {
      imageright: "right.png",
      title:"Separate Legal Entity",
      description:"The subsidiary is a separate legal entity from the parent company"
    },
    {
      imageright: "right.png",
      title:"Operational Flexibility",
      description:"Ability to operate independently in India while benefiting from the parent company’s resources."
    },
    {
      imageright: "right.png",
      title:"Market Access",
      description:"Provides access to the Indian market and its economic opportunities."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Regulatory Compliance",
      description:"Must comply with Indian regulatory requirements, which can be complex."
    },
    {
      imageright: "dangerous.webp",
      title:"Higher Costs",
      description:"Establishing and maintaining a subsidiary may involve higher costs compared to other structures."
    },
    {
      imageright: "dangerous.webp",
      title:"Management Complexity",
      description:"Requires effective management to align subsidiary operations with the parent company’s goals."
    },
    {
      imageright: "dangerous.webp",
      title:"Local Market Challenges",
      description:"Navigating the Indian market may present challenges related to local business practices and regulations."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Parent Company’s Certificate of Incorporation",
      description: "Proof of the parent company's registration in its home country."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Board Resolution",
      description: "Resolution passed by the parent company’s board of directors authorizing the establishment of the subsidiary."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills for the subsidiary’s registered office in India."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof of Indian Directors",
      description: "Passport, Aadhar card, or driving license for Indian directors."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "Permanent Account Number (PAN) for the subsidiary."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof of Indian Directors",
      description: "Recent documents like bank statements or utility bills."
    }
  ];

  const steps = [
    {
      title: "Obtain Digital Signature Certificates (DSC)",
      description: "Acquire DSCs for all the proposed directors of the subsidiary."
    },
    {
      title: "Obtain Director Identification Number (DIN)",
      description: "Apply for DIN for the proposed directors."
    },
    {
      title: "Draft a Memorandum and Articles of Association",
      description: "Prepare the necessary documents outlining the subsidiary’s objectives and rules."
    },
    {
      title: "File for Name Reservation",
      description: "Submit an application to reserve the name of the subsidiary with the Ministry of Corporate Affairs (MCA)."
    },
    {
      title: "Incorporation Application",
      description: "File the incorporation application along with required documents with the MCA."
    },
    {
      title: "Obtain PAN and TAN",
      description: "Apply for Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the subsidiary."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];
  return (
    <>
      <div className='sticky z-50' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="INDIAN Subsidairy (for foreign owners)"
        ctaText="Get in touch"
        imagePath="./Indian.webp"
        children={< IndianSubsi/>}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>Indian Subsidiary Registration Made Easy with T2F</h6>
          <p className='text-center mt-4'>Registering an Indian Subsidiary allows a foreign company to establish a foothold in the Indian market while retaining control. T2F provides comprehensive support to navigate the registration process efficiently and ensure compliance with all legal requirements. Contact us to get started.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16 ' >

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is an Indian Subsidiary?</h6>

            <p className='mb-4'>An Indian Subsidiary is a business entity that is owned or controlled by a foreign company. It operates as a separate legal entity in India, providing the foreign parent company with the benefits of market access, operational flexibility, and limited liability. Key characteristics include:</p>
            <div>
              <ul><strong>Foreign Ownership:</strong> Allows foreign companies to establish a presence in India while retaining majority ownership.</ul>

              <ul><strong>Limited Liability:</strong> Shareholders’ liability is limited to their shareholding in the subsidiary.</ul>

              <ul><strong>Separate Legal Entity:</strong> The subsidiary is a separate legal entity from the parent company.</ul>

              <ul><strong>Operational Flexibility:</strong> Ability to operate independently in India while benefiting from the parent company’s resources.</ul>

              <ul><strong>Market Access:</strong> Provides access to the Indian market and its economic opportunities.</ul>
            </div>
          </div>

          <div className="mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>
        

        <div id='Documents' className="Documents ">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for LLP Registration
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="how" className="Howto mt-16">
              <div className="registration-steps">
                <h2>How to Register</h2>
                <p >Follow these steps for a smooth registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
            </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for your Indian Subsidiary Registration</h6>
            <p>T2F is your trusted partner for Indian Subsidiary registration:</p>
            <ul>
              <li><p><strong>Expert Consultation - </strong> Personalized guidance to help you set up your Indian Subsidiary.</p></li>

              <li><strong>Documentation Assistance - </strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>DSC and PAN Assistance -</strong> Facilitation of obtaining digital signatures and Permanent Account Number.</li>

              <li><strong>Compliance Support -</strong> Ensure adherence to legal and regulatory requirements.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the registration process and beyond.</li>
            </ul>
          </div>
      </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

function IndianSubsi() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
       Establishing an Indian Subsidiary allows a foreign company to expand its operations into India while retaining majority ownership. T2F streamlines the registration process, ensuring compliance with Indian regulations and smooth setup.
      </p>

      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
}
export default IndianSubsidiaryNavbar;
