import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const GstTemporaryRegistration = ({ height }) => {
  // GST Temporary Registration grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "What is GST Temporary Registration?",
      description:
        "GST Temporary Registration allows individuals or businesses to register for GST for a limited period, generally for a specific event or transaction.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Who Needs GST Temporary Registration?",
      description:
        "Entities engaging in occasional business activities, like exhibitions or short-term projects, may require GST temporary registration.",
    },
    {
      imagePath: "flexi.webp",
      title: "Validity of Temporary Registration",
      description:
        "Temporary registration is generally valid for a period as specified by the GST authorities, usually tied to the duration of the specific event.",
    },
    {
      imagePath: "limltd.webp",
      title: "Procedure for Temporary Registration",
      description:
        "The process involves submitting relevant details and documentation online via the GST portal, followed by verification by the GST authorities.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Compliance for Temporary Registration",
      description:
        "Entities with temporary registration must comply with GST regulations, including filing returns for the specified period.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Short-Term Compliance -",
      description:"Temporary GST registration helps individuals or businesses comply with GST regulations for short-term transactions."
    },
    {
      imageright: "right.png",
      title:"Flexibility -",
      description:"Offers flexibility for entities that do not engage in continuous business but need GST for specific purposes."
    },
    {
      imageright: "right.png",
      title:"No Long-Term Commitment -",
      description:"Since it is temporary, businesses are not bound by long-term compliance obligations."
    },
    {
      imageright: "right.png",
      title:"Efficient Taxation -",
      description:"Ensures that businesses engaging in short-term activities also contribute to the GST system."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Limited Validity -",
      description:"Temporary registration is only valid for a specific period, requiring renewal or regular registration if the business activity continues."
    },
    {
      imageright: "dangerous.webp",
      title:"Compliance Requirements -",
      description:"Temporary registration still requires compliance with GST rules, including filing returns and keeping records."
    },
    {
      imageright: "dangerous.webp",
      title:"No Input Tax Credit -",
      description:"Entities with temporary registration may not be eligible to claim input tax credits, affecting profitability."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description:
        "Permanent Account Number (PAN) of the individual or business entity is required for temporary GST registration.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Business Activity",
      description:
        "Documents proving the nature of the business activity, such as contracts, invitations, or event details, must be provided.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "Aadhaar card, passport, or voter ID of the individual or authorized person handling the temporary business activities.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof",
      description:
        "Proof of address for the place of business or activity, such as an electricity bill, rent agreement, or sale deed.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Digital Signature",
      description:
        "A digital signature is required for submitting the GST registration application online.",
    },
  ];

  const steps = [
    {
      title: "Collect Documents",
      description:
        "Prepare and collect all necessary documents like PAN, proof of business activity, and address proof.",
    },
    {
      title: "Fill Application",
      description:
        "Complete the online GST temporary registration application on the GST portal with accurate details.",
    },
    {
      title: "Submit Application",
      description:
        "Submit the completed application along with the supporting documents for verification.",
    },
    {
      title: "Verification",
      description:
        "The GST authorities will verify the application and documents. Upon successful verification, temporary registration will be granted.",
    },
    {
      title: "Receive GSTIN",
      description:
        "Once approved, the GSTIN (Goods and Services Tax Identification Number) will be issued for temporary use.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const gstTempBenefits = [
    {
      title: "Quick Compliance",
      description: "Temporary GST registration provides businesses with immediate compliance to operate legally for a limited period."
    },
    {
      title: "Short-Term Operations",
      description: "It is ideal for businesses involved in short-term projects or events, ensuring tax compliance without a long-term commitment."
    },
    {
      title: "Ease of Application",
      description: "The application process for temporary GST registration is simple and can be done quickly to meet urgent business needs."
    },
    {
      title: "Flexible Validity",
      description: "GST temporary registration is valid for a limited period, offering flexibility for businesses that don’t need permanent registration."
    },
    {
      title: "Legal Safeguards",
      description: "Temporary registration ensures businesses are safeguarded against penalties for operating without proper tax compliance."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="GST Temporary Registration"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<TempTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            GST Temporary Registration
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Temporary GST registration helps entities comply with the law for
            short-term activities or occasional events.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Temporary Registration?
            </h6>

            <p className="mb-4">
              Temporary registration allows businesses to comply with GST laws
              for short-term or occasional business activities.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Temporary Registration
            </h6>
            <p className="mb-4">
              Entities engaged in occasional business activities or short-term
              projects may apply for temporary GST registration.
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Features of GST Temporary Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages 
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for GST Temporary Registration
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Process for GST Temporary Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {gstTempBenefits.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>

        </div>
      </div>
    </>
  );
};

function TempTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
      Temporary GST registration is a short-term registration option for entities engaging in occasional business activities or events.
      </p>
      <p>
      Ensure compliance with GST laws even for temporary business activities with GST Temporary Registration.
      </p>
    </>
  );
}

export default GstTemporaryRegistration;
