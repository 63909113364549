import React from 'react'

const Disadvantages = ({imageSrl , title , para}) => {
  return (
    <div className="w-full flex flex-row items-center ">
      <img className="w-3 h-3  mr-3" src={imageSrl} alt="" />

      <span className="">
        <b>{title}</b> {para}
      </span>
    </div>  
  )
}

export default Disadvantages
