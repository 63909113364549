import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
import FeatureCard from "./FeatureCard";
import HeroSection from "./HeroSection";

const ReturnFiling = ({ height }) => {
  // Return Filing grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "What is Return Filing?",
      description:
        "Return Filing is a mandatory process for individuals and businesses to report their income, expenses, and taxes paid to the government.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Types of Returns",
      description:
        "There are various types of tax returns based on the nature of the taxpayer, such as income tax returns, GST returns, and TDS returns.",
    },
    {
      imagePath: "flexi.webp",
      title: "Due Dates for Filing",
      description:
        "Return filing has specific due dates depending on the type of taxpayer, generally falling within the financial year end.",
    },
    {
      imagePath: "limltd.webp",
      title: "Penalties for Late Filing",
      description:
        "Failure to file returns on time can lead to penalties, interest charges, and legal action by tax authorities.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Filing Process",
      description:
        "Return filing is now largely done online through government portals, requiring proper documentation and careful data entry.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Compliance -",
      description:"Filing returns ensures compliance with legal tax obligations."
    },
    {
      imageright: "right.png",
      title:"Claim Refunds -",
      description:"Taxpayers can claim refunds for excess taxes paid by filing returns."
    },
    {
      imageright: "right.png",
      title:"Loan Eligibility -",
      description:"Return filing is often required for availing loans and financial products."
    },
    {
      imageright: "right.png",
      title:"Record Maintenance -",
      description:"Keeps a formal record of income and taxes paid over the years."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming -",
      description:"The process can be time-consuming, especially for complex filings."
    },
    {
      imageright: "dangerous.webp",
      title:"Fines for Non-Compliance -",
      description:"Late or inaccurate filing can lead to heavy fines and penalties."
    },
    {
      imageright: "dangerous.webp",
      title:"Documentation -",
      description:"Gathering necessary documentation can be tedious and require careful management."
    },
    {
      imageright: "dangerous.webp",
      title:"Increased Scrutiny -",
      description:"Filing returns may lead to increased scrutiny and potential audits by tax authorities."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Income Proof",
      description:
        "Salary slips, bank statements, or other proof of income for the financial year are needed.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Deducted at Source (TDS)",
      description:
        "TDS certificates showing the amount of tax deducted from your income.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Investment Proofs",
      description:
        "Documents showing any investments made for tax-saving purposes like PPF, ELSS, etc.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Form 16",
      description:
        "Form 16 is issued by employers to employees for income tax purposes.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Details",
      description:
        "The bank account number, IFSC code, and branch details where the refund, if any, is to be credited.",
    },
  ];

  const steps = [
    {
      title: "Collect Income Details",
      description:
        "Compile all sources of income including salary, rental income, capital gains, and business profits.",
    },
    {
      title: "Calculate Taxable Income",
      description:
        "Subtract deductions and exemptions from your total income to determine the taxable income.",
    },
    {
      title: "Fill the Return Form",
      description:
        "Log in to the official tax portal and fill the relevant tax return form.",
    },
    {
      title: "Submit Return",
      description:
        "Verify the details and submit the form online. Ensure proper e-verification for the process to complete.",
    },
    {
      title: "Keep Acknowledgement",
      description:
        "Once filed, keep a copy of the acknowledgement for future reference.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseReturnFiling = [
    {
      title: "Ensures Legal Compliance",
      description: "Timely and accurate return filing keeps you compliant with tax regulations, helping to avoid penalties and legal issues."
    },
    {
      title: "Facilitates Loan and Credit Approvals",
      description: "Filed returns serve as proof of income, aiding in faster loan approvals and access to credit facilities from financial institutions."
    },
    {
      title: "Avoids Penalty Charges",
      description: "By filing returns on time, you can avoid penalties, interest, and other charges that can increase tax liabilities."
    },
    {
      title: "Carries Forward Losses",
      description: "Filing returns enables you to carry forward losses to offset future gains, which can help reduce taxable income in future years."
    },
    {
      title: "Eligible for Tax Refunds",
      description: "Accurate filing ensures that any excess taxes paid can be refunded efficiently, putting your money back in your hands sooner."
    },
    {
      title: "Improves Financial Record Accuracy",
      description: "Regular return filing promotes better financial record-keeping, allowing you to have a clear understanding of your income and expenses."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="IT-Return 7: Expert Filing Services"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ReturnFill />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">Return Filing</h6>
          <p className="max-w-[1086px] text-center mt-4">
            Filing tax returns is a crucial process to comply with various tax
            laws applicable to individuals and businesses.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Return Filing?
            </h6>

            <p className="mb-4">
              Return filing is the submission of tax details, including income
              and tax liabilities, to the tax authorities.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Return Filing
            </h6>
            <p className="mb-4">
              Any individual or business earning above the prescribed threshold
              is required to file returns.
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Features of Return Filing
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Return Filing
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Process for Return Filing
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold my-3">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseReturnFiling.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function ReturnFill() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
    Filing returns is essential for ensuring compliance with tax laws, whether it’s income tax, GST, or other forms of returns.
      </p>
      <p>
      Ensure timely and accurate filing of returns to avoid penalties and ensure smooth operations.
      </p>
    </>
  );
}
export default ReturnFiling;
