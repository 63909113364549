import React, { useState } from "react";
import OtpModal from "./OtpPopup";
import ArrowDownIcon from "./icons/ArrowDown";

const HeroSection = ({ title, ctaText, videoSrc,imagePath, children }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className="containerhome">
      <div
        className="flex space-x-4 md:flex-row flex-col justify-between items-center bg-[#E3EEFB] px-4 sm:pt-4 md:px-16 py-3"
      >
        <div className="flex-1">
          <h1 className="font-bold sm:w-full text-3xl md:text-3xl">{title}</h1>

          {children}

          <button
            className="flex bg-[#125B9A] py-[14px] px-4 rounded-lg space-x-4 text-white items-center mt-4"
            onClick={handleShow}
          >
            <span className="text-base font-medium">{ctaText}</span>
            <span className="-rotate-90">
              <ArrowDownIcon color="#ffffff" />
            </span>
          </button>
        </div>

        <div className="flex-1">
          <div className="w-full rounded-lg  sm:w-[480px] mt-3 md:w-[632px]">
          {/* <video
              src={"./Video/DialT2F Form a Business.mp4"}
              autoPlay
              loop
              muted
              playsInline
              poster={""} // Optional fallback image for the video
              className="w-full h-auto rounded-lg border-2 border-red-400"
            /> */}
   {   videoSrc?
         <video width="600" className="rounded-lg" controls autoPlay loop muted playsInline>  
      <source src={"/Video/DialT2F.mp4"} type="video/mp4" />  
      Your browser does not support the video tag.  
    </video> :
    <img src={imagePath} alt="" />
    
  }
          </div>
        </div>
        {/* Render the OTP Modal */}
        <OtpModal showModal={showModal} handleClose={handleClose} />
      </div>
    </div>
  );
};

export default HeroSection;
