import React, { useState, useEffect } from "react";

function ScrollToTopButton() {
    const [isVisible, setIsVisible] = useState(false);

    // Show button when the user scrolls down
    const toggleVisibility = () => {
        if (window.pageYOffset > 300) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility);
        return () => window.removeEventListener("scroll", toggleVisibility);
    }, []);

    return (
        <>
            {isVisible && (
                <div className="fixed right-8 top-1/2 transform -translate-y-1/2">
                    <button
                        onClick={scrollToTop}
                        className="w-12 h-12 rounded-full bg-white shadow-lg flex justify-center items-center text-black hover:bg-gray-200 transition duration-300"
                    >
                        <div className="flex flex-col justify-center items-center">
                            <svg
                                className="w-6 h-6 mb-1 text-black"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M19 15l-7-7-7 7" />
                                <path d="M19 21l-7-7-7 7" />
                            </svg>
                        </div>
                    </button>
                </div>
            )}
        </>
    );
}

export default ScrollToTopButton;