import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const InternationalTrademarkRegistration = ({ height }) => {
  // International Trademark Registration grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Trademark Search",
      description:
        "Conduct a comprehensive search to ensure your trademark does not conflict with existing marks.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Application Filing",
      description:
        "File your trademark application with the appropriate international authorities.",
    },
    {
      imagePath: "flexi.webp",
      title: "Examination Process",
      description:
        "Navigate through the examination process to address any objections or requirements from the trademark office.",
    },
    {
      imagePath: "limltd.webp",
      title: "Opposition Period",
      description:
        "Manage any opposition proceedings that may arise from third parties challenging your trademark.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Registration and Protection",
      description:
        "Once approved, manage your trademark registration to ensure ongoing protection and enforcement.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Global Protection -",
      description:"Provides trademark protection across multiple countries with a single application."
    },
    {
      imageright: "right.png",
      title:"Enhanced Brand Security -",
      description:"Safeguards your brand from infringement and misuse on an international scale."
    },
    {
      imageright: "right.png",
      title:"Streamlined Process -",
      description:"Simplifies the process of applying for trademark protection in various jurisdictions."
    },
    {
      imageright: "right.png",
      title:"Market Expansion -",
      description:"Facilitates the entry into new international markets with secured brand identity."
    },
    {
      imageright: "right.png",
      title:"Legal Recourse -",
      description:"Offers legal recourse in case of trademark disputes or infringements globally."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"High Costs -",
      description:"Can be expensive due to filing fees, legal fees, and maintenance costs in multiple jurisdictions."
    },
    {
      imageright: "dangerous.webp",
      title:"Complexity -",
      description:" Involves a complex application process and compliance with varying national trademark laws."
    },
    {
      imageright: "dangerous.webp",
      title:"Maintenance Requirements -",
      description:"  Requires ongoing monitoring and renewal of registrations in each country."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential for Conflicts -",
      description:"  Risk of conflicts with local trademarks or objections during the examination process."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Trademark Search Report",
      description:
        "A report confirming that your trademark is available and does not conflict with existing marks.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Application Forms",
      description:
        "Completed application forms required for filing with international trademark authorities.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Use",
      description:
        "Evidence showing how you are using the trademark in commerce, if applicable.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Power of Attorney",
      description:
        "A document authorizing a representative or attorney to file the trademark application on your behalf.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Documents",
      description:
        "Documents proving the legitimacy of your business entity for trademark registration purposes.",
    },
  ];

  const steps = [
    {
      title: "Conduct a Trademark Search",
      description:
        "Start by conducting a thorough trademark search to ensure your desired mark is available for registration.",
    },
    {
      title: "Prepare Application Documents",
      description:
        "Prepare and gather all necessary documents and information for the trademark application.",
    },
    {
      title: "File the Application",
      description:
        "Submit the trademark application to the appropriate international trademark office or system.",
    },
    {
      title: "Monitor the Examination Process",
      description:
        "Track the progress of your application and respond to any objections or requirements from the trademark office.",
    },
    {
      title: "Manage Registration and Renewals",
      description:
        "Once registered, manage your trademark to ensure its protection, including timely renewals and enforcement actions.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const reasons = [
    {
      title: 'Global Brand Protection',
      description: 'Secure your brand across multiple countries to prevent unauthorized use and infringement globally.'
    },
    {
      title: 'Market Expansion',
      description: 'Enables you to expand your business into new international markets with brand security.'
    },
    {
      title: 'Cost-effective Process',
      description: 'International registration through a single application reduces costs compared to separate filings.'
    },
    {
      title: 'Legal Compliance',
      description: 'Ensures that your brand meets the trademark laws and regulations of different countries.'
    },
    {
      title: 'Professional Support',
      description: 'Expert guidance throughout the process for successful international trademark registration.'
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="International Trademark Registration"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<TradeInter/>}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            International Trademark Registration Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our International Trademark Registration services offer a
            comprehensive solution for securing trademark protection across
            multiple countries. Benefit from our expertise in navigating complex
            international trademark laws and safeguarding your brand globally.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is International Trademark Registration?
            </h6>

            <p className="mb-4">
              International Trademark Registration allows you to protect your
              trademark in multiple countries through a single application. This
              service streamlines the process of securing brand protection
              across various jurisdictions, ensuring that your intellectual
              property is safeguarded globally.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for International Trademark Registration
            </h6>
            <p className="mb-4">
              To be eligible for International Trademark Registration, you
              should meet the following criteria:
              <ul>
                <li>
                  <strong>Existing Trademark -</strong> You must have an
                  existing trademark that you wish to protect internationally.
                </li>

                <li>
                  <strong>Complete Application -</strong> Ensure all necessary
                  documentation and information for the international
                  application is prepared.
                </li>

                <li>
                  <strong>Global Presence -</strong> Consider if your business
                  operates or plans to operate in multiple countries where
                  trademark protection is desired.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of International Trademark Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for International Trademark Registration
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              International Trademark Registration Filing Process
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {reasons.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function TradeInter() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        International Trademark Registration provides a streamlined approach to
        protecting your brand across multiple countries. Our services help you
        secure global trademark protection and manage your intellectual property
        effectively.
      </p>
      <p>
      Safeguard your brand's identity on an international scale with our
      expert trademark registration services.
      </p>
    </>
  );
}

export default InternationalTrademarkRegistration;
