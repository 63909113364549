// CardGrid.js  
import React from 'react';  
import Card from './LLPcard';  
import './LLPcardgrid.css';  

const CardGrid = () => {  
  const items = [  
    { title: 'Distinct Legal Identity', description: 'Operates as a separate entity from its owners.' },  
    { title: 'Cost Efficient', description: 'Lower setup and maintenance costs compared to companies.' },  
    { title: 'Flexible Partner Structure', description: 'Requires at least one designated partner with no cap on the total number of partners.' },  
    { title: 'Limited Liability', description: 'Partners are only liable for their contributions.' },  
    { title: 'Less Regulation', description: 'Fewer compliance requirements than larger entities.' },  
  ];  

  return (  
    <div className="card-grid">  
      {items.map((item, index) => (  
        <Card key={index} title={item.title} description={item.description} />  
      ))}  
    </div>  
  );  
};  

export default CardGrid;