import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const TrademarkObjection = ({ height }) => {
  // Trademark Objection grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Understand the Objection",
      description:
        "Comprehend the nature of the objection raised against your trademark application, including the grounds and reasons cited.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Prepare a Response",
      description:
        "Draft a detailed response to address the objections, providing evidence and arguments to support the registration of your trademark.",
    },
    {
      imagePath: "flexi.webp",
      title: "Submit Response",
      description:
        "File your response with the trademark office within the stipulated time frame to contest the objection and proceed with the application.",
    },
    {
      imagePath: "limltd.webp",
      title: "Monitor Progress",
      description:
        "Track the status of your objection response and any further actions required, such as attending hearings or providing additional information.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Seek Legal Advice",
      description:
        "Consider consulting with a trademark attorney to navigate complex objections and improve your chances of a successful outcome.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Chance to Address Issues -",
      description:"Provides an opportunity to rectify any issues or objections against your trademark application."
    },
    {
      imageright: "right.png",
      title:"Legal Representation -",
      description:"Allows for professional representation and advice to handle objections effectively."
    },
    {
      imageright: "right.png",
      title:"Preserves Application -",
      description:" Helps in continuing the trademark registration process despite initial objections."
    },
    {
      imageright: "right.png",
      title:"Protect Your Brand -",
      description:"Ensures that your trademark application is thoroughly reviewed and defended to protect your brand rights."
    },
    {
      imageright: "right.png",
      title:"Resolution of Disputes - ",
      description:"Resolves conflicts and objections, allowing for a clearer path to trademark registration."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complex Process -",
      description:"Handling objections can be complex and may require extensive legal knowledge and documentation."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost -",
      description:"Engaging legal counsel and preparing responses can incur additional costs."
    },
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming -",
      description:"The process of responding to objections and monitoring progress can be time-consuming."
    },
    {
      imageright: "dangerous.webp",
      title:"Risk of Rejection -",
      description:"There is a risk that the objection may not be resolved in your favor, potentially leading to rejection."
    },
    {
      imageright: "dangerous.webp",
      title:"Additional Requirements -",
      description:"May require additional information or amendments to your application, complicating the process."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Objection Notice",
      description:
        "The official notice from the trademark office outlining the grounds for the objection against your trademark application.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Response Document",
      description:
        "Your detailed response addressing the objections, including arguments, evidence, and any supporting documents.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Supporting Evidence",
      description:
        "Evidence and documentation supporting your case, such as proof of use, distinctiveness, or other relevant materials.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Legal Correspondence",
      description:
        "Any correspondence with legal counsel or the trademark office related to the objection and response process.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Hearing Documents",
      description:
        "Documents required if a hearing is scheduled to discuss the objection and your response in person.",
    },
  ];

  const steps = [
    {
      title: "Review Objection Notice",
      description:
        "Examine the notice of objection to understand the specific grounds and reasons for the objection.",
    },
    {
      title: "Prepare Detailed Response",
      description:
        "Draft a comprehensive response addressing each point of the objection with supporting evidence and arguments.",
    },
    {
      title: "File Your Response",
      description:
        "Submit your response to the trademark office within the prescribed timeframe to contest the objection.",
    },
    {
      title: "Follow Up",
      description:
        "Monitor the status of your response and any further actions required, including possible hearings or additional submissions.",
    },
    {
      title: "Seek Expert Advice",
      description:
        "Consult with a trademark attorney if needed to enhance your response and navigate the objection process effectively.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const reasons = [
    {
      title: 'Expert Guidance',
      description: 'Professional support to help you respond effectively to a trademark objection and protect your brand.'
    },
    {
      title: 'Timely Response',
      description: 'Ensures a quick and proper response to avoid delays and minimize the risk of rejection.'
    },
    {
      title: 'Legal Expertise',
      description: 'Experienced trademark attorneys provide the best strategy and solutions for overcoming objections.'
    },
    {
      title: 'Minimize Rejections',
      description: 'Proper filing and documentation reduce the chances of further rejections or legal complications.'
    },
    {
      title: 'Protect Brand Identity',
      description: 'Assistance in maintaining and securing your brand identity through effective objection handling.'
    },
  ];


  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Trademark Objection"
        ctaText="Get in touch"
        imagePath="./Tradeobjection.webp"
        children={<TradeObj />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center  ">
          <h6 className="text-2xl text-center font-semibold">
            Trademark Objection Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our Trademark Objection services help you navigate the challenges
            posed by objections to your trademark application. We provide expert
            support to address objections and ensure a smooth path to
            registration.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Trademark Objection?
            </h6>

            <p className="mb-4">
              Trademark Objection is a formal challenge to the registration of
              your trademark, typically raised by third parties or the trademark
              office itself. This objection can be based on various grounds,
              such as similarity to existing trademarks or lack of
              distinctiveness.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Addressing Objections
            </h6>
            <p className="mb-4">
              To address a trademark objection, you must have a pending
              trademark application that has been challenged. Ensure that you
              respond within the stipulated time and provide adequate evidence
              and arguments to counter the objection.
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of Trademark Objection Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Addressing Trademark Objection
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Process for Addressing Trademark Objection
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {reasons.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function TradeObj() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
      Trademark Objection occurs when a third party raises concerns
      against your trademark application, challenging its registration.
      Addressing these objections promptly and effectively is crucial to
      securing your trademark rights and protecting your brand.
      </p>
      {""}
      <p>
      Resolve trademark objections with our expert guidance to ensure a
      smooth and successful registration process.
      </p>
    </>
  );
}


export default TrademarkObjection;
