import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const ITReturn3 = ({ height }) => {
  // IT-Return 3 grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Business Income Reporting",
      description:
        "Declare your income from any business or profession you engage in.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Income from Partnerships",
      description:
        "Report your income from partnerships, LLPs, or other business entities.",
    },
    {
      imagePath: "flexi.webp",
      title: "Presumptive Taxation",
      description:
        "Opt for simplified tax computations under presumptive taxation schemes.",
    },
    {
      imagePath: "limltd.webp",
      title: "House Property and Capital Gains",
      description: "Declare income from house property and capital gains.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Complete E-Filing Support",
      description:
        "Seamless e-filing of IT-Return 3 with accurate calculations.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Business Income -",
      description:
        "IT-Return 3 allows for comprehensive reporting of business or professional income.",
    },
    {
      imageright: "right.png",
      title: "Presumptive Taxation -",
      description:
        "Benefit from simplified taxation schemes if you qualify for presumptive taxation.",
    },
    {
      imageright: "right.png",
      title: "Tax Deductions -",
      description:
        "Claim all applicable deductions to minimize tax liabilities.",
    },
    {
      imageright: "right.png",
      title: "Multiple Income Sources -",
      description:
        "Declare income from business, partnerships, and other sources.",
    },
    {
      imageright: "right.png",
      title: "Online Filing -",
      description: "File your IT-Return 3 online, accurately and on time.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "More Detailed -",
      description:
        "IT-Return 3 requires comprehensive documentation of business income and deductions.",
    },
    {
      imageright: "dangerous.webp",
      title: "Time-Consuming -",
      description:
        " Filing may take time due to the complexity of reporting business income.",
    },
    {
      imageright: "dangerous.webp",
      title: "Late Fees -",
      description:
        "  Delayed filing of IT-Return 3 can attract penalties and interest.",
    },
    {
      imageright: "dangerous.webp",
      title: "Documentation -",
      description:
        " Extensive documentation for business income, partnerships, and assets is needed.",
    },
    {
      imageright: "dangerous.webp",
      title: "Complex Rules -",
      description:
        " Staying up-to-date with business tax regulations and changes can be challenging.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Income Documents",
      description:
        "Proof of income from business or profession, such as profit and loss statements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Partnership Documents",
      description: "Documents related to income from partnerships or LLPs.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Form 16A",
      description: "Certificate for tax deducted at source on business income.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Capital Gains Documents",
      description: "Documents related to the sale of assets or properties.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Property Documents",
      description: "Documents for income from house property, if applicable.",
    },
  ];

  const steps = [
    {
      title: "Gather Business and Other Income Documents",
      description:
        "Collect all necessary documents related to your business, partnerships, and house property.",
    },
    {
      title: "Calculate Total Income",
      description:
        "Include income from all sources like business, partnerships, capital gains, and property.",
    },
    {
      title: "Apply Eligible Deductions",
      description:
        "Maximize your tax savings by applying all eligible deductions for business expenses.",
    },
    {
      title: "Pay Self-Assessment Tax",
      description: "Ensure all due taxes are paid on time to avoid penalties.",
    },
    {
      title: "File IT-Return 3 Online",
      description:
        "File your return online accurately, ensuring that all business income is reported correctly.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="IT-Return 3"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ItrThree />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div
          id="reg"
          className="flex flex-col w-full mt-16 items-center "
        >
          <h6 className="text-2xl text-center font-semibold">
            IT-Return 3 Filing Made Simple
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Get expert guidance to accurately file IT-Return 3, declaring income
            from business, partnerships, and property, and optimizing deductions
            for tax savings.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is IT-Return 3?
            </h6>

            <p className="mb-4">
              IT-Return 3 is intended for individuals earning income from
              business or profession, partnerships, capital gains, and house
              property. It allows for complete reporting and tax deductions.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements
            </h6>
            <p className="mb-4">
              To file IT-Return 3, the following documents and eligibility
              criteria are required:
              <ul>
                <li>
                  <strong>Business Income Documents -</strong> Profit and loss
                  statements, balance sheets, and other related documents.
                </li>

                <li>
                  <strong>Partnership Income Proof -</strong> Income from
                  partnerships, LLPs, or other business entities.
                </li>

                <li>
                  <strong>Capital Gains Documents -</strong> Proof of sale of
                  assets like property or shares.
                </li>

                <li>
                  <strong>Property Documents -</strong> Documents related to
                  house property income, if applicable.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of IT-Return 3
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for IT-Return 3</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to File IT-Return 3</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose my-10">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for Filing IT-Return 3?
            </h6>
            <p>
              Our team of professionals will guide you through every step of
              filing your IT-Return 3, ensuring accuracy, tax savings, and
              complete compliance.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function ItrThree() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        File your IT-Return 3 accurately by declaring income from business,
        partnerships, house property, and capital gains.
      </p>
      <p>
        IT-Return 3 is suited for individuals with income from business or
        profession, as well as partnerships and property. Our service ensures
        compliance and tax optimization.
      </p>
    </>
  );
}

export default ITReturn3;
