import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const CopyrightRegistration = ({ height }) => {
  // Copyright registration grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "What is Copyright?",
      description:
        "Copyright is the legal right given to the creator of original works like literary, artistic, musical, or dramatic pieces to control the use of their creation for a limited time.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Types of Works",
      description:
        "Copyright protects various forms of works including literary works, software, art, music, films, and architectural designs.",
    },
    {
      imagePath: "flexi.webp",
      title: "Registration Process",
      description:
        "Copyright registration ensures that the creator’s intellectual property rights are protected. The process involves filing an application, submitting the work, and obtaining a copyright certificate.",
    },
    {
      imagePath: "limltd.webp",
      title: "Copyright Duration",
      description:
        "The copyright protection lasts for the lifetime of the author plus an additional 60 years after their death for literary, dramatic, musical, and artistic works.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Infringement & Remedies",
      description:
        "Copyright infringement occurs when someone uses the copyrighted work without permission. Legal remedies include damages and injunctions to stop the infringing activity.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Exclusive Rights -",
      description:"Copyright gives the creator the exclusive right to reproduce, distribute, perform, and display their work."
    },
    {
      imageright: "right.png",
      title:"Legal Protection -",
      description:"Registration offers legal protection against unauthorized use of the work."
    },
    {
      imageright: "right.png",
      title:"Economic Benefits -",
      description:"Copyright holders can earn royalties from their work, providing an additional revenue stream."
    },
    {
      imageright: "right.png",
      title:"Moral Rights -",
      description:"It protects the moral rights of creators, ensuring they are properly credited for their work."
    },
    {
      imageright: "right.png",
      title:"Encourages Creativity -",
      description:"Copyright incentivizes creativity by ensuring creators benefit from their original works."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Limited Duration -",
      description:"Copyright protection is time-bound and does not last indefinitely."
    },
    {
      imageright: "dangerous.webp",
      title:"Costly Legal Action -",
      description:"Pursuing legal action for copyright infringement can be expensive and time-consuming."
    },
    {
      imageright: "dangerous.webp",
      title:"Restricted Access -",
      description:"Excessive copyright protections can limit access to knowledge and information."
    },
    {
      imageright: "dangerous.webp",
      title:"Global Enforcement -",
      description:"Enforcing copyright laws across different jurisdictions can be challenging."
    },
    {
      imageright: "dangerous.webp",
      title:"Complexities in Licensing -",
      description:"Licensing and rights management can be complicated, especially for large-scale or collaborative works."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Ownership",
      description:
        "Proof of creation, such as drafts, original files, or any other evidence that supports the creation of the work.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "A valid ID proof like Aadhaar card, PAN card, or passport of the creator or applicant.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Copy of Work",
      description:
        "A clear and complete copy of the work for which the copyright is being applied.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "No Objection Certificate (NOC)",
      description:
        "NOC from other creators, if applicable, in cases of joint ownership or collaborative works.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Power of Attorney",
      description:
        "If an agent is applying on behalf of the creator, a Power of Attorney document is required.",
    },
  ];

  const steps = [
    {
      title: "Submit Application",
      description:
        "File the copyright application online or offline with the relevant authorities.",
    },
    {
      title: "Submit Work",
      description:
        "Submit a copy of the work to be copyrighted along with the necessary documents.",
    },
    {
      title: "Application Review",
      description:
        "The copyright office will review the application and documents for authenticity.",
    },
    {
      title: "Issue of Certificate",
      description:
        "Upon approval, a copyright certificate is issued, confirming legal protection for the work.",
    },
    {
      title: "Legal Protection",
      description:
        "Once registered, the creator can enforce their rights in case of infringement.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const reasons = [
    {
      title: 'Legal Protection',
      description: 'Provides legal evidence of ownership, protecting your creative work from unauthorized use and infringement.'
    },
    {
      title: 'Monetary Benefits',
      description: 'Enables you to earn royalties from your copyrighted work, enhancing its financial value.'
    },
    {
      title: 'International Recognition',
      description: 'Copyright registration offers protection not just domestically but also in other countries through international treaties.'
    },
    {
      title: 'Public Record',
      description: 'Establishes a public record of your ownership, making it easier to prove your rights if disputes arise.'
    },
    {
      title: 'Credibility and Security',
      description: 'Shows that you take your work seriously, building trust and credibility with your audience and business partners.'
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Copyright Registration"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<CopyRegis />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Copyright Registration
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Copyright registration grants legal protection to original works,
            enabling the creator to control how their work is used and
            distributed.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Copyright?
            </h6>

            <p className="mb-4">
              Copyright provides the creator of an original work with exclusive
              rights over the use and distribution of their creation,
              encouraging creativity and innovation.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Copyright Registration
            </h6>
            <p className="mb-4">
              Copyright can be registered for original works like literary,
              dramatic, musical, and artistic creations, provided the work is
              fixed in a tangible medium of expression.
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Features of Copyright Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-4">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Copyright Registration
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Process for Copyright Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {reasons.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>

        </div>
      </div>
    </>
  );
};

function CopyRegis() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Copyright is a legal protection provided to the creators of original
        works, allowing them to control the use and distribution of their
        creations.
      </p>
      <p>
      Ensure your creative work is protected by registering it with the
      copyright office.
      </p>
    </>
  );
}

export default CopyrightRegistration;
