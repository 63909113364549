import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const FCRARegistration = ({ height }) => {

  // FCRA Registration grid items
  const items = [
    { imagePath: "Grp.webp", title: 'What is FCRA Registration?', description: 'FCRA stands for Foreign Contribution Regulation Act. It is a law enacted by the Government of India to regulate foreign donations and ensure their proper utilization.' },
    {imagePath: "Costeff.webp", title: 'Who Needs FCRA?', description: 'Non-profit organizations, charitable trusts, and NGOs that wish to receive foreign donations must register under FCRA.' },
    { imagePath: "flexi.webp",title: 'Key Features of FCRA', description: 'FCRA helps in the transparent receipt and usage of foreign contributions, ensuring national security and accountability.' },
    { imagePath: "limltd.webp",title: 'Eligibility for FCRA', description: 'Organizations must be registered for at least 3 years and have spent a minimum amount on charitable activities to be eligible for FCRA registration.' },
    { imagePath: "lessreg.webp", title: 'Legal Governance', description: 'FCRA is governed by the Ministry of Home Affairs, which monitors the flow and utilization of foreign contributions in India.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Access to Foreign Donations -",
      description:"FCRA registration allows organizations to legally accept foreign donations."
    },
    {
      imageright: "right.png",
      title:"Increased Credibility -",
      description:"Being registered under FCRA adds credibility and trustworthiness to the organization."
    },
    {
      imageright: "right.png",
      title:"Tax Benefits -",
      description:"Foreign donations received under FCRA are tax-exempt under Section 80G."
    },
    {
      imageright: "right.png",
      title:"International Reach -",
      description:"FCRA enables organizations to engage with global donors, expanding their reach and impact."
    },

  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Strict Compliance -",
      description:"Organizations must comply with numerous regulations and reporting requirements."
    },
    {
      imageright: "dangerous.webp",
      title:"Periodic Renewal -",
      description:"FCRA registration must be renewed every 5 years, with stringent checks."
    },
    {
      imageright: "dangerous.webp",
      title:"Penalties for Non-compliance -",
      description:"Failure to comply with FCRA regulations can lead to heavy penalties, including cancellation of registration."
    },
    {
      imageright: "dangerous.webp",
      title:"Restrictions on Political Activities -",
      description:"Organizations registered under FCRA are restricted from engaging in political activities."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Registration Certificate",
      description: "The organization must submit its registration certificate or trust deed during the FCRA application process."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Past Financial Records",
      description: "The financial records of the past 3 years showing the organization’s expenditures must be submitted."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "List of Directors/Trustees",
      description: "A list of all directors or trustees involved in the organization must be provided."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Foreign Donor Agreement",
      description: "Details of any agreements or intentions to receive foreign donations must be shared with the Ministry."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Activity Reports",
      description: "The organization must submit detailed reports of its past activities and the purpose of receiving foreign contributions."
    }
  ];

  const steps = [
    {
      title: "Apply Online",
      description: "The organization must apply online via the FCRA portal and submit all required documents."
    },
    {
      title: "Verification",
      description: "The Ministry of Home Affairs will verify the documents and the activities of the organization."
    },
    {
      title: "Approval from MHA",
      description: "Once the verification process is complete, the Ministry of Home Affairs provides approval for FCRA registration."
    },
    {
      title: "Issue of Registration Certificate",
      description: "After approval, the organization is issued an FCRA registration certificate, allowing it to receive foreign contributions."
    },
    {
      title: "Regular Compliance",
      description: "The organization must maintain compliance with FCRA rules and file annual returns."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const fcraBenefits = [
    {
      title: "Access to Foreign Funding",
      description: "FCRA registration allows organizations to legally receive donations and funding from foreign sources, enhancing their ability to support projects and initiatives.",
    },
    {
      title: "Global Networking Opportunities",
      description: "Registered organizations can build connections with international donors and institutions, leading to collaboration on various projects.",
    },
    {
      title: "Credibility and Trust",
      description: "Organizations with FCRA registration gain credibility, as it signifies compliance with regulations, attracting more foreign donors.",
    },
    {
      title: "Enhanced Project Funding",
      description: "With access to foreign funds, organizations can expand the scope and reach of their social, educational, or charitable projects.",
    },
    {
      title: "Tax Benefits",
      description: "FCRA registered organizations can also avail tax exemptions under various sections of the Income Tax Act, leading to cost savings.",
    },
  ];

  return (
    <>
      <div className='sticky z-20' style={{ 'top': '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="FCRA Registration"
        ctaText="Get in touch"
        imagePath="./MSME.webp"
        children={<FCRATo/>}
      />

      <div className='w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>FCRA Registration</h6>
          <p className='max-w-[1086px] text-center mt-4'>Legalize the acceptance of foreign contributions by registering under the Foreign Contribution Regulation Act (FCRA).</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16 '>
          <div className=' pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is FCRA Registration?</h6>
            <p className='mb-4'>FCRA registration allows organizations to receive foreign donations for charitable, religious, or educational purposes under strict regulations.</p>
          </div>


          <div id="el" className=' mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for FCRA Registration</h6>
            <p className='mb-4'>Non-profits with at least three years of operational history and a minimum amount spent on charitable activities are eligible for FCRA registration.</p>
          </div>

          <div id="features" className='w-full  mt-10   pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of FCRA Registration</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for FCRA Registration</h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Register for FCRA</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
                  </div>

                  <div className='my-5'>
          <h6 className="text-blue-500 text-lg font-semibold"> Why Choose </h6>
          <ul className="list-disc">
              {fcraBenefits.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          </div>
      </div>

      <ScrollToTopButton />
    </>
  );
};
function FCRATo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Enable your organization to legally receive foreign contributions by registering under FCRA.
      </p>

      <p>Ensure that your NGO or non-profit can access foreign funding by complying with the FCRA guidelines.</p>
    </>
  );
};

export default FCRARegistration;