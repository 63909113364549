import React from 'react'

const Page1 = () => {
  return (
    <div className='div3 w-[80%] flex flex-col '>

      <div className='pp'>
    <span className='text-[26px] font-semibold'>Privacy Policy</span>
    <p>Last updated: 17-09-2024</p>
    <p>At Dialt2F, we place the highest importance on respecting and protecting your
privacy. Our relationship with you is our most important asset. We want you to feel
comfortable and confident when using our Platform and Services. The objective of
this Policy is for you to better understand the type of information we intend to collect
from you; the purpose for which the information collected by us will be used; the use
of such information by the parties with whom we will share your information; our
policy regarding cookies and identity theft instances; the data security practices of
third-party website links available on our Platform; the data retention policy and
information security measures implemented by us; our disclaimers in relation to
Services and the Platform; our policy regarding changes and updates to this Policy;
our policy with respect to withdrawal of your consent; and legal rights and
obligations in case of disputes and grievances.</p>  

    <p>This Privacy Policy applies to the use of or access to the website www.dialt2f.com and
    its mobile/web applications by the User..</p>

    <p>The Platform is provided by IIBS SECURITIES PRIVATE LIMITED collaborated
with RYCOON Financial Services Limited, a company incorporated under the
Companies Act, 2013, having its corporate office at D-24, Kalkaji, New Delhi, 110019.</p>

    <p>This Policy is published in accordance with the provisions of Rule 3(1)(a) of the
Information Technology (Intermediary Guidelines and Digital Media Ethics Code)
Rules, 2021 and Rule 4 of the (Indian) Information Technology (Reasonable Security
Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011
framed under the (Indian) Information Technology Act, 2000 (as amended from
time to time). This Policy (read with the Terms of Use) constitutes a binding and
legally enforceable contract between the Company and the User.</p>

    <p>By using the Platform or availing of the Services, you agree to this Policy. Subject to
applicable laws, the terms of this Policy (and any changes thereto) will become
applicable to you retrospectively on and from the date of your first use of the
Platform. If you do not agree to the terms of this Policy, please do not use our
Platform and Services. By using this Platform, you confirm that you are either the
owner of or are duly authorized to use and share the information that the Platform
may require, and that using the Platform does not violate any applicable law or
contract by which you may be bound. You agree to indemnify the Company, its
employees, directors, officers, agents, business associates, affiliates, and agents from
any loss, damage, expenses, or other adverse consequences they suffer due to your
usage of the Platform unless such loss or damage can be attributed to our act or omission.</p>

    
    </div>

    <div className='pp-c'>
      <h6>1.INFORMATION WE COLLECT</h6>

      <ul>
        <li>
      <p>1.1 Personal Information: When you use our Platform or avail of the Services, we may
collect personal data such as your name, email address, contact number, date of
birth, gender, address, Aadhaar, PAN, photographs, payment information, bank
account details, and other information required for KYC or service purposes.  </p>
      </li>

      <li><p>1.2 Communication Information: We collect information you provide when you
interact with us, including customer support queries, feedback, and other
correspondence.</p></li>

      <li><p>1.3 Device and Usage Information: When you interact with the Platform, we collect
data such as IP address, browser type, device identifiers, and operating system
details, cookies, and activity logs..</p></li>

      <li><p>1.4 Third-party Sources: We may obtain information about you from third-party
providers (e.g., KYC agencies, GSTN, government databases) for verifying or
enhancing the information you provide us.</p></li>
      </ul>


      <h6 className='mt-2'>2. HOW WE USE THE INFORMATION</h6>
      <p>2.1 We use your information to:</p>
      <p>Provide and improve the Services, including customized, interactive experiences.
Ensure the security and confidentiality of your transactions.
Troubleshoot operational issues, monitor usage trends, and analyze user behavior.
Conduct audits and comply with legal obligations.
Provide you with relevant information on updates, marketing, and promotional
messages.
Enable interaction with third-party service providers as required for service
fulfillment.</p>


      <p>2.2 Sharing of Information: We do not sell your personal or financial information to
      third parties. However, we may share your information with:</p>

      <p>Government and regulatory entities (e.g., GSTN, IT department).
Strategic partners and third-party service providers for service facilitation.
Judicial authorities to comply with legal obligations.
Third-party advertisers to provide relevant ads on the Platform.</p>

    
      <h6 className='mt-2'>3.Cookies and Tracking Technologies</h6>
      <p>We use cookies and other tracking technologies to improve user experience, facilitate
login processes, and track user preferences. You can disable cookies in your browser,
but doing so may limit your use of certain features on the Platform.</p>

      <h6 className='mt-2'>4.Data Retention</h6>
      <p>We retain your data as long as it is necessary for the purposes outlined in this Policy,
unless otherwise required by law. After that, we securely delete or anonymize the
data.</p>

  

        <h6 className='mt-2'>5.Security of Your Information</h6>
        <p>We use industry-standard security practices, including encryption, firewalls, and
secure servers, to protect your information. However, no method of data
transmission over the Internet is completely secure, and we cannot guarantee
absolute security.</p>


        <h6 className='mt-2'>6. 6. Changes to This Policy</h6>
        <p>We may update this Policy periodically. Please check back regularly to stay informed
of any changes. Your continued use of the Platform signifies your acceptance of any
updated terms.</p>

        <h6 className='mt-2'>7.Contact Us</h6>
        <p>If you have any questions or grievances regarding this Policy or your data, please
        contact us at [info@dialt2f.com].</p>

        
        <h6 className='mt-2'>8. Governing Law</h6>
        <p>This Policy is governed by the laws of India, and any disputes arising out of this
        Policy will be subject to the exclusive jurisdiction of the courts in Delhi, India.</p>

        <p>This Policy was last updated on 17-09-2024.</p>

        
    </div>
    </div>
  )
}

export default Page1
