import React from 'react'


const TickText = ({image , text}) => {
return (
    <div className="flex flex-row leading-7 justify-left  items-center text-sm font-semibold md:grid-cols-4 lg:grid-cols-6 mr-4">
    <img src={image} alt='tick' className="check w-5 h-5 " />
    <span>{text}</span> 
    </div>
)
}

export default TickText
