import React from "react";
import MyCard from "./MyCard";
import Setup from './iconsweb p/Component 22.svg'
import Gst from './iconsweb p/Component 23.svg'
import ITR from './iconsweb p/Component 24 (1).svg'
const Sample = () => {
  return (
    <div
      // style={{
      //   display: "flex",
      //   flexDirection: "row",
      //   height: "auto",
      //   width: "100%",
      //   margin: "auto",
      //   flexWrap:'wrap',
      //   justifyContent:'center',
      // }}
      className="sm:px-4 md:px-16 sm:flex md:flex-row  items-center md:gap-12"
    >
    <MyCard heading={"Set up a business"} para={"All-in-One Tech Solutions for  Startup Compliance"} src={Setup} alt={"Set up a business"} />
    <MyCard heading={"GST"} para={"GST laws confusing? We simplify returns"} src={Gst} alt={"GST"}/>
    <MyCard heading={"ITR Filing"} para={"Fix missed deadlines: revise and handle notices"} src={ITR} alt={"ITR Filing"}/>
      </div>
  );
};

export default Sample;
