import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';
import HeroSection from './HeroSection';
import FeatureCard from './FeatureCard';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const DrugLicense = ({ height }) => {

  // Drug License grid items
  const items = [
    { imagePath: "Grp.webp", title: 'What is a Drug License?', description: 'A drug license is a legal authorization granted by the government to entities involved in the sale, manufacture, and distribution of pharmaceuticals.' },
    { imagePath: "Costeff.webp",title: 'Who Can Apply for a Drug License?', description: 'Pharmaceutical companies, chemists, and healthcare providers involved in the sale or distribution of medicines can apply for a drug license.' },
    {imagePath: "flexi.webp", title: 'Types of Drug Licenses', description: 'There are various types of licenses, including retail, wholesale, and manufacturing licenses, depending on the nature of the business.' },
    { imagePath: "limltd.webp",title: 'Validity and Renewal', description: 'Drug licenses are typically valid for a certain period and require renewal to remain active and compliant.' },
    { imagePath: "lessreg.webp",title: 'Regulatory Compliance', description: 'Entities holding a drug license must adhere to the standards set by regulatory authorities to ensure safety and quality.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Authorization -",
      description:"Provides a legal framework for selling and distributing medicines."
    },
    {
      imageright: "right.png",
      title:"Consumer Trust -",
      description:"Increases trust and credibility with customers by ensuring compliance with laws."
    },
    {
      imageright: "right.png",
      title:"Expansion Opportunities -",
      description:"Allows businesses to expand their pharmaceutical operations legally."
    },
    {
      imageright: "right.png",
      title:"Market Recognition -",
      description:" A drug license improves market visibility and access to a wider consumer base."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Strict Regulations -",
      description:"The process involves adhering to stringent legal and regulatory requirements."
    },
    {
      imageright: "dangerous.webp",
      title:"Ongoing Compliance -",
      description:"Maintaining the license requires regular inspections and compliance checks."
    },
    {
      imageright: "dangerous.webp",
      title:"High Costs -",
      description:"Obtaining and maintaining the license can be expensive due to legal fees and compliance costs."
    },
    {
      imageright: "dangerous.webp",
      title:"Complex Procedures -",
      description:"The application and renewal processes may be time-consuming and complicated."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Application Form",
      description: "A completed application form is the first requirement for obtaining a drug license."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Business Premises",
      description: "Documentation providing proof of ownership or rental of the business premises."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Qualification Certificates",
      description: "Certificates showing the qualifications of the applicant in handling pharmaceutical products."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Affidavits and Declarations",
      description: "Legal affidavits or declarations supporting the application for a drug license."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Payment Evidence",
      description: "Receipts or proof of payment for any fees associated with the drug license application."
    }
  ];

  const steps = [
    {
      title: "Determine License Type",
      description: "Identify the appropriate type of drug license based on your business activity, such as retail or wholesale."
    },
    {
      title: "Submit Application",
      description: "Complete and submit the application form along with the required documents to the regulatory authority."
    },
    {
      title: "Inspection and Verification",
      description: "Regulatory authorities may conduct inspections of your premises to ensure compliance."
    },
    {
      title: "Review and Approval",
      description: "Authorities will review your application and, upon meeting all requirements, approve the drug license."
    },
    {
      title: "Maintain Compliance",
      description: "Ensure ongoing compliance with regulations and renew your license as required."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const drugBenefits = [
    {
      title: "Legal Authorization to Sell Medicines",
      description: "A drug license is mandatory to legally sell, stock, distribute, or manufacture drugs, ensuring compliance with regulatory requirements.",
    },
    {
      title: "Ensures Product Safety",
      description: "Having a drug license ensures that only approved and safe pharmaceutical products are distributed, protecting consumers' health and safety.",
    },
    {
      title: "Builds Trust with Customers",
      description: "A licensed drug business gains more trust from customers, as it signifies compliance with health standards and legal norms.",
    },
    {
      title: "Avoids Legal Penalties",
      description: "Operating without a drug license can lead to severe legal consequences, including fines and business closure. A license helps avoid these risks.",
    },
    {
      title: "Enables Expansion Opportunities",
      description: "With a drug license, businesses can expand their operations to new regions or diversify their product range, tapping into more markets.",
    },
  ];

  return (
    <>
      <div className='sticky z-20' style={{ "top": '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Drug License"
        ctaText="Get in touch"
        imagePath="./MSME.webp"
        children={<DrugTo />}
      />

      <div className='w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>Drug License</h6>
          <p className='max-w-[1086px] text-center mt-4'>The legal authorization required for selling, manufacturing, or distributing pharmaceutical products.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16'>

          <div className=' pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Drug License?</h6>

            <p className='mb-4'>A drug license allows businesses to legally operate within the pharmaceutical industry, ensuring compliance with health and safety regulations.</p>
          </div>

          <div id="el" className='mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for a Drug License</h6>
            <p className='mb-4'>Entities involved in the sale, distribution, or manufacturing of pharmaceutical products are eligible to apply for a drug license.</p>
          </div>

          <div id="features" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of a Drug License</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Drug License</h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Obtain a Drug License</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                      />
                    ))}
                </div>
          </div>

          <div id='choose' className='my-5'>
          <h6 className="text-blue-500 text-lg font-semibold"> Why Choose </h6>
          <ul className="list-disc">
              {drugBenefits.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

function DrugTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Secure a drug license to legally sell, distribute, or manufacture pharmaceutical products.
      </p>

      <p>Ensure your business complies with government regulations and operates smoothly in the pharmaceutical sector.</p>
    </>
  );
};

export default DrugLicense;