import axios from 'axios';

const SendEmail = async(customerEmailAddress) => {
   
   
    const formData={
        emailAddress:customerEmailAddress,
        message:`User with ${customerEmailAddress} has been subscribed to dialt2f.com`
    }


    return axios.post('http://localhost:5000/Subscribe',formData).then(
        response=>{return response?.data}
    ).catch(error=>{return Promise.reject(error)})


}

export default SendEmail
