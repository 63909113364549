
import React from "react";
import "./LLPcard.css";

const Card = ({ title, description , img ,imgstyle }) => {
  return (
    <div className="card ">
      <img src={img} alt={title} style={imgstyle} />
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
  );
};

export default Card;
