import React from 'react'

const Samcard = ({imgLink , heading , para}) => {
  return (

    <>
    <div className=''>
    <div className='Parentcard h-60 w-full md:w-72  border-1 border-solid rounded-lg my-2 bg-samcard-bg '>
        <div className='parent2 p-4'>
        <div className='child1'>
        <img className='h-10 w-10' src={require("../../public/iconsweb p/"+imgLink)} alt='ige'/>
        </div>
        <div className='child2 w-60 h-7 mt-[24px]'>
        <h6 className='font-semibold text-sm leading-7'>{heading }</h6>
        </div>
        <div className='child3'>
        <p className='font-normal '>{para}</p>
        </div>
        <div className='child4 flex flex-row justify-end'>
        <button className= ' w-9 h-9  rounded-[60px] bg-white '>&gt;</button>
        </div>
        </div>
    </div>
    </div>
    </>
  )
}

export default Samcard
