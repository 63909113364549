import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const CibilComplaints = ({ height }) => {
  // CIBIL Complaints grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Dispute Resolution",
      description:
        "We help you resolve disputes related to incorrect credit information on your CIBIL report.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Credit Score Improvement",
      description:
        "Our team works to correct errors and improve your credit score effectively.",
    },
    {
      imagePath: "flexi.webp",
      title: "Legal Support",
      description:
        "Get expert legal support to handle complaints and issues related to your CIBIL score.",
    },
    {
      imagePath: "limltd.webp",
      title: "End-to-End Assistance",
      description:
        "We provide complete assistance, from identifying issues to resolving them with CIBIL.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Faster Resolutions",
      description:
        "Our streamlined approach ensures quicker resolutions for your complaints and disputes.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Error Correction -",
      description:"Ensures incorrect information on your CIBIL report is corrected efficiently."
    },
    {
      imageright: "right.png",
      title:"Credit Score Boost -",
      description:" Helps in improving your credit score by resolving disputes effectively."
    },
    {
      imageright: "right.png",
      title:"Expert Assistance -",
      description:" Access to financial and legal experts to manage your complaints."
    },
    {
      imageright: "right.png",
      title:"Stress-Free Process -",
      description:" We handle all interactions with CIBIL on your behalf, ensuring a hassle-free experience."
    },
    {
      imageright: "right.png",
      title:"Quick Resolutions -",
      description:" Timely resolution of your complaints and disputes to prevent further impact on your score."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Lengthy Process -",
      description:"Sometimes, resolving disputes with CIBIL can take longer than expected."
    },
    {
      imageright: "dangerous.webp",
      title:"Limited Control -",
      description:"The process is dependent on third-party actions, and there may be delays."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Rejections -",
      description:"If CIBIL or creditors do not agree with the dispute, the correction might be denied."
    },
    {
      imageright: "dangerous.webp",
      title:"Legal Costs -",
      description:"Some cases may require additional legal support, which can increase costs."
    },
    {
      imageright: "dangerous.webp",
      title:"No Immediate Changes -",
      description:" Changes to your credit report may take time to reflect, affecting urgent credit needs."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "CIBIL Report",
      description:
        "Provide a copy of your CIBIL report to identify errors and disputes.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Dispute Evidence",
      description:
        "Submit supporting documents that highlight the errors in your credit report.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "Provide a valid identity proof to verify your identity for the complaint process.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Statements",
      description:
        "Include any bank statements or financial records that support your dispute.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Loan Details",
      description:
        "Provide details of loans or credit accounts in dispute for quicker resolution.",
    },
  ];

  const steps = [
    {
      title: "Initial Consultation",
      description:
        "Contact us to discuss your CIBIL issues and provide necessary details about your credit report.",
    },
    {
      title: "Document Collection",
      description:
        "Submit required documents and evidence to support your dispute against the errors.",
    },
    {
      title: "Filing a Complaint",
      description:
        "We will file an official complaint with CIBIL and initiate the dispute resolution process.",
    },
    {
      title: "Follow-up",
      description:
        "Our team will follow up with CIBIL to ensure timely correction and resolution of the dispute.",
    },
    {
      title: "Resolution Confirmation",
      description:
        "Once resolved, we will confirm the corrections and update you on the status of your credit score.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];


  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Resolve Your CIBIL Complaints with Ease"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<CibilTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Quick and Hassle-Free CIBIL Complaint Resolution
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            We help you resolve complaints and disputes related to your CIBIL
            report, ensuring that errors are corrected and your credit score is
            improved in a timely manner.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is a CIBIL Complaint?
            </h6>

            <p className="mb-4">
              A CIBIL complaint refers to an issue or dispute related to
              incorrect or outdated information on your credit report. This
              could impact your credit score and ability to obtain loans. We
              assist in resolving these complaints by filing disputes with CIBIL
              on your behalf.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Filing CIBIL Complaints
            </h6>
            <p className="mb-4">
              To be eligible for filing a CIBIL complaint, you must meet the
              following requirements:
              <ul>
                <li>
                  <strong>Inaccurate Credit Report -</strong> You must have a
                  valid reason to believe that your CIBIL report contains
                  inaccurate or outdated information.
                </li>

                <li>
                  <strong>Valid Documentation -</strong> You should provide
                  supporting documents to prove the inaccuracies in your credit
                  report.
                </li>

                <li>
                  <strong>Identity Verification -</strong> You must provide
                  identity proof to validate your complaint with CIBIL.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Our CIBIL Complaint Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Filing a CIBIL Complaint</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps for Filing a CIBIL Complaint</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose my-4">
            <h6 className="text-blue-500 text-lg font-semibold">
              Why Choose Us for CIBIL Complaint Resolution?
            </h6>
            <p>
              We provide expert assistance to resolve your CIBIL disputes and
              ensure that your credit report is corrected efficiently. Our legal
              and financial experts make the entire process smooth and
              hassle-free for you.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function CibilTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Facing issues with your CIBIL report? We offer expert assistance in resolving disputes, improving your credit score, and handling all communication with CIBIL on your behalf.
      </p>
      <p>
      Our team ensures that your complaints are handled quickly and efficiently, giving you peace of mind.
      </p>
    </>
  );
}

export default CibilComplaints;
