import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const PartnershipFirmNavbar = ({ height }) => {


  const advantageList = [
    {
      imageright: "right.png",
      title: "Shared Responsibility - ",
      parag: "Partners share the responsibility and liability of the business operations.",
    },
    {
      imageright: "right.png",
      title: "Flexibility in Management ",
      parag:
        "Allows for flexible management and decision-making among partners.",
    },
    {
      imageright: "right.png",
      title: "Cost-Effective",
      parag: "Lower registration and compliance costs compared to companies.",
    },
    {
      imageright: "right.png",
      title: "Easy Formation -",
      parag: "Simpler to set up with fewer legal requirements.",
    },
    {
      imageright: "right.png",
      title: "Profit Sharing ",
      parag: "Profits are shared among partners as per the agreement.",
    },
  ];

  const disadvantagesList = [
    {
      imageright: "dangerous.webp",
      title: "Unlimited Liability",
      parag: "Partners are personally liable for the debts and obligations of the firm.",
    },
    {
      imageright: "dangerous.webp",
      title: "Potential for Disputes",
      parag:"Differences in opinions among partners can lead to conflicts.",
    },
    {
      imageright: "dangerous.webp",
      title: "Limited Lifespan",
      parag:
        "The partnership may dissolve upon the death or withdrawal of a partner.",
    },
    {
      imageright: "dangerous.webp",
      title: "Raising Capital ",
      parag:"May face difficulties in raising capital compared to companies.",
    },
  ];

  const PsPoints = [
    {
      title: "Shared Responsibility",
      description:"Partners share the responsibility and liability of the business operations.",
    },
    {
      title: "Flexibility in Management",
      description:"Allows for flexible management and decision-making among partners.",
    },
    {
      title: "Cost-Effective",
      description:"Lower registration and compliance costs compared to companies.",
    },
    {
      title: "Easy Formation",
      description: "Simpler to set up with fewer legal requirements.",
    },
    {
      title: "Profit Sharing",
      description: "Profits are shared among partners as per the agreement..",
    },
  ];

  const elpoints = [ 
    {
      title: "Minimum 2 Partners ",
      description:"At least two partners are required to form a partnership firm."
    },
    {
      title: "Indian Citizens/Residents",
      description:"Partners must be Indian citizens or residents.."
    },
    {
      title: "Partnership Deed ",
      description:"A well-drafted partnership deed outlining the terms and conditions of the partnership.."
    },
    {
      title: "Registered Office",
      description:"Proof of a registered office address is required."
    },
    {
      title: "Compliance with Regulations",
      description:"Adherence to regulations set forth by relevant authorities."
    },
  ];
  
  const featuresCard = [
    {
      imagePath: "./Grp.webp",
      title: "Shared Responsibility",
      para:"Partners share the responsibility and liability of the business operations.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Flexibility in Management",
      para:"Allows for flexible management and decision-making among partners.",
    },
    {
      imagePath: "flexi.webp",
      title: "Cost-Effective",
      para:"Lower registration and compliance costs compared to companies.",
    },
    {
      imagePath: "limltd.webp",
      title: "Easy Formation",
      para: "Simpler to set up with fewer legal requirements.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Profit Sharing",
      para: "Profits are shared among partners as per the agreement.",
    },
  ];


  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Partnership Deed",
      para:
        "A detailed document outlining the terms of the partnership, including profit-sharing ratios and responsibilities.",
      },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Registered Office Address",
      para: "Includes rent agreement and utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof of Partners",
      para: "Recent documents like bank statements or utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof of Partners",
      para: "Passport, Aadhar card, or driving license.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport-size Photos",
      para: "With a white background.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      para: "For all partners.",
    },
  ];

  const steps = [
    {
      title: "Draft a Partnership Deed",
      para:
        "Create a legal document detailing the terms and conditions of the partnership.",
      },
    {
      title: "Choose a Partnership Name",
      para:
        "Ensure the name complies with regulatory guidelines and is not already taken.",
    },
    {
      title: "Register the Partnership Firm",
      para:
        "Submit the necessary documents and forms to the Registrar of Firms.",
      },
    {
      title: "Obtain PAN and TAN",
      para:
        "Apply for Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN).",
      },
    {
      title: "Open a Bank Account",
      para: "Open a bank account in the name of the partnership firm.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Partnership Firm Registration"
        ctaText="Get in touch"
        imagePath="./Partnership.webp"
        children={<PartnershipFirm />}
      />

      <div className="mainLLP w-full flex flex-col px-3 md:px-8">
        <div id="overview" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Partnership Firm Registration Made Easy with T2F
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Starting a Partnership Firm allows for shared responsibilities and
            flexibility in business management. T2F provides comprehensive
            support throughout the registration process, ensuring your
            partnership firm is set up efficiently and in compliance with legal
            requirements. Contact us to get started.
          </p>
        </div>

        <div className=" mt-16 max-w-[972px] md:px-16 ">
          <div className="w-auto h-[15%] pr-2 pt-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is a Partnership Firm?
            </h6>

            <p className="mb-4">
              A Partnership Firm is a business structure where two or more
              individuals or entities come together to carry on a business with
              shared responsibilities, profits, and losses. Key characteristics
              include:
            </p>
            <div>
              <ul className="list-disc">
                {PsPoints.map((item) => (
                  <li>
                    <p>
                      <strong>{item.title}:</strong>
                      {item.description}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            <p>
              Partnership firms are governed by the Partnership Act, 1932, and
              require a partnership deed outlining the terms of the partnership.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility Criteria for Partnership Firm Registration
            </h6>
            <p>
              To register a Partnership Firm, you need to meet the following
              criteria:
            </p>
            <ul className="list-disc">
            {elpoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          
          <div className="mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of a Partnership Firm
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {featuresCard.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.para}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantageList.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.parag}
                />
              ))}
            </div>
            <div className="mt-6">
              <h2>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantagesList.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.parag}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="Documents">
            <h6 className="text-blue-500 text-lg font-semibold">
              Documents Needed for Partnership Firm Registration
            </h6>
            <p>Here’s what you’ll need:</p>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.para}
                  />
                ))}
              </div>
          </div>

          <div id="registration" className="Howto mt-16">
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>
                Follow these steps for a smooth partnership firm registration
                process:
              </p>
              <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.para}
                    />
                  ))}
                </div>
            </div>
          </div>

          <div id="why-choose" className="Why mt-12">
            <h6 className="text-blue-500 text-lg font-semibold">
              Why Choose T2F for your Partnership Firm Registration
            </h6>
            <p>
              T2F is your trusted partner for Partnership Firm registration:
            </p>
            <ul className="list-disc">
              <li>
                <p>
                <strong>Expert Consultation - </strong> Personalized guidance to
                help you set up your partnership firm.</p>
              </li>

              <li>
                <p>
                <strong>Documentation Assistance - </strong> Help with preparing
                and filing all necessary documents.</p>
              </li>

              <li>
                <p>
                <strong>DSC and PAN Assistance -</strong> Facilitation of
                obtaining digital signatures and Permanent Account Number.</p>
              </li>

              <li>
                <p>
                <strong>Compliance Support -</strong> Ensure adherence to legal
                and regulatory requirements.</p>
              </li>

              <li>
                <p>
                <strong>Ongoing Support -</strong> Continued support throughout
                the registration process and beyond.</p>
              </li>
            </ul>
          </div>
        </div>
        </div>
    </>
  );
};

function PartnershipFirm() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        A Partnership Firm is a business structure where two or more individuals
        come together to operate a business with shared responsibilities. T2F
        assists in navigating the registration process, ensuring compliance and
        a smooth setup.
      </p>

      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
}
export default PartnershipFirmNavbar;
