import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const PatentProvisional = ({ height }) => {
  // Patent Provisional grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "What is a Provisional Patent?",
      description:
        "A provisional patent is a temporary application filed with the patent office to secure an early filing date for an invention while giving the applicant time to refine the idea or product.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Why File a Provisional Patent?",
      description:
        'Filing a provisional patent provides a "patent pending" status for your invention, offering protection against competing claims during the development process.',
    },
    {
      imagePath: "flexi.webp",
      title: "How Long is it Valid?",
      description:
        "A provisional patent is valid for 12 months from the date of filing. Within this period, a full patent application must be filed to maintain protection.",
    },
    {
      imagePath: "limltd.webp",
      title: "Conversion to Full Patent",
      description:
        "After filing a provisional patent, you must submit a full patent application, known as a non-provisional patent, to gain complete legal protection.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Cost-Effective Option",
      description:
        "A provisional patent is a more affordable option to secure initial protection for your invention, making it accessible for startups and individual inventors.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Secures Early Filing Date -",
      description:"A provisional patent establishes a filing date which is crucial in protecting your invention."
    },
    {
      imageright: "right.png",
      title:"Patent Pending Status -",
      description:"Offers legal protection by allowing you to use 'Patent Pending' on your invention during development."
    },
    {
      imageright: "right.png",
      title:"Lower Initial Costs -",
      description:"Filing a provisional patent is less expensive than filing a full patent, providing cost-effective protection."
    },
    {
      imageright: "right.png",
      title:"Time to Refine Invention -",
      description:"Gives inventors time to improve and finalize the product before filing a full patent application."
    },
    {
      imageright: "right.png",
      title:"Market Testing -",
      description:"Allows inventors to test the commercial viability of their invention while maintaining protection."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Limited Duration -",
      description:"The protection offered by a provisional patent lasts only for 12 months, requiring further action."
    },
    {
      imageright: "dangerous.webp",
      title:"No Formal Protection -",
      description:"A provisional patent does not grant full patent rights; it only secures the filing date."
    },
    {
      imageright: "dangerous.webp",
      title:"Incomplete Specification -",
      description:"Since the invention may still be in development, the provisional patent may lack a complete specification."
    },
    {
      imageright: "dangerous.webp",
      title:"Must Convert to Full Patent -",
      description:"Inventors must file a non-provisional patent application within 12 months to obtain full protection."
    },
    {
      imageright: "dangerous.webp",
      title:"Non-Enforceable Rights -",
      description:" A provisional patent cannot be enforced legally in court against infringement."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Description of Invention",
      description:
        "A detailed description of the invention, outlining its purpose, functionality, and how it differs from existing products or ideas.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Drawings or Sketches",
      description:
        "Visual representations or diagrams of the invention to clearly illustrate its components and working mechanisms.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Inventor Information",
      description:
        "Details of the inventor, including name, address, and contact information.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Provisional Application Form",
      description:
        "A completed provisional patent application form, which includes relevant technical details about the invention.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Filing Fee",
      description:
        "A filing fee is required when submitting a provisional patent application, varying based on the country and patent office.",
    },
  ];

  const steps = [
    {
      title: "Draft Invention Description",
      description:
        "Prepare a detailed description of your invention, including its purpose, features, and unique advantages.",
    },
    {
      title: "Create Illustrations",
      description:
        "Develop sketches or technical drawings to visually represent the invention's structure and functionality.",
    },
    {
      title: "Complete Application",
      description:
        "Fill out the provisional patent application form, ensuring all necessary details are provided.",
    },
    {
      title: "Submit to Patent Office",
      description:
        "File the provisional application with the relevant patent office and pay the necessary fees.",
    },
    {
      title: "Patent Pending Status",
      description:
        "Upon submission, your invention will receive 'patent pending' status, offering protection for 12 months.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const reasons = [
    {
      title: 'Early Filing Date',
      description: 'Secure an early filing date for your invention, protecting your intellectual property while you finalize details.'
    },
    {
      title: 'Cost-effective Protection',
      description: 'A provisional patent application is a more affordable way to protect your invention initially.'
    },
    {
      title: 'Flexibility to Refine',
      description: 'Gives you 12 months to refine and develop your invention before filing a complete patent application.'
    },
    {
      title: 'Patent Pending Status',
      description: 'Allows you to legally use the term "Patent Pending" on your invention, enhancing credibility and security.'
    },
    {
      title: 'Simplified Process',
      description: 'Filing a provisional patent is simpler and less formal, making it accessible for inventors at any stage.'
    },
  ];


  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Patent Provisional"
        ctaText="Get in touch"
        imagePath="./patentrenewal.webp"
        children={<PatentProv/>}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Patent Provisional
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Filing a provisional patent protects your invention during its
            development phase by securing an early filing date.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is a Provisional Patent?
            </h6>

            <p className="mb-4">
              A provisional patent is a cost-effective way to secure a filing
              date for your invention while offering protection against
              competing claims.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Provisional Patent
            </h6>
            <p className="mb-4">
              Any new and useful invention, whether it is a product or process,
              can be protected by filing a provisional patent.
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Features of Provisional Patent
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="mt-4">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Provisional Patent
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Process for Provisional Patent
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {reasons.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function PatentProv() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        A provisional patent allows you to secure an early filing date for
        your invention while providing time to develop or refine it.
      </p>
      {""}
      <p>
      Protect your invention from being copied during the initial stages
      by filing a provisional patent.
      </p>
    </>
  );
}
export default PatentProvisional;
