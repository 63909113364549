import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const DesignNavbar = ({ height }) => {
  // Design Registration service grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Exclusive Rights",
      description:
        "Provides exclusive rights to the design, preventing others from copying or imitating it.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Legal Protection",
      description:
        "Offers legal protection against infringement and unauthorized use of the design.",
    },
    {
      imagePath: "flexi.webp",
      title: "Market Differentiation",
      description:
        "Helps differentiate your product in the market with a unique and protected design.",
    },
    {
      imagePath: "limltd.webp",
      title: "Increases Brand Value",
      description:
        "Enhances the value of your brand by securing distinctive and appealing designs.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Encourages Creativity",
      description:
        "Promotes innovation and creativity by protecting original design concepts.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Exclusive Rights -",
      description:"Design registration grants exclusive rights to the design, preventing others from using or copying it without permission."
    },
    {
      imageright: "right.png",
      title:"Legal Protection -",
      description:"Provides legal mechanisms to enforce your rights and address any infringement or unauthorized use."
    },
    {
      imageright: "right.png",
      title:"Market Differentiation -",
      description:"Helps your product stand out in the market by protecting unique and distinctive design elements."
    },
    {
      imageright: "right.png",
      title:"Increases Brand Value -",
      description:"Enhances your brand’s value and recognition by securing original and attractive design features."
    },
    {
      imageright: "right.png",
      title:"Encourages Creativity -",
      description:"Protects innovative and creative designs, fostering further development and originality."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Cost -",
      description:"The process of registering a design can incur costs, including application fees and legal expenses."
    },
    {
      imageright: "dangerous.webp",
      title:"Limited Scope -",
      description:"Design protection is limited to the visual appearance and does not cover functional aspects of the product."
    },
    {
      imageright: "dangerous.webp",
      title:"Disclosure of Design -",
      description:"Requires public disclosure of the design, which may expose it to competitors before registration is complete."
    },
    {
      imageright: "dangerous.webp",
      title:"Regional Limitations -",
      description:"Design registration is generally limited to specific countries or regions, requiring separate applications for international protection."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Design Application Form",
      description:
        "Complete the official design registration application form provided by the intellectual property office.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Design Drawings/Images",
      description:
        "Provide clear and detailed drawings or images of the design from different angles.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Description of the Design",
      description:
        "Include a detailed description of the design, explaining its visual features and unique elements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Identity",
      description:
        "Submit proof of identity of the applicant or the company applying for the design registration.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Payment of Fees",
      description:
        "Pay the required application fee as per the intellectual property office’s guidelines.",
    },
  ];

  const steps = [
    {
      title: "Prepare Design Documentation",
      description:
        "Gather and prepare all necessary documents and drawings required for the design registration.",
    },
    {
      title: "File Design Application",
      description:
        "Submit the design application along with the required documents to the intellectual property office.",
    },
    {
      title: "Examination Process",
      description:
        "Undergo the examination process where the office reviews the application to ensure it meets registration criteria.",
    },
    {
      title: "Respond to Objections",
      description:
        "Address any objections or requests for additional information raised by the examiner.",
    },
    {
      title: "Receive Design Registration",
      description:
        "Upon successful review, receive the design registration and enjoy exclusive rights to your design.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const reasons = [
    {
      title: 'Legal Protection',
      description: 'Ensures exclusive rights to your design, preventing others from using, copying, or reproducing it without permission.'
    },
    {
      title: 'Market Advantage',
      description: 'Protecting your design gives you a competitive edge, allowing you to establish a unique market presence.'
    },
    {
      title: 'Increased Brand Value',
      description: 'A registered design enhances the value of your brand by showcasing originality and innovation.'
    },
    {
      title: 'Monetary Benefits',
      description: 'Enables you to license or sell your design, providing opportunities for additional income.'
    },
    {
      title: 'Global Recognition',
      description: "Design registration can offer international protection, enhancing your brand's reach and credibility globally."
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Design Registration"
        ctaText="Get in touch"
        imagePath="./Designregis.webp"
        children={<DesignTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Design Registration Made Simple
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Design registration is essential for protecting the unique visual
            aspects of your product. We provide a streamlined process to ensure
            your designs are legally protected and secure in the marketplace.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Design Registration?
            </h6>

            <p className="mb-4">
              Design registration is the process of securing legal rights for
              the unique visual appearance of a product. This includes shapes,
              patterns, and colors that distinguish your product from others in
              the market. It ensures that your design cannot be copied or
              imitated without your permission.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Design Registration
            </h6>
            <p className="mb-4">
              To apply for design registration, you must meet the following
              requirements:
              <ul>
                <li>
                  <strong>Design Application Form -</strong> Complete the
                  official design registration application form provided by the
                  intellectual property office.
                </li>

                <li>
                  <strong>Design Drawings/Images -</strong> Submit clear and
                  detailed drawings or images of the design from different
                  angles.
                </li>

                <li>
                  <strong>Description of the Design -</strong> Provide a
                  detailed description of the design, including its visual
                  features and unique elements.
                </li>

                <li>
                  <strong>Proof of Identity -</strong> Submit proof of identity
                  of the applicant or the company applying for the design
                  registration.
                </li>

                <li>
                  <strong>Payment of Fees -</strong> Pay the required
                  application fee as per the intellectual property office’s
                  guidelines.
                </li>
              </ul>
            </p>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of Design Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-3">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Design Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Apply for Design Registration</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {reasons.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function DesignTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Design registration provides legal protection for the unique visual
        appearance of a product, ensuring that your design is safeguarded
        against unauthorized copying and use. It helps differentiate your
        product in the market and adds value to your brand.
      </p>
      <p>
      Countless designers and businesses have protected their unique
      designs with our assistance.
      </p>
    </>
  );
}

export default DesignNavbar;
