import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
const PatentSearchAndProvisionalApplication = ({ height }) => {
  // Patent Search and Provisional Application grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Patent Search",
      description:
        "Conduct a comprehensive search to identify existing patents and assess the novelty of your invention.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Provisional Patent Application",
      description:
        "File a provisional patent application to establish an early filing date and secure your invention.",
    },
    {
      imagePath: "flexi.webp",
      title: "Drafting Patent Claims",
      description:
        "Prepare detailed patent claims to define the scope of your invention.",
    },
    {
      imagePath: "limltd.webp",
      title: "Patent Attorney Consultation",
      description:
        "Consult with a patent attorney to ensure all aspects of your invention are protected.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Filing Process Guidance",
      description:
        "Guidance on the filing process, including submission requirements and timelines.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Comprehensive Search - ",
      description:
        "Identify prior patents and assess the novelty of your invention.",
    },
    {
      imageright: "right.png",
      title: "Early Filing Date - ",
      description:
        "Secure your invention with a provisional patent application.",
    },
    {
      imageright: "right.png",
      title: "Expert Drafting -",
      description:
        "Professional assistance in drafting detailed and effective patent claims.",
    },
    {
      imageright: "right.png",
      title: "Legal Consultation -",
      description:
        "Access to experienced patent attorneys for guidance and advice.",
    },
    {
      imageright: "right.png",
      title: "Streamlined Process -",
      description: "Efficient filing process with clear guidance and support.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Complex Procedure -",
      description:
        "Patent search and application filing can be complex and require expert knowledge.",
    },
    {
      imageright: "dangerous.webp",
      title: "Potential Costs -",
      description:
        "Associated costs for patent search, drafting, and legal consultations.",
    },
    {
      imageright: "dangerous.webp",
      title: "Time-Consuming -",
      description:
        "The process can be time-consuming, requiring detailed preparation and documentation.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Patent Search Report",
      description:
        "A detailed report of existing patents related to your invention.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Provisional Patent Application Form",
      description:
        "The official form for filing a provisional patent application with detailed descriptions of your invention.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Detailed Description of Invention",
      description:
        "A comprehensive description of your invention including its features and functionality.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Patent Claims",
      description:
        "Claims defining the scope and unique aspects of your invention.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Supporting Documents",
      description:
        "Additional documents such as drawings, prototypes, or research data related to your invention.",
    },
  ];

  const steps = [
    {
      title: "Conduct Patent Search",
      description:
        "Perform a thorough search to identify existing patents and evaluate the uniqueness of your invention.",
    },
    {
      title: "Prepare Provisional Application",
      description:
        "Draft and prepare the provisional patent application form with detailed information about your invention.",
    },
    {
      title: "File Provisional Application",
      description:
        "Submit the provisional patent application to the relevant patent office.",
    },
    {
      title: "Consult with Patent Attorney",
      description:
        "Seek advice from a patent attorney to review your application and ensure comprehensive protection.",
    },
    {
      title: "Monitor Application Status",
      description:
        "Track the status of your application and respond to any queries or additional requirements from the patent office.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const reasons = [
    {
      title: 'Comprehensive Analysis',
      description: 'In-depth research to ensure your invention is unique and not already patented by someone else.'
    },
    {
      title: 'Avoid Legal Issues',
      description: 'Helps avoid potential legal conflicts by identifying existing patents similar to your invention.'
    },
    {
      title: 'Strategic Advantage',
      description: 'Gain insights into existing technologies to refine your patent application and strengthen your position.'
    },
    {
      title: 'Cost-effective Approach',
      description: 'Investing in a professional patent search can save costs associated with rejected applications.'
    },
    {
      title: 'Expert Guidance',
      description: 'Work with experienced professionals who understand patent databases and regulations.'
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Patent Search"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<PatentTo/>}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="search" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Patent Search and Provisional Application Filing
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Patent Search helps identify prior patents and assess your
            invention's uniqueness. Provisional Application Filing secures an
            early filing date and establishes your intellectual property rights.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Patent Search and Provisional Application Filing?
            </h6>

            <p className="mb-4">
              Patent Search involves a thorough investigation of existing
              patents to evaluate the novelty and potential patentability of
              your invention. Filing a Provisional Patent Application allows you
              to establish an early filing date while you finalize your
              invention and prepare a complete patent application.
            </p>
          </div>

          <div id="application" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Application Process for Provisional Patent Filing
            </h6>
            <p className="mb-4">
              To file a provisional patent application, you need to prepare and
              submit the following:
              <ul>
                <li>
                  <strong>Patent Search Report -</strong> A comprehensive report
                  identifying existing patents related to your invention.
                </li>

                <li>
                  <strong>Provisional Patent Application Form -</strong> The
                  official form with detailed descriptions of your invention.
                </li>

                <li>
                  <strong>Detailed Description of Invention -</strong> A
                  thorough description of your invention, including its features
                  and functionality.
                </li>

                <li>
                  <strong>Patent Claims -</strong> Claims outlining the scope
                  and unique aspects of your invention.
                </li>

                <li>
                  <strong>Supporting Documents -</strong> Additional materials
                  such as drawings, prototypes, or research data.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="flex flex-wrap mt-8">
            <h6 className="text-blue-500 text-lg font-semibold text-center">
              Key Features of Our Patent Search and Provisional Application
              Filing Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="mt-4">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="documents" className="why-choose mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Documents Required for Patent Search and Provisional Application
              Filing
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="process" className="how-to-apply mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Steps to Complete Patent Search and Provisional Application Filing
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {reasons.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function PatentTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Patent Search and Provisional Application Filing are crucial steps in
        protecting your invention. Our services guide you through a
        comprehensive patent search and assist with filing a provisional patent
        application to secure your intellectual property rights.
      </p>
      {""}
      <p>
      Ensure your invention is protected with our expert assistance in
      patent search and provisional application filing.
      </p>
    </>
  );
}
export default PatentSearchAndProvisionalApplication;
