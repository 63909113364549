import React from 'react';
import './LLPcardgrid.css'; // Custom styling for card grid
import './LLP.css'; // Custom styling for the page
import HeroSection from './HeroSection';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';
import FeatureCard from './FeatureCard';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';

const PartnershipToLLPNavbar = ({ height }) => {

  // Features, advantages, disadvantages, documents, and steps items
  const items = [
    { 
      imagePath:"/Grp.webp",
      title: 'Limited Liability', 
      description: 'Partners’ liability is limited to their contribution in the LLP.' 
    },
    { 
      imagePath:"/Costeff.webp",
      title: 'Separate Legal Entity', 
      description: 'LLP is a separate legal entity from its partners.' 
    },
    { 
      imagePath:"/flexi.webp",
      title: 'Flexibility in Management', 
      description: 'Flexibility in the structure and management of the LLP.' 
    },
    { 
      imagePath:"/limltd.webp",
      title: 'Tax Benefits',
      description: 'Possibility of tax advantages compared to traditional partnership firms.' },
    { 
      imagePath:"/lessreg.webp",
      title: 'Ease of Fundraising', 
      description: 'Easier to attract investment due to the LLP structure.' 
    },
  ];

  const advantages = [
    {
      imageright: "/right.png",
      title:"Limited Liability",
      description:"Partners’ liability is limited to their contribution in the LLP."
    },
    {
      imageright: "/right.png",
      title:"Separate Legal Entity",
      description:"LLP is a separate legal entity from its partners."
    },
    {
      imageright: "/right.png",
      title:"Flexibility in Management",
      description:"Possibility of tax advantages compared to traditional partnership firms."
    },
    {
      imageright: "/right.png",
      title:"Ease of Fundraising",
      description:"Easier to attract investment due to the LLP structure."
    },
  ];

  const disadvantages = [
    {
      imageright: "/dangerous.webp",
      title:"Regulatory Compliance",
      description:"LLPs are subject to regulatory compliance and formalities."
    },
    {
      imageright: "/dangerous.webp",
      title:"Cost of Conversion",
      description:"Initial costs associated with conversion may be higher."
    },
    {
      imageright: "/dangerous.webp",
      title:"Complex Structure",
      description:"Transitioning from a partnership to an LLP may involve complex legal processes."
    },
    {
      imageright: "/dangerous.webp",
      title:"Regulatory Compliance",
      description:"LLPs are subject to regulatory compliance and formalities."
    },
  ];

  const documents = [
    { 
      imageDoc: "/docu.webp",
      imageDoc2: "/docu2.png",
      title: "Partnership Deed",
      description: "Copy of the existing partnership deed."
    },
    { 
      imageDoc: "/docu.webp",
      imageDoc2: "/docu2.png",
      title: "Certificate of Incorporation", 
      description: "Certificate of incorporation of the new LLP."
     },
    { 
      imageDoc: "/docu.webp",
      imageDoc2: "/docu2.png",
      title: "Board Resolutions",
       description: "Resolutions passed by partners agreeing to the conversion." 
      },
    { 
      imageDoc: "/docu.webp",
      imageDoc2: "/docu2.png",
      title: "Proof of Address", 
      description: "Proof of address of the registered office of the LLP." 
    },
    { 
      imageDoc: "/docu.webp",
      imageDoc2: "/docu2.png",
      title: "Identity Proof of Partners", 
      description: "Identity proof of all the partners involved." 
    },
    { 
      imageDoc: "/docu.webp",
      imageDoc2: "/docu2.png",
      title: "PAN Card", 
      description: "Permanent Account Number (PAN) of the LLP."
     }
  ];

  const steps = [
    { title: "Obtain Digital Signature Certificates (DSC)", description: "Acquire DSCs for all the designated partners of the LLP." },
    { title: "Obtain Director Identification Number (DIN)", description: "Apply for DIN for all the designated partners of the LLP." },
    { title: "Draft LLP Agreement", description: "Prepare the LLP agreement detailing the rights and duties of the partners." },
    { title: "File for Conversion", description: "File an application for conversion with the Ministry of Corporate Affairs (MCA)." },
    { title: "Obtain Certificate of Conversion", description: "Receive the certificate of conversion from the MCA." },
    { title: "Update Records", description: "Update all relevant records and documents with the new LLP details." }
  ];

  const navLink = [
    { name: "Overview", link: "#overview" },
    { name: "Eligibility", link: "#eligibility" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pros-cons" },
    { name: "Documentation", link: "#documents" },
    { name: "Registration", link: "#conversion" },
    { name: "Why choose?", link: "#why-choose" },
  ];

  return (
    <>
    <div className="sticky z-50" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Conversion of Partnership Firm to LLP"
        ctaText="Get in touch"
        imagePath="/LLP_Icon.webp"
        children={<Partnershipllp />}
      />

      <div className='mainLLP w-full flex flex-wrap px-8 py-16'>
        <div id="overview" className="LLPRegis w-full mb-12 text-center">
          <h2 className='text-2xl font-semibold'>Conversion of Partnership Firm to LLP</h2>
          <p className='mt-4'>Converting a partnership firm to an LLP offers limited liability protection to its partners while retaining the flexibility of a partnership structure. T2F offers expert guidance through each step of the conversion process to ensure compliance and a smooth transition.</p>
        </div>

        <div className='LLP-container mt-16 max-w-[972px] md:px-16'>
          <div className='mb-16'>
            <h3 className='text-blue-500 text-lg font-semibold mb-4'>What is Conversion of Partnership Firm to LLP?</h3>
            <p>Converting a partnership firm into a Limited Liability Partnership (LLP) involves transitioning from a traditional partnership structure to an LLP structure. This conversion provides several benefits including limited liability for partners, separate legal status, and enhanced flexibility in management and operations.</p>
            <ul className='mt-4 list-disc pl-5'>
              {items.map((item, index) => (
                <li key={index}><strong>{item.title}:</strong> {item.description}</li>
              ))}
            </ul>
          </div>

          <div className='mb-16'>
            <h3 className='text-blue-500 text-lg font-semibold' id="features">Key Features of LLP Conversion</h3>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id='documents' className='mb-16'>
            <h3 className='text-blue-500 text-lg font-semibold'>Documents Needed for Conversion to LLP</h3>
            <p>Here’s what you’ll need:</p>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id='conversion' className='mb-16'>
            <h3 className='text-lg font-semibold'>How to Convert</h3>
            <p>Follow these steps for a smooth conversion of your partnership firm to an LLP:</p>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
          </div>

          <div id='why-choose' className='mb-16'>
            <h3 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for Your LLP Conversion</h3>
            <ul className='list-disc pl-5'>
              <li><strong>Expert Consultation:</strong> Personalized guidance to help you through the conversion process.</li>
              <li><strong>Documentation Assistance:</strong> Help with preparing and filing all necessary documents.</li>
              <li><strong>DSC and PAN Assistance:</strong> Facilitation of obtaining digital signatures and Permanent Account Number.</li>
              <li><strong>Compliance Support:</strong> Ensure adherence to legal and regulatory requirements.</li>
              <li><strong>Ongoing Support:</strong> Continued support throughout the conversion process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function Partnershipllp() {
  return (
    <div style={{ marginTop: "10px", fontWeight: "500", fontSize: "18px", lineHeight: "30px" }}>
      <p>Converting your partnership firm to a Limited Liability Partnership (LLP) can provide enhanced flexibility, limited liability, and tax benefits. T2F simplifies this transition, ensuring a smooth and compliant process for your firm’s conversion.</p>
      <p>10K+ Trusted Companies Successfully Registered</p>
    </div>
  );
}

export default PartnershipToLLPNavbar;
