import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const SellYourCompany = ({ height }) => {
  // Sell Your Company grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Business Valuation",
      description:
        "We offer fair and accurate valuations based on your company’s market potential, financial health, and assets.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Streamlined Process",
      description:
        "Our team ensures a hassle-free process, handling legal paperwork and negotiations on your behalf.",
    },
    {
      imagePath: "flexi.webp",
      title: "Confidentiality",
      description:
        "We value your privacy and guarantee that your company’s details will remain confidential throughout the sale process.",
    },
    {
      imagePath: "limltd.webp",
      title: "Flexible Payment Options",
      description:
        "We provide multiple payment options to suit your financial needs and requirements.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Fast Turnaround",
      description:
        "Our goal is to finalize the sale quickly while ensuring a smooth transition for your company.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Quick Sale -",
      description:
        "You can sell your company without the usual delays of finding buyers in the market.",
    },
    {
      imageright: "right.png",
      title: "Fair Valuation -",
      description:
        "We provide competitive valuations to ensure you receive the right value for your business.",
    },
    {
      imageright: "right.png",
      title: "Hassle-Free -",
      description:
        "We handle all the paperwork, negotiations, and legal work for you, saving time and effort.",
    },
    {
      imageright: "right.png",
      title: "Confidentiality -",
      description:
        "We ensure complete confidentiality throughout the process, keeping sensitive information secure.",
    },
    {
      imageright: "right.png",
      title: "Flexible Terms -",
      description:
        "We offer flexible payment terms to accommodate your financial needs.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Reduced Flexibility -",
      description:
        "You may not have control over certain terms of the sale compared to selling on your own.",
    },
    {
      imageright: "dangerous.webp",
      title: "Potential Lower Offers -",
      description:
        "Selling directly to a buyer may fetch higher offers compared to selling to a third party.",
    },
    {
      imageright: "dangerous.webp",
      title: "Limited Buyer Interaction -",
      description:
        "Since we handle negotiations, you may have less direct communication with the final buyer.",
    },
    {
      imageright: "dangerous.webp",
      title: "Time Constraints -",
      description:
        "The need to complete the sale quickly might limit your negotiation leverage.",
    },
    {
      imageright: "dangerous.webp",
      title: "Legal Restrictions -",
      description:
        "There might be restrictions or clauses that you have to agree to when selling to us.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Financial Statements",
      description:
        "Submit your company's balance sheets, income statements, and financial reports for accurate valuation.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Ownership Proof",
      description:
        "Provide documentation that confirms the ownership structure and legal authority to sell the company.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Filings",
      description:
        "Make available recent tax filings and records to ensure compliance with tax obligations.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Asset Documentation",
      description:
        "Provide details of your company’s tangible and intangible assets for evaluation.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Contracts",
      description:
        "Submit all active contracts and agreements that affect the business.",
    },
  ];

  const steps = [
    {
      title: "Initial Consultation",
      description:
        "Reach out to us for a confidential consultation and provide us with the necessary details about your company.",
    },
    {
      title: "Business Valuation",
      description:
        "Our team will conduct a detailed valuation of your company based on financial health, market trends, and other factors.",
    },
    {
      title: "Offer Presentation",
      description:
        "We will present a fair and competitive offer based on the valuation and negotiate terms to meet your needs.",
    },
    {
      title: "Legal Documentation",
      description:
        "We handle all legal paperwork and ensure a secure transfer of ownership with no hassle.",
    },
    {
      title: "Finalize the Sale",
      description:
        "Once all terms are agreed upon, we finalize the sale, ensuring a smooth transition for both parties.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Sell Your Company Quickly and Confidentially"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<SellTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Quick, Hassle-Free Company Sale
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            We offer a smooth and secure process for selling your company,
            handling all aspects of the transaction to give you peace of mind.
            From accurate valuations to fast turnarounds, we ensure a seamless
            experience.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Sell Your Company to Us?
            </h6>

            <p className="mb-4">
              This service offers business owners the opportunity to sell their
              company directly to us, allowing for a quick, straightforward sale
              without the complexities of traditional methods. We handle all
              legal, financial, and negotiation aspects for a seamless
              transaction.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Selling Your Company
            </h6>
            <p className="mb-4">
              To sell your company to us, ensure the following:
              <ul>
                <li>
                  <strong>Ownership Proof -</strong> Provide documentation that
                  verifies your legal ownership of the company.
                </li>

                <li>
                  <strong>Financial Records -</strong> Up-to-date financial
                  statements are required to assess the value of the business.
                </li>

                <li>
                  <strong>Tax Compliance -</strong> Make sure all tax filings
                  and obligations are current.
                </li>

                <li>
                  <strong>Asset Inventory -</strong> Prepare a detailed list of
                  your company's assets, including tangible and intangible
                  items.
                </li>

                <li>
                  <strong>No Legal Disputes -</strong> Ensure your company is
                  free from ongoing legal disputes or claims.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Selling Your Company to Us
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req my-10 ">
            <h2 className="text-blue-500 text-lg font-semibold">
              Documents Required for Selling Your Company to Us
            </h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply my-10 ">
            <h2 className="text-blue-500 text-lg font-semibold">
              Steps for Selling Your Company to Us
            </h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for Selling Your Company?
            </h6>
            <p>
              We ensure that your company sale is conducted quickly and
              efficiently, with our legal and financial experts handling all the
              heavy lifting. Our goal is to provide a smooth, secure, and
              satisfactory process for all parties involved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function SellTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Looking to sell your company? We offer a simple and secure process for
        business owners looking for a quick sale, all while ensuring your
        privacy and providing fair valuations.
      </p>
      <p>
        Our experts handle every step, from valuation to closing the deal,
        providing a hassle-free experience for you.
      </p>
    </>
  );
}

export default SellYourCompany;
