import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "./LLPcard";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const ShopEstablishmentLicense = ({ height }) => {
  // Shop & Establishment License grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Legal Compliance",
      description:
        "Ensures that your business complies with local labor and business laws.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Operational Legitimacy",
      description:
        "Provides legal legitimacy to operate a shop or establishment in the area.",
    },
    {
      imagePath: "flexi.webp",
      title: "Employee Benefits",
      description:
        "Ensures employees receive statutory benefits and rights under the law.",
    },
    {
      imagePath: "limltd.webp",
      title: "Avoid Legal Issues",
      description:
        "Helps avoid legal complications and penalties associated with operating without a license.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Streamlined Operations",
      description:
        "Facilitates smoother business operations by complying with regulatory requirements.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Compliance -",
      description:"Ensures your business adheres to local labor laws and regulations, avoiding legal issues."
    },
    {
      imageright: "right.png",
      title:"Operational Legitimacy -",
      description:"Grants legal permission to operate a shop or establishment, contributing to business credibility."
    },
    {
      imageright: "right.png",
      title:"Employee Benefits -",
      description:"Guarantees that employees receive their entitled benefits and protections under the law."
    },
    {
      imageright: "right.png",
      title:"Avoid Legal Issues -",
      description:"Helps prevent legal complications and fines by maintaining proper licensing."
    },
    {
      imageright: "right.png",
      title:"Streamlined Operations -",
      description:"Promotes smoother operations by meeting regulatory requirements and avoiding disruptions."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Regulatory Requirements -",
      description:"Compliance with local regulations can be complex and time-consuming."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost of Licensing -",
      description:"Involves costs associated with obtaining and renewing the Shop & Establishment License."
    },
    {
      imageright: "dangerous.webp",
      title:"Periodic Inspections -",
      description:"Subject to inspections by local authorities to ensure ongoing compliance with regulations."
    },
    {
      imageright: "dangerous.webp",
      title:"Operational Restrictions -",
      description:" May impose certain operational constraints and requirements on the business."
    },
    {
      imageright: "dangerous.webp",
      title:"Penalties for Non-Compliance -",
      description:" Non-compliance can result in fines or other legal consequences."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "Valid identity proof of the owner or business entity, such as Aadhar card, PAN card, or passport.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Premises Proof",
      description:
        "Proof of ownership or lease agreement for the premises where the shop or establishment operates.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Address",
      description:
        "Proof of business address, such as utility bills or rent agreements, to verify the location of the establishment.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Employee Details",
      description:
        "Details of employees working in the establishment, including their identification and employment contracts.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Payment Receipts",
      description:
        "Receipts for any fees paid for the license application and processing.",
    },
  ];

  const steps = [
    {
      title: "Gather Documents",
      description:
        "Collect all necessary documents, including identity proof, premises proof, and employee details.",
    },
    {
      title: "Complete Application",
      description:
        "Fill out the application form with accurate details about your shop or establishment and its operations.",
    },
    {
      title: "Submit Application",
      description:
        "Submit the completed application along with required documents to the local authority.",
    },
    {
      title: "Inspection",
      description:
        "Undergo an inspection by local authorities to ensure compliance with regulations.",
    },
    {
      title: "Receive License",
      description:
        "Upon successful inspection and verification, receive the Shop & Establishment License to operate legally.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Shop & Establishment License"
        ctaText="Get in touch"
        imagePath="./Udhyamregis.webp"
        children={<ShopTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="overview" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Shop & Establishment License Overview
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            A Shop & Establishment License is required to legally operate a
            retail or commercial establishment. It ensures compliance with local
            regulations and provides legal validity to your business operations.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is a Shop & Establishment License?
            </h6>

            <p className="mb-4">
              A Shop & Establishment License is a mandatory registration
              required for operating a shop or establishment. It ensures
              adherence to labor laws and regulations, provides legitimacy to
              business operations, and protects employee rights.
            </p>
          </div>

          <div id="eligibility" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Shop & Establishment License
            </h6>
            <p className="mb-4">
              To be eligible for a Shop & Establishment License, you must:
              <ul>
                <li>
                  <strong>Provide Identity Proof -</strong> Submit valid
                  identity proof of the owner or business entity.
                </li>

                <li>
                  <strong>Submit Premises Proof -</strong> Provide proof of
                  ownership or lease agreement for the premises.
                </li>

                <li>
                  <strong>Provide Business Address -</strong> Proof of business
                  address, such as utility bills or rent agreements.
                </li>

                <li>
                  <strong>Details of Employees -</strong> Information about
                  employees, including their identification and employment
                  contracts.
                </li>

                <li>
                  <strong>Payment Receipts -</strong> Receipts for any fees paid
                  for the license application and processing.
                </li>
              </ul>
            </p>
          </div>

          <div className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of Shop & Establishment License
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="documents" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Shop & Establishment License</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="steps" className="how-to-apply mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Apply for Shop & Establishment License</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="why" className="why-choose my-5">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for Shop & Establishment License?
            </h6>
            <p>
              We provide expert assistance in obtaining your Shop &
              Establishment License, ensuring compliance with all local
              regulations and a smooth application process.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function ShopTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Obtaining a Shop & Establishment License is essential for
              operating your business legally. Our streamlined process ensures
              you meet all legal requirements and operate without interruptions.
      </p>
      <p>
      Ensure legal compliance and smooth operations with our expert
              assistance in obtaining your Shop & Establishment License.
      </p>
    </>
  );
}

export default ShopEstablishmentLicense;
