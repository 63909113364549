import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const ChangeAuthCapital = ({ height }) => {
  // Change Authorized Capital grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Increased Flexibility",
      description:
        "Adjust your company’s authorized capital to accommodate growth or new investments.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Improved Financial Health",
      description:
        "Enhance your company’s financial stability by increasing its capital base.",
    },
    {
      imagePath: "flexi.webp",
      title: "Investor Confidence",
      description:
        "Boost investor confidence by demonstrating financial strength and potential.",
    },
    {
      imagePath: "limltd.webp",
      title: "Legal Compliance",
      description:
        "Ensure compliance with legal requirements for authorized capital changes.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Streamlined Process",
      description:
        "Benefit from a streamlined process for updating your company’s authorized capital efficiently.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Enhanced Flexibility -",
      description:"Allows you to adjust your company’s capital to match its financial needs and business goals."
    },
    {
      imageright: "right.png",
      title:"Stronger Financial Position -",
      description:"Increasing authorized capital can improve your company’s financial health and stability."
    },
    {
      imageright: "right.png",
      title:"Investor Appeal -",
      description:"A higher authorized capital may attract more investors and improve their confidence in your business."
    },
    {
      imageright: "right.png",
      title:"Regulatory Compliance -",
      description:"Helps ensure that your company remains compliant with regulatory requirements related to capital structure."
    },
    {
      imageright: "right.png",
      title:"Efficient Process -",
      description:" A streamlined process ensures that changes are made quickly and with minimal disruption to business operations."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Increased Documentation -",
      description:"Changing authorized capital may require additional documentation and regulatory filings."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Costs -",
      description:"There might be costs associated with legal and administrative aspects of changing authorized capital."
    },
    {
      imageright: "dangerous.webp",
      title:"Impact on Shareholders -",
      description:"Changes in authorized capital could affect existing shareholders, particularly if new shares are issued."
    },
    {
      imageright: "dangerous.webp",
      title:"Complexity -",
      description:"The process can be complex and may require professional assistance to ensure all legal requirements are met."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Board Resolution",
      description:
        "A resolution passed by the board of directors approving the change in authorized capital.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Shareholders’ Approval",
      description:
        "Approval from shareholders, if required, for the change in authorized capital.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Updated Memorandum of Association (MOA)",
      description: "Revised MOA reflecting the new authorized capital.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Compliance Certificate",
      description:
        "Certificate confirming compliance with relevant regulatory requirements for capital change.",
    },
  ];

  const steps = [
    {
      title: "Board Meeting",
      description:
        "Hold a board meeting to approve the decision to change the authorized capital.",
    },
    {
      title: "Shareholders’ Meeting",
      description:
        "Obtain approval from shareholders if necessary, for the proposed change in authorized capital.",
    },
    {
      title: "Amend MOA",
      description:
        "Update the Memorandum of Association (MOA) to reflect the new authorized capital.",
    },
    {
      title: "File with Authorities",
      description:
        "Submit the necessary documents and forms to regulatory authorities for approval.",
    },
    {
      title: "Receive Confirmation",
      description:
        "Obtain confirmation from authorities that the change in authorized capital has been registered.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Change Your Company’s Authorized Capital with Ease"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ChangeAuth />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Change Your Company’s Authorized Capital with Ease
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Modifying your company’s authorized capital can provide flexibility
            for growth and attract potential investors. Our efficient process
            ensures a smooth transition while maintaining compliance with all
            legal requirements.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Changing Authorized Capital?
            </h6>

            <p className="mb-4">
              Changing authorized capital involves increasing or decreasing the
              amount of capital a company is authorized to raise through the
              issuance of shares. This adjustment can help in accommodating
              business expansion or improving financial positioning.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements
            </h6>
            <p className="mb-4">
              To change the authorized capital of your company, you need:
              <ul>
                <li>
                  <strong>Board Resolution -</strong> A resolution from the
                  board of directors approving the change in authorized capital.
                </li>

                <li>
                  <strong>Shareholders’ Approval -</strong> Approval from
                  shareholders if necessary.
                </li>

                <li>
                  <strong>Updated MOA -</strong> Revised Memorandum of
                  Association (MOA) reflecting the new authorized capital.
                </li>

                <li>
                  <strong>Compliance Certificate -</strong> Certificate
                  confirming adherence to regulatory requirements.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Changing Authorized Capital
            </h6>

            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-3">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Changing Authorized Capital</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply my-8 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Change Authorized Capital</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose my-4">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for Changing Authorized Capital?
            </h6>
            <p>
              We offer a comprehensive and efficient process for changing your
              company’s authorized capital. Our experienced team ensures all
              legal requirements are met, and we handle the process with minimal
              disruption to your business.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function ChangeAuth() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Adjusting your company’s authorized capital is essential for accommodating growth, attracting investors, and improving financial stability. Our streamlined process makes it easy to update your capital efficiently and effectively.
      </p>
      <p>
      Optimize your company’s financial health and investor appeal with our expert assistance in changing authorized capital.
      </p>
    </>
  );
}


export default ChangeAuthCapital;
