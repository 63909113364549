import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const TrademarkRenewal = ({ height }) => {
  // Trademark Renewal grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Review Trademark Status",
      description:
        "Check the status of your trademark to ensure it is due for renewal and has not been expired or canceled.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Prepare Renewal Documents",
      description:
        "Gather the necessary documents required for the trademark renewal process, including proof of use and previous registration details.",
    },
    {
      imagePath: "flexi.webp",
      title: "File Renewal Application",
      description:
        "Submit your trademark renewal application to the relevant trademark office before the expiry date.",
    },
    {
      imagePath: "limltd.webp",
      title: "Pay Renewal Fees",
      description:
        "Ensure that all required renewal fees are paid to avoid any lapses or complications in the renewal process.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Monitor Renewal Status",
      description:
        "Track the status of your renewal application to confirm successful renewal and avoid any issues with trademark protection.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Continued Protection -",
      description:"Renewing your trademark ensures that your brand continues to be legally protected."
    },
    {
      imageright: "right.png",
      title:"Legal Rights -",
      description:"Maintains your exclusive rights to use and enforce the trademark, preventing others from using similar marks."
    },
    {
      imageright: "right.png",
      title:"Brand Integrity -",
      description:"Keeps your brand’s reputation intact by ensuring uninterrupted trademark protection."
    },
    {
      imageright: "right.png",
      title:"Avoid Re-registration -",
      description:" Renewal saves you from the need to reapply for a new trademark, saving time and effort."
    },
    {
      imageright: "right.png",
      title:"Business Continuity -",
      description:" Ensures that your business continues to benefit from trademark protection without any gaps."
    },

  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Cost -",
      description:"Trademark renewal involves payment of fees, which can add up, especially if multiple trademarks are involved."
    },
    {
      imageright: "dangerous.webp",
      title:"Administrative Burden -",
      description:"Managing the renewal process and ensuring timely submissions can be administratively burdensome."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential for Errors -",
      description:"Mistakes in the renewal application or missed deadlines can lead to complications or loss of trademark rights."
    },
    {
      imageright: "dangerous.webp",
      title:"Requires Monitoring -",
      description:"Ongoing monitoring is needed to track renewal deadlines and maintain trademark protection."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Renewal Application Form",
      description:
        "The form required for filing a trademark renewal application with the relevant authorities.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Use",
      description:
        "Evidence demonstrating that the trademark has been used in commerce during the renewal period.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Previous Registration Details",
      description:
        "Details of the existing trademark registration to facilitate the renewal process.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Payment Receipts",
      description:
        "Receipts or proof of payment for the renewal fees associated with the trademark.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identification Documents",
      description:
        "Proof of identity for the applicant or business entity as required for trademark renewal.",
    },
  ];

  const steps = [
    {
      title: "Review Trademark Status",
      description:
        "Check the status of your trademark to determine if it is due for renewal and ensure it is in good standing.",
    },
    {
      title: "Prepare Necessary Documents",
      description:
        "Gather all required documents and information, including proof of use and previous registration details.",
    },
    {
      title: "Submit Renewal Application",
      description:
        "File the trademark renewal application with the appropriate trademark office before the expiration date.",
    },
    {
      title: "Pay Required Fees",
      description:
        "Ensure payment of all renewal fees to complete the renewal process and avoid lapses in protection.",
    },
    {
      title: "Confirm Renewal",
      description:
        "Monitor the status of your renewal application to confirm successful renewal and maintain trademark protection.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];


  const reasons = [
    {
    
      title: 'Legal Protection',
      description: 'Renewing your trademark ensures continued legal protection for your brand, preventing unauthorized use.'
    },
    {
    
      title: 'Brand Value',
      description: 'A renewed trademark maintains your brand value and credibility, helping build customer trust over time.'
    },
    {
    
      title: 'Exclusive Rights',
      description: 'Renewal secures exclusive rights to use the trademark for your products or services.'
    },
    {
    
      title: 'Avoid Penalties',
      description: 'Timely renewal helps avoid penalties or loss of rights associated with an expired trademark.'
    },
    {
      
      title: 'Business Expansion',
      description: 'A renewed trademark allows your business to expand and grow while maintaining brand identity.'
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Trademark Renewal Services"
        ctaText="Get in touch"
        imagePath="./TradeRene.webp"
        children={<TradeRene />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Trademark Renewal Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our Trademark Renewal services are designed to help you extend the
            protection of your trademark and ensure that your brand remains
            legally protected. We provide comprehensive support throughout the
            renewal process to maintain your trademark rights.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Trademark Renewal?
            </h6>

            <p className="mb-4">
              Trademark Renewal is the process of extending the registration of
              your trademark beyond its initial period. This is necessary to
              keep your trademark valid and enforceable, ensuring continued
              protection for your brand and intellectual property rights.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for Trademark Renewal
            </h6>
            <p className="mb-4">
              To be eligible for trademark renewal, your trademark must be in
              good standing and have been used in commerce during the
              registration period. Ensure that you meet all requirements and
              deadlines for renewal to maintain your trademark protection.
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of Trademark Renewal
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Trademark Renewal
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Trademark Renewal Process
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {reasons.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};


function TradeRene() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
    Trademark Renewal is the process of extending the protection of your trademark beyond its initial registration period. Regular renewal ensures that your trademark remains valid and enforceable, safeguarding your brand and intellectual property rights.
      </p>
      {""}
      <p>
      Stay ahead of potential issues and maintain your trademark rights by renewing on time with our expert assistance.
      </p>
    </>
  );
}
export default TrademarkRenewal;
