
export const CARDS_DATA=[
    {
      id:0,
      imgLink:'form a business.webp',
      para:"All-in-one Tech Solutions for startup Compliance",
      heading:"Form a Business",
    },
    {
      id:1,
      imgLink:'registration.webp',
      para:"All-in-one Tech Solutions for startup Compliance",
      heading:"Registration",
    },
    {
      id:2,
      imgLink:'intellectual-property.webp',
      para:"All-in-one Tech Solutions for startup Compliance",
      heading:"Intellectual Property",
    },
    {
      id:3,
      imgLink:'business-tool.webp',
      para:"All-in-one Tech Solutions for startup Compliance",
      heading:"Business Tools",
    },
    {
      id:4,
      imgLink:'compliance.webp',
      para:"All-in-one Tech Solutions for startup Compliance",
      heading:"Compliances",
    },
    {
      id:5,
      imgLink:'taxation.webp',
      para:"All-in-one Tech Solutions for startup Compliance",
      heading:"Taxation",
    },
    {
      id:6,
      imgLink:'audits.webp',
      para:"All-in-one Tech Solutions for startup Compliance",
      heading:"Audits",
    },
    {
      id:7,
      imgLink:'partner-with-us.webp',
      para:"All-in-one Tech Solutions for startup Compliance",
      heading:"Partner with Us",
    },
];

export const Data=[
  {
    keyF:
    [
      {
      imgLink:"",
      heading:"Limited Liability",
      para:"Partners’ liability is limited to their contribution in the LLP."
    },
      {
      imgLink:"",
      heading:"Separate Legal Entity",
      para:"LLP is a separate legal entity from its partners."
    },
      {
      imgLink:"",
      heading:"Flexibility in Management",
      para:"Flexibility in the structure and management of the LLP."
    },
      {
      imgLink:"",
      heading:"Tax Benefits",
      para:"Possibility of tax advantages compared to traditional partnership firms."
    },
      {
      imgLink:"",
      heading:"Ease of Fundraising",
      para:"Easier to attract investment due to the LLP structure."
    },
  ],
  docs:[
    {
      imgLink:"",
      heading:"Partnership Deed",
      para:"Copy of the existing partnership deed."
    },
    {
      imgLink:"",
      heading:"Certificate of Incorporation",
      para:"Certificate of incorporation of the new LLP."
    },
    {
      imgLink:"",
      heading:"Board Resolutions",
      para:"Resolutions passed by partners agreeing to the conversion."
    },
    {
      imgLink:"",
      heading:"Proof of Address",
      para:"Proof of address of the registered office of the LLP."
    },
    {
      imgLink:"",
      heading:"Identity Proof of Partners",
      para:"Identity proof of all the partners involved."
    },
    {
      imgLink:"",
      heading:"PAN Card",
      para:"Permanent Account Number (PAN) of the LLP."
    },
  ],
  register:[
    {
      heading:"",
      para:""
    },
    {
      heading:"",
      para:""
    },
    {
      heading:"",
      para:""
    },
    {
      heading:"",
      para:""
    },
    {
      heading:"",
      para:""
    },
  ]
  },
  {},
]