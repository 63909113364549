import React from "react";
import Card from "./LLPcard";
import "./LLPcardgrid.css";
import "./LLP.css";
import ScrollToTopButton from "./ScrollTop";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Advantages from "./llp/Advantages";
import Disadvantages from "./llp/Disadvantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const PrivateLimitedNavbar = ({ height }) => {

  const plcPoints = [
    {
      title: "Separate Legal Entity",
      description:
        "PLCs are distinct from their owners and continue to exist independently of changes in ownership.",
    },
    {
      title: "Limited Liability",
      description:
        " Shareholders are not personally liable for company debts beyond their investment.",
    },
    {
      title: "Perpetual Succession",
      description:
        " The company’s existence is not affected by changes in ownership or management.",
    },
  ];

  const elPoints = [
    {
      title: "Minimum 2 Directors",
      description:
        "An LLP must have at least two partners, with no maximum limit.",
    },
    {
      title: "Minimum 2 Shareholders ",
      description:
        "At least two designated partners must be individuals, and one must be an Indian resident.",
    },
    {
      title: "Unique Company Name ",
      description:
        "An LLP should have a minimum authorized capital of Rs. 1 lakh",
    },
  ];

  const featuresCard = [
    {
      imagePath: "./Grp.webp",
      title: "Separate Legal Entity",
      para: "A Private Limited Company is a separate legal entity from its owners.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Limited Liability",
      para: "Shareholders have limited liability for company debts.",
    },
    {
      imagePath: "flexi.webp",
      title: "Perpetual Succession",
      para: "The company continues to exist regardless of changes in ownership.",
    },
    {
      imagePath: "limltd.webp",
      title: "Ability to Raise Capital",
      para: "Easier to raise capital through private investment.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Compliance Requirements",
      para: "Subject to specific regulatory and compliance requirements.",
    },
  ];

  // Advantages and Disadvantages
  const advantagesPLc = [
    {
      imageright: "right.png",
      title: "Limited Liability ",
      parag:
        "Shareholders are liable only up to their investment in the company.",
    },
    {
      imageright: "right.png",
      title: "Separate Legal Entity",
      parag: "The company exists independently of its owners.",
    },
    {
      imageright: "right.png",
      title: "Perpetual Succession",
      parag:
        "The company continues regardless of changes in ownership or management",
    },
    {
      imageright: "right.png",
      title: "Easier Fund Raising ",
      parag:
        "Ability to raise capital through private investors and other means.",
    },
    {
      imageright: "right.png",
      title: "Credibility",
      parag:
        "Often perceived as more credible than sole proprietorships or partnerships.",
    },
  ];

  const disadvantagesPlc = [
    {
      imageright: "dangerous.webp",
      title: "Regulatory Compliance",
      parag: "Must adhere to strict regulatory and reporting requirements",
    },
    {
      imageright: "dangerous.webp",
      title: "Higher Setup Costs ",
      parag:
        "Generally more expensive to set up than a sole proprietorship or partnership.",
    },
    {
      imageright: "dangerous.webp",
      title: "Restrictions on Share Transfer",
      parag:
        "Shares cannot be freely transferred; requires agreement from existing shareholders.",
    },
    {
      imageright: "dangerous.webp",
      title: "Ongoing Costs ",
      parag: "Regular compliance costs and maintenance of statutory records.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Registered Office Address",
      para: "Includes rent agreement and utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof of Directors",
      para: "Recent documents like bank statements or utility bills.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof of Directors",
      para: "Passport, Aadhar card, or driving license.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport-size Photos",
      para: "With a white background.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      para: "For all directors.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Digital Signature Certificate (DSC)",
      para: "Required for at least one director.",
    },
  ];

  const steps = [
    {
      title: "Obtain a Digital Signature Certificate (DSC)",
      para: "Required for all directors.",
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      para: "For all directors.",
    },
    {
      title: "Choose a Company Name",
      para:
        "Ensure it meets legal requirements and is not already taken.",
    },
    {
      title: "Prepare and File Incorporation Documents",
      para:
        "Submit necessary documents and forms to the Registrar of Companies (RoC).",
    },
    {
      title: "Draft the Memorandum and Articles of Association",
      para: "Outline the company's objectives and internal rules.",
    },
    {
      title: "Receive Certificate of Incorporation",
      para: "Issued by the Registrar of Companies (RoC).",
    },
    {
      title: "Apply for PAN and TAN",
      para: "Essential for tax purposes.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] w-full">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Private Limited Company"
        ctaText="Get in touch"
        imagePath="./PrivateLLP.webp"
        children={<Privateltd />}
      />

      <div id="private" className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Private Limited Company Registration Simplified with T2F
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Looking to establish a Private Limited Company with a robust
            structure and limited liability? T2F makes the registration process
            seamless, guiding you through every step from documentation to
            compliance. With extensive experience and expertise, we ensure your
            company is set up efficiently and in accordance with legal
            requirements. Contact us today to get started.
          </p>
        </div>

        <div id="privates" className="mt-16 max-w-[972px] md:px-16 ">
          <div className="w-auto h-[15%] pr-2 pt-5 ">
            <h6 className="text-blue-500 text-2xl  font-semibold">
              What is a Private Limited Company?
            </h6>
            <p className="mb-4">
              A Private Limited Company (PLC) is a business structure that
              provides limited liability to its shareholders, a separate legal
              entity from its owners, and the ability to raise capital through
              private means. Key characteristics include:
            </p>
            <div>
              <ul className="list-disc">
                {plcPoints.map((item) => (
                  <li>
                    <p>
                      <strong>{item.title}:</strong>
                      {item.description}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
            <p>
              Private Limited Companies are governed by the Companies Act and
              are ideal for those seeking a structured business model with
              limited liability and growth potential.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Registration
            </h6>
            <p className="mb-4">
              To register a Private Limited Company in India, you need to meet
              the following criteria:{" "}
            </p>

            <div>
              <ul className="list-disc">
                {elPoints.map((item) => (
                  <li>
                    <p>
                      <strong>{item.title}:</strong>
                      {item.description}
                    </p>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of a Private Limited Company
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {featuresCard.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.para}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="pros mt-16">
            <h2>Advantages of a Private Limited Company</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantagesPLc.map((advantage, index) => (
                <Advantages
                  key={index}
                  imagePrl={advantage.imageright}
                  title={advantage.title}
                  para={advantage.parag}
                />
              ))}
            </div>

            <div className="Disadvantages mt-4">
              <h2>Disadvantages of a Private Limited Company</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantagesPlc.map((disadvantage, index) => (
                  <Disadvantages
                    key={index}
                    imagePrl={disadvantage.imageright}
                    title={disadvantage.title}
                    para={disadvantage.parag}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="Documents mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Documents Needed for Private Limited Company Registration
            </h6>
            <p>Here’s what you’ll need:</p>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.para}
                />
              ))}
            </div>
          </div>

          <div id="how" className="Howto mt-16">
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth registration process:</p>
              <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg 
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.para}
                    />
                  ))}
                </div>
            </div>
          </div>

          <div id="choose" className="Why mt-12">
            <h6 className="text-blue-500 text-lg font-semibold">
              Why Choose T2F for your Private Limited Company Registration
            </h6>
            <p>
              T2F is your trusted partner for Private Limited Company
              registration:
            </p>
            <ul>
              <li>
                <p>
                <strong>Expert Consultation - </strong> Personalized guidance to
                help you set up your Private Limited Company.</p>
              </li>

              <li>
                <p>
                <strong>Name Reservation - </strong> We’ll assist in reserving
                your desired company name.</p>
              </li>

              <li>
                <p>
                <strong>Comprehensive Documentation -</strong> Help with
                preparing and filing all necessary documents.</p>
              </li>

              <li>
                <p>
                <strong>DSC and DIN Assistance -</strong> Facilitation of
                obtaining digital signatures and identification numbers.
                </p>
              </li>

              <li>
                <p>
                <strong>PAN and TAN Application -</strong> Assistance with
                applying for tax IDs.</p>
              </li>

              <li>
                <p>
                <strong>Ongoing Support -</strong> Continued support throughout
                the registration process and beyond.</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function Privateltd() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        A Private Limited Company is a popular business structure that offers
        limited liability and separate legal entity status.
      </p>
      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
}

export default PrivateLimitedNavbar;
