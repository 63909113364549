import React from "react";
import {Container , Card ,  Row , Col, CardBody} from "react-bootstrap"
import Rohit from './iconsweb p/Rohit SHarma T2F.png'
import Anjali from './iconsweb p/Anjali verma T2F.png'
import Deepak from './iconsweb p/Deepak Mehta T2F.png'
import './client.css'

function Testimonials(){
    const testimonials =[
        {
            id:1,
            responce: "My tax filing was made less complicated by dialT2F, I saved myself from trekking out of the house and achieved it all online, win-win.",
            name:"Rohit  Sharma",
            designation:"Owner of Small Business",
            image:Rohit
        },
        {
            id:2,
            responce: "Starting my own business was easy on dialT2F. They took care of all my business registration and compliance requirements in a very professional manner.",
            name:"Anjali Verma",
            designation:"Startup Founder",
            image:Anjali
        },

        {
            id:3,
            responce: "ITR filing has never been this easy for me. dialT2F has a very convenient platform and support (@ siraj) provided every necessary guideline for me to follow.",
            name:"Deepak Mehta",
            designation:"IT Professional",
            image:Deepak
        },
    ]
    return(
        <Container className="client-container">
            <h4 className="heading">What our <b>Clients Say</b> About us </h4>
            <Row >
                {testimonials.map(testimonials =>(
                    <Col md={4} key={testimonials.id}>
                    <Card className="client-card">
                        <CardBody>
                            <Card.Text className="client-response">
                                {testimonials.responce}
                            </Card.Text>
                            </CardBody>
                            <div className="client-info">
                                    <img src={testimonials.image} alt={testimonials.name} className="client-image"/>
                                    <div className="client-details">
                                        <h5 className="client-name">{testimonials.name}</h5>
                                        <p className="client-designation">{testimonials.designation}</p>
                                    </div>
                                </div>
                    </Card>
                </Col>
                ))}
            </Row>
        </Container>
    );
}
export default Testimonials;
