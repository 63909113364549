import React, { useState } from 'react';
import { BrowserRouter as Router , Routes , Route} from 'react-router-dom';
import NavBar from './components/Navbar';
import Footer from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';
import ContactUS from './components/ContactUs';
import LLPcard from './components/LLPcard'
import LLPcardgrid from './components/LLPcradgrid'
import SecondaryNavbar from './components/LLP';
import Sample2 from './components/Sample2';
import Body from './components/Body';
import Webelieve from './components/Webelieve';
import NidhiNavbar from './components/NidhiNavbar';
import PrivateLimitedNavbar from './components/PrivateLimited';
import OnePersonCompanyNavbar from './components/OnePersonCompanyNavbar';
import MicroFinanceCompanyNavbar from './components/MicroFinanceCompanyNavbar';
import PartnershipFirmNavbar from './components/PartnershipFirmNavbar';
import ProprietorshipToPrivateLimitedNavbar from './components/ProprietorshipToPrivateLimitedNavbar';
import TradeLicenseNavbar from './components/TradeLicenseNavbar';
import TrademarkRegistrationNavbar from './components/TrademarkRegistrationNavbar';
import Section8Navbar from './components/SectionEight';
import ProprietorshipNavbar from './components/ProprietorshipNavbar ';
import IndianSubsidiaryNavbar from './components/IndianSubsidiaryNavbar';
import PartnershipToLLPNavbar from './components/PartnershipToLLPNavbar';
import PublicLtdCompanyNavbar from './components/PublicLtdCompanyNavbar';
import PublicToPrivate from './components/PublicToPrivate';
import NBFNavbar from './components/Nbfc';
import MSMENavbar from './components/MSME';
import FSSAILicenseNavbar from './components/Fssai';
import SocietyRegistration from './components/SocietyReg';
import TrustRegistration from './components/Trust';
import DesignNavbar from './components/DesignReg';
import DSCNavbar from './components/Dsc';
import GSTNavbar from './components/Gst';
import FCRARegistration from './components/Fcra';
import ProducerCompanyRegistration from './components/ProducerCompany';
import InsuranceCompanyRegistration from './components/Insurance';
import NitiAayogRegistration from './components/NitiAyag';
import ISOCertificationNavbar from './components/ISOCertificationNavbar';
import InsuranceBrokerLicense from './components/InsuranceBroker';
import UdyamNavbar from './components/Udyam';
import DrugLicense from './components/DrugLicense';
import EatingHouseLicense from './components/EatingHouse';
import ESIRegistration from './components/Esi';
import EWayBills from './components/EwayBills';
import EPFRegistrationNavbar from './components/EPFRegistrationNavbar';
import Invoicing from './components/Invoicing';
import PayrollServicesNavbar from './components/Payroll';
import ShopEstablishmentLicense from './components/ShopNEst';
import CompanySalePurchase from './components/CompanySaleNPurchase';
import SellYourCompany from './components/SellYrCompany';
import NRI_TaxationServicesNavbar from './components/Nri';
import NRITaxationServicesNavbar from './components/Nri';
import CibilComplaints from './components/Cibil';
import CompanyDirectorChange from './components/ChangeDirector';
import CompanyShareTransferNavbar from './components/CompanyShares';
import CompanyAddressChange from './components/ChangeRegisterOffice';
import BusinessPlanPreparation from './components/TaxPlanning';
import TaxPlanningServicesNavbar from './components/TaxPlanIndiv';
import PatentNavbar from './components/PatentRegisteration';
import MOAAmendment from './components/MOAmmendment';
import PrepInMinutes from './components/PrepInMins';
import ChangeAuthCapital from './components/ChangeAuthorized';
import SEBIRegistration from './components/Sebi';
import RBIRegistration from './components/Rbi';
import WindingUpOfCompanyNavbar from './components/WindingUpOfCompanyNavbar';
import TaxSavingServices from './components/FDBond';
import ForeignRegistration from './components/ForeignReg';
import AppealAgainstIncomeTaxOrdersNavbar from './components/ResponsetoNotice';
import EInvoicing from './components/EInvoicing';
import AnnualReturns from './components/AnnualReturns';
import TaxNoticeAppeals from './components/TaxNotice';
import WindingUpLLP from './components/WindingLLp';
import ITReturn1 from './components/Itr1';
import ITReturn2 from './components/ITReturn2';
import ITReturn3 from './components/ITReturn3';
import ITReturn4Navbar from './components/ITReturn4';
import ITReturn5Navbar from './components/ITReturn5';
import ITReturn6Navbar from './components/ITReturn6';
import ITReturn7Navbar from './components/ITReturn7';
import CloudAccounting from './components/CloudAccounting';
import GenerateForm16 from './components/Form16';
import TrademarkOpposition from './components/TrademarkOpposition';
import TrademarkRenewal from './components/TradenmarkRenewal';
import TrademarkObjection from './components/TrademarkObjection';
import Refund from './components/Refund';
import TrademarkSearch from './components/TrademarkSearch';
import InternationalTrademarkRegistration from './components/InternationTradeReg';
import PTRNavbar from './components/PTR';
import SSI from './components/SSI';
import IECNavbar from './components/IEC';
import PANTANNavbar from './components/TAanReg';
import CopyrightRegistration from './components/CopyrightReg';
import LUTNavbar from './components/LutFilling';
import PatentProvisional from './components/PatentProv';
import GstTemporaryRegistration from './components/GstTemp';
import ReturnFiling from './components/ReturnFiling';
import RegistrationCancellation from './components/RegCancell';
import Newsroom from './components/Newsroom';
import BlogsNArticles from './components/BlogsNArticles';
import Experiment from './components/Experiment';
import PatentSearchAndProvisionalApplication from './components/PatentSearch';
import TrademarkAssignment from './components/TrademarkAssignment';
import Samcard from './components/Samcard';
import MasterCard from './components/MasterCard';


function App() {
  const [navbarHeight,setNavbarHeight]=useState(null);
  console.log("upper navbarHeight : ",navbarHeight);
  
  return (
    <Router>
    <div className="App">
    <NavBar setNavbarHeight={setNavbarHeight}/>
    <Routes>
          <Route
            path="/"
            element={
                <Body/>
            }
          />
          <Route path="/contact-us" element={<ContactUS />} />
          <Route path="/LLP" element={<SecondaryNavbar height={navbarHeight}/>}  />
          <Route path="/LLP" element={<LLPcard/>}/>
          <Route path="/LLP" element={<LLPcardgrid/>}/>


          <Route path="/privacy-policy/:pageNum" element={<Sample2 />}/>
          <Route path="/we-believe" element={<Webelieve/>}/>

          <Route path="/nidhi" element={<NidhiNavbar/>}/>

          <Route path="/private" element={<PrivateLimitedNavbar/>}/>
          <Route path="/OnePersonCompany" element={<OnePersonCompanyNavbar/>}/>
          <Route path="/micro" element={<MicroFinanceCompanyNavbar/>}/>
          <Route path="/PartnershipFirmNavbar" element={<PartnershipFirmNavbar/>}/>

          <Route path="/partnerfirm" element={<OnePersonCompanyNavbar/>}/>
          <Route path="/proptoLim" element={<ProprietorshipToPrivateLimitedNavbar/>}/>
          <Route path="/trade" element={<TradeLicenseNavbar/>}/>

          <Route path="/indian" element={<IndianSubsidiaryNavbar/>}/>
          <Route path="/partnett/:id" element={<PartnershipToLLPNavbar/>}/>


          <Route path="/trade" element={<TradeLicenseNavbar/>}/>
          <Route path="/one" element={<OnePersonCompanyNavbar/>}/>
          <Route path="/Section8Navbar" element={<Section8Navbar/>}/>
          <Route path="/ProprietorshipNavbar" element={<ProprietorshipNavbar/>}/>
          <Route path="/ProprietorshipToPrivateLimitedNavbar" element={<ProprietorshipToPrivateLimitedNavbar/>}/>
          <Route path="/public" element={<PublicLtdCompanyNavbar/>}/>
          <Route path="/publictoprivate" element={<PublicToPrivate/>}/>
          <Route path="/MicroFinanceCompanyNavbar" element={<MicroFinanceCompanyNavbar/>}/>

          <Route path="/nbfc" element={<NBFNavbar/>}/>
          <Route path="/MSME" element={<MSMENavbar/>}/>
          <Route path="/fssai" element={<FSSAILicenseNavbar/>}/>
          <Route path="/society" element={<SocietyRegistration/>}/>
          <Route path="/Trust" element={<TrustRegistration/>}/>
          <Route path="/designReg" element={<DesignNavbar/>}/>
          <Route path="/dsc" element={<DSCNavbar/>}/>
          <Route path="/gst" element={<GSTNavbar/>}/>
          <Route path="/fcra" element={<FCRARegistration/>}/>
          <Route path="/prod" element={<ProducerCompanyRegistration/>}/>
          <Route path="/insurance" element={<InsuranceCompanyRegistration/>}/>
          <Route path="/niti" element={<NitiAayogRegistration/>}/>
          <Route path="/iso" element={<ISOCertificationNavbar/>}/>
          <Route path="/insure" element={<InsuranceBrokerLicense/>}/>
          <Route path="/udyam" element={<UdyamNavbar/>}/>



          <Route path="/drug" element={<DrugLicense/>}/>
          <Route path="/eat" element={<EatingHouseLicense/>}/>
          <Route path="/esi" element={<ESIRegistration/>} /> 
          <Route path="/eway" element={<EWayBills/>} /> 
          
          <Route path="/epf" element={<EPFRegistrationNavbar/>} /> 
          <Route path="/invoicing" element={<Invoicing/>} /> 
          <Route path="/payroll" element={<PayrollServicesNavbar/>} /> 
          <Route path="/shop" element={<ShopEstablishmentLicense/>} /> 
          <Route path="/eway" element={<EWayBills/>} /> 

          <Route path="/compSale" element={<CompanySalePurchase/>} /> 


          <Route path="/sellyr" element={<SellYourCompany/>} /> 
          <Route path="/compSale" element={<CompanySalePurchase/>} /> 
          <Route path="/Nri" element={<NRITaxationServicesNavbar/>} /> 


          <Route path="/cibil" element={<CibilComplaints/>} /> 

          <Route path="/changeDir" element={<CompanyDirectorChange/>} /> 


          <Route path="/compSale" element={<CompanySalePurchase/>} /> 


          <Route path="/CompShares" element={<CompanyShareTransferNavbar/>} /> 


          <Route path="/compAdd" element={<CompanyAddressChange/>} /> 


          <Route path="/taxplanning" element={<BusinessPlanPreparation/>} /> 


          <Route path="/taxplanInd" element={<TaxPlanningServicesNavbar/>} />


          <Route path="/patent" element={<PatentNavbar/>} />


          <Route path="/patentSearch" element={<PatentSearchAndProvisionalApplication/>} /> 


          <Route path="/moa" element={<MOAAmendment/>} />


          <Route path="/prepInMins" element={<PrepInMinutes/>} />

          <Route path="/compSale" element={<CompanySalePurchase/>} /> 


          <Route path="/changeAuth" element={<ChangeAuthCapital/>} />


          <Route path="/sebi" element={<SEBIRegistration/>} /> 



          <Route path="/Rbi" element={<RBIRegistration/>} /> 



          <Route path="/winding" element={<WindingUpOfCompanyNavbar/>} /> 

          <Route path="/WindingLLp" element={<WindingUpLLP/>} /> 


          <Route path="/fdbond" element={<TaxSavingServices/>} /> 


          <Route path="/foreignReg" element={<ForeignRegistration/>} /> 


          
          <Route path="/ResponsetoNotice" element={<AppealAgainstIncomeTaxOrdersNavbar/>} /> 




          <Route path="/einvoicing" element={<EInvoicing/>} /> 




          <Route path="/AnnualReturns" element={<AnnualReturns/>} /> 




          <Route path="/TaxNotice" element={<TaxNoticeAppeals/>} /> 


          <Route path="/ResponsetoNotice" element={<AppealAgainstIncomeTaxOrdersNavbar/>} /> 


          <Route path="/
          " element={<WindingUpLLP/>} /> 

       
          <Route path="/Itr1" element={<ITReturn1/>} /> 

          <Route path="/Itr2" element={<ITReturn2/>} />
          

          <Route path="/Itr3" element={<ITReturn3/>} /> 


          <Route path="/Itr4" element={<ITReturn4Navbar/>} />

          <Route path="/Itr5" element={<ITReturn5Navbar/>} /> 

          <Route path="/Itr6" element={<ITReturn6Navbar/>} /> 

          <Route path="/Itr7" element={<ITReturn7Navbar/>} />


          <Route path="/cloud" element={<CloudAccounting/>} /> 

          <Route path="/form16" element={<GenerateForm16/>} /> 

          <Route path="/refund" element={<Refund/>} /> 

          <Route path="/traderegis" element={<TrademarkRegistrationNavbar/>} /> 
          <Route path="/TrademarkOpp" element={<TrademarkOpposition/>} /> 
          <Route path="/TrademarkRen" element={<TrademarkRenewal/>} /> 
          <Route path="/TrademarkObj" element={<TrademarkObjection/>} /> 
          <Route path="/TrademarkSearch" element={<TrademarkSearch/>} /> 
          <Route path="/TrademarkAssign" element={<TrademarkAssignment/>} /> 
          <Route path="/TradeReg" element={<InternationalTrademarkRegistration/>} /> 


          <Route path="/Ptr" element={<PTRNavbar/>} /> 
          <Route path="/ssi" element={<SSI/>} /> 
          <Route path="/iec" element={<IECNavbar/>} /> 

          <Route path="/Tan" element={<PANTANNavbar/>} /> 

          <Route path="/iec" element={<IECNavbar/>} />

          <Route path="/copyReg" element={<CopyrightRegistration/>} /> 
          <Route path="/lut" element={<LUTNavbar/>} /> 
          <Route path="/foreign" element={<ForeignRegistration/>} /> 
          <Route path="/patentProv" element={<PatentProvisional/>} /> 
          <Route path="/gstTemp" element={<GstTemporaryRegistration/>} /> 
          <Route path="/returnFile" element={<ReturnFiling/>} /> 
          <Route path="/regCancel" element={<RegistrationCancellation/>} /> 
          <Route path="/Newsroom" element={<Newsroom/>} /> 



          <Route path="/blogs" element={<BlogsNArticles height={navbarHeight}/>} /> 
          <Route path="/Samcard" element={<MasterCard/>} /> 
          

        </Routes>
    <Footer/>
    </div>
    </Router>
  );
}
export default App;
