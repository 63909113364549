import React from 'react';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import Documentsrr from './llp/Documentsrr';
import FeatureCard from './FeatureCard';
import HowToReg from './llp/HowToReg';

const MicroFinanceCompanyNavbar = ({ height }) => {

  // Micro Finance Company service grid items
  const items = [
    { 
      imagePath: "Grp.webp",
      title: 'Financial Inclusion', 
      description: 'Helps in providing financial services to underserved populations.' 
    },
    { 
      imagePath: "Costeff.webp",
      title: 'Regulated Sector', 
      description: 'Operates under strict regulatory guidelines ensuring transparency and fairness.'
    },
    { 
      imagePath: "flexi.webp",
      title: 'Microloans', 
      description: 'Provides small loans to individuals or businesses with limited access to traditional banking services.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'Community Development', 
      description: 'Focuses on empowering communities through financial education and support.' },
    { 
      imagePath: "lessreg.webp",
      title: 'Social Impact',
       description: 'Aims at improving the socio-economic conditions of low-income groups.' 
      },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Financial Inclusion -",
      description:"Expands access to financial services for underserved populations."
    },
    {
      imageright: "right.png",
      title:"Regulated Sector -",
      description:"Adheres to strict regulations ensuring transparency and fairness."
    },
    {
      imageright: "right.png",
      title:"Microloans -",
      description:"Provides access to small loans for individuals and businesses with limited banking services."
    },
    {
      imageright: "right.png",
      title:"Community Development -",
      description:"Focuses on empowering communities through financial support and education."
    },
    {
      imageright: "right.png",
      title:"Social Impact -",
      description:"Aims to improve socio-economic conditions of low-income groups."
    },

  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"High Risk -",
      description:"Microfinance operations can involve higher risks due to the nature of the clientele."
    },
    {
      imageright: "dangerous.webp",
      title:"Regulatory Compliance -",
      description:"Requires strict adherence to regulatory guidelines which can be complex."
    },
    {
      imageright: "dangerous.webp",
      title:"Operational Costs -",
      description:"May involve high operational costs, especially in rural areas."
    },
    {
      imageright: "dangerous.webp",
      title:"Limited Scalability -",
      description:"May face challenges in scaling operations due to high transaction costs."
    }
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Registered Office Address",
      description: "Includes rent agreement and utility bills."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof of Directors",
      description: "Recent documents like bank statements or utility bills."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof of Directors",
      description: "Passport, Aadhar card, or driving license."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport-size Photos",
      description: "With a white background."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "For all directors and the company."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Digital Signature Certificate (DSC)",
      description: "Required for the directors."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Plan",
      description: "Detailed plan outlining the business model and financial projections."
    }
  ];

  const steps = [
    {
      title: "Obtain a Digital Signature Certificate (DSC)",
      description: "Required for all directors of the company."
    },
    {
      title: "Apply for Director Identification Number (DIN)",
      description: "For each director of the company."
    },
    {
      title: "Prepare a Detailed Business Plan",
      description: "Including financial projections and target market analysis."
    },
    {
      title: "Apply for a Micro Finance Institution (MFI) License",
      description: "Submit an application to the relevant regulatory authority."
    },
    {
      title: "Choose a Company Name",
      description: "Ensure it complies with regulatory requirements and is not already taken."
    },
    {
      title: "Prepare and File Incorporation Documents",
      description: "Submit necessary documents and forms to the Registrar of Companies (RoC)."
    },
    {
      title: "Receive Certificate of Incorporation",
      description: "Issued by the Registrar of Companies (RoC)."
    },
    {
      title: "Apply for PAN and TAN",
      description: "Essential for tax purposes."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const elpoints=[
    {
      title:"Financial Inclusion:",
      description:"MFCs focus on serving underserved populations, promoting financial inclusion."
    },
    {
      title:"Regulated Sector:",
      description:"Operates under strict regulatory guidelines ensuring transparency and adherence to financial norms."
    },
    {
      title:"Microloans:",
      description:"Provides small-scale loans to individuals or small businesses that lack access to traditional credit facilities."
    },
    {
      title:"Community Development:",
      description:"Focuses on empowering communities through financial support and education."
    },
    {
      title:"Social Impact:",
      description:"Aims to improve the socio-economic conditions of low-income groups."
    },
  ]

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Micro Finance Company (MFC)"
        ctaText="Get in touch"
        imagePath="./LLP_Icon.webp"
        children={<LLpDescription/>}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8 '>
        <div id="overview" className="flex flex-col w-full mt-16 items-center">
          <h6 className='text-2xl text-center font-semibold'>Micro Finance Company Registration Simplified with T2F</h6>
          <p className='max-w-[1086px] text-center mt-4'>Starting a Micro Finance Company (MFC) enables you to provide crucial financial services to underserved communities. T2F offers expert guidance through the registration process, from documentation to compliance, ensuring a smooth setup for your MFC. Connect with us to get started.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16' >

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Micro Finance Company (MFC)?</h6>
            <p className='mb-4'>A Micro Finance Company (MFC) is a financial institution that provides small loans and financial services to individuals and businesses with limited access to traditional banking. Key characteristics include:</p>
            <div>
              <ul>
              {elpoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}</strong>
                    {item.description}
                  </p>
                </li>
              ))}
              </ul>
            </div>
            <p>MFCs are regulated by specific financial authorities to ensure they meet the standards of operation and provide beneficial services to their target populations.</p>
          </div>

          <div id='eligibility' className='pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility Criteria for Micro Finance Company Registration</h6>
            <p>To register a Micro Finance Company (MFC), you need to meet the following criteria:</p>
            <ul>
              <li><strong>Indian Citizen/Resident:</strong> The company must be owned and operated by Indian citizens and residents.</li>

              <li><strong>Minimum Capital Requirement:</strong> Specific minimum capital requirements set by the regulatory authority.</li>

              <li><strong>Business Plan:</strong> A comprehensive business plan outlining the financial model, target market, and operational strategy.</li>

              <li><strong>Office Space:</strong> Proof of a registered office address is required.</li>

              <li><strong>Regulatory Compliance:</strong> Compliance with regulations set forth by the financial regulatory authority.</li>
            </ul>
          </div>

          <div className="mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>




          <div id='documents' className="Documents ">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for LLP Registration
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="how" className="Howto mt-16">
              <div className="registration-steps">
                <h2 className="text-blue-500 text-lg font-semibold">How to Register</h2>
                <p >Follow these steps for a smooth registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
            </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for your Micro Finance Company Registration</h6>
            <p>T2F is your trusted partner for Micro Finance Company registration:</p>
            <ul>
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you set up your MFC.</li>

              <li><strong>License Application - </strong> We’ll assist in applying for the necessary MFI license.</li>

              <li><strong>Comprehensive Documentation -</strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>DSC and DIN Assistance -</strong> Facilitation of obtaining digital signatures and identification numbers.</li>

              <li><strong>PAN and TAN Application -</strong> Assistance with applying for tax IDs.</li>

              <li><strong>PAN and TAN Application -</strong> Assistance with applying for tax IDs.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the registration process and beyond.</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function LLpDescription() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
      A Micro Finance Company (MFC) provides financial services to underserved populations, focusing on small loans and community development. MFCs play a crucial role in promoting financial inclusion and social impact.
      </p>

      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
}
export default MicroFinanceCompanyNavbar;
