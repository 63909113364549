import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const EWayBills = ({ height }) => {
  // E-way Bills grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Simplified Transport",
      description:
        "E-way bills streamline the transport process by providing a digital waybill for goods movement.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Reduced Paperwork",
      description:
        "Minimizes the need for physical documentation, reducing administrative burden.",
    },
    {
      imagePath: "flexi.webp",
      title: "Compliance Tracking",
      description:
        "Facilitates compliance with GST regulations by ensuring proper documentation of goods movement.",
    },
    {
      imagePath: "limltd.webp",
      title: "Enhanced Security",
      description:
        "Provides a secure and tamper-proof method of tracking goods in transit.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Real-Time Updates",
      description:
        "Offers real-time tracking and updates of goods during transportation for better management.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Simplified Transport -",
      description:"E-way bills provide a digital solution for transporting goods, streamlining the entire process."
    },
    {
      imageright: "right.png",
      title:"Reduced Paperwork -",
      description:" Reduces the amount of physical paperwork required, leading to less administrative work and fewer errors."
    },
    {
      imageright: "right.png",
      title:"Compliance Tracking -",
      description:" Ensures compliance with GST regulations by maintaining accurate records of goods movement."
    },
    {
      imageright: "right.png",
      title:"Enhanced Security -",
      description:" Offers secure and tamper-proof tracking of goods during transportation."
    },
    {
      imageright: "right.png",
      title:"Real-Time Updates -",
      description:" Provides real-time tracking and updates, improving logistics and management."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"System Dependency -",
      description:"Requires a stable internet connection and system access for generating and managing e-way bills."
    },
    {
      imageright: "dangerous.webp",
      title:"Technical Issues -",
      description:"Potential technical issues with the e-way bill system can disrupt the process of generating or verifying bills."
    },
    {
      imageright: "dangerous.webp",
      title:"Compliance Challenges -",
      description:"Ensuring compliance with evolving regulations and maintaining accurate data can be challenging."
    },
    {
      imageright: "dangerous.webp",
      title:"User Training -",
      description:"May require training for staff to effectively use the e-way bill system and understand its features."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Invoice Details",
      description:
        "Detailed invoice information for the goods being transported, including quantities and values.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "GST Details",
      description:
        "GST details related to the goods and services to ensure accurate e-way bill generation.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Transporter Information",
      description:
        "Information about the transporter handling the goods, including their registration details.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Goods Details",
      description:
        "Details of the goods being transported, such as description, quantity, and value.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Vehicle Details",
      description:
        "Details of the vehicle used for transportation, including vehicle number and driver information.",
    },
  ];

  const steps = [
    {
      title: "Gather Required Information",
      description:
        "Collect all necessary details including invoice, GST, and transporter information.",
    },
    {
      title: "Generate E-Way Bill",
      description:
        "Use the e-way bill system to create a digital waybill by entering the required details.",
    },
    {
      title: "Verify Details",
      description:
        "Ensure that all information entered is accurate and complies with regulations before finalizing the e-way bill.",
    },
    {
      title: "Print E-Way Bill",
      description:
        "Print the generated e-way bill and attach it to the goods being transported.",
    },
    {
      title: "Maintain Records",
      description:
        "Keep records of generated e-way bills and related documentation for compliance and future reference.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="E-Way Bills"
        ctaText="Get in touch"
        imagePath="./Eway.webp"
        children={<EwayTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            E-Way Bills Overview
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            E-way bills offer an efficient way to manage the transportation of
            goods across state borders. Our service ensures you can handle e-way
            bills seamlessly, complying with regulations and enhancing your
            logistics efficiency.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is an E-Way Bill?
            </h6>

            <p className="mb-4">
              An e-way bill is a digital document required for the movement of
              goods exceeding a certain value. It serves as proof of compliance
              with GST regulations and facilitates the tracking and verification
              of goods in transit.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for E-Way Bills
            </h6>
            <p className="mb-4">
              To generate an e-way bill, you need to:
              <ul>
                <li>
                  <strong>Invoice Details -</strong> Information about the goods
                  being transported, including values and quantities.
                </li>

                <li>
                  <strong>GST Details -</strong> GST details related to the
                  goods to ensure accurate e-way bill generation.
                </li>

                <li>
                  <strong>Transporter Information -</strong> Details of the
                  transporter handling the goods.
                </li>

                <li>
                  <strong>Goods Details -</strong> Detailed description of the
                  goods being transported.
                </li>

                <li>
                  <strong>Vehicle Details -</strong> Information about the
                  vehicle used for transportation.
                </li>
              </ul>
            </p>
          </div>

          <div className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of E-Way Bills
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-4">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>
          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for E-Way Bills</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Generate E-Way Bills</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose my-4">
            <h6 className="text-blue-500 text-lg font-semibold">
              Why Choose Us for E-Way Bills?
            </h6>
            <p>
              Our e-way bill services offer ease of use, comprehensive
              compliance, and enhanced management for your logistics operations.
              Partner with us to streamline your e-way bill processes and ensure
              smooth transport operations.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function EwayTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        E-way bills are crucial for the seamless movement of goods across state
        borders. Our solutions simplify the e-way bill process, ensuring
        compliance and efficiency in your logistics operations.
      </p>
      <p>
      Transform your logistics with our streamlined e-way bill solutions
      designed to enhance your supply chain management.
      </p>
    </>
  );
}

export default EWayBills;
