import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import FeatureCard from "./FeatureCard";
import HowToReg from "./llp/HowToReg";
import HeroSection from "./HeroSection";

const ITReturn5Navbar = ({ height }) => {
  // IT-Return 5 grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Income from Salary",
      description:
        "Taxation details for individuals earning income through salary, including deductions and exemptions.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Income from House Property",
      description:
        "Guidance on tax treatment of income earned from house property, including deductions for interest on home loans.",
    },
    {
      imagePath: "flexi.webp",
      title: "Capital Gains Tax",
      description:
        "Information on taxation of capital gains from the sale of assets such as stocks, real estate, and other investments.",
    },
    {
      imagePath: "limltd.webp",
      title: "Tax Reliefs and Benefits",
      description:
        "Overview of available tax reliefs and benefits under IT-Return 5, including exemptions and deductions.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Income from Other Sources",
      description:
        "Taxation on income from other sources such as interest income, royalties, and dividends.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Comprehensive Coverage -",
      description:"Includes various sources of income like salary, house property, and capital gains."
    },
    {
      imageright: "right.png",
      title:"Detailed Deductions -",
      description:"Access to detailed deductions and exemptions to reduce taxable income."
    },
    {
      imageright: "right.png",
      title:"Tax Reliefs -",
      description:"Availability of tax reliefs and benefits to further reduce tax liabilities."
    },
    {
      imageright: "right.png",
      title:"Clear Guidelines -",
      description:"Well-defined rules for reporting different types of income."
    },
    {
      imageright: "right.png",
      title:"Ease of Filing -",
      description:"Simplified filing process with predefined sections for different income sources."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complexities in Reporting -",
      description:" Reporting different types of income may be complex for some taxpayers."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential for Errors -",
      description:" Increased likelihood of errors if all sources of income and deductions are not accurately reported."
    },
    {
      imageright: "dangerous.webp",
      title:"Detailed Documentation Required -",
      description:"Extensive documentation might be required to support various claims and deductions."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Salary Slips",
      description:
        "Copies of salary slips to verify income earned through employment.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "House Property Documents",
      description:
        "Documents related to house property, including rent receipts and home loan statements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Capital Gains Statements",
      description:
        "Statements detailing capital gains from the sale of assets such as stocks and real estate.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Interest Income Proof",
      description:
        "Proof of interest income earned from savings accounts, fixed deposits, or other investments.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Other Income Records",
      description:
        "Records of other sources of income such as dividends, royalties, or any other non-salary income.",
    },
  ];

  const steps = [
    {
      title: "Gather Required Documents",
      description:
        "Collect all necessary documents related to salary, house property, capital gains, and other sources of income.",
    },
    {
      title: "Calculate Income and Deductions",
      description:
        "Determine the total income and calculate applicable deductions and exemptions.",
    },
    {
      title: "Prepare IT-Return 5",
      description:
        "Fill out the IT-Return 5 form with accurate details of income, deductions, and exemptions.",
    },
    {
      title: "Submit Return",
      description:
        "File the completed IT-Return 5 form electronically or manually, as per the guidelines.",
    },
    {
      title: "Review and Comply",
      description:
        "Review the filed return for accuracy and ensure compliance with all tax regulations.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseITReturn5 = [
    {
      title: "Detailed Reporting",
      description: "The ITR-5 form allows for comprehensive reporting of various income sources, ideal for firms, LLPs, and associations with complex financial structures."
    },
    {
      title: "Flexible Filing for Multiple Entities",
      description: "ITR-5 accommodates a variety of entities such as partnerships, LLPs, and societies, making it flexible and suitable for diverse tax needs."
    },
    {
      title: "Maximizes Tax Benefits",
      description: "Proper filing with ITR-5 ensures that entities can claim deductions and exemptions effectively, optimizing tax liabilities and compliance."
    },
    {
      title: "Accurate Financial Snapshot",
      description: "The form captures a clear and detailed picture of an entity's finances, aiding transparency and regulatory compliance."
    },
    {
      title: "Efficient Documentation Management",
      description: "ITR-5 requires organized reporting of income, deductions, and expenditures, promoting systematic documentation management."
    },
    {
      title: "Streamlined Compliance Process",
      description: "Designed for non-individual taxpayers, ITR-5 simplifies the filing process, ensuring that businesses can easily comply with tax regulations."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="IT-Return 5: Comprehensive Income Tax Filing"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ItrFive />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            IT-Return 5 Filing Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our IT-Return 5 services provide comprehensive assistance for filing
            income tax returns covering salary, house property, capital gains,
            and other income sources. Ensure accurate reporting and benefit from
            available tax reliefs.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is IT-Return 5?
            </h6>

            <p className="mb-4">
              IT-Return 5 is designed for individuals with multiple sources of
              income including salary, house property, and capital gains. It
              simplifies the reporting process and ensures all applicable
              deductions and exemptions are utilized.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for IT-Return 5
            </h6>
            <p className="mb-4">
              To file IT-Return 5, you need to fulfill the following criteria:
              <ul>
                <li>
                  <strong>Income Sources -</strong> The return is applicable if
                  you have income from salary, house property, capital gains, or
                  other sources.
                </li>

                <li>
                  <strong>Documented Proof -</strong> Ensure you have all
                  required documents for each income source to substantiate
                  claims.
                </li>

                <li>
                  <strong>Tax Reliefs -</strong> Make sure you are aware of the
                  available tax reliefs and benefits for accurate filing.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Key Features of IT-Return 5
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for IT-Return 5
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              IT-Return 5 Filing Process
            </h6>
            <div className="LLP-grid-container mt-10">
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold my-3">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseITReturn5.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function ItrFive() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        IT-Return 5 covers income from various sources including salary, house property, and capital gains. It offers detailed guidelines to ensure accurate reporting and maximization of tax reliefs and deductions.
      </p>
      <p>
      Streamline your tax filing process with our expert assistance for IT-Return 5, ensuring compliance and optimized tax benefits.
      </p>
    </>
  );
}
export default ITReturn5Navbar;
