import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import ScrollToTopButton from "./ScrollTop";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
import HeroSection from "./HeroSection";

const TrademarkRegistrationNavbar = ({ height }) => {
  // Trademark grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Legal Protection",
      description:
        "Trademark registration provides legal protection for your brand name and logo.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Brand Identity",
      description:
        "Helps in establishing and maintaining your brand’s identity in the market.",
    },
    {
      imagePath: "flexi.webp",
      title: "Exclusive Rights",
      description:
        "Grants exclusive rights to use the trademark in connection with the goods or services.",
    },
    {
      imagePath: "limltd.webp",
      title: "Market Advantage",
      description:
        "Offers a competitive edge by differentiating your products or services.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Asset Creation",
      description:
        "A registered trademark can become a valuable asset for your business.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Legal Protection -",
      description:
        "Provides exclusive rights to use and protect your brand name and logo.",
    },
    {
      imageright: "right.png",
      title: "Brand Recognition -",
      description:
        "Helps in building and maintaining your brand’s identity and reputation.",
    },
    {
      imageright: "right.png",
      title: "Market Differentiation -",
      description:
        "Distinguishes your products or services from competitors in the market.",
    },
    {
      imageright: "right.png",
      title: "Asset Value -",
      description:
        "A registered trademark can be a valuable asset and can be licensed or sold.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Cost -",
      description:
        "Trademark registration involves fees and ongoing maintenance costs.",
    },
    {
      imageright: "dangerous.webp",
      title: "Time-Consuming -",
      description:
        "The process can take several months and involves detailed paperwork.",
    },
    {
      imageright: "dangerous.webp",
      title: "Limited Scope -",
      description:
        "Protection is limited to the jurisdiction where the trademark is registered.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Certificate",
      description: "Proof of business registration or establishment.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Logo/Brand Name",
      description: "The logo or brand name to be registered as a trademark.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Use",
      description:
        "Evidence that the trademark is being used in commerce (if applicable).",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "Identity proof of the applicant (e.g., PAN card, Aadhar card).",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Power of Attorney",
      description:
        "Authorization for a trademark attorney or agent to act on behalf of the applicant.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Trademark Search Report",
      description:
        "A report confirming that the trademark is not already registered or in use.",
    },
  ];

  const steps = [
    {
      title: "Conduct a Trademark Search",
      description:
        "Ensure the trademark is not already registered or similar to existing ones.",
    },
    {
      title: "Prepare Application",
      description:
        "Prepare the trademark registration application with necessary details and documents.",
    },
    {
      title: "File the Application",
      description: "Submit the application with the relevant trademark office.",
    },
    {
      title: "Examination Process",
      description:
        "The trademark office will examine the application and may request additional information.",
    },
    {
      title: "Publication in Trademark Journal",
      description:
        "If accepted, the trademark will be published in the Trademark Journal for opposition.",
    },
    {
      title: "Receive Trademark Registration Certificate",
      description:
        "Once approved, receive the trademark registration certificate.",
    },
    {
      title: "Renewal",
      description:
        "Trademark registration is valid for 10 years and requires renewal.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Trademark Registration"
        ctaText="Get in touch"
        imagePath="./TradeRigs.webp"
        children={<TradeRegis />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Trademark Registration Made Simple with T2F
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Ready to protect your brand? T2F simplifies the trademark
            registration process, ensuring your brand’s identity is legally
            protected. Contact us today to start securing your trademark.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Trademark Registration?
            </h6>

            <p className="mb-4">
              Trademark registration provides legal protection for unique
              symbols, names, or logos associated with your products or
              services. It helps in distinguishing your brand from others and
              prevents unauthorized use. Here’s why trademark registration is
              important:
            </p>
            <div>
              <ul>
                <strong>Legal Protection:</strong> Ensures exclusive rights to
                use your trademark and prevents others from using it.
              </ul>

              <ul>
                <strong>Brand Identity:</strong> Helps in establishing and
                protecting your brand’s identity in the market.
              </ul>

              <ul>
                <strong>Exclusive Rights:</strong> Grants legal rights to use
                the trademark in connection with your goods or services.
              </ul>
            </div>

            <p>
              Trademark registration is crucial for protecting your intellectual
              property and maintaining a competitive edge in the market.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Registration
            </h6>
            <p className="mb-4">
              To register a trademark, you need to meet the following criteria:
              <ul>
                <li>
                  <strong>Distinctive Mark -</strong> The trademark must be
                  unique and distinguishable from existing trademarks.
                </li>

                <li>
                  <strong>Business Use -</strong> The trademark should be used
                  or intended to be used in connection with your business goods
                  or services.
                </li>

                <li>
                  <strong>Trademark Search -</strong> Conduct a search to ensure
                  the trademark is not already registered.
                </li>
              </ul>
            </p>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of Trademark Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="Documents my-12">
            <h6 className="text-blue-500 text-lg font-semibold">
              Documents Needed for Trademark Registration
            </h6>
            <p>Here’s what you’ll need:</p>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="Howto my-12">
            <div className="registration-steps">
              <h2 className="text-blue-500 text-lg font-semibold">How to Register</h2>
              <p>
                Follow these steps for a smooth trademark registration process:
              </p>
              <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
            </div>
          </div>

          <div id="choose" className="Why my-12">
            <h6 className="text-blue-500 text-lg font-semibold">
              Why Choose T2F for Your Trademark Registration
            </h6>
            <p>T2F is your trusted partner for trademark registration:</p>
            <ul >
              <li>
                <strong>Expert Guidance - </strong> Professional support with
                the trademark registration process.
              </li>

              <li>
                <strong>Document Assistance - </strong> Help with gathering and
                preparing the necessary documents.
              </li>

              <li>
                <strong>Trademark Search -</strong> Conduct thorough searches to
                avoid conflicts with existing trademarks.
              </li>

              <li>
                <strong>Efficient Processing -</strong> Streamlined handling of
                your trademark application.
              </li>

              <li>
                <strong>Ongoing Support -</strong> Continuous assistance
                throughout the registration and compliance process.
              </li>

              <li>
                <strong>Renewal Assistance -</strong> Support with trademark
                renewal and maintenance.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

function TradeRegis() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Trademark registration protects your brand name, logo, and other identifiers from unauthorized use and ensures your exclusive rights.
      </p>
      {""}
      <p>
      10K+ Trademarks Successfully Registered
      </p>
    </>
  );
}
export default TrademarkRegistrationNavbar;
