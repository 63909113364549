import React, { useEffect, useState } from "react";
import './ContactUs.css'; // Ensure you have appropriate styles in this CSS file
import ScrollToTopButton from "./ScrollTop";

function ContactUS() {
    const [formData, setFormData] = useState({
        fullName: '',
        designation: '',
        email: '',
        mobile: '',
        companyName: '',
        industry: '',
        message: '',
        privacyPolicy: false
    });

    const [errors, setErrors] = useState({});
    const [responseMessage, setResponseMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        };
        scrollToTop();
    },[]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const validate = () => {
        let tempErrors = {};
        if (!formData.fullName) tempErrors.fullName = "Full Name is required";
        if (!formData.designation) tempErrors.designation = "Designation is required";
        if (!formData.email) {
            tempErrors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            tempErrors.email = "Email is not valid";
        }
        if (!formData.mobile) {
            tempErrors.mobile = "Mobile number is required";
        } else if (!/^[0-9]{10}$/.test(formData.mobile)) {
            tempErrors.mobile = "Mobile number should be 10 digits";
        }
        if (!formData.companyName) tempErrors.companyName = "Company Name is required";
        if (!formData.industry) tempErrors.industry = "Industry type is required";
        if (!formData.message) tempErrors.message = "Message is required";
        if (!formData.privacyPolicy) tempErrors.privacyPolicy = "You must agree to the Privacy Policy";

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!validate()) {
            return;
        }

        setIsSubmitting(true);
        setResponseMessage('');

        try {
            const response = await fetch('http://localhost:5000/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await response.json();
            if (response.ok) {
                setResponseMessage('Your request has been submitted successfully!');
                setFormData({
                    fullName: '',
                    designation: '',
                    email: '',
                    mobile: '',
                    companyName: '',
                    industry: '',
                    message: '',
                    privacyPolicy: false
                });
                setErrors({});
            } else {
                setResponseMessage(result.error || 'An error occurred.');
            }
        } catch (error) {
            setResponseMessage('Failed to send the request. Please try again later.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="ContactUs-container" id="contact-us">
            <div className="Firsthead">
                <h3>Contact & Support</h3>
                <p>Reach out to us and let us know how we can assist you!</p>
            </div>

            <div className="wholeC flex flex-row flex-wrap">
                <div className="w-[50%] ml-16">
                    <div className="mb-6">
                        <h4 className="text-xl font-semibold">Submit a Request</h4>
                        <p>Fill out the form below or give us a call, and one of our tax experts will get back to you shortly.</p>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Full Name</label>
                                <input
                                    type="text"
                                    name="fullName"
                                    className="mt-1 block w-full md:w-80 border border-gray-300 rounded-lg p-2"
                                    required
                                    value={formData.fullName}
                                    onChange={handleChange}
                                />
                                {errors.fullName && <p className="text-red-600 text-sm">{errors.fullName}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Designation</label>
                                <input
                                    type="text"
                                    name="designation"
                                    className="mt-1 block w-full md:w-80 border border-gray-300 rounded-lg p-2"
                                    required
                                    value={formData.designation}
                                    onChange={handleChange}
                                />
                                {errors.designation && <p className="text-red-600 text-sm">{errors.designation}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Email Address</label>
                                <input
                                    type="email"
                                    name="email"
                                    className="mt-1 block w-full md:w-80 border border-gray-300 rounded-lg p-2"
                                    required
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                {errors.email && <p className="text-red-600 text-sm">{errors.email}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Mobile No.</label>
                                <input
                                    type="text"
                                    name="mobile"
                                    className="mt-1 block w-full md:w-80 border border-gray-300 rounded-lg p-2"
                                    required
                                    value={formData.mobile}
                                    onChange={handleChange}
                                />
                                {errors.mobile && <p className="text-red-600 text-sm">{errors.mobile}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Company Name</label>
                                <input
                                    type="text"
                                    name="companyName"
                                    className="mt-1 block w-full md:w-80 border border-gray-300 rounded-lg p-2"
                                    required
                                    value={formData.companyName}
                                    onChange={handleChange}
                                />
                                {errors.companyName && <p className="text-red-600 text-sm">{errors.companyName}</p>}
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Industry Type</label>
                                <select
                                    name="industry"
                                    className="mt-1 block w-full md:w-80 border border-gray-300 rounded-lg p-2"
                                    required
                                    value={formData.industry}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Industry</option>
                                    <option value="tech">Technology</option>
                                    <option value="finance">Finance</option>
                                    <option value="health">Healthcare</option>
                                </select>
                                {errors.industry && <p className="text-red-600 text-sm">{errors.industry}</p>}
                            </div>
                        </div>

                        <div className="mb-4">
                            <label className="block text-sm font-medium text-gray-700">Message</label>
                            <textarea
                                name="message"
                                className="mt-1 block w-full border border-gray-300 rounded-lg p-2"
                                rows="4"
                                required
                                value={formData.message}
                                onChange={handleChange}
                            ></textarea>
                            {errors.message && <p className="text-red-600 text-sm">{errors.message}</p>}
                        </div>
                        <div className="flex items-center mb-4">
                            <input
                                type="checkbox"
                                name="privacyPolicy"
                                className="mr-2"
                                required
                                checked={formData.privacyPolicy}
                                onChange={handleChange}
                            />
                            <span className="text-sm">By submitting this form, you agree to the Privacy Policy.</span>
                        </div>
                        {errors.privacyPolicy && <p className="text-red-600 text-sm">{errors.privacyPolicy}</p>}

                        <button
                            type="submit"
                            className="bg-blue-600 text-white font-bold py-2 px-4 rounded"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? 'Submitting...' : 'Submit'}
                        </button>
                    </form>
                    {responseMessage && (
                        <p className="mt-4 text-center text-green-900">{responseMessage}</p>
                    )}
                </div>

                <div className="flex flex-col p-4 bg-white shadow-md rounded-lg max-w-md w-[50%] mt-6 ml-4">
                    <h2 className="text-xl font-bold text-black-600 mb-2">Talk To Expert</h2>
                    <p className="text-gray-700 mb-4">Speak to our friendly team via:</p>
                    <div className="mb-4">
                        <p className="text-2xl font-bold text-black-500">Call Us</p>
                        <p className="text-gray-600">Available from 9am to 9pm</p>
                        <p className="text-gray-600">9910960606</p>
                        <p className="text-gray-600">info@dialt2f.com</p>
                    </div>
                    <h3 className="text-xl font-bold text-black-600 mb-2">Visit Us</h3>
                    <p className="text-gray-600">Chat with us in person at our office HQ</p>
                    <p className="text-gray-600">
                        New Delhi
                    </p>
                </div>
            </div>
            <ScrollToTopButton/>
        </div>
    );
}

export default ContactUS;