import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const CompanySalePurchase = ({ height }) => {
  // Company Sale/Purchase grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Legal Documentation",
      description:
        "Ensure all legal documents are in place for a smooth transaction between the buyer and seller.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Asset Evaluation",
      description:
        "Conduct thorough evaluations of assets to determine the correct market value before finalizing the deal.",
    },
    {
      imagePath: "flexi.webp",
      title: "Tax Compliance",
      description:
        "Make sure both parties adhere to tax obligations and regulations related to the sale or purchase of a company.",
    },
    {
      imagePath: "limltd.webp",
      title: "Due Diligence",
      description:
        "Perform detailed due diligence to investigate the financial health and legal standing of the company.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Transaction Security",
      description:
        "Utilize secure transaction methods to protect both parties from potential fraud or legal issues.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Security -",
      description:"Ensures that all necessary legal documents are handled, reducing the risk of future disputes."
    },
    {
      imageright: "right.png",
      title:"Accurate Asset Valuation -",
      description:" A fair and thorough valuation helps both parties reach a fair agreement."
    },
    {
      imageright: "right.png",
      title:"Tax Benefits -",
      description:" Depending on the structure of the sale or purchase, there could be potential tax advantages for both parties."
    },
    {
      imageright: "right.png",
      title:"Streamlined Process -",
      description:" Engaging professional assistance can streamline the sale/purchase process, saving time and effort."
    },
    {
      imageright: "right.png",
      title:"Comprehensive Due Diligence -",
      description:" Helps avoid potential legal or financial pitfalls by thoroughly investigating the company’s records."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complex Documentation -",
      description:"The process requires a large amount of paperwork and legal documentation."
    },
    {
      imageright: "dangerous.webp",
      title:"Risk of Discrepancies -",
      description:" If due diligence is not properly conducted, there is a risk of financial or legal discrepancies."
    },
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming -",
      description:" The sale or purchase of a company can be a lengthy process, often requiring months to complete."
    },
    {
      imageright: "dangerous.webp",
      title:"Costs -",
      description:" The sale or purchase of a company can be a lengthy process, often requiring months to complete."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential for Hidden Liabilities -",
      description:" Despite thorough investigations, there could still be undiscovered liabilities."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Company Financial Statements",
      description:
        "Provide up-to-date financial records including balance sheets, income statements, and cash flow reports.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Legal Documents",
      description:
        "Ensure the availability of legal documents such as contracts, leases, and intellectual property agreements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Records",
      description:
        "Make sure tax returns and related records are accurate and available for review.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Asset Evaluation Reports",
      description:
        "Provide asset valuation reports to determine the value of tangible and intangible assets.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Ownership Documents",
      description:
        "Submit documents verifying the ownership structure of the company being sold or purchased.",
    },
  ];

  const steps = [
    {
      title: "Prepare Documentation",
      description:
        "Gather all necessary legal, financial, and tax documents related to the company.",
    },
    {
      title: "Asset Valuation",
      description:
        "Conduct an accurate asset evaluation to determine the value of the company’s assets.",
    },
    {
      title: "Due Diligence",
      description:
        "Perform due diligence to investigate the company’s financial health, liabilities, and legal standing.",
    },
    {
      title: "Negotiate Terms",
      description:
        "Negotiate the terms of the sale or purchase, ensuring both parties agree on key conditions.",
    },
    {
      title: "Finalize the Transaction",
      description:
        "Complete the transaction by signing legal documents and transferring ownership.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Company Sale/Purchase"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<SaleTo  />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Smooth Company Sale/Purchase Process
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our services are designed to simplify the sale or purchase of a
            company, ensuring a streamlined and compliant process. From legal
            documentation to due diligence, we provide comprehensive support at
            every step.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Company Sale/Purchase?
            </h6>

            <p className="mb-4">
              Company sale or purchase involves the legal transfer of ownership
              between parties. The process requires meticulous attention to
              legal, financial, and regulatory details to ensure a smooth and
              successful transaction.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Company Sale/Purchase
            </h6>
            <p className="mb-4">
              To sell or purchase a company, ensure that:
              <ul>
                <li>
                  <strong>Legal Documents -</strong> Ensure the availability of
                  all legal contracts and agreements for a clear transaction.
                </li>

                <li>
                  <strong>Financial Records -</strong> Have accurate and
                  up-to-date financial statements for evaluation.
                </li>

                <li>
                  <strong>Tax Compliance -</strong> Ensure that all tax returns
                  and liabilities are in order.
                </li>

                <li>
                  <strong>Ownership Structure -</strong> Provide clear
                  documentation of the ownership structure for clarity in the
                  transaction.
                </li>

                <li>
                  <strong>Asset Evaluation -</strong> Complete an accurate
                  evaluation of assets for fair pricing.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Company Sale/Purchase
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-5">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="documents" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Company Sale/Purchase</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps for Company Sale/Purchase</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose my-8">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for Company Sale/Purchase?
            </h6>
            <p>
              We ensure that your company sale or purchase is handled with
              professionalism, transparency, and efficiency. With our legal,
              financial, and tax expertise, we make sure the process is smooth
              and secure for both parties involved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function SaleTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Whether you are buying or selling a company, it is crucial to ensure all legal, financial, and tax aspects are handled with care. Our services streamline the process, ensuring a secure and compliant transaction.
      </p>
      <p>
      We assist in navigating the complexities of company sales and purchases, from documentation to final transaction completion.
      </p>
    </>
  );
}

export default CompanySalePurchase;
