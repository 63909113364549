import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import FeatureCard from './FeatureCard';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const ProducerCompany = ({ height }) => {

  // Producer Company grid items
  const items = [
    { 
      imagePath: "Grp.webp",
      title: 'What is a Producer Company?', 
      description: 'A Producer Company is a type of cooperative society formed to promote and organize production activities. It helps farmers and producers to work together, share resources, and market their products efficiently.' 
    },
    { 
      imagePath: "Costeff.webp",
      title: 'Benefits of Producer Companies', 
      description: 'Producer Companies offer benefits like improved bargaining power, better access to markets, and enhanced resource sharing. They also help in pooling resources for common goals and reducing individual risks.' 
    },
    { 
      imagePath: "flexi.webp",
      title: 'Registration Process',
      description: 'The process includes drafting the company’s charter, registering with the Registrar of Companies, and obtaining necessary licenses. Legal compliance and proper documentation are crucial for a successful registration.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'Governance Structure',
      description: 'Producer Companies are governed by a board of directors elected by members. They adhere to democratic principles and ensure transparency in operations and decision-making.' 
      },
    { 
      imagePath: "lessreg.webp",
      title: 'Operational Guidelines',
      description: 'Operational guidelines include adherence to cooperative principles, maintaining financial records, and conducting regular meetings. Producer Companies must comply with statutory regulations and maintain member engagement.' 
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Enhanced Bargaining Power -",
      description:"Collective effort improves negotiation capabilities with suppliers and buyers."
    },
    {
      imageright: "right.png",
      title:"Resource Sharing -",
      description:"Members can share resources like machinery, storage, and technology, reducing individual costs."
    },
    {
      imageright: "right.png",
      title:"Market Access -",
      description:"Facilitates better market access and marketing opportunities for members' products."
    },
    {
      imageright: "right.png",
      title:"Risk Reduction -",
      description:"Sharing risks among members lowers individual financial exposure."
    },
    {
      imageright: "right.png",
      title:"Support for Small Producers -",
      description:"Helps small producers gain access to larger markets and financial resources."
    },

  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complex Setup -",
      description:"The registration and setup process can be complex and require significant documentation."
    },
    {
      imageright: "dangerous.webp",
      title:"Management Challenges -",
      description:"Effective management requires careful planning and coordination among members."
    },
    {
      imageright: "dangerous.webp",
      title:"Regulatory Compliance -",
      description:"Must adhere to various regulatory requirements and maintain compliance."
    },
    {
      imageright: "dangerous.webp",
      title:"Limited Scope -",
      description:"May have limitations in terms of the scale of operations and product diversification."
    },
    {
      imageright: "dangerous.webp",
      title:"Conflict Resolution -",
      description:"Differences among members can lead to conflicts that need effective resolution mechanisms."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Charter Document",
      description: "A document outlining the objectives, structure, and rules of the Producer Company, essential for registration."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Member Agreements",
      description: "Agreements between members detailing their roles, contributions, and responsibilities within the company."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Registration Forms",
      description: "Forms required for registering the Producer Company with the Registrar of Companies."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Identity",
      description: "Identity proof of all members and directors for verification during registration."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Statements",
      description: "Initial financial statements and projections for the company's operations and growth."
    }
  ];

  const steps = [
    {
      title: "Draft the Charter",
      description: "Prepare the charter document outlining the objectives, structure, and operational guidelines of the Producer Company."
    },
    {
      title: "Register with Authorities",
      description: "Submit the registration forms and required documents to the Registrar of Companies for official recognition."
    },
    {
      title: "Obtain Licenses",
      description: "Apply for and obtain necessary licenses and approvals for operating as a Producer Company."
    },
    {
      title: "Establish Governance",
      description: "Form a board of directors and establish governance practices according to cooperative principles."
    },
    {
      title: "Commence Operations",
      description: "Start the operational activities of the Producer Company, adhering to guidelines and maintaining member engagement."
    }
  ];
  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whychoose = [
    {
      title: 'Access to Credit Facilities',
      description: 'Producer companies can access various credit facilities and government schemes aimed at supporting agricultural and rural businesses, enhancing growth opportunities.',
    },
    {
    
      title: 'Collective Power and Collaboration',
      description: 'By working as a collective, producer companies benefit from economies of scale, improved bargaining power, and better market opportunities for their products.',
    },
    {
      
      title: 'Limited Liability Protection',
      description: 'Members of a producer company enjoy limited liability, ensuring that personal assets remain protected while participating in the company’s ventures.',
    },
    {
      
      title: 'Government Support and Tax Benefits',
      description: 'Producer companies receive various government incentives, tax benefits, and subsidies, making it easier to operate and grow sustainably in rural markets.',
    },
    {
      title: 'Improved Market Access',
      description: 'With collective marketing and distribution channels, producer companies can access wider markets, ensuring better prices and stable demand for their products.',
    },
    {
      title: 'Democratic Management Structure',
      description: 'Producer companies are managed democratically, ensuring that each member has a voice in decision-making, leading to better management and member satisfaction.',
    }
  ];
  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Producer Company"
        ctaText="Get in touch"
        imagePath="./ProducerCom.webp"
        children={<ProduceTo />}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8'>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>Producer Company</h6>

          <p className='max-w-[1086px] text-center mt-4'>A Producer Company enables producers to collaborate and leverage collective strength for better production and market opportunities. It is an ideal structure for those involved in agricultural and production activities.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16'>

          <div className=' pr-2 pt-5 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is a Producer Company?</h6>

            <p className='mb-4'>A Producer Company is a type of cooperative designed to bring together producers to improve their production processes, share resources, and market their products effectively. It operates under cooperative principles and aims to benefit all its members.</p>
          </div>

          <div id="el" className='pr-2 mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Forming a Producer Company</h6>
            <p className='mb-4'>Any group of producers involved in agricultural, dairy, fishery, or other primary production activities can form a Producer Company. It requires a minimum of 10 producers or two or more producer institutions to initiate the formation.</p>
          </div>

          <div id="features" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of a Producer Company</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className="text-lg font-semibold text-blue-500">Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-lg font-semibold text-blue-500">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Producer Company Formation</h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Process for Forming a Producer Company</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
          </div>
        
        <div id="choose" className="Why mt-12">
              <h6 className="text-blue-500 text-lg font-semibold">
                Why Choose Producer Company
              </h6>
              <ul>
              {whychoose.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
              </ul>
            </div>

              </div>
      </div>
    </>
  );
};

function ProduceTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        A Producer Company is designed to help producers collaborate, share resources, and market their products effectively. It is a cooperative structure that supports collective production and business activities.
      </p>

      <p>Establish a Producer Company to enhance your operational efficiency and market access.</p>
    </>
  );
};

export default ProducerCompany;