import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import ScrollToTopButton from './ScrollTop';
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const NitiAayogRegistration = ({ height }) => {

  // Niti Aayog Registration grid items
  const items = [
    {imagePath: "Grp.webp", title: 'What is Niti Aayog Registration?', description: 'Niti Aayog is a government think tank that provides NGOs and other organizations a platform for transparency and governance.' },
    {imagePath: "Costeff.webp", title: 'Who Needs Niti Aayog?', description: 'Non-profit organizations, NGOs, and social welfare entities looking for government collaborations and funding must register with Niti Aayog.' },
    { imagePath: "flexi.webp", title: 'Key Features of Niti Aayog', description: 'The registration helps organizations become more transparent, secure government recognition, and apply for various government schemes.' },
    {imagePath: "limltd.webp", title: 'Eligibility for Niti Aayog', description: 'Any non-profit, NGO, or social welfare organization that operates for the benefit of society is eligible for Niti Aayog registration.' },
    { imagePath: "lessreg.webp", title: 'Legal Governance', description: 'Niti Aayog ensures that NGOs and non-profits are compliant with government regulations, ensuring ethical and legal conduct.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Access to Government Schemes -",
      description:"Registered NGOs can apply for various government welfare schemes."
    },
    {
      imageright: "right.png",
      title:"Increased Credibility -",
      description:"Being listed with Niti Aayog improves the credibility of an organization."
    },
    {
      imageright: "right.png",
      title:"Transparency -",
      description:"It ensures that registered NGOs operate transparently and ethically.."
    },
    {
      imageright: "right.png",
      title:"Recognition -",
      description:"Niti Aayog registration provides official recognition and support from the government."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Strict Regulations -",
      description:"Organizations must adhere to strict reporting and auditing requirements."
    },
    {
      imageright: "dangerous.webp",
      title:"Limited to Non-profits -",
      description:"Only non-profit organizations can register with Niti Aayog."
    },
    {
      imageright: "dangerous.webp",
      title:"Long Process -",
      description:"The registration and compliance process may take time and requires thorough documentation."
    },
    {
      imageright: "dangerous.webp",
      title:"Non-compliance Penalties -",
      description:"TFailure to comply with rules may result in removal from the Niti Aayog list."
    }, 
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Organization Registration Certificate",
      description: "NGOs must submit their registration certificate or trust deed during the application process."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Statements",
      description: "Past financial records demonstrating transparency and ethical use of funds must be submitted."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "List of Board Members",
      description: "A list of the organization's board members or trustees needs to be provided."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Work Report",
      description: "Organizations must submit a detailed report of their past activities and ongoing projects."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN and Aadhar Details",
      description: "The PAN and Aadhar details of the key members must be shared during the registration process."
    }
  ];

  const steps = [
    {
      title: "Online Application",
      description: "NGOs must apply online through the Niti Aayog Darpan portal and fill in the required details."
    },
    {
      title: "Document Submission",
      description: "Submit the required documents, including registration certificates, financial reports, and work reports."
    },
    {
      title: "Verification Process",
      description: "The submitted documents are verified by Niti Aayog to ensure authenticity and compliance."
    },
    {
      title: "Approval and Issue of Registration",
      description: "Once verified, Niti Aayog provides approval and issues a registration number for the organization."
    },
    {
      title: "Maintain Compliance",
      description: "NGOs must file regular reports and maintain compliance with Niti Aayog's regulations."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const AayogBenefits = [
    {
      title: "Eligibility for Government Grants",
      description: "Niti Aayog registration allows NGOs and non-profits to apply for various government grants and funding opportunities, which are only available to registered entities.",
    },
    {
      title: "Recognition and Credibility",
      description: "Organizations registered with Niti Aayog are recognized as legitimate by government bodies, increasing their credibility and trustworthiness among donors and stakeholders.",
    },
    {
      title: "Access to CSR Funding",
      description: "Registered entities are eligible to receive Corporate Social Responsibility (CSR) funds from corporations, allowing them to finance and expand their projects.",
    },
    {
      title: "Ease of Collaboration",
      description: "With Niti Aayog registration, organizations can collaborate more easily with other NGOs, international agencies, and government departments for joint projects.",
    },
    {
      title: "Centralized Data Management",
      description: "Niti Aayog provides a centralized platform for managing the data of NGOs, which helps streamline the process of applying for various schemes and programs.",
    },
  ];

  return (
    <>
      <div className='sticky z-20' style={{ 'top': '${height}px' }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Niti Aayog Registration"
        ctaText="Get in touch"
        imagePath="./MSME.webp"
        children={<NitiTo/>}
      />

      <div className='w-full flex flex-col px-3 md:px-8'>
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className='text-2xl text-center font-semibold'>Niti Aayog Registration</h6>
          <p className='max-w-[1086px] text-center mt-4'>Get recognized by the Government of India for your social work and enhance your organization's credibility.</p>
        </div>

            <div className='mt-16 max-w-[972px] md:px-16 '>

          <div className='my-4 '>
            <h6 className='text-blue-500 text-lg font-semibold'>What is Niti Aayog Registration?</h6>
            <p className='mb-4'>Niti Aayog is a central government initiative to support and provide a transparent platform for NGOs and other social welfare organizations.</p>
          </div>

          <div id="el" className='mt-16'>
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility for Niti Aayog Registration</h6>
            <p className='mb-4'>Non-profit organizations, NGOs, and entities engaged in social welfare activities are eligible for Niti Aayog registration.</p>
          </div>

          <div id="features" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Features of Niti Aayog Registration</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='w-full  mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>Documents Required for Niti Aayog Registration</h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='w-full mt-10 pt-2'>
            <h6 className='text-lg font-semibold text-blue-500'>How to Register for Niti Aayog</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
          </div>

          <div id='choose' className='my-5'>
          <h6 className="text-blue-500 text-lg font-semibold"> Why Choose </h6>
          <ul className="list-disc">
              {AayogBenefits.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
            </div>
          </div>
      </div>

      <ScrollToTopButton />
    </>
  );
};

function NitiTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Secure recognition and support from the Government of India by registering with Niti Aayog.
      </p>

      <p>Ensure your NGO or social organization is recognized by the government for transparency and better funding opportunities.</p>
    </>
  );
};


export default NitiAayogRegistration;