import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const PatentNavbar = ({ height }) => {
  // Patent Registration service grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Exclusive Rights",
      description:
        "Grants exclusive rights to the inventor to prevent others from making, using, or selling the invention.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Legal Protection",
      description:
        "Provides legal protection for your invention against infringement.",
    },
    {
      imagePath: "flexi.webp",
      title: "Market Advantage",
      description:
        "Offers a competitive edge in the market by protecting your innovative ideas.",
    },
    {
      imagePath: "limltd.webp",
      title: "Licensing Opportunities",
      description:
        "Allows licensing of the patent to others, creating potential revenue streams.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Encourages Innovation",
      description:
        "Encourages further research and development by securing intellectual property.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Exclusive Rights -",
      description:"Patent registration provides exclusive rights to the inventor, preventing others from using or profiting from the invention without permission."
    },
    {
      imageright: "right.png",
      title:"Legal Protection -",
      description:"Offers legal protection and a mechanism to enforce your rights if someone infringes on your patent."
    },
    {
      imageright: "right.png",
      title:"Market Advantage -",
      description:"Provides a significant competitive edge by protecting your innovative ideas and preventing market competition."
    },
    {
      imageright: "right.png",
      title:"Licensing Opportunities -",
      description:"Enables you to license your patent to other companies or individuals, potentially generating additional income."
    },
    {
      imageright: "right.png",
      title:"Encourages Innovation -",
      description:"Secures intellectual property rights, promoting further innovation and development in the field."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Cost -",
      description:"The process of obtaining a patent can be expensive, including application fees, legal costs, and maintenance fees."
    },
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming -",
      description:"Patent registration can be a lengthy process, often taking several months to years to complete."
    },
    {
      imageright: "dangerous.webp",
      title:"Disclosure of Information -",
      description:"Requires public disclosure of the invention, which may expose details to competitors."
    },
    {
      imageright: "dangerous.webp",
      title:"Geographical Limitations -",
      description:"Patents are generally limited to specific countries or regions, requiring separate applications for international protection."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Patent Application Form",
      description:
        "Complete the official patent application form provided by the patent office.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Detailed Description of the Invention",
      description:
        "Provide a comprehensive description of the invention, including its functionality and how it works.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Claims of the Invention",
      description:
        "Draft and include claims that define the scope of the invention and its unique features.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Drawings/Diagrams",
      description:
        "Submit technical drawings or diagrams that illustrate the invention, if applicable.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Inventorship",
      description:
        "Provide evidence of the inventors and their contributions to the invention.",
    },
  ];

  const steps = [
    {
      title: "Prepare Documentation",
      description:
        "Gather and prepare all necessary documents and information required for the patent application.",
    },
    {
      title: "File Patent Application",
      description:
        "Submit the patent application along with required documents to the patent office.",
    },
    {
      title: "Examination Process",
      description:
        "Undergo the examination process where the patent office reviews the application for patentability.",
    },
    {
      title: "Respond to Objections",
      description:
        "Address any objections or requests for additional information raised by the patent examiner.",
    },
    {
      title: "Grant of Patent",
      description:
        "Upon successful review, receive the patent grant and enjoy exclusive rights to your invention.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const reasons = [
    {
      title: 'Legal Protection',
      description: 'Gain exclusive rights to your invention, preventing others from making, using, or selling it without permission.'
    },
    {
      title: 'Monetary Benefits',
      description: 'Patents can be licensed or sold, providing a valuable source of income and business opportunities.'
    },
    {
      title: 'Increased Market Value',
      description: 'Having a registered patent boosts the credibility and market value of your company or product.'
    },
    {
      title: 'Competitive Advantage',
      description: 'Protects your innovation, giving you a competitive edge by preventing imitation and copying.'
    },
    {
      title: 'Global Recognition',
      description: 'Patent registration offers recognition and protection not only domestically but also internationally.'
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Patent Registration"
        ctaText="Get in touch"
        imagePath="./Patentregis.webp"
        children={<PatentRegis />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Patent Registration Simplified
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Patent registration is a vital step for protecting your innovations
            and gaining exclusive rights to your inventions. We make the process
            straightforward and efficient, guiding you through each stage of the
            application.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Patent Registration?
            </h6>

            <p className="mb-4">
              Patent registration is the process of securing legal rights to an
              invention, granting the inventor exclusive rights to make, use,
              and sell the invention for a specific period. This process helps
              protect intellectual property and provides a competitive advantage
              in the market.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Patent Registration
            </h6>
            <p className="mb-4">
              To apply for a patent, you must meet the following requirements:
              <ul>
                <li>
                  <strong>Patent Application Form -</strong> Complete the
                  official patent application form provided by the patent
                  office.
                </li>

                <li>
                  <strong>Detailed Description of the Invention -</strong>{" "}
                  Provide a comprehensive description of the invention,
                  including its functionality and how it works.
                </li>

                <li>
                  <strong>Claims of the Invention -</strong> Draft and include
                  claims that define the scope of the invention and its unique
                  features.
                </li>

                <li>
                  <strong>Drawings/Diagrams -</strong> Submit technical drawings
                  or diagrams that illustrate the invention, if applicable.
                </li>

                <li>
                  <strong>Proof of Inventorship -</strong> Provide evidence of
                  the inventors and their contributions to the invention.
                </li>
              </ul>
            </p>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of Patent Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-4">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Patent Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Apply for Patent Registration</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {reasons.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function PatentRegis() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Patent registration is a legal process that grants exclusive rights to
        inventors for their inventions, protecting their ideas from unauthorized
        use. It ensures that the inventor can benefit from their innovation and
        prevents others from exploiting the invention without permission.
      </p>
      <p>
      Thousands of inventors and companies have secured their patents
      with our help.
      </p>
    </>
  );
}

export default PatentNavbar;
