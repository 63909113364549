import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "./LLPcard";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const SEBIRegistration = ({ height }) => {
  // SEBI Registration grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Regulatory Compliance",
      description: "Ensure compliance with SEBI regulations and guidelines.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Investor Protection",
      description: "Enhance investor confidence and safeguard their interests.",
    },
    {
      imagePath: "flexi.webp",
      title: "Market Integrity",
      description:
        "Promote fairness and transparency in the financial markets.",
    },
    {
      imagePath: "limltd.webp",
      title: "Corporate Governance",
      description:
        "Adopt best practices in corporate governance as mandated by SEBI.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Enhanced Credibility",
      description:
        "Boost your company’s credibility and trustworthiness in the market.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Regulatory Compliance -",
      description:"Adherence to SEBI regulations ensures legal operation and prevents penalties."
    },
    {
      imageright: "right.png",
      title:"Investor Confidence - ",
      description:"Compliance with SEBI guidelines enhances trust and attracts more investors."
    },
    {
      imageright: "right.png",
      title:"Market Integrity -",
      description:"Promotes transparency and fairness in financial transactions."
    },
    {
      imageright: "right.png",
      title:"Corporate Governance -",
      description:"Implements best practices in governance to maintain high standards."
    },
    {
      imageright: "right.png",
      title:"Enhanced Credibility -",
      description:"Strengthens your company’s reputation in the financial sector."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Regulatory Complexity -",
      description:"Navigating SEBI regulations can be complex and time-consuming."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost of Compliance -",
      description:"Ensuring compliance may involve additional costs and resources."
    },
    {
      imageright: "dangerous.webp",
      title:"Frequent Updates -",
      description:"SEBI guidelines may change, requiring continuous updates and adjustments."
    },
    {
      imageright: "dangerous.webp",
      title:"Documentation Requirements -",
      description:"Extensive documentation may be required for registration and compliance."
    },
    {
      imageright: "dangerous.webp",
      title:"Monitoring and Reporting -",
      description:" Ongoing monitoring and reporting obligations can be demanding."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Application Form",
      description: "Complete application form for SEBI registration.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Company Documents",
      description:
        "Incorporation certificate, MOA, AOA, and other relevant company documents.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Compliance Certificate",
      description: "Certificate of compliance with SEBI regulations.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Statements",
      description: "Audited financial statements of the company.",
    },
  ];

  const steps = [
    {
      title: "Prepare Documents",
      description:
        "Gather and prepare all necessary documents for SEBI registration.",
    },
    {
      title: "Complete Application",
      description:
        "Fill out the SEBI registration application form with accurate details.",
    },
    {
      title: "Submit to SEBI",
      description:
        "Submit the completed application form and documents to SEBI.",
    },
    {
      title: "Review and Approval",
      description:
        "SEBI reviews the application and documents before granting registration.",
    },
    {
      title: "Compliance Adherence",
      description:
        "Ensure ongoing compliance with SEBI regulations and guidelines post-registration.",
    },
  ];
  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="SEBI Registration"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<SebiTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            SEBI Registration Made Simple
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Ensure your company meets all SEBI regulatory requirements with our
            streamlined registration process. Gain investor confidence and
            maintain market integrity with our expert support.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is SEBI Registration?
            </h6>

            <p className="mb-4">
              SEBI registration is a mandatory process for companies involved in
              securities and financial markets. It ensures adherence to
              regulations set by the Securities and Exchange Board of India,
              promoting transparency and investor protection.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements
            </h6>
            <p className="mb-4">
              To obtain SEBI registration, your company must:
              <ul>
                <li>
                  <strong>Complete Application Form -</strong> Fill out the SEBI
                  registration application accurately.
                </li>

                <li>
                  <strong>Company Documents -</strong> Provide incorporation
                  certificate, MOA, AOA, and other relevant documents.
                </li>

                <li>
                  <strong>Compliance Certificate -</strong> Submit a certificate
                  of compliance with SEBI regulations.
                </li>

                <li>
                  <strong>Financial Statements -</strong> Include audited
                  financial statements for the company.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of SEBI Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for SEBI Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps for SEBI Registration</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-4">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for SEBI Registration?
            </h6>
            <p>
              We offer expert assistance in navigating the SEBI registration
              process, ensuring compliance with all regulatory requirements. Our
              efficient service minimizes disruptions to your business while
              enhancing your credibility in the financial market.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function SebiTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Obtaining SEBI registration is crucial for ensuring compliance with regulatory standards and fostering investor trust. Our team provides expert assistance throughout the registration process, making it smooth and efficient.
      </p>
      <p>
      Ensure your company's compliance and enhance its market credibility with our SEBI registration services.
      </p>
    </>
  );
}


export default SEBIRegistration;
