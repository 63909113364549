import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import ScrollToTopButton from "./ScrollTop";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const ISOCertificationNavbar = ({ height }) => {
  // ISO Certification grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Global Recognition",
      description:
        "ISO certification is recognized worldwide, enhancing your business’s credibility.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Quality Assurance",
      description:
        "Ensures that your processes meet international quality standards.",
    },
    {
      imagePath: "flexi.webp",
      title: "Customer Satisfaction",
      description: "Improves customer satisfaction through consistent quality.",
    },
    {
      imagePath: "limltd.webp",
      title: "Operational Efficiency",
      description:
        "Optimizes your processes, leading to increased efficiency and reduced waste.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Market Advantage",
      description:
        "Provides a competitive edge by demonstrating commitment to quality.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Global Recognition -",
      description:
        "ISO certification is recognized internationally, boosting your credibility and trust in the global market.",
    },
    {
      imageright: "right.png",
      title: "Quality Assurance -",
      description:
        "Ensures your processes and products meet internationally accepted standards.",
    },
    {
      imageright: "right.png",
      title: "Customer Satisfaction -",
      description:
        "Enhances customer satisfaction through consistent quality and improved service.",
    },
    {
      imageright: "right.png",
      title: "Operational Efficiency -",
      description:
        " Helps in streamlining processes, reducing waste, and improving overall efficiency.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Cost -",
      description:
        "Involves certification fees and potential costs for implementing required changes.",
    },
    {
      imageright: "dangerous.webp",
      title: "Time-Consuming -",
      description:
        "The certification process can be lengthy and require significant time and effort.",
    },
    {
      imageright: "dangerous.webp",
      title: "Maintenance -",
      description:
        "Requires ongoing compliance and periodic audits to maintain certification.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Quality Management Manual",
      description:
        "Document detailing your quality management system and procedures.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Procedures and Policies",
      description: "Written procedures and policies relevant to ISO standards.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Training Records",
      description:
        "Proof of training provided to employees on quality management systems.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Internal Audit Reports",
      description:
        "Records of internal audits conducted to assess compliance with ISO standards.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Management Review Records",
      description:
        "Records of management reviews and actions taken to address issues.",
    },
  ];

  const steps = [
    {
      title: "Understand ISO Standards",
      description:
        "Familiarize yourself with the ISO standards relevant to your business.",
    },
    {
      title: "Gap Analysis",
      description:
        "Conduct a gap analysis to identify areas needing improvement to meet ISO standards.",
    },
    {
      title: "Implement Changes",
      description:
        "Make necessary changes to your processes and documentation to comply with ISO standards.",
    },
    {
      title: "Internal Audits",
      description:
        "Conduct internal audits to ensure compliance and readiness for certification.",
    },
    {
      title: "Select Certification Body",
      description:
        "Choose an accredited certification body to perform the certification audit.",
    },
    {
      title: "Certification Audit",
      description:
        "Undergo the certification audit conducted by the chosen certification body.",
    },
    {
      title: "Receive Certificate",
      description: "Upon successful audit, receive the ISO certification.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="ISO Certification"
        ctaText="Get in touch"
        imagePath="./ISO.webp"
        children={<ISOTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            ISO Certification Made Simple with T2F
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Ready to enhance your business's credibility and operational
            efficiency? T2F simplifies the ISO certification process, guiding
            you through every step to ensure compliance with international
            standards. Contact us today to start your ISO certification journey.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16 ">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is ISO Certification?
            </h6>

            <p className="mb-4">
              ISO certification demonstrates that your business complies with
              international standards for quality, safety, and efficiency. It
              provides a structured approach to quality management, improving
              your processes and boosting your reputation in the global market.
            </p>
            <div>
              <ul>
                <strong>Global Recognition:</strong> ISO certification is
                internationally recognized and enhances your global credibility.
              </ul>

              <ul>
                <strong>Quality Assurance:</strong> Ensures your processes meet
                rigorous international standards.
              </ul>

              <ul>
                <strong>Customer Satisfaction:</strong> Improves customer
                satisfaction through consistent quality and service.
              </ul>
            </div>

            <p>
              ISO certification is crucial for businesses aiming to establish
              trust and demonstrate commitment to quality and efficiency.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for ISO Certification
            </h6>
            <p className="mb-4">
              To obtain ISO certification, you need to meet the following
              criteria:
              <ul>
                <li>
                  <strong>Established Processes -</strong> Your business must
                  have established and documented processes.
                </li>

                <li>
                  <strong>Compliance with Standards -</strong> Processes should
                  comply with the specific ISO standard you are applying for.
                </li>

                <li>
                  <strong>Management Commitment -</strong> Demonstrate
                  commitment from top management to uphold ISO standards.
                </li>
              </ul>
            </p>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of ISO Certification
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

            <div className="Documents my-5">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for ISO Certification</h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

            <div id="how" className="Howto mt-16">
              <div className="registration-steps">
                <h2 className="text-blue-500 text-lg font-semibold">How to Obtain ISO Certification</h2>
                <p>
                  Follow these steps for a successful ISO certification process:
                </p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
            </div>

            <div id="choose" className="Why my-12">
              <h6 className="text-blue-500 text-lg font-semibold">
                Why Choose T2F for Your ISO Certification
              </h6>
              <p>T2F is your trusted partner for ISO certification:</p>
              <ul >
                <li>
                  <strong>Expert Guidance - </strong> Professional support
                  throughout the ISO certification process.
                </li>

                <li>
                  <strong>Documentation Assistance - </strong> Help with
                  preparing and organizing the necessary documents.
                </li>

                <li>
                  <strong>Efficient Processing -</strong> Streamlined handling
                  of your ISO certification application.
                </li>

                <li>
                  <strong>Ongoing Support -</strong> Continuous support and
                  advice throughout the certification journey.
                </li>

                <li>
                  <strong>Compliance Monitoring -</strong> Assistance with
                  maintaining compliance and preparing for audits.
                </li>
              </ul>
            </div>
          </div>
        </div>
      <ScrollToTopButton />
    </>
  );
};

function ISOTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        ISO certification demonstrates your commitment to quality and
        international standards, enhancing your business’s credibility and
        operational efficiency.
      </p>

      <p>10K+ Businesses Successfully ISO Certified.</p>
    </>
  );
}

export default ISOCertificationNavbar;
