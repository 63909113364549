import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const PANTANNavbar = ({ height }) => {
  // PAN & TAN Registration service grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Essential for Compliance",
      description:
        "PAN and TAN are essential for compliance with tax regulations and conducting business operations smoothly.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Streamlined Process",
      description:
        "Simplifies the process of obtaining PAN and TAN for exporters, reducing paperwork and processing time.",
    },
    {
      imagePath: "flexi.webp",
      title: "Expert Guidance",
      description:
        "Provides expert assistance throughout the registration process to ensure accuracy and efficiency.",
    },
    {
      imagePath: "limltd.webp",
      title: "Legal Requirement",
      description:
        "Mandatory for financial transactions, tax payments, and business operations.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Efficient Handling",
      description:
        "Efficient handling of PAN and TAN applications to avoid delays and complications.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Essential for Compliance -",
      description:"PAN and TAN are crucial for compliance with tax regulations and smooth business operations."
    },
    {
      imageright: "right.png",
      title:"Streamlined Process -",
      description:"Our process simplifies the registration of PAN and TAN, making it quicker and less cumbersome."
    },
    {
      imageright: "right.png",
      title:"Expert Guidance - ",
      description:"Receive professional assistance to ensure accurate and efficient registration of PAN and TAN."
    },
    {
      imageright: "right.png",
      title:"Legal Requirement - ",
      description:"PAN and TAN are legally required for various financial transactions and tax-related activities."
    },
    {
      imageright: "right.png",
      title:"Efficient Handling - ",
      description:"We handle the registration process efficiently to avoid any delays or complications."
    },

  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Documentation Requirements -",
      description:"Requires specific documents which might be a hassle to gather for some applicants."
    },
    {
      imageright: "dangerous.webp",
      title:"Processing Time -",
      description:"The registration process might take time depending on the authorities' workload."
    },
    {
      imageright: "dangerous.webp",
      title:"Complexity for Some -",
      description:"Understanding the requirements and process can be complex for individuals unfamiliar with PAN and TAN registration."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Identity",
      description:
        "A valid government-issued identity proof such as Aadhar card, passport, or driving license.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Address",
      description:
        "Proof of residence, such as a utility bill or bank statement, needs to be provided.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Certificate",
      description:
        "For TAN, a certificate of business registration or incorporation is required.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card of Authorized Person",
      description:
        "PAN card of the authorized signatory of the business or individual applying for TAN.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Photographs",
      description:
        "Recent passport-sized photographs may be required for the application process.",
    },
  ];

  const steps = [
    {
      title: "Gather Documents",
      description:
        "Collect all necessary documents including proof of identity, proof of address, and business registration certificate.",
    },
    {
      title: "Prepare Application",
      description:
        "Fill out the PAN and TAN application forms with accurate information.",
    },
    {
      title: "Submit Application",
      description:
        "Submit the completed application forms along with the required documents to the relevant authorities.",
    },
    {
      title: "Verification Process",
      description:
        "Complete the verification process as required by the authorities for PAN and TAN registration.",
    },
    {
      title: "Receive PAN & TAN",
      description:
        "After successful verification, receive your PAN and TAN certificates for business use.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyData = [
    {
      id: 1,
      title: "Easy Application Process",
      description: "Simple and straightforward application process for PAN & TAN registration.",
    },
    {
      id: 2,
      title: "Quick Processing",
      description: "Fast processing ensures you receive your PAN & TAN numbers promptly.",
    },
    {
      id: 3,
      title: "Government Compliance",
      description: "Fully compliant with government rules and regulations.",
    },
    {
      id: 4,
      title: "Secure Documentation",
      description: "Safe and secure handling of all your personal and business information.",
    },
    {
      id: 5,
      title: "Expert Assistance",
      description: "Guidance from experts to help you through each step of the registration process.",
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="PAN & TAN Registration"
        ctaText="Get in touch"
        imagePath="./ESI.webp"
        children={<TANTo/>}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Simplified PAN & TAN Registration
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Obtaining PAN and TAN is crucial for exporters to comply with tax
            regulations and carry out business operations efficiently. We make
            the registration process seamless and straightforward, ensuring you
            get both numbers without delays.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is PAN & TAN Registration?
            </h6>

            <p className="mb-4">
              PAN (Permanent Account Number) is a unique identifier for
              taxpayers, while TAN (Tax Deduction and Collection Account Number)
              is used for deducting or collecting tax at source. Both are
              essential for managing tax obligations and ensuring compliance
              with regulations.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for PAN & TAN Registration
            </h6>
            <p className="mb-4">
              To register for PAN and TAN, you need to:
              <ul>
                <li>
                  <strong>Proof of Identity -</strong> Provide a valid
                  government-issued identity proof such as an Aadhar card,
                  passport, or driving license.
                </li>

                <li>
                  <strong>Proof of Address -</strong> Submit proof of residence,
                  such as a utility bill or bank statement.
                </li>

                <li>
                  <strong>Business Registration Certificate -</strong> For TAN,
                  provide a certificate of business registration or
                  incorporation.
                </li>

                <li>
                  <strong>PAN Card of Authorized Person -</strong> PAN card of
                  the authorized signatory of the business or individual
                  applying for TAN.
                </li>

                <li>
                  <strong>Photographs -</strong> Recent passport-sized
                  photographs may be required for the application process.
                </li>
              </ul>
            </p>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of PAN & TAN Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for PAN & TAN Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className="how-to-apply my-12 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Apply for PAN & TAN Registration</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
          </div>

          <div className='my-5'>
          <h6 className="text-blue-500 text-lg font-semibold"> Why Choose </h6>
          <ul className="list-disc">
              {whyData.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function TANTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        PAN (Permanent Account Number) and TAN (Tax Deduction and
              Collection Account Number) are crucial for exporters to manage
              their tax obligations and conduct business transactions. Our
              efficient registration process ensures that you get both numbers
              quickly and without hassle.
      </p>

      <p>Many exporters have streamlined their PAN and TAN registration
      with our expert help.</p>
    </>
  );
};

export default PANTANNavbar;
