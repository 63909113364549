import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import ScrollToTopButton from "./ScrollTop";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const ESIRegistration = ({ height }) => {
  // ESI Registration grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "What is ESI Registration?",
      description:
        "Employee State Insurance (ESI) is a self-financed social security scheme for Indian workers.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Who Needs ESI?",
      description:
        "Employers with more than 10 employees earning below a specified wage limit must register for ESI.",
    },
    {
      imagePath: "flexi.webp",
      title: "Key Features of ESI",
      description:
        "ESI provides medical and financial benefits to employees and their dependents.",
    },
    {
      imagePath: "limltd.webp",
      title: "Coverage",
      description:
        "ESI covers employees earning less than ₹21,000 per month in factories and establishments.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Legal Governance",
      description:
        "ESI is governed by the Employee State Insurance Act, 1948, ensuring mandatory coverage and contributions.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Medical Benefits -",
      description:
        "Provides comprehensive medical care to employees and their families.",
    },
    {
      imageright: "right.png",
      title: "Sickness Benefits -",
      description:
        "Offers cash benefits during medical leave due to illness or injury.",
    },
    {
      imageright: "right.png",
      title: "Maternity Benefits -",
      description:
        "Female employees can claim maternity leave and medical care.",
    },
    {
      imageright: "right.png",
      title: "Disability Benefits -",
      description:
        "Employees who are disabled due to injury or illness are supported with cash compensation.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Limited Eligibility -",
      description:
        "ESI is only available to employees earning below a specified wage limit.",
    },
    {
      imageright: "dangerous.webp",
      title: "Complex Compliance -",
      description:
        "Employers need to ensure correct filing and contributions, which adds to administrative burdens.",
    },
    {
      imageright: "dangerous.webp",
      title: "Limited Control -",
      description:
        "Employees may have to use the ESI hospitals or dispensaries, restricting their healthcare options.",
    },
    {
      imageright: "dangerous.webp",
      title: "Contribution Costs -",
      description:
        "Both employers and employees must contribute to the ESI fund, impacting payroll.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Employer Registration",
      description:
        "Employers need to register themselves under the Employee State Insurance Act with the necessary documents.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Employee Details",
      description:
        "The details of all employees, including their salaries and identification, must be provided.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Factory or Establishment License",
      description:
        "A valid license showing the nature of the business is required for ESI registration.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Address",
      description:
        "Documents verifying the address of the business or establishment must be submitted.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Contribution Details",
      description:
        "Employers need to provide details of contributions paid towards the ESI fund.",
    },
  ];

  const steps = [
    {
      title: "Obtain Employer Code",
      description:
        "The employer must apply for an Employer Code from the ESIC (Employee State Insurance Corporation).",
    },
    {
      title: "Employee Registration",
      description:
        "The employer registers all eligible employees for ESI, providing their details and contributions.",
    },
    {
      title: "Submit Documents",
      description:
        "Submit all required documents to the regional ESIC office, ensuring compliance with the law.",
    },
    {
      title: "Verification by ESIC",
      description:
        "ESIC officials review the documents and may inspect the premises for confirmation.",
    },
    {
      title: "Issue ESI Number",
      description:
        "Each employee is assigned a unique ESI number for future reference and benefits.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyESIData = [
    {
      id: 1,
      title: "Comprehensive Coverage",
      description:
        "Provides medical, financial, and social security benefits for employees.",
    },
    {
      id: 2,
      title: "Hassle-Free Registration",
      description:
        "Quick and easy registration process with minimal paperwork.",
    },
    {
      id: 3,
      title: "Government Approved",
      description:
        "Complies with all legal requirements and recognized by government authorities.",
    },
    {
      id: 4,
      title: "Affordable Premiums",
      description:
        "Low-cost premiums with extensive benefits for both employees and employers.",
    },
  ];

 
  

  return (
    <>
      <div className="sticky z-20" style={{ " top": "${height}px" }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="ESI Registration"
        ctaText="Get in touch"
        imagePath="./ESI.webp"
        children={<ESITo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            ESI Registration
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            A legal requirement for employers to provide medical and social
            security benefits to employees.
          </p>
        </div>
        <div className="mt-16 max-w-[972px] md:px-16 ">
          <div className=" pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is ESI Registration?
            </h6>

            <p className="mb-4">
              ESI registration ensures that employees have access to medical
              care and financial benefits in case of illness or injury.
            </p>
          </div>

          <div id="el" className=" my-12">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility for ESI Registration
            </h6>
            <p className="mb-4">
              All establishments with 10 or more employees must register for ESI
              if their employees' earnings fall below ₹21,000 per month.
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Features of ESI Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for ESI Registration
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              How to Register for ESI
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyESIData.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ScrollToTopButton />
    </>
  );
};

function ESITo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Secure health and social benefits for your employees through ESI
        registration under the ESIC.
      </p>

      <p>
        Ensure your organization complies with ESI regulations and provides
        essential medical coverage for your workforce.
      </p>
    </>
  );
}

export default ESIRegistration;
