import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const ITReturn2 = ({ height }) => {
  // IT-Return 2 grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Income from Capital Gains",
      description:
        "Declare your income from the sale of assets like stocks or property.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Foreign Income Reporting",
      description:
        "Report your income from any foreign sources or foreign assets.",
    },
    {
      imagePath: "flexi.webp",
      title: "Multiple Source Income",
      description:
        "Include income from salary, business, and multiple other sources.",
    },
    {
      imagePath: "limltd.webp",
      title: "House Property",
      description:
        "Declare your income from house property for tax computation.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Hassle-Free E-Filing",
      description: "File IT-Return 2 online with complete accuracy and ease.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Comprehensive Reporting -",
      description:
        "IT-Return 2 allows for reporting of capital gains and foreign income.",
    },
    {
      imageright: "right.png",
      title: "Multiple Income Sources -",
      description: "Ideal for those with diversified income streams.",
    },
    {
      imageright: "right.png",
      title: "Tax Benefits -",
      description:
        "Maximize deductions and minimize tax liability by declaring all sources of income.",
    },
    {
      imageright: "right.png",
      title: "Ease of Filing -",
      description: "File your return online with a step-by-step guide.",
    },
    {
      imageright: "right.png",
      title: "Avoid Legal Complications -",
      description:
        " Accurate reporting of income avoids legal issues and penalties.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "More Complex -",
      description:
        "IT-Return 2 requires detailed reporting of capital gains and foreign income.",
    },
    {
      imageright: "dangerous.webp",
      title: "Penalties -",
      description:
        "Failure to report income or filing late can result in heavy fines.",
    },
    {
      imageright: "dangerous.webp",
      title: "Time-Consuming -",
      description:
        "Gathering all necessary documents may take time and effort.",
    },
    {
      imageright: "dangerous.webp",
      title: "Documentation -",
      description:
        "Extensive documentation is required for reporting multiple income sources.",
    },
    {
      imageright: "dangerous.webp",
      title: "Tax Rules Complexity -",
      description:
        "Understanding ever-changing tax rules and regulations can be challenging.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Capital Gains Documents",
      description:
        "Documents related to the sale of assets like property or shares.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Form 16",
      description:
        "Salary certificate showing income and deductions from your employer.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Foreign Income Proof",
      description:
        "Proof of income earned from foreign sources, if applicable.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Property Documents",
      description: "Documents related to income from house property.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "TDS Certificates",
      description:
        "Certificates for tax deducted at source on income from various sources.",
    },
  ];

  const steps = [
    {
      title: "Collect All Necessary Documents",
      description:
        "Gather all documents related to capital gains, foreign income, salary, and house property.",
    },
    {
      title: "Calculate Income from All Sources",
      description:
        "Include income from salary, house property, capital gains, and other sources.",
    },
    {
      title: "Claim Applicable Deductions",
      description:
        "Maximize your tax savings by claiming all eligible deductions.",
    },
    {
      title: "Pay Applicable Taxes",
      description:
        "Ensure that you have paid all advance taxes due to avoid penalties.",
    },
    {
      title: "File IT-Return 2 Online",
      description:
        "Submit your income tax return online before the due date to avoid any late fees.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];
  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="IT-Return 2"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ItrTwo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div
          id="reg"
          className="flex flex-col w-full mt-16 items-center  "
        >
          <h6 className="text-2xl text-center font-semibold">
            IT-Return 2 Filing Made Easy
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our expert assistance helps you file IT-Return 2 seamlessly,
            ensuring all income sources are accurately reported, and eligible
            deductions are claimed for maximum tax savings.
          </p>
        </div>
          <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is IT-Return 2?
            </h6>

            <p className="mb-4">
              IT-Return 2 is for individuals who earn income from sources like
              capital gains, foreign assets, or multiple sources, including
              house property and salary. Filing ensures legal compliance and tax
              benefits.
            </p>
          </div>



          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements
            </h6>
            <p className="mb-4">
              To file IT-Return 2, the following documents and eligibility
              criteria are required:
              <ul>
                <li>
                  <strong>Capital Gains Documents -</strong> Proof of sale of
                  assets.
                </li>

                <li>
                  <strong>Foreign Income Proof -</strong> Income earned from
                  foreign assets or sources.
                </li>

                <li>
                  <strong>Property Documents -</strong> Proof of house property
                  income, if applicable.
                </li>

                <li>
                  <strong>PAN Card -</strong> Required for tax identification.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of IT-Return 2
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for IT-Return 2</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how"className="how-to-apply mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to File IT-Return 2</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose " className="why-choose my-10">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for Filing IT-Return 2?
            </h6>
            <p>
              Our team provides expert guidance and support to ensure seamless
              and accurate filing of IT-Return 2, maximizing tax savings and
              legal compliance.
            </p>
          </div>
        </div>
        </div>
    </>
  );
};

function ItrTwo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        File your IT-Return 2 accurately by declaring income from multiple
        sources, including capital gains, foreign assets, and house property.
      </p>
      <p>
        IT-Return 2 is ideal for individuals who earn income from various
        sources, including capital gains and foreign income. Our service ensures
        accurate filing, maximizing deductions and minimizing tax liabilities.
      </p>
    </>
  );
}

export default ITReturn2;
