import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "./LLPcard";
import "./LLPcardgrid.css";
import "./LLP.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const NRITaxationServicesNavbar = ({ height }) => {
  // NRI Taxation Services grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Income Tax Filing for NRIs",
      description:
        "Expert assistance in filing income tax returns for Non-Resident Indians, ensuring compliance with domestic and international regulations.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Tax Planning for NRIs",
      description:
        "Strategic tax planning to optimize tax liabilities and benefit from applicable deductions and exemptions for NRIs.",
    },
    {
      imagePath: "flexi.webp",
      title: "Investment Taxation Guidance",
      description:
        "Guidance on tax implications of investments made in India and abroad, including real estate, stocks, and mutual funds.",
    },
    {
      imagePath: "limltd.webp",
      title: "Double Taxation Avoidance Agreement (DTAA) Consultation",
      description:
        "Consultation on leveraging DTAA provisions to avoid double taxation on income earned in multiple countries.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Repatriation of Funds Assistance",
      description:
        "Assistance with the repatriation of funds from India to the NRI’s country of residence, ensuring compliance with regulatory requirements.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Expertise in NRI Regulations -",
      description:"In-depth knowledge of tax regulations affecting NRIs and their financial implications."
    },
    {
      imageright: "right.png",
      title:"Optimized Tax Liabilities -",
      description:"Tailored tax planning to minimize tax liabilities and maximize available benefits."
    },
    {
      imageright: "right.png",
      title:"Comprehensive Investment Guidance -",
      description:"Detailed advice on managing and taxing investments across different jurisdictions."
    },
    {
      imageright: "right.png",
      title:"DTAA Utilization -",
      description:"Effective use of DTAA provisions to avoid double taxation and streamline tax processes."
    },
    {
      imageright: "right.png",
      title:"Efficient Fund Repatriation -",
      description:"Assistance with the smooth repatriation of funds while ensuring regulatory compliance."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complex Regulations -",
      description:"Navigating NRI tax regulations can be complex due to varying rules across jurisdictions."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential for Misinterpretation -",
      description:"Incorrect understanding or application of tax laws may lead to compliance issues."
    },
    {
      imageright: "dangerous.webp",
      title:"Professional Fees -",
      description:"Engaging specialized tax services may involve additional costs."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport and Visa Details",
      description:
        "Copy of the passport and visa to confirm non-resident status and residency details.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Income Proof",
      description:
        "Documents proving income earned both in India and abroad, including salary slips, bank statements, and investment statements.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Previous Tax Returns",
      description:
        "Copies of previous years' tax returns for reference and continuity in tax filing.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Investment Documents",
      description:
        "Details of investments held in India and abroad, including property documents, mutual fund statements, and stock portfolios.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Repatriation Records",
      description:
        "Documentation related to repatriation of funds, including bank transfer receipts and related correspondence.",
    },
  ];

  const steps = [
    {
      title: "Gather Required Documents",
      description:
        "Collect all necessary documents including income proof, investment details, and previous tax returns.",
    },
    {
      title: "Review Tax Obligations",
      description:
        "Assess your tax obligations in India and your country of residence to identify applicable regulations and benefits.",
    },
    {
      title: "Develop Tax Strategy",
      description:
        "Create a tax strategy to optimize liabilities and ensure compliance with NRI tax laws and DTAA provisions.",
    },
    {
      title: "File Tax Returns",
      description:
        "Prepare and file income tax returns in accordance with Indian tax laws and international regulations.",
    },
    {
      title: "Repatriate Funds",
      description:
        "Assist with the repatriation of funds ensuring compliance with all regulatory requirements.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseNRITaxation = [
    {
      title: "Specialized Expertise",
      description: "Our team specializes in NRI taxation, understanding the unique complexities NRIs face with cross-border tax matters."
    },
    {
      title: "Compliance Assurance",
      description: "We ensure full compliance with Indian tax regulations, minimizing the risk of penalties or legal issues."
    },
    {
      title: "Optimized Tax Savings",
      description: "We work to maximize tax benefits, utilizing deductions and exemptions applicable to NRIs."
    },
    {
      title: "Clear Communication",
      description: "We provide straightforward guidance, keeping you informed and up-to-date with your tax obligations."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="NRI Taxation"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<NriTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Expert NRI Taxation Services
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our NRI taxation services provide specialized assistance to help
            Non-Resident Indians manage their tax obligations efficiently. From
            filing income tax returns to strategic tax planning, we ensure
            compliance and optimize your tax position.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What are NRI Taxation Services?
            </h6>

            <p className="mb-4">
              NRI taxation services encompass a range of solutions designed to
              address the unique tax needs of Non-Resident Indians. This
              includes assistance with income tax filing, tax planning,
              investment taxation, and repatriation of funds. Our services are
              tailored to ensure compliance with both Indian and international
              tax regulations.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for NRI Taxation Services
            </h6>
            <p className="mb-4">
              To benefit from our NRI taxation services, you need to provide the
              following:
              <ul>
                <li>
                  <strong>Passport and Visa Details -</strong> Copy of the
                  passport and visa to confirm non-resident status and residency
                  details.
                </li>

                <li>
                  <strong>Income Proof -</strong> Documents proving income
                  earned both in India and abroad, including salary slips, bank
                  statements, and investment statements.
                </li>

                <li>
                  <strong>Previous Tax Returns -</strong> Copies of previous
                  years' tax returns for reference and continuity in tax filing.
                </li>

                <li>
                  <strong>Investment Documents -</strong> Details of investments
                  held in India and abroad, including property documents, mutual
                  fund statements, and stock portfolios.
                </li>

                <li>
                  <strong>Repatriation Records -</strong> Documentation related
                  to repatriation of funds, including bank transfer receipts and
                  related correspondence.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="w-full  mt-10 pt-2">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Our NRI Taxation Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>


          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for NRI Taxation Services</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Set Up NRI Taxation Services</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseNRITaxation.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function NriTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Our NRI taxation services offer expert guidance on income tax filing, tax planning, and investment taxation for Non-Resident Indians. Benefit from our comprehensive solutions to ensure compliance and optimize your tax position.
      </p>
      <p>
      Navigate the complexities of NRI taxation with ease and efficiency through our specialized services.
      </p>
    </>
  );
}

export default NRITaxationServicesNavbar;
