import React from "react";

const FeatureCard = ({ imageUrl, title, para }) => {
  return (
    <div className="w-full max-w-[405px] rounded-lg bg-[#E3EEFB] p-[20px]">
      <img className="mb-[16px] w-10 h-10" src={imageUrl} alt="" />
      <h6 className="">{title}</h6>
      <p className="text-ellipsis">{para}</p>
    </div>
  );
};

export default FeatureCard;
