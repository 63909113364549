import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const TaxPlanningServicesNavbar = ({ height }) => {
  // Tax Planning Services grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Personalized Tax Strategies",
      description:
        "Receive tailored tax planning strategies to optimize your tax savings and reduce liabilities.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Tax Deduction Maximization",
      description:
        "Identify and maximize available tax deductions to minimize your taxable income.",
    },
    {
      imagePath: "flexi.webp",
      title: "Investment Planning",
      description:
        "Strategically plan your investments to leverage tax benefits and grow your wealth.",
    },
    {
      imagePath: "limltd.webp",
      title: "Retirement Planning",
      description:
        "Plan for your retirement with tax-efficient strategies to ensure a secure financial future.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Tax Compliance Guidance",
      description:
        "Stay compliant with tax regulations and avoid penalties through expert advice and support.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Customized Strategies -",
      description:"Tailor-made tax strategies to fit your unique financial situation and goals."
    },
    {
      imageright: "right.png",
      title:"Maximized Savings -",
      description:"Identify and utilize all available deductions to reduce your taxable income."
    },
    {
      imageright: "right.png",
      title:"Investment Benefits -",
      description:"Optimize your investments for tax benefits and growth potential."
    },
    {
      imageright: "right.png",
      title:"Retirement Security -",
      description:"Plan for a tax-efficient retirement to ensure long-term financial stability."
    },
    {
      imageright: "right.png",
      title:"Regulatory Compliance -",
      description:"Expert guidance to ensure compliance with tax laws and avoid penalties."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complexity -",
      description:"Tax planning can be complex and may require a thorough understanding of tax laws and regulations."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost -",
      description:"Professional tax planning services may involve additional costs."
    },
    {
      imageright: "dangerous.webp",
      title:"Dependence on Advice -",
      description:"Relying on expert advice means any errors could affect your tax situation."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Income Statements",
      description:
        "Provide detailed income statements from all sources including salary, investments, and other income.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Expense Records",
      description:
        "Document all personal and business-related expenses that may qualify for deductions.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Investment Records",
      description:
        "Records of all investments including stocks, bonds, and retirement accounts.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Returns",
      description:
        "Previous years' tax returns to review past filings and plan for future tax years.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Retirement Accounts",
      description:
        "Information on retirement accounts and plans to incorporate tax-efficient strategies.",
    },
  ];

  const steps = [
    {
      title: "Gather Financial Information",
      description:
        "Collect all relevant financial documents including income, expenses, and investments.",
    },
    {
      title: "Analyze Tax Situation",
      description:
        "Review your current tax situation and identify opportunities for optimization.",
    },
    {
      title: "Develop Tax Strategies",
      description:
        "Create personalized tax strategies to maximize savings and achieve financial goals.",
    },
    {
      title: "Implement Strategies",
      description:
        "Put the developed strategies into action and make necessary adjustments to your financial plan.",
    },
    {
      title: "Review and Monitor",
      description:
        "Regularly review and monitor your tax plan to ensure continued effectiveness and compliance.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];


  const whyChooseTaxPlanning = [
    {
      title: "Personalized Strategies",
      description: "We provide tailored tax strategies to fit your unique financial situation and goals."
    },
    {
      title: "Maximize Savings",
      description: "Our expert guidance ensures you take full advantage of deductions and credits to reduce your tax liability."
    },
    {
      title: "Up-to-Date Knowledge",
      description: "We stay informed about the latest tax laws and regulations, helping you remain compliant and benefit from new opportunities."
    },
    {
      title: "Comprehensive Support",
      description: "From tax planning to filing, we offer end-to-end support, ensuring your tax process is smooth and stress-free."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Expert Tax Planning Services for Individuals"
        ctaText="Get in touch"
        imagePath="./TaxIndi.webp"
        children={<PlanTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Optimize Your Tax Strategy
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Effective tax planning can significantly impact your financial
            well-being. Our services provide personalized strategies to help you
            minimize tax liabilities and maximize savings.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Tax Planning?
            </h6>

            <p className="mb-4">
              Tax planning involves the development of strategies to optimize
              your tax liabilities, maximize deductions, and ensure compliance
              with tax regulations. Our services help individuals create
              effective tax plans tailored to their financial situation and
              goals.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Tax Planning Services
            </h6>
            <p className="mb-4">
              To benefit from our tax planning services, you need to provide the
              following:
              <ul>
                <li>
                  <strong>Income Statements -</strong> Detailed statements of
                  all sources of income, including salary, investments, and
                  other earnings.
                </li>

                <li>
                  <strong>Expense Records -</strong> Documentation of personal
                  and business-related expenses that may qualify for deductions.
                </li>

                <li>
                  <strong>Investment Records -</strong> Records of all
                  investments including stocks, bonds, and retirement accounts.
                </li>

                <li>
                  <strong>Tax Returns -</strong> Previous years' tax returns for
                  review and planning purposes.
                </li>

                <li>
                  <strong>Retirement Accounts -</strong> Information on
                  retirement accounts and plans for tax-efficient strategies.
                </li>
              </ul>
            </p>
          </div>

          <div className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of Our Tax Planning Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Tax Planning Services</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Set Up Tax Planning Services</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseTaxPlanning.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
function PlanTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Our tax planning services offer personalized strategies to optimize your tax savings and ensure compliance with tax regulations. Whether you need help with investment planning, retirement strategies, or maximizing deductions, we provide comprehensive support for your financial success.
      </p>
      <p>
      Enhance your financial strategy and reduce tax liabilities with our expert tax planning services.
      </p>
    </>
  );
}

export default TaxPlanningServicesNavbar;
