import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HowToReg from "./llp/HowToReg";
import Documentsrr from "./llp/Documentsrr";

const PublicToPrivate = ({ height }) => {
  // Public Limited Company grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Raising Capital",
      description:
        "Ability to raise capital by issuing shares to the public through stock exchanges.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Limited Liability",
      description:
        "Provides limited liability protection to shareholders, safeguarding personal assets.",
    },
    {
      imagePath: "flexi.webp",
      title: "Market Presence",
      description:
        "Enhanced market presence and credibility due to public listing and regulatory compliance.",
    },
    {
      imagePath: "limltd.webp",
      title: "Share Liquidity",
      description:
        "Shares are more liquid and can be easily traded on stock exchanges.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Regulatory Oversight",
      description:
        "Subject to rigorous regulatory oversight, ensuring transparency and accountability.",
    },
  ];

  const advantages = [
    {
      imageright: "right.png",
      title: "Raising Capital",
      description:
        "A Public Limited Company can raise significant capital by offering shares to the public through stock exchanges.",
    },
    {
      imageright: "right.png",
      title: "Limited Liability -",
      description:
        "Shareholders enjoy limited liability, protecting their personal assets from company debts and liabilities.",
    },
    {
      imageright: "right.png",
      title: "Market Presence",
      description:
        "Enhanced visibility and credibility due to public listing and adherence to strict regulatory requirements.",
    },
    {
      imageright: "right.png",
      title: "Share Liquidity - ",
      description:
        "Shares of a public company are more liquid and can be traded easily on stock exchanges.",
    },
    {
      imageright: "right.png",
      title: "Regulatory Oversight -",
      description:
        "Subject to rigorous regulatory oversight, promoting transparency and good corporate governance.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title: "Regulatory Compliance",
      description:
        "Requires adherence to extensive regulatory and reporting requirements, which can be costly and time-consuming.",
    },
    {
      imageright: "dangerous.webp",
      title: "Public Scrutiny -",
      description:
        " Subject to increased public and media scrutiny, which can impact company reputation and operations.",
    },

    {
      imageright: "dangerous.webp",
      title: "Cost -",
      description:
        "Higher costs associated with compliance, reporting, and maintaining public company status.",
    },
    {
      imageright: "dangerous.webp",
      title: "Management Complexity",
      description:
        "Greater complexity in management due to larger scale and regulatory demands.",
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description:
        "Valid government-issued identity proofs of all directors and shareholders, such as Aadhar card or passport.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Address Proof",
      description:
        "Proof of the registered office address of the company, such as a utility bill or lease agreement.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Incorporation Documents",
      description:
        "Documents required for incorporation, including the Memorandum of Association (MOA) and Articles of Association (AOA).",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN and TAN",
      description:
        "Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the company.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Director Identification Number (DIN)",
      description: "DIN for all proposed directors of the company.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Share Capital Details",
      description:
        "Details regarding the share capital structure and number of shares to be issued.",
    },
  ];

  const steps = [
    {
      title: "Choose a Company Name",
      description:
        "Select a unique name for your company and ensure it complies with naming regulations.",
    },
    {
      title: "Prepare Documents",
      description:
        "Gather all required documents including identity proofs, address proofs, and incorporation documents.",
    },
    {
      title: "Register with ROC",
      description:
        "Submit the registration application along with required documents to the Registrar of Companies (ROC).",
    },
    {
      title: "Obtain PAN and TAN",
      description:
        "Apply for the Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the company.",
    },
    {
      title: "Share Capital and Listing",
      description:
        "Finalize share capital details and apply for listing on a stock exchange if required.",
    },
    {
      title: "Receive Certificate of Incorporation",
      description:
        "Upon successful verification, receive the Certificate of Incorporation from the ROC.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const elPoints = [
    {
      title: "Identity Proof -",
      description:
        "Provide valid government-issued identity proofs of all directors and shareholders, such as Aadhar card or passport.",
    },
    {
      title: "Address Proof -",
      description:
        "At least two designated partners must be individuals, and one must be an Indian resident.",
    },
    {
      title: "Incorporation Documents -",
      description:
        "Prepare necessary documents including the Memorandum of Association (MOA) and Articles of Association (AOA).",
    },
    {
      title: "PAN and TAN -",
      description:
        "Obtain the Permanent Account Number (PAN) and Tax Deduction and Collection Account Number (TAN) for the company.",
    },
    {
      title: "Director Identification Number (DIN) -",
      description: "Ensure all proposed directors have a valid DIN.",
    },
    {
      title: "Share Capital -",
      description:
        "Provide details regarding the share capital structure and the number of shares to be issued.",
    },
  ];

  const whyChooseItems = [
    {
      title: "Expert Guidance",
      description:
        "Our team of professionals provides personalized consultation, ensuring that you understand the legal and procedural requirements for converting your public company into a private limited company.",
    },
    {
      title: "Hassle-Free Documentation",
      description:
        "We assist in preparing and filing all necessary documents, from drafting resolutions to updating the company’s articles of association.",
    },
    {
      title: "Seamless Compliance Support",
      description:
        "We ensure compliance with all regulatory norms set by the Ministry of Corporate Affairs (MCA) and other relevant authorities, so you can focus on your business.",
    },
    {
      title: "Efficient Processing",
      description:
        "Our streamlined processes and prompt support ensure that your conversion is completed efficiently and without unnecessary delays.",
    },
    {
      title: "Post-Conversion Assistance",
      description:
        "We provide ongoing support even after the conversion, helping you navigate compliance requirements and maintain the private limited company’s status smoothly.",
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Public To Private Limited Company Registration"
        ctaText="Get in touch"
        imagePath="./LLP_Icon.webp"
        children={<PublicTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8 ">
        <div id="reg" className="LLPRegisflex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Establish Your Public Limited Company with Confidence
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Setting up a Public Limited Company offers numerous benefits
            including access to public capital markets and enhanced business
            credibility. We ensure a smooth and compliant registration process
            for your public company.
          </p>
        </div>

        <div className=" mt-16 max-w-[972px] md:px-16 ">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is a Public Limited Company?
            </h6>

            <p className="mb-4">
              A Public Limited Company is a type of business entity that offers
              its shares to the public through stock exchanges. It provides the
              benefit of raising substantial capital while limiting shareholder
              liability. This structure is ideal for larger businesses seeking
              to expand and attract public investment.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Registration
            </h6>
            <p className="mb-4">
              To register an LLP in India, you need to meet the following
              criteria:
            </p>
            <ul className="list-disc">
              {elPoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>


            <div className="mt-16 ">
              <h6 className="text-blue-500 text-lg font-semibold" id="features">
                Key Features
              </h6>
              <div className=" w-full grid  md:grid-cols-2 gap-6">
                {items.map((item) => (
                  <FeatureCard
                    imageUrl={item.imagePath}
                    title={item.title}
                    para={item.description}
                  />
                ))}
              </div>
            </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2>Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2>Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="Documents ">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for Public To Private
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

          <div id="how" className="Howto mt-16">
            <div className="registration-steps">
              <h2>How to Register</h2>
              <p>Follow these steps for a smooth registration process:</p>
              <div className=" w-full grid  md:grid-cols-2 gap-6">
                {steps.map((items, index) => (
                  <HowToReg
                    key={index}
                    index={index + 1}
                    title={items.title}
                    para={items.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why-choose" className="mb-16">
            <h3 className="text-blue-500 text-lg font-semibold">
              Why Choose T2F for Public to Private Limited Company Registration
            </h3>
            <ul className="list-disc pl-5">
              {whyChooseItems.map((item, index) => (
                <li key={index} className="mb-4">
                  <strong>{item.title}:</strong> {item.description}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function PublicTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Registering a Public Limited Company provides significant advantages
        such as the ability to raise capital through public offerings and
        limited liability protection. This structure is suitable for businesses
        seeking substantial growth and market presence. We guide you through the
        registration process to ensure your company complies with all regulatory
        requirements.
      </p>

      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
}

export default PublicToPrivate;
