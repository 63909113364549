import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const ForeignRegistration = ({ height }) => {
  // Foreign Registration grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Easy Process",
      description:
        "Streamlined process for foreign nationals to register businesses in the country.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Compliance Support",
      description:
        "Assistance with legal and regulatory compliance throughout the registration process.",
    },
    {
      imagePath: "flexi.webp",
      title: "Document Assistance",
      description:
        "Help with gathering and preparing the required documentation for registration.",
    },
    {
      imagePath: "limltd.webp",
      title: "Expert Guidance",
      description:
        "Professional advice on meeting all requirements and ensuring a smooth registration.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Timely Service",
      description:
        "Efficient handling of registration to minimize delays and expedite the process.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Streamlined Process -",
      description:"Simplified procedures for foreigners to register their businesses effectively."
    },
    {
      imageright: "right.png",
      title:"Compliance Support -",
      description:"Comprehensive support to ensure adherence to local regulations and legal requirements."
    },
    {
      imageright: "right.png",
      title:"Document Assistance -",
      description:"Help with collecting and preparing all necessary documents for smooth registration."
    },
    {
      imageright: "right.png",
      title:"Expert Guidance -",
      description:"Professional advice and assistance from experienced consultants throughout the registration process."
    },
    {
      imageright: "right.png",
      title:"Timely Service -",
      description:"Efficient handling of the registration process to avoid unnecessary delays."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complex Regulations -",
      description:"Navigating local regulations and requirements can be challenging for foreigners."
    },
    {
      imageright: "dangerous.webp",
      title:"Documentation -",
      description:"Gathering and preparing the required documents may be time-consuming."
    },
    {
      imageright: "dangerous.webp",
      title:"Cultural Differences -",
      description:"Understanding local business practices and cultural norms can be difficult."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost -",
      description:"Professional fees and associated costs may be higher for foreigners compared to local businesses."
    },
    {
      imageright: "dangerous.webp",
      title:"Legal Challenges -",
      description:"Potential legal hurdles or complications specific to foreign registrations."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Passport Copy",
      description:
        "A valid copy of the passport for identification and verification purposes.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Visa Status",
      description: "Proof of visa status or residence permit in the country.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Plan",
      description:
        "A detailed plan outlining the business objectives and operations.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Address",
      description:
        "Documentation verifying the address of the business and the foreign national.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Statement",
      description:
        "Recent bank statements showing financial stability and capacity for investment.",
    },
  ];

  const steps = [
    {
      title: "Gather Documents",
      description: "Collect all necessary documents required for registration.",
    },
    {
      title: "Submit Application",
      description:
        "Complete and submit the registration application along with the required documents.",
    },
    {
      title: "Verification Process",
      description:
        "Undergo the verification process by the relevant authorities.",
    },
    {
      title: "Receive Confirmation",
      description:
        "Obtain confirmation of the registration and official registration certificate.",
    },
    {
      title: "Start Operations",
      description:
        "Begin business operations once the registration is complete.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Registration for Foreigners"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ForeignTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Foreign Business Registration Made Easy
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our expert team offers comprehensive services for foreign nationals
            seeking to register their businesses in the country. From document
            assistance to compliance support, we ensure a smooth registration
            process.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Foreign Business Registration?
            </h6>

            <p className="mb-4">
              Foreign Business Registration allows non-residents to establish
              and operate a business within the country. Our services are
              designed to streamline the registration process, ensuring
              compliance with local laws and regulations while providing expert
              guidance throughout.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements
            </h6>
            <p className="mb-4">
              To register a business as a foreign national, you need to:
              <ul>
                <li>
                  <strong>Valid Passport -</strong> Provide a copy of your valid
                  passport for identification.
                </li>

                <li>
                  <strong>Visa Status -</strong> Proof of your visa status or
                  residence permit in the country.
                </li>

                <li>
                  <strong>Business Plan -</strong> A detailed business plan
                  outlining your objectives and operations.
                </li>

                <li>
                  <strong>Proof of Address -</strong> Documentation verifying
                  your address and that of the business.
                </li>
                <li>
                  <strong>Bank Statement -</strong> Recent bank statements to
                  demonstrate financial stability.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Foreign Business Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="docs-req mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 my-8">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Register Your Business</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose my-10">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for Your Foreign Business Registration?
            </h6>
            <p>
              Our dedicated team provides expert assistance with the
              registration process for foreign nationals. We offer personalized
              support to help you navigate the complexities of local regulations
              and ensure a successful registration.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

function ForeignTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Simplify the process of business registration in the country with our dedicated services for foreign nationals. We assist with every step to ensure a smooth and efficient registration.
      </p>
      <p>
      Our expert team provides comprehensive support for foreigners looking to establish their businesses locally, offering guidance and handling all necessary procedures.
      </p>
    </>
  );
}

export default ForeignRegistration;
