import React from 'react';
import './LLPcardgrid.css'; // Custom styling for card grid
import './LLP.css'; // Custom styling for the page
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const ProprietorshipToPrivateLimitedNavbar = ({ height }) => {

  // Features, advantages, and process items
  const items = [
    { 
      imagePath: "Grp.webp",
      title: 'Limited Liability', 
      description: 'Shareholders’ liability is limited to their shares in the company.' 
    },
    { 
      imagePath: "Costeff.webp",
      title: 'Separate Legal Entity', 
      description: 'The company is a separate legal entity from its owners.'
     },
    { 
      imagePath: "flexi.webp",
      title: 'Enhanced Credibility', 
      description: 'Greater credibility and trust among clients and partners.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'Ease of Raising Capital',
       description: 'Easier to raise capital through the sale of shares.' 
      },
    { 
      imagePath: "lessreg.webp",
      title: 'Structured Management', 
      description: 'Structured management with defined roles and responsibilities.'
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Limited Liability -",
      description:"Shareholders’ liability is limited to their shares in the company."
    },
    {
      imageright: "right.png",
      title:"Separate Legal Entity -",
      description:"The company is a separate legal entity from its owners.",
    },
    {
      imageright: "right.png",
      title:"Enhanced Credibility -",
      description:"Greater credibility and trust among clients and partners.",
    },
    {
      imageright: "right.png",
      title:"Ease of Raising Capital -",
      description:"Easier to raise capital through the sale of shares.",
    },
    {
      imageright: "right.png",
      title:"Structured Management -",
      description:"Structured management with defined roles and responsibilities.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Increased Compliance -",
      description:"More regulatory compliance and reporting requirements."
    },
    {
      imageright: "dangerous.webp",
      title:"Higher Costs  -",
      description:"Higher costs associated with incorporation and ongoing compliance."
    },
    {
      imageright: "dangerous.webp",
      title:"Complex Structure -",
      description:"More complex structure compared to a proprietorship firm."
    },
    {
      imageright: "dangerous.webp",
      title:"Formalities -",
      description:"Requires adherence to formalities for meetings, resolutions, and documentation."
    },
  ];

  const points =[
    {
      title:"Limited Liability:",
      description:"Shareholders’ liability is limited to their shares in the company."
    },
    {
      title:"Separate Legal Entity:",
      description:"The company is a separate legal entity from its owners."
    },
    {
      title:"Enhanced Credibility:",
      description:"Greater credibility and trust among clients and partners."
    },
    {
      title:"Ease of Raising Capital:",
      description:"Easier to raise capital through the sale of shares."
    },
    {
      title:"Structured Management:",
      description:"Structured management with defined roles and responsibilities."
    },
  ]

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proprietorship Registration Certificate",
      description: "Copy of the registration certificate of the proprietorship firm."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Address",
      description: "Proof of address of the registered office of the new company."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof of Proprietor",
      description: "Identity proof of the proprietor and proposed directors."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "Permanent Account Number (PAN) of the proprietor and proposed directors."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Shareholders Agreement",
      description: "Agreement detailing the rights and duties of shareholders."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Director’s Consent",
      description: "Written consent from individuals who will be appointed as directors."
    }
  ];

  const steps = [
    {
      title: "Obtain Digital Signature Certificates (DSC)",
      description: "Acquire DSCs for all the proposed directors of the company."
    },
    {
      title: "Obtain Director Identification Number (DIN)",
      description: "Apply for DIN for all the proposed directors."
    },
    {
      title: "Draft Company Documents",
      description: "Prepare the Memorandum of Association (MOA) and Articles of Association (AOA)."
    },
    {
      title: "File for Incorporation",
      description: "File an application for incorporation with the Ministry of Corporate Affairs (MCA)."
    },
    {
      title: "Obtain Certificate of Incorporation",
      description: "Receive the certificate of incorporation from the MCA."
    },
    {
      title: "Update Records",
      description: "Update all relevant records and documents with the new company details."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className='sticky z-20' style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title=" Conversion of Proprietorship Firm to Private Limited Company"
        ctaText="Get in touch"
        imagePath="./LLP_Icon.webp"
        children={<ConversionTo />}
      />

      <div className='mainLLP w-full flex flex-col px-3 md:px-8'>
        <div id="overview" className="LLPRegis mt-16 w-auto h-[15%] px-44 ">
          <h6 className='text-2xl text-center font-semibold'>Conversion of Proprietorship Firm to Private Limited Company</h6>
          <p className='text-center mt-4'>Converting a proprietorship firm to a Private Limited Company offers a range of advantages, including limited liability, increased credibility, and the ability to raise capital more effectively. T2F ensures a hassle-free conversion process with expert guidance at every step.</p>
        </div>

        <div className=' mt-16 max-w-[972px] md:px-16 '>

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is Conversion of Proprietorship Firm to Private Limited Company?</h6>

            <p className='mb-4'>Converting a proprietorship firm to a Private Limited Company involves transitioning from a sole proprietorship structure to a private limited company structure. This conversion allows for limited liability, enhanced credibility, and more options for raising capital.</p>
            <div>
            <ul className="list-disc">
              {points.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
            </div>
          </div>

          <div className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of Conversion</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className='text-blue-500 text-lg font-semibold' >Advantages</h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className='text-blue-500 text-lg font-semibold' >Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>


        

        <div id='documents' className='secondLLP pr-2  mt-16'>
        <div className="Documents ">
              <h6 className="text-blue-500 text-lg font-semibold">
                Documents Needed for Conversion 
              </h6>
              <p>Here’s what you’ll need:</p>
              <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
            </div>

          <div id='how' className='Howto mt-16'>
          <div className="registration-steps">
                <h2 className='text-blue-500 text-lg font-semibold' >How to Register</h2>
                <p >Follow these steps for a smooth registration process:</p>
                <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
              </div>
          </div>

          <div id='why-choose' className='Why mt-12'>
            <h6 className='text-blue-500 text-lg font-semibold'>Why Choose T2F for Your Conversion?</h6>
            <p>T2F is your trusted partner for converting your proprietorship firm to a Private Limited Company:</p>
            <ul >
              <li><strong>Expert Consultation - </strong> Personalized guidance to help you through the conversion process.</li>

              <li><strong>Documentation Assistance - </strong> Help with preparing and filing all necessary documents.</li>

              <li><strong>DSC and PAN Assistance -</strong> Facilitation of obtaining digital signatures and Permanent Account Number.</li>

              <li><strong>Compliance Support -</strong> Ensure adherence to legal and regulatory requirements.</li>

              <li><strong>Ongoing Support -</strong> Continued support throughout the conversion process and beyond.</li>
            </ul>
          </div>
        </div>
        </div>
      </div>
    </>
  );
};

function ConversionTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Converting your proprietorship firm to a Private Limited Company can offer significant benefits, including limited liability, enhanced credibility, and access to more funding opportunities. T2F provides expert assistance to make this transition seamless and compliant.
      </p>

      <p>10K+ Trusted Companies Successfully Registered</p>
    </>
  );
}

export default ProprietorshipToPrivateLimitedNavbar;
