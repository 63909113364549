import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import FeatureCard from "./FeatureCard";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
// import payroll from './servicesIMG/Payrollservices.webp'

const PayrollServicesNavbar = ({ height }) => {
  // Payroll Services grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Automated Payroll Processing",
      description:
        "Efficient and accurate payroll processing with automated systems to minimize errors and ensure timely payments.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Tax Compliance",
      description:
        "Ensure compliance with all tax regulations and timely submission of tax-related documents.",
    },
    {
      imagePath: "flexi.webp",
      title: "Employee Self-Service Portal",
      description:
        "Provide employees with a user-friendly portal to access pay slips, tax forms, and update personal information.",
    },
    {
      imagePath: "limltd.webp",
      title: "Customizable Payroll Reports",
      description:
        "Generate detailed and customizable payroll reports for better financial planning and analysis.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Dedicated Support",
      description:
        "Access to dedicated payroll support to handle queries and resolve issues promptly.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title: "Accuracy and Efficiency -",
      description:
        "Automated payroll systems reduce errors and ensure timely salary payments.",
    },
    {
      imageright: "right.png",
      title: "Compliance Assurance -",
      description:
        "Adhere to tax regulations and avoid penalties with accurate tax calculations and submissions.",
    },
    {
      imageright: "right.png",
      title: "Employee Convenience -",
      description:
        "Self-service portals allow employees to access their pay slips and tax forms easily.",
    },
    {
      imageright: "right.png",
      title: "Detailed Reporting -",
      description:
        "Customizable reports provide insights for better financial management and decision-making.",
    },
    {
      imageright: "right.png",
      title: "Expert Support -",
      description:
        "Dedicated payroll support helps resolve issues and answer queries quickly.",
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Initial Setup Costs -",
      description:"There may be initial costs associated with setting up automated payroll systems."
    },
    {
      imageright: "dangerous.webp",
      title:"System Dependence -",
      description:"Reliance on automated systems may require troubleshooting and regular updates."
    },
    {
      imageright: "dangerous.webp",
      title:"Data Security -",
      description:"Ensuring the security of payroll data is critical and requires robust measures to prevent breaches."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Employee Details",
      description:
        "Accurate details of all employees including personal information, bank details, and tax information.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Payroll Policies",
      description:
        "Documentation of company payroll policies including pay rates, benefits, and deductions.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Tax Registration Documents",
      description:
        "Documents related to tax registration and compliance for both the company and employees.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Information",
      description:
        "Company’s bank account details for processing payroll payments.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Contractual Agreements",
      description:
        "Employee contracts and agreements related to compensation and benefits.",
    },
  ];

  const steps = [
    {
      title: "Collect Employee Information",
      description:
        "Gather all necessary employee details and documentation for payroll processing.",
    },
    {
      title: "Set Up Payroll System",
      description:
        "Implement an automated payroll system tailored to your company’s requirements.",
    },
    {
      title: "Process Payroll",
      description:
        "Run payroll calculations and process payments according to the established schedule.",
    },
    {
      title: "Ensure Tax Compliance",
      description:
        "Verify tax calculations and ensure timely submission of tax-related documents.",
    },
    {
      title: "Generate Reports",
      description:
        "Create detailed payroll reports for financial planning and compliance purposes.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const whyChooseData = [
    {
      title: 'Accuracy and Compliance',
      description: 'Ensure precise calculations and compliance with the latest tax and employment regulations, minimizing the risk of errors and penalties.',
    },
    {
      title: 'Time Efficiency',
      description: 'Save valuable time by automating payroll processes, allowing your HR team to focus on more strategic tasks and business growth.',
    },
    {
      title: 'Data Security',
      description: 'Protect sensitive employee information with secure data handling practices and robust security protocols.',
    },
    {
      title: 'Employee Satisfaction',
      description: 'Deliver timely and accurate salary payments, enhancing employee trust and satisfaction within the organization.',
    },
    {
      title: 'Scalability',
      description: 'Adaptable solutions that grow with your business, whether you have a small team or a large workforce.',
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
            {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Comprehensive Payroll Services"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<PayrollTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Streamline Your Payroll Management
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Efficient payroll management is essential for any business. Our
            comprehensive payroll services provide automated processing, tax
            compliance, and expert support to ensure accurate and timely payroll
            management.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What are Payroll Services?
            </h6>

            <p className="mb-4">
              Payroll services involve managing employee compensation, including
              salary payments, tax calculations, and compliance with legal
              regulations. Our services ensure accurate and timely processing of
              payroll, along with expert support and customizable reports.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Payroll Services
            </h6>
            <p className="mb-4">
              To utilize our payroll services, you typically need to provide the
              following:
              <ul>
                <li>
                  <strong>Employee Details -</strong> Accurate personal and bank
                  details of all employees.
                </li>

                <li>
                  <strong>Payroll Policies -</strong> Documentation outlining
                  company payroll policies including pay rates and deductions.
                </li>

                <li>
                  <strong>Tax Registration Documents -</strong> Documents
                  related to tax registration and compliance.
                </li>

                <li>
                  <strong>Bank Account Information -</strong> Details of the
                  company’s bank account for payroll payments.
                </li>

                <li>
                  <strong>Contractual Agreements -</strong> Employee contracts
                  and agreements related to compensation.
                </li>
              </ul>
            </p>
          </div>

          <div className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of Our Payroll Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>
          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Payroll Services</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Set Up Payroll Services</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>
          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyChooseData.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function PayrollTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Our payroll services ensure that your employees are paid accurately and
        on time while maintaining compliance with tax regulations. We offer
        automated processing, tax compliance, and dedicated support to
        streamline your payroll management.
      </p>
      <p>
        From setting up your payroll system to generating detailed reports, we
        provide end-to-end solutions to meet your payroll needs.
      </p>
    </>
  );
}

export default PayrollServicesNavbar;
