import React from 'react';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const MSMENavbar = ({ height }) => {

  // MSME service grid items
  const items = [
    { 
      imagePath: "Grp.webp",
      title: 'Micro Enterprises',
    description: 'Businesses with investment up to ₹1 crore and turnover up to ₹5 crore.' 
    },
    { 
      imagePath: "Costeff.webp",
      title: 'Small Enterprises', 
      description: 'Businesses with investment up to ₹10 crore and turnover up to ₹50 crore.' 
    },
    { 
      imagePath: "flexi.webp",
      title: 'Medium Enterprises', 
      description: 'Businesses with investment up to ₹50 crore and turnover up to ₹250 crore.' 
    },
    { 
      imagePath: "limltd.webp",
      title: 'Easy Online Registration', 
      description: 'Simple and efficient online registration process through the Udyam portal.'
    },
    { 
      imagePath: "lessreg.webp",
      title: 'Government Benefits', description: 'Access to various government schemes and financial benefits.' },
  ];

  const advantages = [
    {
      imageright: "right.png",
      title:"Government Support -",
      description:"MSMEs are eligible for various government schemes and financial assistance."
    },
    {
      imageright: "right.png",
      title:"Priority Lending -",
      description:"Access to loans with lower interest rates."
    },
    {
      imageright: "right.png",
      title:"Tax Benefits -",
      description:"Avail tax exemptions and benefits under various government schemes."
    },
    {
      imageright: "right.png",
      title:"Protection Against Delayed Payments -",
      description:"Legal protection for delayed payments from buyers."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Limited Resources -",
      description:"Smaller scale businesses may face challenges in accessing resources."
    },
    {
      imageright: "dangerous.webp",
      title:"Compliance Requirements -",
      description:"Adhering to regulatory requirements can be challenging."
    },
    {
      imageright: "dangerous.webp",
      title:"Growth Constraints -",
      description:"Scaling up can be difficult due to limited resources and market constraints."
    },

  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Aadhaar Card",
      description: "Aadhaar card of the business owner is required for registration."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "Valid PAN card of the business or its owner is needed."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Address Proof",
      description: "Documents proving the address of the business such as utility bills or rental agreements."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Details",
      description: "Details of the business’s bank account for verification."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Investment and Turnover Details",
      description: "Financial documents showing the business’s investment and turnover figures."
    }
  ];

  const steps = [
    {
      title: "Provide Aadhaar Details",
      description: "The business owner must provide their Aadhaar number for registration."
    },
    {
      title: "Enter Business Details",
      description: "Provide details about the business such as PAN, location, and type of enterprise."
    },
    {
      title: "Submit Financial Information",
      description: "Enter information regarding investment and turnover for the business."
    },
    {
      title: "Complete Online Application",
      description: "Fill out and submit the application form online through the Udyam portal."
    },
    {
      title: "Receive Udyam Registration Certificate",
      description: "After successful submission, you will receive a Udyam Registration Number and certificate."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const elPoints = [
    {
      title: "Business Type -",
      description:"The business must be categorized as Micro, Small, or Medium based on investment and turnover.",
    },
    {
      title: "Aadhaar and PAN -",
      description:"Aadhaar and PAN details of the business owner or entity are required.",
    },
    {
      title: "Investment and Turnover -",
      description:"Accurate figures for business investment and turnover must be provided.",
    },
    {
      title: "Business Location -",
      description:"Provide proof of the business location and operational address.",
    },
  ];

  const whychoose = [
    {
      title: "Access to Government Schemes",
      description: "Registered MSMEs can avail various government schemes such as subsidies, loans, and tax benefits.",
    },
    {
      title: "Collateral-Free Loans",
      description: "MSMEs can obtain loans without any collateral under the Credit Guarantee Fund Scheme.",
    },
    {
      title: "Lower Interest Rates",
      description: "Businesses with MSME registration can enjoy lower interest rates on loans, improving cash flow.",
    },
    {
      title: "Protection Against Late Payments",
      description: "MSMEs get protection under the MSME Act to ensure timely payments from buyers, avoiding payment delays.",
    },
    {
      title: "ISO Certification Reimbursement",
      description: "Registered MSMEs can avail reimbursement for the cost incurred in obtaining ISO certification.",
    },
  ];
  
  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="MSME (Udyam) Registration"
        ctaText="Get in touch"
        imagePath="./MSME.webp"
        children={<MSMETo />}
      />

      <div className='w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className='text-2xl text-center font-semibold'>MSME Registration Made Easy</h6>
          <p className='max-w-[1086px] text-center mt-4'>Registering your business as an MSME under the Udyam scheme is simple and offers numerous benefits. We guide you through each step to ensure a smooth registration process.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16 ' >

          <div className=' pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is MSME?</h6>

            <p className='mb-4'>Micro, Small, and Medium Enterprises (MSMEs) play a critical role in the economic development of India. The Udyam Registration provides a streamlined process to avail of various benefits and support from the government.</p>
          </div>

          <div id="el" className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for MSME Registration</h6>
            <p className='mb-4'>To be eligible for MSME registration, the business must meet the following criteria:
            <ul className="list-disc">
              {elPoints.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
            </p>
          </div>

          <div className="mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 '>
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for MSME Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='how-to-apply mt-16'>
            <h2>Steps to Register for MSME (Udyam)</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
          </div>

          <div className='my-5'>
          <h6 className="text-blue-500 text-lg font-semibold"> Why Choose </h6>
          <ul className="list-disc">
              {whychoose.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>

    </>
  );
};

function MSMETo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        MSME (Udyam) Registration provides businesses with numerous benefits and access to government schemes.
      </p>

      <p>Thousands of MSMEs have successfully registered with us.</p>
    </>
  );
};

export default MSMENavbar;