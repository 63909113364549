import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Card from "./LLPcard";
import "./BackupCss.css";
import "./BackupCss1.css";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import HeroSection from "./HeroSection";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";
import FeatureCard from "./FeatureCard";

const DirectorChange = ({ height }) => {
  // Director Change grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "What is Director Change?",
      description:
        "Director change involves updating the board of directors of a company with the relevant authorities.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Why Change Directors?",
      description:
        "Director changes might be needed due to retirement, resignation, or appointment of new directors.",
    },
    {
      imagePath: "flexi.webp",
      title: "Key Features of Director Change",
      description:
        "Updating directors ensures accurate representation of the company’s leadership in official records.",
    },
    {
      imagePath: "limltd.webp",
      title: "Who Needs Director Change?",
      description:
        "Companies needing to update their board due to internal changes, legal requirements, or business strategy shifts.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Legal Requirements",
      description:
        "The change must be filed with the Registrar of Companies and updated in the company’s records.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title :"Updated Leadership -",
      description:"Reflects current leadership, ensuring accurate representation."
    },
    {
      imageright: "right.png",
      title :"Legal Compliance -",
      description:" Keeps the company in compliance with legal and regulatory requirements."
    },
    {
      imageright: "right.png",
      title :"Enhanced Management -",
      description:" Allows for strategic changes in management to align with business goals."
    },
    {
      imageright: "right.png",
      title :"Transparency -",
      description:" Provides clarity and transparency in the company's leadership structure."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Administrative Burden -",
      description:"The process can be complex and time-consuming."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Disruptions -",
      description:"Leadership changes might cause temporary disruptions in company operations."
    },
    {
      imageright: "dangerous.webp",
      title:"Legal Fees -",
      description:"There may be costs associated with filing and updating records."
    },
    {
      imageright: "dangerous.webp",
      title:"Transition Challenges -",
      description:"New directors might face a learning curve or integration challenges."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Board Resolution",
      description:
        "A board resolution approving the appointment or resignation of directors is required.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Form DIR-12",
      description:
        "Filing Form DIR-12 with the Registrar of Companies to notify about the director change.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Director's Consent",
      description:
        "Written consent from the new director accepting the appointment.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Resignation Letter",
      description:
        "If applicable, a resignation letter from the outgoing director must be provided.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Updated Certificates",
      description:
        "Updated company certificates reflecting the new board structure should be submitted.",
    },
  ];

  const steps = [
    {
      title: "Board Meeting",
      description:
        "Hold a board meeting to approve the director change and pass a resolution.",
    },
    {
      title: "Prepare Documents",
      description:
        "Prepare necessary documents, including Form DIR-12 and board resolutions.",
    },
    {
      title: "File with ROC",
      description:
        "Submit the documents and forms to the Registrar of Companies for approval.",
    },
    {
      title: "Update Records",
      description:
        "Update the company’s records and certificates to reflect the new directors.",
    },
    {
      title: "Notify Stakeholders",
      description:
        "Inform all relevant stakeholders, including employees and partners, about the director change.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const directorChange = [
    {
      title: "Regulatory Compliance",
      description: "We ensure that your director change is processed in compliance with all legal and regulatory requirements, minimizing risks."
    },
    {
      title: "End-to-End Support",
      description: "Our team provides comprehensive support throughout the director change process, from documentation to filing and beyond."
    },
    {
      title: "Expert Legal Advice",
      description: "Get expert legal guidance to navigate the complexities of appointing or removing directors, ensuring a smooth transition."
    },
    {
      title: "Quick Turnaround",
      description: "We expedite the entire process, ensuring the director change is completed swiftly, so your company operations are not disrupted."
    },
    {
      title: "Confidentiality",
      description: "We prioritize confidentiality to protect sensitive company and director information during the transition process."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title=" Director Change"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<DirectorTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className="text-2xl text-center font-semibold">
            Director Change
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Update your board of directors with our seamless process and ensure
            compliance with all legal requirements.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Director Change?
            </h6>

            <p className="mb-4">
              Director change refers to updating the board of directors of a
              company, including new appointments or resignations.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Who Needs Director Change?
            </h6>
            <p className="mb-4">
              Companies that need to update their board of directors due to
              internal changes, legal requirements, or business strategy
              adjustments.
            </p>
          </div>

          <div id="features" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Features of Director Change
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="w-full  mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Documents Required for Director Change
            </h6>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="w-full mt-10 pt-2">
            <h6 className="text-lg font-semibold text-blue-500">
              Process for Director Change
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {directorChange.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function DirectorTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Update your company’s board of directors efficiently with our
        expert support.
      </p>
      <p>
      Ensure your company's leadership records are current and compliant
      with regulatory requirements.
      </p>
    </>
  );
}


export default DirectorChange;
