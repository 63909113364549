import React from 'react'
import { Link } from 'react-router-dom'

const MyCard = ({heading,para , src,alt }) => {
  return (
    <div
        
        className='flex flex-row w-full md:w-[406px] h-[182px] p-2 mt-6 border-2 rounded-md shadow-md px-16 '>


        <div className='pt-6 md:px-6 '>
        <h4 style={{fontSize:'20px', lineHeight:'30px', fontWeight:'600'}}>
        {heading}
        </h4>
        <p style={{fontSize:"14px" , lineHeight:'22px', fontWeight:'400'}}>
        {para}
        </p>

      <Link to="/get-started" style={{textDecoration:'none'}}>Get started &gt;</Link>
        </div>

        <div className='w-[100px] h-[130px] bg-[#F1F8FE] rounded-full mt-6' style={{ display: "flex", flexDirection: "column",justifyContent:"center",alignItems:"center" }}>

          <div className='w-14 h-14'>
            <img className='h-full w-full ' src={src} alt={alt}/>
          </div>
        </div>
      </div>
  )
}

export default MyCard;
