import React from "react";
import { CARDS_DATA } from "../constants";
import Samcard from "./Samcard";

const MasterCard = () => {
  return (
    <>
      <div className="heading mt-16 sm:ml-[64px]">
        <h3 className="container-heading">
          One stop destination for all you
          <span className="highlight font-bold"> Business!</span>
        </h3>
      </div>
      <div className="w-full  grid grid-cols-1   xl:grid-cols-4 mb-2 mt-14 lg:w-full sm:px-4 md:px-16 md:gap-x-12 md:gap-y-1 2xl:gap-y-7">
        {CARDS_DATA.map((card) => (
          <Samcard
            key={card?.id}
            imgLink={card?.imgLink}
            para={card?.para}
            heading={card?.heading}
          />
        ))}
      </div>
    </>
  );
};

export default MasterCard;
