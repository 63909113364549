import React from 'react'

const Page3 = () => {
  return (
    <div className='div3 w-[80%] flex flex-col '>

      <div className='pp'>
    <span className='text-[26px] font-semibold'>TERMS OF SERVICE</span>
    <p>Last updated: 17-09-2024</p>


    
    </div>

    <div className='pp-c'>
      <h6>1.INTRODUCTION</h6>

      <p>Welcome to DialT2F! These Terms of Service govern your access to and use of the
DialT2F website and mobile applications provided by IIBS SECURITIES PRIVATE
LIMITED collaborated with RYCOON Financial Services Limited. By accessing or
using our Platform, you agree to comply with and be bound by these Terms. If you do
not agree to these Terms, you may not use the Platform.  </p>



      <h6 className='mt-2'>2. USE OF THE PLATFORM</h6>
      <p>2.1 Access: You must be at least 18 years old to use our Platform. By accessing or
      using the Platform, you represent and warrant that you are at least 18 years old.</p>


      <p>2.2 License: Subject to your compliance with these Terms, DialT2F grants you a
limited, non-exclusive, non-transferable, and revocable license to use the Platform
for your personal and non-commercial use.</p>


      <p>2.3 User Account: You may be required to create an account to access certain
features of the Platform. You agree to provide accurate and complete information
during the registration process and to update such information to keep it accurate
and current.:</p>

      <p>2.4 Prohibited Conduct: You agree not to engage in any of the following prohibited
activities: (a) use the Platform for any unlawful purpose or in violation of these
Terms; (b) attempt to gain unauthorized access to any portion or feature of the
Platform; (c) interfere with or disrupt the operation of the Platform or any servers or
networks connected to the Platform; (d) engage in any conduct that restricts or
inhibits any other person from using or enjoying the Platform; (e) reproduce, modify,
distribute, transmit, display, perform, publish, license, create derivative works from,
transfer, or sell any information, software, products, or services obtained from or
through the Platform.</p>

    
      <h6 className='mt-2'>3.PRIVACY POLICY</h6>
      <p>Your use of the Platform is also subject to our Privacy Policy, which explains how we
collect, use, and disclose your information. By using the Platform, you consent to the
collection, use, and disclosure of your information as described in the Privacy Policy.</p>

      <h6 className='mt-2'>4.INTELLECTUAL PROPERTY</h6>
      <p>4.1 Ownership: The Platform and its original content, features, and functionality are
and will remain the exclusive property of DialT2F and its licensors. The Platform is
protected by copyright, trademark, and other laws of both the India and foreign
countries.</p>

<p>4.2 Trademarks: &quot;DialT2F&quot; and other DialT2F graphics, logos, designs, page headers,
button icons, scripts, and service names are trademarks or trade dress of DialT2F.
DialT2F&#39;s trademarks and trade dress may not be used in connection with any
product or service without the prior written consent of DialT2F.</p>

  

        <h6 className='mt-2'>5.DISCLAIMER OF WARRANTIES</h6>
        <p>THE PLATFORM IS PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS,
WITHOUT ANY WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED.
DIALT2F DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING,
BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY,
FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>


        <h6 className='mt-2'>6.LIMITATION OF LIABILITY</h6>
        <p>IN NO EVENT SHALL DIALT2F, NOR ITS DIRECTORS, EMPLOYEES, AGENTS,
PARTNERS, OR SUPPLIERS, BE LIABLE UNDER CONTRACT, TORT, STRICT
LIABILITY, NEGLIGENCE, OR ANY OTHER LEGAL OR EQUITABLE THEORY
WITH RESPECT TO THE PLATFORM FOR ANY INDIRECT, INCIDENTAL,
CONSEQUENTIAL, SPECIAL, OR EXEMPLARY DAMAGES, INCLUDING,
WITHOUT LIMITATION, LOSS OF PROFITS, DATA, USE, GOODWILL, OR
OTHER INTANGIBLE LOSSES.</p>

        <h6 className='mt-2'>7. GOVERNING LAW</h6>
        <p>These Terms shall be governed by and construed in accordance with the laws of
India, without regard to its conflict of law principles. You agree to submit to the
exclusive jurisdiction of the courts located in Bangalore, Karnataka, India, to resolve
any disputes arising out of or related to these Terms or your use of the Platform.</p>

        
        <h6 className='mt-2'>8.CHANGES TO TERMS</h6>
        <p>DialT2F reserves the right to modify or replace these Terms at any time in its sole
discretion. If a revision is material, we will provide at least 30 days&#39; notice prior to
any new terms taking effect. What constitutes a material change will be determined
at our sole discretion.</p>

<h6 className='mt-2'>9..CONTACT US</h6>
        <p>If you have any questions about these Terms, please contact us at:</p>

      <p>IIBS SECURITIES PRIVATE LIMITED collaborated with RYCOON Financial
      Services Limited</p>

      <p>Address: D-24, Kalkaji, New Delhi, 110019</p>

      <p>Email: info@dialt2f.com</p>

        
    </div>
    </div>
  )
}

export default Page3

