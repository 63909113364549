import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const WindingUpLLP = ({ height }) => {
  // Winding Up of LLP grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Legal Compliance",
      description:
        "Ensure all legal requirements are met during the winding-up process.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Debt Settlement",
      description:
        "Proper settlement of all outstanding debts and obligations.",
    },
    {
      imagePath: "flexi.webp",
      title: "Asset Liquidation",
      description:
        "Efficient liquidation of company assets to pay off liabilities.",
    },
    {
      imagePath: "limltd.webp",
      title: "Regulatory Filings",
      description:
        "Complete all necessary regulatory filings and notifications.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Final Accounts Preparation",
      description:
        "Prepare and finalize accounts to conclude the LLP’s financial activities.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Compliance -",
      description:"Ensures all legal procedures and requirements are followed during the winding-up process."
    },
    {
      imageright: "right.png",
      title:"Debt Settlement -",
      description:"Effective settlement of all outstanding debts and obligations."
    },
    {
      imageright: "right.png",
      title:"Asset Liquidation -",
      description:"Efficient liquidation of company assets to pay off liabilities."
    },
    {
      imageright: "right.png",
      title:"Regulatory Filings -",
      description:"Ensures all required regulatory filings and notifications are completed."
    },
    {
      imageright: "right.png",
      title:"Final Accounts -",
      description:"Preparation and finalization of accounts to wrap up financial activities."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Complex Process -",
      description:"Winding up can be a complex and lengthy process involving various legal and financial steps."
    },
    {
      imageright: "dangerous.webp",
      title:"Cost -",
      description:"The process may involve costs for legal and accounting services."
    },
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming -",
      description:" It can be time-consuming, impacting the business's operations during the process."
    },
    {
      imageright: "dangerous.webp",
      title:"Regulatory Hurdles -",
      description:"There may be multiple regulatory requirements and paperwork to handle."
    },
    {
      imageright: "dangerous.webp",
      title:"Asset Realization -",
      description:"Liquidating assets may not always fetch the expected value, affecting the settlement process."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Resolution for Winding Up",
      description:
        "A formal resolution passed by the partners to initiate the winding-up process.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Financial Statements",
      description:
        "Recent financial statements to assess the company's financial position and liabilities.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "List of Creditors",
      description:
        "A comprehensive list of all creditors and outstanding obligations.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Asset Inventory",
      description:
        "An inventory of all company assets to be liquidated or distributed.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Regulatory Forms",
      description:
        "Completed forms required for regulatory filings and notifications.",
    },
  ];

  const steps = [
    {
      title: "Pass Winding Up Resolution",
      description:
        "The partners must pass a resolution to commence the winding-up process.",
    },
    {
      title: "Settle Debts and Liabilities",
      description:
        "Identify and settle all outstanding debts and obligations of the LLP.",
    },
    {
      title: "Liquidate Assets",
      description:
        "Sell or distribute the company’s assets to pay off liabilities.",
    },
    {
      title: "File Regulatory Forms",
      description:
        "Submit necessary regulatory forms and notifications to relevant authorities.",
    },
    {
      title: "Prepare Final Accounts",
      description:
        "Finalize and prepare the final accounts of the LLP to complete the winding-up process.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Winding Up of LLP"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<WindTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Efficient Winding Up of LLP
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            We provide comprehensive services to assist with the winding-up of
            LLPs, ensuring legal compliance, proper debt settlement, and
            efficient asset liquidation. Trust us to handle the complexities and
            streamline the process for you.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Winding Up of LLP?
            </h6>

            <p className="mb-4">
              Winding up of an LLP is the process of closing down the business,
              settling all debts, liquidating assets, and completing regulatory
              requirements. It involves several legal and financial steps to
              ensure the company is closed properly and all obligations are met.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements
            </h6>
            <p className="mb-4">
              To wind up an LLP, the following are required:
              <ul>
                <li>
                  <strong>Resolution for Winding Up -</strong> A formal
                  resolution passed by the partners to start the process.
                </li>

                <li>
                  <strong>Financial Statements -</strong> Current financial
                  statements to assess liabilities and assets.
                </li>

                <li>
                  <strong>List of Creditors -</strong> A comprehensive list of
                  creditors and outstanding obligations.
                </li>

                <li>
                  <strong>Asset Inventory -</strong> Detailed inventory of all
                  assets to be liquidated or distributed.
                </li>

                <li>
                  <strong>Regulatory Forms -</strong> Required forms for
                  regulatory filings and notifications.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className=" pr-2 mt-16">
            <h6 className="text-blue-500 text-lg font-semibold">
              Key Features of Winding Up of LLP
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="my-6">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>


          <div id="why" className="docs-req mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Winding Up of LLP</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Wind Up an LLP</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="why-choose my-10">
            <h6 className="text-blue-500 text-lg font-semibold ">
              Why Choose Us for Winding Up of LLP?
            </h6>
            <p>
              Our team provides expert assistance to ensure a smooth and
              compliant winding-up process. We manage legal compliance, debt
              settlement, asset liquidation, and regulatory filings to handle
              the complexities of winding up efficiently.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
function WindTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Efficiently wind up your LLP with our expert guidance. We handle legal compliance, debt settlement, asset liquidation, and regulatory filings to ensure a smooth and compliant winding-up process.
      </p>
      <p>
      Winding up an LLP involves several complex steps including legal compliance, settlement of debts, and liquidation of assets. Our services simplify this process, ensuring all requirements are met and the process is handled efficiently.
      </p>
    </>
  );
}

export default WindingUpLLP;
