import React from 'react';
import Card from './LLPcard';
import './LLPcardgrid.css';
import './LLP.css';
import HeroSection from './HeroSection';
import Disadvantages from './llp/Disadvantages';
import Advantages from './llp/Advantages';
import FeatureCard from './FeatureCard';
import Documentsrr from './llp/Documentsrr';
import HowToReg from './llp/HowToReg';

const IECNavbar = ({ height }) => {

  // IEC service grid items
  const items = [
    { imagePath: "Grp.webp", title: 'Import Authorization', description: 'Enables businesses to import goods and services into the country.' },
    {imagePath: "Costeff.webp", title: 'Export Authorization', description: 'Facilitates the export of goods and services from the country.' },
    {imagePath: "flexi.webp", title: 'Simplified Process', description: 'Streamlined application process for obtaining IEC with minimal documentation.' },
    {imagePath: "limltd.webp", title: 'Online Registration', description: 'Apply for IEC registration online through the DGFT portal.' },
    { imagePath: "lessreg.webp",title: 'Global Trade Opportunities', description: 'Unlocks opportunities for international trade and global market access.' },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Legal Authorization -",
      description:"Grants the legal right to import and export goods and services."
    },
    {
      imageright: "right.png",
      title:"No Renewal Required -",
      description:"Once obtained, the IEC does not require renewal."
    },
    {
      imageright: "right.png",
      title:"Online Application -",
      description:"Simple and efficient online application process."
    },
    {
      imageright: "right.png",
      title:"Trade Benefits -",
      description:"Access to various trade benefits and incentives from the government."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Compliance Requirements -",
      description:"Must adhere to various regulatory requirements and compliance norms."
    },
    {
      imageright: "dangerous.webp",
      title:"Documentation -",
      description:"Requires accurate documentation and adherence to trade regulations."
    },
    {
      imageright: "dangerous.webp",
      title:"Operational Costs -",
      description:"Potential costs related to international trade and compliance."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "PAN Card",
      description: "A valid PAN card of the business or its owner is required for registration."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Address Proof",
      description: "Documents proving the business address, such as utility bills or rental agreements."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Bank Account Details",
      description: "Details of the business’s bank account for verification purposes."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Certificate",
      description: "Proof of the business's legal registration, such as a certificate of incorporation."
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Identity Proof",
      description: "Identity proof of the business owner or authorized signatory."
    }
  ];

  const steps = [
    {
      title: "Obtain PAN Card",
      description: "Ensure that you have a valid PAN card for the business or owner."
    },
    {
      title: "Prepare Documents",
      description: "Gather all necessary documents required for IEC registration."
    },
    {
      title: "Apply Online",
      description: "Submit the application for IEC registration online through the DGFT portal."
    },
    {
      title: "Submit Application",
      description: "Submit the completed application along with required documents for verification."
    },
    {
      title: "Receive IEC Code",
      description: "After successful verification, receive the Import Export Code (IEC) issued by DGFT."
    }
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const iecBenefits = [
    {
      title: "Mandatory for Import and Export",
      description: "IEC (Import Export Code) registration is essential for businesses to engage in the import and export of goods and services legally.",
    },
    {
      title: "Global Market Expansion",
      description: "IEC registration enables businesses to expand their reach into international markets, increasing growth opportunities.",
    },
    {
      title: "Ease of International Transactions",
      description: "With an IEC, businesses can smoothly conduct cross-border transactions without complications, facilitating easier trading.",
    },
    {
      title: "Government Incentives",
      description: "Registered businesses can benefit from various government schemes and subsidies aimed at promoting export activities.",
    },
    {
      title: "No Renewal Required",
      description: "IEC registration is a one-time process with no need for renewal, providing businesses with long-term benefits without ongoing hassle.",
    },
  ];
  

  return (
    <>
      <div className='sticky z-20' style={{ "top": `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="IEC Registration"
        ctaText="Get in touch"
        imagePath="./IEC.webp"
        children={<ImportTo />}
      />

      <div className='w-full flex flex-col px-3 md:px-8 '>
        <div id="reg" className="flex flex-col w-full mt-16 items-center ">
          <h6 className='text-2xl text-center font-semibold'>IEC Registration Made Simple</h6>
          <p className='max-w-[1086px] text-center mt-4'>Obtaining an Import Export Code (IEC) is straightforward and crucial for international trade. We assist you in every step to ensure a hassle-free registration process.</p>
        </div>

        <div className='mt-16 max-w-[972px] md:px-16' >

          <div className='pr-2 pt-5 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>What is IEC?</h6>

            <p className='mb-4'>The Import Export Code (IEC) is a unique code issued by the Directorate General of Foreign Trade (DGFT) that allows businesses to engage in international trade. It is essential for exporting and importing goods and services.</p>
          </div>

          <div id="el" className=' pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold'>Eligibility and Requirements for IEC Registration</h6>
            <p className='mb-4'>To be eligible for IEC registration, the business must meet the following criteria:

              <ul>
                <li><strong>Business Type -</strong> The applicant must be a registered business entity involved in international trade.</li>

                <li><strong>PAN Card -</strong> A valid PAN card of the business or its owner is required.</li>

                <li><strong>Business Address Proof -</strong> Provide proof of the business address, such as utility bills or rental agreements.</li>

                <li><strong>Bank Account Details -</strong> Details of the business’s bank account for verification purposes.</li>
              </ul>
            </p>
          </div>

          <div className='pr-2 mt-16 ' >
            <h6 className='text-blue-500 text-lg font-semibold' id="features">Key Features of IEC Registration</h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">Advantages</h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">Disadvantages</h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className='why-choose mt-16 '>
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for IEC Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
                {documents.map((docitem, index) => (
                  <Documentsrr
                    key={index}
                    imageDoc={docitem.imageDoc}
                    imageDoc2={docitem.imageDoc2}
                    title={docitem.title}
                    para={docitem.description}
                  />
                ))}
              </div>
          </div>

          <div id="how" className='how-to-apply mt-12 '>
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Register for IEC</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
                  {steps.map((items, index) => (
                    <HowToReg
                      key={index}
                      index={index + 1}
                      title={items.title}
                      para={items.description}
                    />
                  ))}
                </div>
          </div>

          <div className='my-5'>
          <h6 className="text-blue-500 text-lg font-semibold"> Why Choose </h6>
          <ul className="list-disc">
              {iecBenefits.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};
function ImportTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        IEC Registration is essential for businesses involved in international trade, providing the legal authorization to import and export goods and services.
      </p>

      <p>Thousands of businesses have successfully obtained their IEC with us.</p>
    </>
  );
};

export default IECNavbar;