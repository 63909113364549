import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const CompanyShareTransferNavbar = ({ height }) => {
  // Company Share Transfer grid items
  const items = [
    {
      imagePath: "Grp.webp",
      title: "Understanding Share Transfer",
      description:
        "Learn about the process and regulations for transferring shares in a company, including types of share transfers and legal requirements.",
    },
    {
      imagePath: "Costeff.webp",
      title: "Preparing for Transfer",
      description:
        "Guidance on gathering necessary documentation and preparing for a smooth share transfer process.",
    },
    {
      imagePath: "flexi.webp",
      title: "Executing the Transfer",
      description:
        "Steps involved in executing the share transfer, including legal formalities and documentation.",
    },
    {
      imagePath: "limltd.webp",
      title: "Updating Company Records",
      description:
        "Support in updating company records and ensuring compliance with legal and regulatory requirements post-transfer.",
    },
    {
      imagePath: "lessreg.webp",
      title: "Handling Disputes",
      description:
        "Assistance in resolving any disputes or issues that may arise during the share transfer process.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Expert Guidance -",
      description:"Professional assistance to navigate the complexities of share transfers."
    },
    {
      imageright: "right.png",
      title:"Comprehensive Support -",
      description:"Help with documentation, execution, and compliance."
    },
    {
      imageright: "right.png",
      title:"Legal Compliance -",
      description:" Ensuring all legal requirements are met for a smooth transfer."
    },
    {
      imageright: "right.png",
      title:"Efficient Process -",
      description:" Streamlined process to minimize delays and errors."
    },
    {
      imageright: "right.png",
      title:"Peace of Mind -",
      description:"  Reducing stress and uncertainty during the share transfer."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title:"Time-Consuming -",
      description:"The share transfer process can be lengthy and require detailed documentation."
    },
    {
      imageright: "dangerous.webp",
      title:"Potential Costs -",
      description:"Professional fees and other costs associated with the transfer."
    },
    {
      imageright: "dangerous.webp",
      title:"Complexity -",
      description:"Handling share transfers can be complex, especially with legal and regulatory requirements."
    }
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Share Transfer Agreement",
      description:
        "The agreement outlining the terms and conditions of the share transfer.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Share Certificate",
      description: "The certificate representing the shares being transferred.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Board Resolution",
      description:
        "Resolution passed by the board of directors approving the share transfer.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Identity",
      description: "Proof of identity for both the transferor and transferee.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Form of Transfer",
      description:
        "The prescribed form for transferring shares, typically signed by both parties.",
    },
  ];

  const steps = [
    {
      title: "Review Transfer Agreement",
      description:
        "Examine the share transfer agreement to ensure all terms and conditions are clear and agreed upon.",
    },
    {
      title: "Prepare Documentation",
      description:
        "Gather all necessary documents, including share certificates, board resolutions, and proof of identity.",
    },
    {
      title: "Execute Transfer",
      description:
        "Complete the share transfer process by signing required documents and updating company records.",
    },
    {
      title: "Update Records",
      description:
        "Ensure company records are updated to reflect the new shareholder information.",
    },
    {
      title: "Handle Disputes",
      description:
        "Address any disputes or issues that arise during or after the share transfer process.",
    },
  ];
  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];

  const shareTran = [
    {
      title: "Legal Compliance",
      description: "Ensure your share transfer process adheres to all regulatory requirements, avoiding potential legal pitfalls."
    },
    {
      title: "Streamlined Documentation",
      description: "Our services include handling all necessary paperwork and documentation, making the process efficient and stress-free."
    },
    {
      title: "Expert Consultation",
      description: "Get guidance from our experienced legal and financial consultants to ensure a smooth and secure transfer of shares."
    },
    {
      title: "Confidential Process",
      description: "We ensure complete confidentiality throughout the process to protect sensitive information about your company and its shareholders."
    },
    {
      title: "Timely Execution",
      description: "Our team is committed to completing the share transfer within a defined timeline, ensuring no delays in your business transactions."
    }
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%]">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline">
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Company Share Transfer"
        ctaText="Get in touch"
        imagePath="./TradeSe.webp"
        children={<ShareTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Company Share Transfer
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Our services for company share transfer provide comprehensive
            support to ensure the process is handled efficiently and in
            compliance with all legal requirements.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16">
          <div className="pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Company Share Transfer?
            </h6>

            <p className="mb-4">
              Company share transfer refers to the process of transferring
              ownership of shares from one person or entity to another within a
              company. This involves legal formalities and documentation to
              ensure the transfer is valid and compliant with company laws and
              regulations.
            </p>
          </div>

          <div id="el" className="pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Share Transfer
            </h6>
            <p className="mb-4">
              To transfer company shares, the following requirements and
              documents are necessary:
              <ul>
                <li>
                  <strong>Share Transfer Agreement -</strong> The agreement
                  outlining the terms and conditions of the share transfer.
                </li>

                <li>
                  <strong>Share Certificate -</strong> The certificate
                  representing the shares being transferred.
                </li>

                <li>
                  <strong>Board Resolution -</strong> Resolution passed by the
                  board of directors approving the share transfer.
                </li>

                <li>
                  <strong>Proof of Identity -</strong> Proof of identity for
                  both the transferor and transferee.
                </li>

                <li>
                  <strong>Form of Transfer -</strong> The prescribed form for
                  transferring shares, typically signed by both parties.
                </li>
              </ul>
            </p>
          </div>

          <div id="features" className="llp-card-grid flex flex-wrap">
            <h6 className="text-blue-500 text-lg font-semibold text-center">
              Key Features of Our Share Transfer Services
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Share Transfer</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Transfer Company Shares</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {shareTran.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function ShareTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Transferring shares in a company involves a legal process to change the ownership of shares from one person to another. Our services offer expert assistance to ensure the transfer is executed smoothly and in compliance with all legal requirements.
      </p>
      <p>
      Get expert help with your company share transfer to ensure a hassle-free process and compliance with regulations.
      </p>
    </>
  );
}

export default CompanyShareTransferNavbar;
