import React from "react";

const HowToReg = ({ title, para, index }) => {
  return (
    <div className="w-full max-w-[405px] h-[136px] border-1 border-[#D1D1D1] rounded-lg  p-[20px]">
      <div className="flex gap-2">
        <div className="flex items-center justify-center w-[24px] h-[24px] border rounded-full text-center">
          <span className="font-semibold text-sm  text-[#125B9A]">
            {index}
          </span>
        </div>
        <div className="flex-1">
          <h6 className="font-semibold text-sm text-[#125B9A]">{title}</h6>
          <p className="font-normal text-sm ">{para}</p>
        </div>
      </div>
    </div>
  );
};

export default HowToReg;
