import React from "react";
import "./LLPcardgrid.css";
import "./LLP.css";
import HeroSection from "./HeroSection";
import Disadvantages from "./llp/Disadvantages";
import Advantages from "./llp/Advantages";
import FeatureCard from "./FeatureCard";
import Documentsrr from "./llp/Documentsrr";
import HowToReg from "./llp/HowToReg";

const UdyamNavbar = ({ height }) => {
  // Udyam Registration service grid items
  const items = [
    {imagePath: "Grp.webp",
      title: "Simplified Process",
      description: "Easy online registration process for Udyam Certification.",
    },
    {imagePath: "Costeff.webp",
      title: "Government Recognition",
      description: "Recognized by the Government of India for MSME benefits.",
    },
    {imagePath: "flexi.webp",
      title: "Access to Subsidies",
      description:
        "Eligibility for various government subsidies and incentives.",
    },
    {imagePath: "limltd.webp",
      title: "No Renewal Required",
      description: "Permanent registration with no need for periodic renewals.",
    },
    {imagePath: "lessreg.webp",
      title: "Support for MSMEs",
      description:
        "Supports small and medium enterprises with growth and compliance.",
    },
  ];

  // Advantages and Disadvantages
  const advantages = [
    {
      imageright: "right.png",
      title:"Government Recognition -",
      description:"Official recognition by the Government of India, which can enhance credibility."
    },
    {
      imageright: "right.png",
      title:"Subsidies and Incentives -",
      description:"Access to various government subsidies and incentives for MSMEs."
    },
    {
      imageright: "right.png",
      title:"Simplified Process -",
      description:"Streamlined online registration process for ease of application."
    },
    {
      imageright: "right.png",
      title:"Permanent Registration -",
      description:"No need for periodic renewals, ensuring ongoing validity."
    },
  ];

  const disadvantages = [
    {
      imageright: "dangerous.webp",
      title :"Limited to MSMEs -",
      description:"Only applicable to micro, small, and medium enterprises."
    },
    {
      imageright: "dangerous.webp",
      title :"Eligibility Criteria -",
      description:"Must meet specific criteria to qualify as an MSME."
    },
    {
      imageright: "dangerous.webp",
      title :"Document Requirements -",
      description:"Requires submission of various documents, which can be cumbersome for some."
    },
  ];

  const documents = [
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business PAN Card",
      description:
        "A valid PAN card of the business is required for registration.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Proof of Business Address",
      description:
        "Submit proof of the business address such as a utility bill or lease agreement.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Bank Account Details",
      description: "Bank account details of the business need to be provided.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Business Registration Proof",
      description:
        "Documents related to the business registration, such as the Certificate of Incorporation.",
    },
    {
      imageDoc: "docu.webp",
      imageDoc2: "docu2.png",
      title: "Aadhar Card of Proprietor/Partner",
      description:
        "A valid Aadhar card of the business proprietor or partner is needed for verification.",
    },
  ];

  const steps = [
    {
      title: "Gather Documents",
      description:
        "Collect all necessary documents required for Udyam Registration.",
    },
    {
      title: "Apply Online",
      description:
        "Submit the Udyam Registration application online through the Udyam Registration portal.",
    },
    {
      title: "Verification",
      description:
        "Complete any verification processes required by the authorities.",
    },
    {
      title: "Receive Certificate",
      description:
        "After successful verification, receive your Udyam Registration Certificate.",
    },
    {
      title: "Utilize Benefits",
      description:
        "Access various government subsidies and benefits available to registered MSMEs.",
    },
  ];

  const navLink = [
    { name: "Overview", link: "#reg" },
    { name: "Eligibility", link: "#el" },
    { name: "Features", link: "#features" },
    { name: "Pros/Cons", link: "#pro/con" },
    { name: "Documentation", link: "#why" },
    { name: "Registration", link: "#how" },
    { name: "Why choose?", link: "#choose" },
  ];
  
  const whyUdyamData = [
    {

      title: "Legal Recognition",
      description: "Get your business recognized by the government as a registered MSME.",
    },
    {
      title: "Easy Loan Approvals",
      description: "Enjoy faster and easier loan approvals with lower interest rates.",
    },
    {
      title: "Subsidies and Incentives",
      description: "Access various government subsidies, schemes, and incentives.",
    },
    {
      title: "Enhanced Business Opportunities",
      description: "Increase your business credibility and opportunities by getting registered.",
    },
    {
      title: "Expert Support",
      description: "Guidance and support throughout the registration process.",
    },
  ];

  return (
    <>
      <div className="sticky z-20" style={{ top: `${height}px` }}>
        <nav className="bg-blue-900 h-[15%] ">
          <div className="flex flex-row flex-wrap justify-around items-center">
          {navLink.map((point) => (
              <a
                href={point.link}
                className="text-white text-center rounded-md flex-row text-md font-medium hover:bg-blue-700 no-underline"
              >
                {point.name}
              </a>
            ))}
          </div>
        </nav>
      </div>

      <HeroSection
        title="Udyam Registration"
        ctaText="Get in touch"
        imagePath="./Udhyamregis.webp"
        children={<UdhyamTo />}
      />

      <div className="w-full flex flex-col px-3 md:px-8">
        <div id="reg" className="flex flex-col w-full mt-16 items-center">
          <h6 className="text-2xl text-center font-semibold">
            Udyam Registration Made Simple
          </h6>
          <p className="max-w-[1086px] text-center mt-4">
            Udyam Registration is a key step for MSMEs to gain recognition and
            access various government benefits. We provide a streamlined process
            to help you complete your registration efficiently.
          </p>
        </div>

        <div className="mt-16 max-w-[972px] md:px-16 ">
          <div className=" pr-2 pt-5 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              What is Udyam Registration?
            </h6>

            <p className="mb-4">
              Udyam Registration is a government registration program for micro,
              small, and medium enterprises (MSMEs) in India. It provides
              official recognition to businesses, enabling them to access
              various government schemes, subsidies, and incentives aimed at
              supporting their growth and development.
            </p>
          </div>

          <div id="el" className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold">
              Eligibility and Requirements for Udyam Registration
            </h6>
            <p className="mb-4">
              To apply for Udyam Registration, you need to meet the following
              requirements:
              <ul>
                <li>
                  <strong>Business PAN Card -</strong> A valid PAN card of the
                  business is required.
                </li>

                <li>
                  <strong>Proof of Business Address -</strong> Submit proof of
                  the business address, such as a utility bill or lease
                  agreement.
                </li>

                <li>
                  <strong>Business Bank Account Details -</strong> Provide
                  details of the business bank account.
                </li>

                <li>
                  <strong>Business Registration Proof -</strong> Include
                  documents related to business registration, such as the
                  Certificate of Incorporation.
                </li>

                <li>
                  <strong>Aadhar Card of Proprietor/Partner -</strong> A valid
                  Aadhar card of the business proprietor or partner is needed.
                </li>
              </ul>
            </p>
          </div>

          <div className=" pr-2 mt-16 ">
            <h6 className="text-blue-500 text-lg font-semibold" id="features">
              Key Features of Udyam Registration
            </h6>
            <div className=" w-full grid  md:grid-cols-2 gap-6">
              {items.map((item) => (
                <FeatureCard
                  imageUrl={item.imagePath}
                  title={item.title}
                  para={item.description}
                />
              ))}
            </div>
          </div>

          <div id="pro/con" className="Advan  mt-16">
            <h2m className="text-blue-500 text-lg font-semibold">
              Advantages
            </h2m>
            <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
              {advantages.map((ticpoint, index) => (
                <Advantages
                  key={index}
                  imagePrl={ticpoint.imageright}
                  title={ticpoint.title}
                  para={ticpoint.description}
                />
              ))}
            </div>
            <div className="">
              <h2 className="text-blue-500 text-lg font-semibold">
                Disadvantages
              </h2>
              <div className="flex flex-col gap-[16px] px-9 py-8 border border-[#d1d1d1] rounded-lg">
                {disadvantages.map((dicpoint, index) => (
                  <Disadvantages
                    key={index}
                    imageSrl={dicpoint.imageright}
                    title={dicpoint.title}
                    para={dicpoint.description}
                  />
                ))}
              </div>
            </div>
          </div>

          <div id="why" className="why-choose mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Documents Required for Udyam Registration</h2>
            <div className=" w-full py-12 grid  md:grid-cols-3  gap-x-3 gap-y-16">
              {documents.map((docitem, index) => (
                <Documentsrr
                  key={index}
                  imageDoc={docitem.imageDoc}
                  imageDoc2={docitem.imageDoc2}
                  title={docitem.title}
                  para={docitem.description}
                />
              ))}
            </div>
          </div>

          <div id="how" className="how-to-apply mt-16 ">
            <h2 className="text-blue-500 text-lg font-semibold">Steps to Apply for Udyam Registration</h2>
            <div className=" w-full grid  md:grid-cols-2 gap-6 my-4">
              {steps.map((items, index) => (
                <HowToReg
                  key={index}
                  index={index + 1}
                  title={items.title}
                  para={items.description}
                />
              ))}
            </div>
          </div>

          <div id="choose" className="my-5">
            <h6 className="text-blue-500 text-lg font-semibold">
              {" "}
              Why Choose{" "}
            </h6>
            <ul className="list-disc">
              {whyUdyamData.map((item) => (
                <li>
                  <p>
                    <strong>{item.title}:</strong>
                    {item.description}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

function UdhyamTo() {
  return (
    <>
      <p
        style={{
          marginTop: "10px",
          fontWeight: "500",
          fontSize: "18px",
          lineHeight: "30px",
        }}
      >
        Udyam Registration is a government initiative aimed at supporting
              and recognizing micro, small, and medium enterprises (MSMEs) in
              India. This registration helps businesses access various benefits
              and subsidies, making the process of establishing and growing a
              business more manageable.
      </p>
      <p>
      Thousands of businesses have benefited from Udyam Registration
      with our assistance.
      </p>
    </>
  );
}

export default UdyamNavbar;
